import {
  IDictionaryAccountStatusesData,
  IDictionaryCountriesData,
  IDictionaryCurrenciesData,
  IDictionaryCustomerStatusesData,
  IDictionaryEndpointTypesData,
  IDictionaryEventDataElemsData,
  IDictionaryEventsData,
  IDictionaryInterfaceTypesData,
  IDictionaryRepaymentMethodsData,
  IDictionaryRepaymentTypesData,
  IDictionaryTransactionTypesData,
} from './types';

import { TApiResponse } from 'types';

export enum ActionTypeKeys {
  /** Account statuses action type keys */
  GET_DICTIONARY_ACCOUNT_STATUSES = 'dictionaries/GET_DICTIONARY_ACCOUNT_STATUSES',
  GET_DICTIONARY_ACCOUNT_STATUSES_FULFILLED = 'dictionaries/GET_DICTIONARY_ACCOUNT_STATUSES_FULFILLED',
  GET_DICTIONARY_ACCOUNT_STATUSES_REJECTED =
    'dictionaries/GET_DICTIONARY_ACCOUNT_STATUSES_REJECTED',

  /** Customer statuses action type keys */
  GET_DICTIONARY_CUSTOMER_STATUSES = 'dictionaries/GET_DICTIONARY_CUSTOMER_STATUSES',
  GET_DICTIONARY_CUSTOMER_STATUSES_FULFILLED = 'dictionaries/GET_DICTIONARY_CUSTOMER_STATUSES_FULFILLED',
  GET_DICTIONARY_CUSTOMER_STATUSES_REJECTED = 'dictionaries/GET_DICTIONARY_CUSTOMER_STATUSES_REJECTED',

  /** Endpoint types action type keys */
  GET_DICTIONARY_ENDPOINT_TYPES = 'dictionaries/GET_DICTIONARY_ENDPOINT_TYPES',
  GET_DICTIONARY_ENDPOINT_TYPES_FULFILLED = 'dictionaries/GET_DICTIONARY_ENDPOINT_TYPES_FULFILLED',
  GET_DICTIONARY_ENDPOINT_TYPES_REJECTED = 'dictionaries/GET_DICTIONARY_ENDPOINT_TYPES_REJECTED',

  /** Interface types action type keys */
  GET_DICTIONARY_INTERFACE_TYPES = 'dictionaries/GET_DICTIONARY_INTERFACE_TYPES',
  GET_DICTIONARY_INTERFACE_TYPES_FULFILLED =
    'dictionaries/GET_DICTIONARY_INTERFACE_TYPES_FULFILLED',
  GET_DICTIONARY_INTERFACE_TYPES_REJECTED = 'dictionaries/GET_DICTIONARY_INTERFACE_TYPES_REJECTED',

  /** Transaction types action types keys */
  GET_DICTIONARY_TRANSACTION_TYPES = 'dictionaries/GET_DICTIONARY_TRANSACTION_TYPES',
  GET_DICTIONARY_TRANSACTION_TYPES_FULFILLED = 'dictionaries/GET_DICTIONARY_TRANSACTION_TYPES_FULFILLED',
  GET_DICTIONARY_TRANSACTION_TYPES_REJECTED = 'dictionaries/GET_DICTIONARY_TRANSACTION_TYPES_REJECTED',

  /** Countries action types keys */
  GET_DICTIONARY_COUNTRIES = 'dictionaries/GET_DICTIONARY_COUNTRIES',
  GET_DICTIONARY_COUNTRIES_FULFILLED = 'dictionaries/GET_DICTIONARY_COUNTRIES_FULFILLED',
  GET_DICTIONARY_COUNTRIES_REJECTED = 'dictionaries/GET_DICTIONARY_COUNTRIES_REJECTED',

  /** Currencies action types keys */
  GET_DICTIONARY_CURRENCIES = 'dictionaries/GET_DICTIONARY_CURRENCIES',
  GET_DICTIONARY_CURRENCIES_FULFILLED = 'dictionaries/GET_DICTIONARY_CURRENCIES_FULFILLED',
  GET_DICTIONARY_CURRENCIES_REJECTED = 'dictionaries/GET_DICTIONARY_CURRENCIES_REJECTED',

  /** Event data elements action types keys */
  FILTER_DICTIONARY_EVENT_DATA_ELEMS = 'dictionaries/FILTER_DICTIONARY_EVENT_DATA_ELEMS',
  FILTER_DICTIONARY_EVENT_DATA_ELEMS_FULFILLED = 'dictionaries/FILTER_DICTIONARY_EVENT_DATA_ELEMS_FULFILLED',
  FILTER_DICTIONARY_EVENT_DATA_ELEMS_REJECTED = 'dictionaries/FILTER_DICTIONARY_EVENT_DATA_ELEMS_REJECTED',

  /** Events action types keys */
  FILTER_DICTIONARY_EVENTS = 'dictionaries/FILTER_DICTIONARY_EVENTS',
  FILTER_DICTIONARY_EVENTS_FULFILLED = 'dictionaries/FILTER_DICTIONARY_EVENTS_FULFILLED',
  FILTER_DICTIONARY_EVENTS_REJECTED = 'dictionaries/FILTER_DICTIONARY_EVENTS_REJECTED',

  /** Repayment methods action types keys */
  GET_DICTIONARY_REPAYMENT_METHODS = 'dictionaries/GET_DICTIONARY_REPAYMENT_METHODS',
  GET_DICTIONARY_REPAYMENT_METHODS_FULFILLED = 'dictionaries/GET_DICTIONARY_REPAYMENT_METHODS_FULFILLED',
  GET_DICTIONARY_REPAYMENT_METHODS_REJECTED = 'dictionaries/GET_DICTIONARY_REPAYMENT_METHODS_REJECTED',

  /** Repayment types action types keys */
  GET_DICTIONARY_REPAYMENT_TYPES = 'dictionaries/GET_DICTIONARY_REPAYMENT_TYPES',
  GET_DICTIONARY_REPAYMENT_TYPES_FULFILLED =
    'dictionaries/GET_DICTIONARY_REPAYMENT_TYPES_FULFILLED',
  GET_DICTIONARY_REPAYMENT_TYPES_REJECTED = 'dictionaries/GET_DICTIONARY_REPAYMENT_TYPES_REJECTED',
}

/** Account statuses action interfaces */

export interface IGetDictionaryAccountStatusesAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_DICTIONARY_ACCOUNT_STATUSES;
}

export interface IGetDictionaryAccountStatusesFulfilledAction {
  readonly payload: IDictionaryAccountStatusesData;
  readonly type: ActionTypeKeys.GET_DICTIONARY_ACCOUNT_STATUSES_FULFILLED;
}

export interface IGetDictionaryAccountStatusesRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_DICTIONARY_ACCOUNT_STATUSES_REJECTED;
}

/** Customer statuses action interfaces */

export interface IGetDictionaryCustomerStatusesAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_DICTIONARY_CUSTOMER_STATUSES;
}

export interface IGetDictionaryCustomerStatusesFulfilledAction {
  readonly payload: IDictionaryCustomerStatusesData;
  readonly type: ActionTypeKeys.GET_DICTIONARY_CUSTOMER_STATUSES_FULFILLED;
}

export interface IGetDictionaryCustomerStatusesRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_DICTIONARY_CUSTOMER_STATUSES_REJECTED;
}

/** Endpoint types action interfaces */

export interface IGetDictionaryEndpointTypesAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_DICTIONARY_ENDPOINT_TYPES;
}

export interface IGetDictionaryEndpointTypesFulfilledAction {
  readonly payload: IDictionaryEndpointTypesData;
  readonly type: ActionTypeKeys.GET_DICTIONARY_ENDPOINT_TYPES_FULFILLED;
}

export interface IGetDictionaryEndpointTypesRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_DICTIONARY_ENDPOINT_TYPES_REJECTED;
}

/** Interface types action interfaces */

export interface IGetDictionaryInterfaceTypesAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_DICTIONARY_INTERFACE_TYPES;
}

export interface IGetDictionaryInterfaceTypesFulfilledAction {
  readonly payload: IDictionaryInterfaceTypesData;
  readonly type: ActionTypeKeys.GET_DICTIONARY_INTERFACE_TYPES_FULFILLED;
}

export interface IGetDictionaryInterfaceTypesRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_DICTIONARY_INTERFACE_TYPES_REJECTED;
}

/** Transaction types action interfaces */

export interface IGetDictionaryTransactionTypesAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_DICTIONARY_TRANSACTION_TYPES;
}

export interface IGetDictionaryTransactionTypesFulfilledAction {
  readonly payload: IDictionaryTransactionTypesData;
  readonly type: ActionTypeKeys.GET_DICTIONARY_TRANSACTION_TYPES_FULFILLED;
}

export interface IGetDictionaryTransactionTypesRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_DICTIONARY_TRANSACTION_TYPES_REJECTED;
}

/** Countries action interfaces */

export interface IGetDictionaryCountriesAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_DICTIONARY_COUNTRIES;
}

export interface IGetDictionaryCountriesFulfilledAction {
  readonly payload: IDictionaryCountriesData;
  readonly type: ActionTypeKeys.GET_DICTIONARY_COUNTRIES_FULFILLED;
}

export interface IGetDictionaryCountriesRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_DICTIONARY_COUNTRIES_REJECTED;
}

/** Currencies action interfaces */

export interface IGetDictionaryCurrenciesAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_DICTIONARY_CURRENCIES;
}

export interface IGetDictionaryCurrenciesFulfilledAction {
  readonly payload: IDictionaryCurrenciesData;
  readonly type: ActionTypeKeys.GET_DICTIONARY_CURRENCIES_FULFILLED;
}

export interface IGetDictionaryCurrenciesRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_DICTIONARY_CURRENCIES_REJECTED;
}

/** Event data elements action interfaces */

export interface IFilterDictionaryEventDataElemsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_DICTIONARY_EVENT_DATA_ELEMS;
}

export interface IFilterDictionaryEventDataElemsFulfilledAction {
  readonly payload: IDictionaryEventDataElemsData;
  readonly type: ActionTypeKeys.FILTER_DICTIONARY_EVENT_DATA_ELEMS_FULFILLED;
}

export interface IFilterDictionaryEventDataElemsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_DICTIONARY_EVENT_DATA_ELEMS_REJECTED;
}

/** Events action interfaces */

export interface IFilterDictionaryEventsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_DICTIONARY_EVENTS;
}

export interface IFilterDictionaryEventsFulfilledAction {
  readonly payload: IDictionaryEventsData;
  readonly type: ActionTypeKeys.FILTER_DICTIONARY_EVENTS_FULFILLED;
}

export interface IFilterDictionaryEventsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_DICTIONARY_EVENTS_REJECTED;
}

/** Repayment methods action interfaces */

export interface IGetDictionaryRepaymentMethodsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_DICTIONARY_REPAYMENT_METHODS;
}

export interface IGetDictionaryRepaymentMethodsFulfilledAction {
  readonly payload: IDictionaryRepaymentMethodsData;
  readonly type: ActionTypeKeys.GET_DICTIONARY_REPAYMENT_METHODS_FULFILLED;
}

export interface IGetDictionaryRepaymentMethodsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_DICTIONARY_REPAYMENT_METHODS_REJECTED;
}

/** Repayment types action interfaces */

export interface IGetDictionaryRepaymentTypesAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_DICTIONARY_REPAYMENT_TYPES;
}

export interface IGetDictionaryRepaymentTypesFulfilledAction {
  readonly payload: IDictionaryRepaymentTypesData;
  readonly type: ActionTypeKeys.GET_DICTIONARY_REPAYMENT_TYPES_FULFILLED;
}

export interface IGetDictionaryRepaymentTypesRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_DICTIONARY_REPAYMENT_TYPES_REJECTED;
}

export type TDictionaryAction =
  | IFilterDictionaryEventDataElemsFulfilledAction
  | IFilterDictionaryEventsFulfilledAction
  | IGetDictionaryAccountStatusesFulfilledAction
  | IGetDictionaryCountriesFulfilledAction
  | IGetDictionaryCurrenciesFulfilledAction
  | IGetDictionaryCustomerStatusesFulfilledAction
  | IGetDictionaryEndpointTypesFulfilledAction
  | IGetDictionaryInterfaceTypesFulfilledAction
  | IGetDictionaryRepaymentMethodsFulfilledAction
  | IGetDictionaryRepaymentTypesFulfilledAction
  | IGetDictionaryTransactionTypesFulfilledAction;
