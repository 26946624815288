import { uiSessionsStatusOptions } from 'consts';
import { IUiSession, IUiSessionData, IUiSessionsFilter, IUiSessionsFilterToSend } from './types';

import { dateUtil, stringsUtil } from 'utils';

export const normalizeFilterQueryString = (data: IUiSessionsFilter): string => {
  const {
    dateFrom,
    dateTo,
    institutionId,
    pageNumber,
    username,
  } = data || {};

  const normalizedData: IUiSessionsFilterToSend = {
    date_from: dateUtil.formatDateForSending(dateFrom),
    date_to: dateUtil.formatDateForSending(dateTo),
    institution_id: institutionId?.value,
    page_number: pageNumber,
    username: username?.value,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeData = (data: IUiSessionData): IUiSession => {
  const {
    first_name,
    institution_id,
    institution_name,
    ip_address,
    last_datetime,
    last_name,
    status,
    user_agent,
    username,
  } = data || {};

  const uiSessionStatus = uiSessionsStatusOptions.find(el => el.value === status);

  return {
    fullName: `${first_name || ''} ${last_name || ''}`,
    institutionId: institution_id,
    institutionName: institution_name,
    ipAddress: ip_address,
    lastDatetime: last_datetime,
    status: uiSessionStatus?.label,
    userAgent: user_agent,
    username,
  };
};
