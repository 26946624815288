import Immutable, { ImmutableObject } from 'seamless-immutable';

import { ActionTypeKeys, TUtilsAction } from './actionTypes';

import { IUtilsState } from './types';

export const utilsInitialState: ImmutableObject<IUtilsState> = Immutable({
  activeItemId: null,
  activeProductType: null,
  activeDebitCreditIndicator: null,
  isLockedActiveItem: false,
  isReadOnlyActivePage: null,
});

const utilsReducer = (state = utilsInitialState, action: TUtilsAction) => {
  switch (action.type) {
    case ActionTypeKeys.SET_ACTIVE_ITEM_ATTRIBUTES:
      return state
        .set('activeItemId', action.payload?.id)
        .set('activeProductType', action.payload?.productType)
        .set('activeDebitCreditIndicator', action.payload?.debitCreditIndicator)
        .set('isLockedActiveItem', action.payload?.lockedFlag);

    case ActionTypeKeys.SET_ACTIVE_PAGE_PERMISSION:
      return state.set('isReadOnlyActivePage', action.payload);

    case ActionTypeKeys.RESET_UTILS:
      return state = utilsInitialState;

    default: return state;
  }
};

export default utilsReducer;
