import React from 'react';

import { Box } from '@rebass/grid';

import { IWithModal, withModal } from 'HOCs';

import { IconName, ModalName } from 'consts';

import { ButtonWrapper } from './ButtonWrapper';
import { icons } from './icons';

interface IButton extends IWithModal {
  classNames?: Array<'no-text-transform' | 'no-border' | 'is-circle' | 'is-accent' | 'text-left'>;
  confirmationText?: string;
  confirmationTitle?: string;
  disabled?: boolean;
  iconName?: IconName;
  isFocused?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  size?: string;
  text?: string;
  title?: string;
  type?: 'reset' | 'submit';
  width?: string;
  withConfirmation?: boolean;
}

const Button: React.FC<IButton> = ({
  classNames,
  confirmationText,
  confirmationTitle,
  disabled,
  iconName,
  isFocused,
  isLoading,
  onClick,
  openModal,
  size,
  text,
  title,
  type,
  width,
  withConfirmation,
}) => {
  const buttonClasses = React.useMemo(
    () => {
      const focused = isFocused ? 'is-focused' : '';
      const classes = classNames ? classNames.join(',') : '';

      return `button ${focused} ${classes}`;
    },
    [isFocused, classNames]
  );

  // eslint-disable-next-line
  const handleClick = React.useCallback(
    disabled
      ? null
      : withConfirmation
        ? () => openModal({
          name: ModalName.CONFIRMATION,
          payload: {
            confirmationAction: onClick,
            confirmationTitle,
            confirmationText,
          },
        })
        : onClick,
    [
      confirmationText,
      confirmationTitle,
      disabled,
      onClick,
      openModal,
      withConfirmation,
    ]
  );

  return (
    <ButtonWrapper
      title={title}
      width={width}
      disabled={disabled || isLoading}
      type={type}
      size={size}
      hasIcon={Boolean(iconName)}
      className={buttonClasses}
      onClick={handleClick}
    >
      {iconName && (
        <Box mr="2px" className="icon">
          {icons[iconName]}
        </Box>
      )}
      {text && (
        <span className={isLoading ? 'text-wrapper is-loading' : 'text-wrapper'}>
          {text}
        </span>
      )}
    </ButtonWrapper>
  );

};

export default withModal(Button);
