import React from 'react';

import { Flex } from '@rebass/grid';

import styled from 'theme';

import { Button, CircleList } from 'components';

import { IconName } from 'consts';

const DashedBlock = styled.div`
  margin: 10px 0 20px;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  background-color: ${({ theme }) => theme.colors.lighterGray};
  border: 1px dashed ${({ theme }) => theme.colors.gray};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.code};
`;

interface ICodeScreen {
  code: string;
  dataUrl: string;
  onConfirm: () => void;
  onRegenerate: () => void;
}

const CodeScreen: React.FC<ICodeScreen> = ({
  onConfirm,
  onRegenerate,
  code,
  dataUrl,
}) => (
  <>
    <CircleList
      items={[
        'Download authenticator app from Google Play or Apple Store.',
        'Launch authenticator app on your mobile phone and follow instructions to complete registration.',
      ]}
    />

    {dataUrl && (
      <Flex justifyContent="center" my="15px">
        <img alt="" src={dataUrl} />
      </Flex>
    )}

    {code && (
      <DashedBlock>{code}</DashedBlock>
    )}

    <Flex
      justifyContent="space-between"
      alignItems="center"
      mt="10px"
    >
      <Button
        text="Regenerate key"
        iconName={IconName.QRCODE}
        onClick={onRegenerate}
      />

      <Button
        text="Registration completed"
        withConfirmation={true}
        confirmationTitle="Confirmation"
        confirmationText="Confirm BOX (new user) profile appeared in your authenticator app."
        onClick={onConfirm}
      />
    </Flex>
  </>
);

export default CodeScreen;
