import React from 'react';
import { Field } from 'redux-form';

import { Box } from '@rebass/grid';

import { InputField, NewLine, SelectField } from 'components';

import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface ICustomersFilter {
  institutionsOptions: Array<ISelectValue>;
  isDisabled: boolean;
}

const CustomersFilter: React.FC<ICustomersFilter> = ({
  institutionsOptions,
  isDisabled,
}) => (
  <>
    <Box width="360px" p="8px">
      <Field
        id="institutionId"
        name="institutionId"
        component={SelectField}
        label="Institution"
        placeholder="Select Institution"
        options={institutionsOptions}
        isClearable={false}
        isDisabled={isDisabled}
        isRequired={true}
        validate={[formErrorUtil.isRequired]}
      />
    </Box>
    <Box width="360px" p="8px">
      <Field
        id="firstName"
        name="firstName"
        component={InputField}
        label="First Name"
        placeholder="Enter First Name"
        disabled={isDisabled}
      />
    </Box>

    <NewLine />

    <Box width="240px" p="8px">
      <Field
        id="customerReference"
        name="customerReference"
        component={InputField}
        label="Reference"
        placeholder="Enter Reference"
        disabled={isDisabled}
      />
    </Box>
    <Box width="120px" p="8px">
      <Field
        id="customerId"
        name="customerId"
        component={InputField}
        label="Customer ID"
        placeholder="Enter ID"
        isNumber={true}
        disabled={isDisabled}
        validate={[formErrorUtil.isInteger]}
      />
    </Box>
    <Box width="360px" p="8px">
      <Field
        id="lastName"
        name="lastName"
        component={InputField}
        label="Last Name"
        placeholder="Enter Last Name"
        disabled={isDisabled}
      />
    </Box>
  </>
);

export default CustomersFilter;
