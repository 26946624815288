import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { isDirty } from 'redux-form';

import { FormName } from 'consts';

import {
  currentUsersGroupNameSelector,
  IStoreState,
  resetUsersGroup,
} from 'store';
import DetailsUsersGroupModal from './DetailsUsersGroupModal';

const generalInfoFormDirty = isDirty(FormName.DETAILS_USERS_GROUP);
const userGroupMembersFormDirty = isDirty(FormName.DETAILS_USERS_GROUP_MEMBERS);
const groupPermissionFormDirty = isDirty(FormName.ADD_USERS_GROUP_PERMISSIONS);

const mapStateToProps = (state: IStoreState) => ({
  groupName: currentUsersGroupNameSelector(state),
  isGeneralInfoFormDirty: generalInfoFormDirty(state),
  isUsersGroupMembersFormDirty: userGroupMembersFormDirty(state),
  isGroupPermissionFormDirty: groupPermissionFormDirty(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetUsersGroup,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsUsersGroupModal);
