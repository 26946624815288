// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
// import { throttleUtil } from 'utils';

// import { userMock, usersMock } from './mock';
import { IUserData } from './types';

/**
 * Filter users API
 */
export const filterUsers = (queryString: string) =>
  // throttleUtil.getDataAfter(usersMock, 500);
  apiClientService.get(`users?${queryString}`);

/**
 * Get user details API
 */
export const getUser = (userId: number) =>
  // throttleUtil.getDataAfter(userMock, 500);
  apiClientService.get(`users/${userId}`);

/**
 * Update user details API
 */
export const updateUser = (data: Partial<IUserData>, userId: number) =>
  // throttleUtil.getDataAfter(successResponseMock, 1000);
  apiClientService.put(`users/${userId}`, { data });

/**
 * Create user API
 */
export const addUser = (data: Partial<IUserData>) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.post('users', { data });
