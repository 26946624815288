import { ProductType } from 'consts';
// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
import { IApiCallConfig } from 'types';
// import { throttleUtil } from 'utils';

// import {
//   accountCardsMock,
//   loanAccountMock,
//   loanAccountsMock,
//   loanTransactionResultMock,
//   revolvingAccountsMock,
//   revolvingCreditAccountMock,
//   revolvingCreditTransactionResultMock,
// } from './mock';
import { IAccountData } from './types';
import { IManualTransactionRequest } from './typesManualTransaction';

/**
 * Filter accounts API
 */
export const filterAccounts = (queryString: string, productType: string | number) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/accounts?${queryString}`,
      // mock: loanAccountsMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/accounts?${queryString}`,
      // mock: revolvingAccountsMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Get account details API
 */
export const getAccount = (accountId: number, productType: string) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/accounts/${accountId}`,
      // mock: loanAccountMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/accounts/${accountId}`,
      // mock: revolvingCreditAccountMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Update account details API
 */
export const updateAccount = (
  data: Partial<IAccountData>,
  accountId: number,
  productType: string
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/accounts/${accountId}`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/accounts/${accountId}`,
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 1000);

  const { path } = config[productType];
  return apiClientService.put(path, { data });
};

/**
 * Add account API
 */
export const addAccount = (data: Partial<IAccountData>, productType: string | number) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: 'il/accounts',
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: 'rc/accounts',
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.post(path, { data });
};

/**
 * Manual transaction API
 */
export const makeTransaction = (
  data: IManualTransactionRequest,
  productType: string
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/transactions`,
      // mock: loanTransactionResultMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: 'rc/transactions',
      // mock: revolvingCreditTransactionResultMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 1000);

  const { path } = config[productType];
  return apiClientService.post(path, { data });
 };
