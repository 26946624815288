
import { reset as resetForm } from 'redux-form';

import { FormName, ModalName } from 'consts';
import {
  activeItemIdSelector,
  activeProductTypeSelector,
  closeModal,
} from 'store';
import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import {
  ActionTypeKeys,
  IAddProductAprAction,
  IAddProductFeeAction,
  IAddProductPenaltyAction,
  IAddProductRewardAction,
  IDeleteProductAprAction,
  IDeleteProductFeeAction,
  IDeleteProductPenaltyAction,
  IDeleteProductRewardAction,
  IGetProductAprsAction,
  IGetProductFeesAction,
  IGetProductPenaltiesAction,
  IGetProductRewardsAction,
  IUpdateProductAprAction,
} from './actionTypes';

import * as api from './api';

import {
  IProductApr,
  IProductAprData,
  IProductAprIds,
  IProductFee,
  IProductFeeData,
  IProductPenalty,
  IProductPenaltyData,
  IProductReward,
  IProductRewardData,
} from './types';

import {
  normalizeFormValuesProductAprForSending,
  normalizeProductApForSending,
  normalizeProductFeeForSending,
  normalizeProductPenaltyForSending,
  normalizeProductRewardForSending,
} from './utils';

/**
 * Fees actions
 */

// Get fees
export type TGetProductFees = (productId: number, productType: string) => IGetProductFeesAction;
export type THandleGetProductFees = () => Thunk<void>;

export const getProductFees: TGetProductFees = (productId, productType) => ({
  type: ActionTypeKeys.GET_PRODUCT_FEES,
  payload: api.getProductFees(productId, productType),
});

export const handleGetProductFees: THandleGetProductFees = () => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();
      const productId = activeItemIdSelector(state);
      const productType = activeProductTypeSelector(state);

      await dispatch(getProductFees(productId, productType));
    },
    dispatch
  );
};

// Add fee
export type TAddProductFee = (
  data: Partial<IProductFeeData>,
  productId: number,
  productType: string
) => IAddProductFeeAction;

export type THandleAddProductFee = (data: Partial<IProductFee>) => Thunk<void>;

export const addProductFee: TAddProductFee = (data, productId, productType) => ({
  type: ActionTypeKeys.ADD_PRODUCT_FEE,
  payload: api.addProductFee(data, productId, productType),
});

export const handleAddProductFee: THandleAddProductFee = data => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();
      const productId = activeItemIdSelector(state);
      const productType = activeProductTypeSelector(state);
      const normalizedData = normalizeProductFeeForSending(data, productId, productType);

      await dispatch(addProductFee(normalizedData, productId, productType));
      await dispatch(handleGetProductFees());
      dispatch(resetForm(FormName.PRODUCT_FEE));
    },
    dispatch
  );
};

// Delete fee
export type TDeleteProductFee = (data: IProductFee, productType: string) => IDeleteProductFeeAction;
export type THandleDeleteProductFee = (data: IProductFee) => Thunk<void>;

export const deleteProductFee: TDeleteProductFee = (data, productType) => ({
  type: ActionTypeKeys.DELETE_PRODUCT_FEE,
  payload: api.deleteProductFee(data, productType),
});

export const handleDeleteProductFee: THandleDeleteProductFee = data =>
  async (dispatch, getState)  => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const productType = activeProductTypeSelector(state);

        await dispatch(deleteProductFee(data, productType));
        await dispatch(handleGetProductFees());
      },
      dispatch
    );
  };

/**
 * Rewards actions
 */

// Get rewards
export type TGetProductRewards = (productId: number, productType: string) =>
  IGetProductRewardsAction;

export type THandleGetProductRewards = () => Thunk<void>;

export const getProductRewards: TGetProductRewards = (productId, productType) => ({
  type: ActionTypeKeys.GET_PRODUCT_REWARDS,
  payload: api.getProductRewards(productId, productType),
});

export const handleGetProductRewards: THandleGetProductRewards = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const productId = activeItemIdSelector(state);
        const productType = activeProductTypeSelector(state);

        await dispatch(getProductRewards(productId, productType));
      },
      dispatch
    );
  };

// Add reward
export type TAddProductReward = (
  data: Partial<IProductRewardData>,
  productId: number,
  productType: string
) => IAddProductRewardAction;

export type THandleAddProductReward = (data: Partial<IProductReward>) => Thunk<void>;

export const addProductReward: TAddProductReward = (data, productId, productType) => ({
  type: ActionTypeKeys.ADD_PRODUCT_REWARD,
  payload: api.addProductReward(data, productId, productType),
});

export const handleAddProductReward: THandleAddProductReward = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const productId = activeItemIdSelector(state);
        const productType = activeProductTypeSelector(state);
        const normalizedData = normalizeProductRewardForSending(data, productId, productType);

        await dispatch(addProductReward(normalizedData, productId, productType));
        await dispatch(handleGetProductRewards());
        dispatch(resetForm(FormName.PRODUCT_REWARD));
      },
      dispatch
    );
  };

// Delete reward
export type TDeleteProductReward = (
  data: IProductReward,
  productType: string
) => IDeleteProductRewardAction;

export type THandleDeleteProductReward = (data: IProductReward) => Thunk<void>;

export const deleteProductReward: TDeleteProductReward = (data, productType) => ({
  type: ActionTypeKeys.DELETE_PRODUCT_REWARD,
  payload: api.deleteProductReward(data, productType),
});

export const handleDeleteProductReward: THandleDeleteProductReward = data =>
  async (dispatch, getState)  => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const productType = activeProductTypeSelector(state);

        await dispatch(deleteProductReward(data, productType));
        await dispatch(handleGetProductRewards());
      },
      dispatch
    );
  };

/**
 * Penalties actions
 */

// Get penalties
export type TGetProductPenalties = (productId: number, productType: string) =>
  IGetProductPenaltiesAction;

export type THandleGetProductPenalties = () => Thunk<void>;

export const getProductPenalties: TGetProductPenalties = (productId, productType) => ({
  type: ActionTypeKeys.GET_PRODUCT_PENALTIES,
  payload: api.getProductPenalties(productId, productType),
});

export const handleGetProductPenalties: THandleGetProductPenalties = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const productId = activeItemIdSelector(state);
        const productType = activeProductTypeSelector(state);

        await dispatch(getProductPenalties(productId, productType));
      },
      dispatch
    );
  };

// Add penalty
export type TAddProductPenalty = (
  data: Partial<IProductPenaltyData>,
  productId: number,
  productType: string
) => IAddProductPenaltyAction;

export type THandleAddProductPenalty = (data: Partial<IProductPenalty>) => Thunk<void>;

export const addProductPenalty: TAddProductPenalty = (data, productId, productType) => ({
  type: ActionTypeKeys.ADD_PRODUCT_PENALTY,
  payload: api.addProductPenalty(data, productId, productType),
});

export const handleAddProductPenalty: THandleAddProductPenalty = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const productId = activeItemIdSelector(state);
        const productType = activeProductTypeSelector(state);
        const normalizedData = normalizeProductPenaltyForSending(data, productId, productType);

        await dispatch(addProductPenalty(normalizedData, productId, productType));
        await dispatch(handleGetProductPenalties());
        dispatch(resetForm(FormName.PRODUCT_PENALTY));
      },
      dispatch
    );
  };

// Delete penalty
export type TDeleteProductPenalty = (data: IProductPenalty, productType: string) =>
  IDeleteProductPenaltyAction;

export type THandleDeleteProductPenalty = (data: IProductPenalty) => Thunk<void>;

export const deleteProductPenalty: TDeleteProductPenalty = (data, productType) => ({
  type: ActionTypeKeys.DELETE_PRODUCT_PENALTY,
  payload: api.deleteProductPenalty(data, productType),
});

export const handleDeleteProductPenalty: THandleDeleteProductPenalty = data =>
  async (dispatch, getState)  => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const productType = activeProductTypeSelector(state);

        await dispatch(deleteProductPenalty(data, productType));
        await dispatch(handleGetProductPenalties());
      },
      dispatch
    );
  };

/**
 * APRs actions
 */

// Get APRs
export type TGetProductAprs = (productId: number) => IGetProductAprsAction;
export type THandleGetProductAprs = () => Thunk<void>;

export const getProductAprs: TGetProductAprs = productId => ({
  type: ActionTypeKeys.GET_PRODUCT_APRS,
  payload: api.getProductAprs(productId),
});

export const handleGetProductAprs: THandleGetProductAprs = () => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();
      const productId = activeItemIdSelector(state);

      await dispatch(getProductAprs(productId));
    },
    dispatch
  );
};

// Add APR
export type TAddProductApr = (data: Partial<IProductAprData>) => IAddProductAprAction;
export type THandleAddProductApr = (data: Partial<IProductApr>) => Thunk<void>;

export const addProductApr: TAddProductApr = data => ({
  type: ActionTypeKeys.ADD_PRODUCT_APR,
  payload: api.addProductApr(data),
});

export const handleAddProductApr: THandleAddProductApr = data => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();
      const productId = activeItemIdSelector(state);
      const normalizedData = normalizeFormValuesProductAprForSending(data, productId);

      await dispatch(addProductApr(normalizedData));
      await dispatch(handleGetProductAprs());
      dispatch(resetForm(FormName.PRODUCT_APR));
    },
    dispatch
  );
};

// Update APR
export type TUpdateProductApr = (data: Partial<IProductAprData>) => IUpdateProductAprAction;
export type THandleUpdateProductApr = (data: Partial<IProductApr>) => Thunk<void>;

export const updateProductApr: TUpdateProductApr = data => ({
  type: ActionTypeKeys.UPDATE_PRODUCT_APR,
  payload: api.updateProductApr(data),
});

export const handleUpdateProductApr: THandleUpdateProductApr = data => async dispatch => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const normalizedData = normalizeProductApForSending(data);

      await dispatch(updateProductApr(normalizedData));
      dispatch(closeModal(ModalName.UPDATE_PRODUCT_APR));
      await dispatch(handleGetProductAprs());
    },
    dispatch
  );
};

// Delete APR
export type TDeleteProductApr = (data: IProductAprIds) => IDeleteProductAprAction;
export type THandleDeleteProductApr = (data: IProductAprIds) => Thunk<void>;

export const deleteProductApr: TDeleteProductApr = data => ({
  type: ActionTypeKeys.DELETE_PRODUCT_APR,
  payload: api.deleteProductApr(data),
});

export const handleDeleteProductApr: THandleDeleteProductApr = data => async dispatch => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      await dispatch(deleteProductApr(data));
      dispatch(closeModal(ModalName.UPDATE_PRODUCT_APR));
      await dispatch(handleGetProductAprs());
    },
    dispatch
  );
};

/**
 * Reset
 */
export type TResetPayments = () => void;

export const resetPayments: TResetPayments = () => ({
  type: ActionTypeKeys.RESET_PAYMENTS,
});
