import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import { Button, NumberFormatField } from 'components';
import { FormName } from 'consts';
import { THandleIllustrateRevCredit } from 'store';
import { formErrorUtil } from 'utils';

interface IRevolvingCreditIllustrationForm {
  illustrate: THandleIllustrateRevCredit;
  isLoading: boolean;
}

type TRevolvingCreditIllustrationForm = IRevolvingCreditIllustrationForm
  & InjectedFormProps<{}, IRevolvingCreditIllustrationForm>;

const RevolvingCreditIllustrationForm: React.FC<TRevolvingCreditIllustrationForm> = ({
  handleSubmit,
  illustrate,
  isLoading,
}) => {
  // eslint-disable-next-line
  const handleSubmitForm = React.useCallback(
    handleSubmit(illustrate),
    [handleSubmit, illustrate]
  );

  return (
    <form onSubmit={handleSubmitForm}>
      <Flex
        alignItems="flex-end"
        flexWrap="wrap"
        width="100%"
        mx="-8px"
      >
        <Box width="130px" p="8px">
          <Field
            id="maximumMonths"
            name="maximumMonths"
            component={NumberFormatField}
            label="Maximum months"
            placeholder="0"
            fixedDecimalScale={true}
            decimalScale={0}
            disabled={isLoading}
            validate={[
              formErrorUtil.isRequired,
              formErrorUtil.isPositive,
            ]}
          />
        </Box>
        <Box width="130px" p="8px">
          <Field
            id="repaymentAmount"
            name="repaymentAmount"
            component={NumberFormatField}
            label="Repayment amount"
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            disabled={isLoading}
            validate={[
              formErrorUtil.isRequired,
              formErrorUtil.isPositive,
            ]}
          />
        </Box>
        <Box p="8px">
          <Button
            text="Illustrate"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleSubmitForm}
          />
          </Box>
      </Flex>
    </form>
  );
};

export default reduxForm<{}, IRevolvingCreditIllustrationForm>({
  form: FormName.PRODUCT_REVOLVING_CREDIT_ILLUSTRATION,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(RevolvingCreditIllustrationForm);
