import {
  CookiesName,
  Flag,
  LocalStorageName,
  SESSION_TIMEOUT,
} from 'consts';

import { cookiesUtil } from 'utils';

/**
 * Session timeout
 */
export const setSessionTimeout = (sessionTimeout: number) => {
  const timeout = sessionTimeout || SESSION_TIMEOUT;

  localStorage.setItem(LocalStorageName.SESSION_TIMEOUT, JSON.stringify(timeout));
};

export const getSessionTimeout = () =>
  localStorage.getItem(LocalStorageName.SESSION_TIMEOUT);

export const removeSessionTimeout = () =>
  localStorage.removeItem(LocalStorageName.SESSION_TIMEOUT);

export const getLoggedInFlag = () =>
  localStorage.getItem(LocalStorageName.LOGGED_IN_FLAG);

export const setLoggedInFlag = () =>
  localStorage.setItem(LocalStorageName.LOGGED_IN_FLAG, Flag.YES);

export const removeLoggedInFlag = () =>
  localStorage.removeItem(LocalStorageName.LOGGED_IN_FLAG);

/**
 * Session ID storage
 */
export const setSessionId = (sessionId: string, sessionTimeout?: number) => {
  const storedSessionTimeout = getSessionTimeout();

  const timeout = sessionTimeout
    ? sessionTimeout
    : storedSessionTimeout
      ? Number(storedSessionTimeout)
      : SESSION_TIMEOUT;

  setSessionTimeout(timeout);
  cookiesUtil.set(CookiesName.SESSION_ID, sessionId, { path: '/', expires: timeout });
};

export const getSessionId = () =>
  cookiesUtil.get(CookiesName.SESSION_ID);

export const removeSessionId = () =>
  cookiesUtil.remove(CookiesName.SESSION_ID, { path: '/' });

/**
 * Auth pending flag
 */
export const setAuthPendingFlag = (sessionTimeout?: number) => {
  const timeout = sessionTimeout || SESSION_TIMEOUT;

  cookiesUtil.set(CookiesName.AUTH_PENDING_FLAG, Flag.YES, { path: '/', expires: timeout });
};

export const getAuthPendingFlag = () =>
  cookiesUtil.get(CookiesName.AUTH_PENDING_FLAG);

export const removeAuthPendingFlag = () =>
  cookiesUtil.remove(CookiesName.AUTH_PENDING_FLAG, { path: '/' });

/**
 * Clear storage
 */
export const clear = () => {
  removeSessionId();
  removeSessionTimeout();
  removeLoggedInFlag();

  removeAuthPendingFlag();
};
