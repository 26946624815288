import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  ActionButtons,
  Button,
  CheckboxField,
  HorizontalLine,
  InputField,
  MaskField,
  NewLine,
  SelectField,
} from 'components';
import {
  commonStatusOptions,
  DateFormat,
  FormName,
  IconName,
  MaskFormat,
} from 'consts';
import {
  IInstitutionDetails,
  THandleAddInstitution,
  THandleDeleteInstitution,
  THandleGetDictionaryCurrencies,
  THandleUpdateInstitution,
} from 'store';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface IInstitutionForm {
  addInstitution: THandleAddInstitution;
  currenciesOptions: Array<ISelectValue>;
  deleteInstitution: THandleDeleteInstitution;
  getCurrencies: THandleGetDictionaryCurrencies;
  initialValues?: IInstitutionDetails;
  isAdding: boolean;
  isCurrenciesLoading: boolean;
  isDeleting: boolean;
  isEditMode?: boolean;
  isReadOnly?: boolean;
  isUpdating: boolean;
  onCancel: () => void;
  updateInstitution: THandleUpdateInstitution;
}

type TInstitutionForm = IInstitutionForm & InjectedFormProps<{}, IInstitutionForm>;

const InstitutionForm: React.FC<TInstitutionForm> = ({
  addInstitution,
  currenciesOptions,
  deleteInstitution,
  dirty,
  getCurrencies,
  handleSubmit,
  initialValues,
  isAdding,
  isCurrenciesLoading,
  isDeleting,
  isEditMode,
  isReadOnly,
  isUpdating,
  onCancel,
  pristine,
  updateInstitution,
}) => {
  React.useEffect(
    () => {
      getCurrencies();
    },
    [getCurrencies]
  );

  const submitFormAction = React.useMemo(
    () => isEditMode ? updateInstitution : addInstitution,
    [isEditMode, updateInstitution, addInstitution]
  );

  const isReadOnlyField = React.useMemo(
    () => isReadOnly || isAdding || isUpdating || isDeleting,
    [isReadOnly, isAdding, isUpdating, isDeleting]
  );

  return (
    <form onSubmit={isReadOnly ? null : handleSubmit(submitFormAction)}>
      <Flex
        alignItems="flex-end"
        flexWrap="wrap"
        mx="-8px"
      >
        {isEditMode && (
          <Box width={[1 / 5]} p="8px">
            <Field
              id="id"
              name="id"
              component={InputField}
              label="ID"
              placeholder="Enter ID"
              disabled={true}
              isNumber={true}
            />
          </Box>
        )}
        <Box width={isEditMode ? [7 / 15] : [2 / 3]} p="8px">
          <Field
            id="institutionName"
            name="institutionName"
            component={InputField}
            label="Name"
            placeholder="Enter Institution"
            disabled={isEditMode || isReadOnlyField}
            validate={[
              formErrorUtil.isRequired,
            ]}
          />
        </Box>
        <Box width={[1 / 3]} p="8px">
          <Field
            id="status"
            name="status"
            component={SelectField}
            options={commonStatusOptions}
            label="Status"
            placeholder="Select Status"
            isDisabled={isReadOnlyField}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>
        <Box width={[2 / 3]} p="8px">
          <Field
            id="baseCurrency"
            name="baseCurrency"
            component={SelectField}
            label="Base Currency"
            placeholder="Select Currencies"
            options={currenciesOptions}
            isLoading={isCurrenciesLoading}
            isDisabled={isReadOnlyField}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>

        {isEditMode && (
          <>
            <NewLine />
            <Box width={[1 / 3]} p="8px">
              <Field
                id="currentOperationDate"
                name="currentOperationDate"
                component={MaskField}
                label="Current Operation Date"
                placeholder={DateFormat.DATE}
                mask={MaskFormat.DATE}
                disabled={true}
              />
            </Box>
          </>
        )}

        <Box width={[1 / 3]} p="8px">
          <Field
            id="nextOperationDate"
            name="nextOperationDate"
            component={MaskField}
            label="Next Operation Date"
            placeholder={DateFormat.DATE}
            mask={MaskFormat.DATE}
            disabled={isReadOnlyField}
            validate={[formErrorUtil.isDate]}
          />
        </Box>

        <HorizontalLine />

        <Box width={[1]} p="8px">
          <Field
            id="sftpLocation"
            name="sftpLocation"
            component={InputField}
            label="SFTP Location"
            placeholder="Enter SFTP Location"
            disabled={isReadOnly || isReadOnlyField}
          />
        </Box>
        <Box width={[1]} p="8px">
          <Field
            id="sftpPublicKey"
            name="sftpPublicKey"
            component={InputField}
            label="SFTP Public Key"
            placeholder="Enter SFTP Public Key"
            disabled={isReadOnly || isReadOnlyField}
          />
        </Box>
        {initialValues?.masterInstitutionFlag && (
          <Box width={[1]} p="8px">
            <Field
              id="masterInstitutionFlag"
              name="masterInstitutionFlag"
              component={CheckboxField}
              label="Master Institution"
              disabled={true}
            />
          </Box>
        )}
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt="15px"
      >
        <Box>
          {isEditMode && !isReadOnly && (
            <Button
              text="Delete"
              iconName={IconName.DELETE}
              type="reset"
              withConfirmation={true}
              confirmationText={`Delete institution "${initialValues?.institutionName}"?`}
              onClick={deleteInstitution}
              isLoading={isDeleting}
            />
          )}
        </Box>
        <ActionButtons
          okText="Save"
          cancelText="Close"
          withCancelConfirmation={dirty}
          disabledOk={pristine}
          onCancel={onCancel}
          hideOk={isReadOnly}
          isLoadingOk={isAdding || isUpdating}
        />
      </Flex>
    </form >
  );
};

export default reduxForm<{}, IInstitutionForm>({
  form: FormName.INSTITUTION,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(InstitutionForm);
