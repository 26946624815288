import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';
import { userInstitutionsOptionsSelector } from 'store/domains/profile';

import { ActionTypeKeys } from './actionTypes';
import { normalizeData, normalizeDetails } from './utils';

export const defaultEndpointsSelector = (state: IStoreState) =>
  state.admin.endpoints.endpoints;

export const endpointsSelector = createSelector(
  defaultEndpointsSelector,
  userInstitutionsOptionsSelector,
  (endpoints, institutions) => endpoints?.map(endpoint => {
    const institution = institutions?.find(el => el.value === endpoint.institution_id);

    return normalizeData(endpoint, institution);
  })
);

export const endpointsOptionsSelector = createSelector(
  defaultEndpointsSelector,
  data => data?.asMutable().map(el => {
    return {
      value: el.id,
      label: el.name,
    };
  })
);

export const isEndpointsLoadedSelector = createSelector(
  defaultEndpointsSelector,
  data => data?.length > 0
);

/**
 * Current endpoint selectors
 */

export const defaultCurrentEndpointSelector = (state: IStoreState) =>
  state.admin.endpoints.currentEndpoint;

export const currentEndpointSelector = createSelector(
  defaultCurrentEndpointSelector,
  userInstitutionsOptionsSelector,
  (currentEndpoint, institutions) => {
    return {
      ...normalizeDetails(currentEndpoint),
      institutionId: institutions?.find(el => el.value === currentEndpoint?.institution_id),
    };
  }
);

export const isFilteringEndpointsSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_ENDPOINTS,
]);

export const isGettingEndpointSelector = createLoadingSelector([
  ActionTypeKeys.GET_ENDPOINT,
]);

export const isAddingEndpointSelector = createLoadingSelector([
  ActionTypeKeys.ADD_ENDPOINT,
]);

export const isUpdatingEndpointSelector = createLoadingSelector([
  ActionTypeKeys.UPDATE_ENDPOINT,
]);

export const isDeletingEndpointSelector = createLoadingSelector([
  ActionTypeKeys.DELETE_ENDPOINT,
]);
