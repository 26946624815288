import { ProductType } from 'consts';
// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
import { IApiCallConfig } from 'types';
// import { throttleUtil } from 'utils';

// import {
//   productAprsMock,
//   productLoanFeesMock,
//   productLoanPenaltiesMock,
//   productLoanRewardsMock,
//   productRevolvingCreditFeesMock,
//   productRevolvingCreditPenaltiesMock,
//   productRevolvingCreditRewardsMock,
// } from './mock';

import {
  IProductAprData,
  IProductAprIds,
  IProductFee,
  IProductFeeData,
  IProductPenalty,
  IProductPenaltyData,
  IProductReward,
  IProductRewardData,
} from './types';

/**
 * Get product Fees API
 */
export const getProductFees = (productId: number, productType: string) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-fees?product_id=${productId}`,
      // mock: productLoanFeesMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/product-fees?product_id=${productId}`,
      // mock: productRevolvingCreditFeesMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Add product Fee API
 */
export const addProductFee = (
  data: Partial<IProductFeeData>,
  productId: number,
  productType: string
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-fees?product_id=${productId}`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/product-fees?product_id=${productId}`,
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.post(path, { data });
};

/**
 * Delete product Fee API
 */
export const deleteProductFee = (data: IProductFee, productType: string) => {
  const { feeId, productId } = data;

  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-fees/${feeId}?product_id=${productId}`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/product-fees/${feeId}?product_id=${productId}`,
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 1000);

  const { path } = config[productType];
  return apiClientService.delete(path);
};

/**
 * Get product rewards API
 */
export const getProductRewards = (productId: number, productType: string) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-rewards?product_id=${productId}`,
      // mock: productLoanRewardsMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/products-rewards?product_id=${productId}`,
      // mock: productRevolvingCreditRewardsMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Add product reward API
 */
export const addProductReward = (
  data: Partial<IProductRewardData>,
  productId: number,
  productType: string
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-rewards?product_id=${productId}`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/products-rewards?product_id=${productId}`,
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.post(path, { data });
};

/**
 * Delete product reward API
 */
export const deleteProductReward = (data: IProductReward, productType: string) => {
  const { rewardId, productId } = data;

  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-rewards/${rewardId}?product_id=${productId}`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/products-rewards/${rewardId}?product_id=${productId}`,
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 1000);

  const { path } = config[productType];
  return apiClientService.delete(path);
 };

/**
 * Get product penalties API
 */
export const getProductPenalties = (productId: number, productType: string) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-penalties?product_id=${productId}`,
      // mock: productLoanPenaltiesMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: '',
      // mock: productRevolvingCreditPenaltiesMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Add product penalty API
 */
export const addProductPenalty = (
  data: Partial<IProductPenaltyData>,
  productId: number,
  productType: string
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-penalties?product_id=${productId}`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: '',
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.post(path, { data });
};

/**
 * Delete product penalty API
 */
export const deleteProductPenalty = (data: IProductPenalty, productType: string) => {
  const { penaltyId, productId } = data;

  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-penalties/${penaltyId}?product_id=${productId}`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: '',
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 1000);

  const { path } = config[productType];
  return apiClientService.delete(path);
 };

/**
 * Get product APRs API
 */
export const getProductAprs = (productId: number) =>
  // throttleUtil.getDataAfter(productAprsMock, 500);
  apiClientService.get(`rc/product-aprs?product_id=${productId}`);

/**
 * Add product APR API
 */
export const addProductApr = (data: Partial<IProductAprData>) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.post('/rc/product-aprs', { data });

/**
 * Update product APR API
 */
export const updateProductApr = (data: Partial<IProductAprData>) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.put(
    `rc/product-aprs/${data?.product_apr_id}?product_id=${data?.product_id}`,
    { data }
  );

/**
 * Delete product APR API
 */
export const deleteProductApr = (data: IProductAprIds) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.delete(
    `rc/product-aprs/${data?.productAprId}?product_id=${data?.productId}`,
    { data }
  );
