import React from 'react';
import { Field } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  HorizontalLine,
  InputField,
  MaskField,
  NewLine,
  NumberFormatField,
  SelectField,
  T3,
} from 'components';
import {
  DateFormat,
  identificationTypesOptions,
  MaskFormat
} from 'consts';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface ICustomerInfo {
  countryCodes: Array<ISelectValue>;
  customerStatusOptions: Array<ISelectValue>;
  institutionsOptions: Array<ISelectValue>;
  isCountryCodesLoading: boolean;
  isCounty: boolean;
  isEditMode?: boolean;
  isIdentification: boolean;
  isLoadingCustomerStatuses: boolean;
  isReadOnly: boolean;
}

const CustomerInfo: React.FC<ICustomerInfo> = ({
  countryCodes,
  customerStatusOptions,
  institutionsOptions,
  isCountryCodesLoading,
  isCounty,
  isEditMode = false,
  isIdentification,
  isLoadingCustomerStatuses,
  isReadOnly,
}) => (
  <Flex
    alignItems="flex-end"
    flexWrap="wrap"
    mx="-8px"
  >
    <Box width="490px" p="8px">
      <Field
        id="firstName"
        name="firstName"
        component={InputField}
        label="First Name"
        placeholder="Enter First Name"
        disabled={isReadOnly}
        validate={[
          formErrorUtil.isRequired,
          formErrorUtil.isAlpha,
        ]}
      />
    </Box>
    <Box width="490px" p="8px">
      <Field
        id="lastName"
        name="lastName"
        component={InputField}
        label="Last Name"
        placeholder="Enter Last Name"
        disabled={isReadOnly}
        validate={[
          formErrorUtil.isRequired,
          formErrorUtil.isAlpha,
        ]}
      />
    </Box>

    {isEditMode && (
      <>
        <Box width="120px" p="8px">
          <Field
            id="id"
            name="id"
            component={InputField}
            label="ID"
            isNumber={true}
            disabled={true}
          />
        </Box>
        <Box width="290px" p="8px">
          <Field
            id="customerReference"
            name="customerReference"
            component={InputField}
            label="Reference"
            placeholder="-"
            disabled={true}
          />
        </Box>
        <Box width="290px" p="8px">
          <Field
            id="institutionName"
            name="institutionName"
            component={InputField}
            label="Institution"
            placeholder="-"
            disabled={true}
          />
        </Box>
        <Box width="280px" p="8px">
          <Field
            id="status"
            name="status"
            component={SelectField}
            label="Status"
            placeholder="Status"
            options={customerStatusOptions}
            isLoading={isLoadingCustomerStatuses}
            isDisabled={isReadOnly}
            isClearable={false}
          />
        </Box>
      </>
    )}

    {!isEditMode && (
      <Box width="290px" p="8px">
        <Field
          id="institution"
          name="institution"
          component={SelectField}
          label="Institution"
          placeholder="Select Institution"
          options={institutionsOptions}
          isDisabled={isEditMode || isReadOnly}
          isClearable={false}
          validate={[formErrorUtil.isRequired]}
        />
      </Box>
    )}

    <NewLine />

    <Box width="120px" p="8px">
      <Field
        id="dateOfBirth"
        name="dateOfBirth"
        component={MaskField}
        label="Date of Birth"
        placeholder={DateFormat.DATE}
        mask={MaskFormat.DATE}
        disabled={isReadOnly}
        validate={[
          formErrorUtil.isRequired,
          formErrorUtil.isDate,
        ]}
      />
    </Box>
    <Box width="290px" p="8px">
      <Field
        id="identificationType"
        name="identificationType"
        component={SelectField}
        options={identificationTypesOptions}
        label="Identification Type"
        placeholder="Select Type"
        isDisabled={isReadOnly}
        validate={[formErrorUtil.isRequired]}
      />
    </Box>

    <Box width="290px" p="8px">
      <Field
        id="identificationNumber"
        name="identificationNumber"
        component={InputField}
        label="Identification Number"
        placeholder="Enter Number"
        disabled={isReadOnly}
        validate={[formErrorUtil.isRequired]}
      />
    </Box>

    <NewLine />

    <Box width="410px" p="8px">
      <Field
        id="email"
        name="email"
        component={InputField}
        label="Email"
        placeholder="Enter Email"
        disabled={isReadOnly}
        validate={[
          formErrorUtil.isRequired,
          formErrorUtil.isEmail,
        ]}
      />
    </Box>
    <Box width="290px" p="8px">
      <Field
        id="mobilePhoneNumber"
        name="mobilePhoneNumber"
        component={InputField}
        label="Mobile Phone Number"
        placeholder="Enter Phone Number"
        disabled={isReadOnly}
        hint="Phone number must be supplied with the country code."
        validate={[formErrorUtil.isRequired]}
      />
    </Box>

    <HorizontalLine />

    <Box width={[1]} px="10px">
      <T3>Address</T3>
    </Box>

    <Box width="490px" p="8px">
      <Field
        id="nationalityCountryCode"
        name="nationalityCountryCode"
        component={SelectField}
        options={countryCodes}
        isLoading={isCountryCodesLoading}
        label="Nationality Country Code"
        placeholder="Select Nationality Country Code"
        isDisabled={isReadOnly}
        validate={[formErrorUtil.isRequired]}
      />
    </Box>
    <Box width="490px" p="8px">
      <Field
        id="addressCountryCode"
        name="addressCountryCode"
        component={SelectField}
        options={countryCodes}
        isLoading={isCountryCodesLoading}
        label="Country Code"
        placeholder="Select Country Code"
        isDisabled={isReadOnly}
        validate={[formErrorUtil.isRequired]}
      />
    </Box>
    <Box width="245px" p="8px">
      <Field
        id="addressLine1"
        name="addressLine1"
        component={InputField}
        label="Line 1"
        placeholder="Enter Line 1"
        disabled={isReadOnly}
        validate={[formErrorUtil.isRequired]}
      />
    </Box>
    <Box width="245px" p="8px">
      <Field
        id="addressLine2"
        name="addressLine2"
        component={InputField}
        label="Line 2"
        placeholder="Enter Line 2"
        disabled={isReadOnly}
      />
    </Box>
    <Box width="245px" p="8px">
      <Field
        id="addressLine3"
        name="addressLine3"
        component={InputField}
        label="Line 3"
        placeholder="Enter Line 3"
        disabled={isReadOnly}
      />
    </Box>
    <Box width="245px" p="8px">
      <Field
        id="addressLine4"
        name="addressLine4"
        component={InputField}
        label="Line 4"
        placeholder="Enter Line 4"
        disabled={isReadOnly}
      />
    </Box>
    <Box width="245px" p="8px">
      <Field
        id="addressTown"
        name="addressTown"
        component={InputField}
        label="Town"
        placeholder="Enter Town"
        disabled={isReadOnly}
        validate={[formErrorUtil.isRequired]}
      />
    </Box>

    {isCounty && (
      <Box width="120px" p="8px">
        <Field
          id="addressCountyState"
          name="addressCountyState"
          component={InputField}
          label="County State"
          placeholder="Enter County"
          hint="2 letter ISO3166-2:US state code (e.g. CA for California)"
          disabled={isReadOnly}
        />
      </Box>
    )}

    <Box width="120px" p="8px">
      <Field
        id="addressPostCode"
        name="addressPostCode"
        component={InputField}
        label="Post Code"
        placeholder="Post Code"
        disabled={isReadOnly}
        validate={[
          formErrorUtil.isRequired,
          formErrorUtil.isPostalCode,
        ]}
      />
    </Box>

    <HorizontalLine />

    <Box width={[1]} px="10px">
      <T3>Limit</T3>
    </Box>

    <Box width="120px" p="8px">
      <Field
        id="customerLimit"
        name="customerLimit"
        component={NumberFormatField}
        label="Limit"
        placeholder="0.00"
        fixedDecimalScale={true}
        decimalScale={2}
        hint={isEditMode ? '' : 'Institution base currency will be used'}
        disabled={isReadOnly}
        validate={[
          formErrorUtil.isRequired,
          formErrorUtil.isPositive,
        ]}
      />
    </Box>

    {isEditMode && (
      <Box width="125px" p="8px">
        <Field
          id="baseCurrencyCode"
          name="baseCurrencyCode"
          component={InputField}
          label="Institution Currency Code"
          placeholder="-"
          disabled={true}
        />
      </Box>
    )}

    {isEditMode && (
      <>
        <HorizontalLine />
        <Box width="120px" p="8px">
          <Field
            id="dateCreated"
            name="dateCreated"
            label="Date Created"
            component={MaskField}
            placeholder={DateFormat.DATE}
            mask={MaskFormat.DATE}
            disabled={true}
          />
        </Box>
        <Box width="120px" p="8px">
          <Field
            id="dateClosed"
            name="dateClosed"
            label="Date Closed"
            component={MaskField}
            placeholder="-"
            mask={MaskFormat.DATE}
            disabled={true}
          />
        </Box>
      </>
    )}
  </Flex>
);

export default CustomerInfo;
