const {
  NODE_ENV: mode = 'development',
} = process.env;

const environment = {
  development: {
    isDevelopment: true,
  },
  production: {
    isDevelopment: false,
  },
  test: {
    isDevelopment: true,
  },
}[mode];

interface Config {
  isDevelopment: boolean;
  apiUrl: string;
  apiHost: string;
  apiUrlLambda: string;
  apiHostLambda: string;
}

let config: Config;
let envConfig;

const app = {};

try {
  envConfig = require(`./${mode}`);
  config = {...app, ...environment, ...envConfig.default};
} catch (e) {
  throw new Error('INVALID CONFIG FILE');
}

export default Object.freeze(config);
