import React from 'react';

import { Box, Flex } from '@rebass/grid';

import { Button } from 'components';
import { TResetPayments } from 'store';

import AprsForm from './AprsForm';
import AprsTable from './AprsTable';

interface IAprs {
  isReadOnly: boolean;
  onCancel: () => void;
  resetPayments: TResetPayments;
}

const Aprs: React.FC<IAprs> = ({
  isReadOnly,
  onCancel,
  resetPayments,
}) => {

  React.useEffect(
    () => {
      return () => resetPayments();
    },
    [resetPayments]
  );

  return (
    <>
      {!isReadOnly && (
        <AprsForm />
      )}

      <Box mt="30px">
        <AprsTable isReadOnly={isReadOnly} />
      </Box>

      <Flex
        justifyContent="flex-end"
        mt="15px"
      >
        <Button
          text="Close"
          onClick={onCancel}
        />
      </Flex>
    </>
  );
};

export default Aprs;
