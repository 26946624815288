import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { change, formValueSelector } from 'redux-form';

import Rules from './Rules';

import { FormName } from 'consts';

import {
  currentProductRuleSelector,
  dictionaryEventsOptionsSelector,
  eventDataElemsOptionsForRulesSelector,
  handleAddProductRule,
  handleDeleteProductRule,
  handleFilterDictionaryEventDataElems,
  handleFilterDictionaryEvents,
  handleGetDictionaryTransactionTypes,
  handleGetProductAprs,
  handleGetProductFees,
  handleGetProductPenalties,
  handleGetProductRewards,
  handleGetProductRule,
  handleUpdateProductRule,
  isEventsLoadingSelector,
  isProductRuleLoadingSelector,
  isProductRuleUpdatingSelector,
  IStoreState,
  productAprsOptionsSelector,
  productFeesOptionsSelector,
  productPenaltiesOptionsForRulesSelector,
  productRewardsOptionsForRulesSelector,
  resetPayments,
  resetProductRule,
  transTypesOptionsForRulesSelector,
} from 'store';

const formSelector = formValueSelector(FormName.PRODUCT_RULES_FILTER);

const mapStateToProps = (state: IStoreState) => ({
  actionTypeSelected: formSelector(state, 'actionType'),
  eventIdSelected: formSelector(state, 'eventId'),
  aprsOptions: productAprsOptionsSelector(state),
  currentProductRule: currentProductRuleSelector(state),
  eventDataElemsOptions: eventDataElemsOptionsForRulesSelector(state),
  eventsOptions: dictionaryEventsOptionsSelector(state),
  feesOptions: productFeesOptionsSelector(state),
  isEventsLoading: isEventsLoadingSelector(state),
  isRuleLoading: isProductRuleLoadingSelector(state),
  isRuleUpdating: isProductRuleUpdatingSelector(state),
  penaltiesOptions: productPenaltiesOptionsForRulesSelector(state),
  rewardsOptions: productRewardsOptionsForRulesSelector(state),
  transactionTypesOptions: transTypesOptionsForRulesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    change,
    filterDictionaryEventDataElems: handleFilterDictionaryEventDataElems,
    filterEvents: handleFilterDictionaryEvents,
    getProductAprs: handleGetProductAprs,
    getProductFees: handleGetProductFees,
    getProductPenalties: handleGetProductPenalties,
    getProductRewards: handleGetProductRewards,
    getRule: handleGetProductRule,
    getTransactionTypes: handleGetDictionaryTransactionTypes,
    resetPayments,
    resetRule: resetProductRule,
    updateRule: handleUpdateProductRule,
    addRule: handleAddProductRule,
    deleteRule: handleDeleteProductRule,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rules);
