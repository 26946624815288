import { ModalName } from 'consts';

import {
  activeItemIdSelector,
  closeModal,
  getUserInstitutions,
  openModal,
} from 'store';
import {
  ActionTypeKeys,
  IAddInstitutionAction,
  IDeleteInstitutionAction,
  IGetInstitutionsAction,
  IUpdateInstitutionAction,
} from './actionTypes';
import * as api from './api';
import { IInstitutionData, IInstitutionDetails } from './types';
import { normalizeDataForSending } from './utils';

import { Thunk, VoidPromiseThunk } from 'types';
import { errorDecoratorUtil } from 'utils';

/**
 * Get institutions action
 */

export type TGetInstitutions = () => IGetInstitutionsAction;
export type THandleGetInstitutions = VoidPromiseThunk;

export const getInstitutions: TGetInstitutions = () => ({
  type: ActionTypeKeys.GET_INSTITUTIONS,
  payload: api.getInstitutions(),
});

export const handleGetInstitutions: THandleGetInstitutions = () =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        await dispatch(getInstitutions());
      },
      dispatch
    );
  };

/**
 * Add institution action
 */

export type TAddInstitution = (data: Partial<IInstitutionData>) => IAddInstitutionAction;
export type THandleAddInstitution = (data: Partial<IInstitutionDetails>) => Thunk<void>;

export const addInstitution: TAddInstitution = data => ({
  type: ActionTypeKeys.ADD_INSTITUTION,
  payload: api.addInstitution(data),
});

export const handleAddInstitution: THandleAddInstitution = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const normalizedData = normalizeDataForSending(data);

        await dispatch(addInstitution(normalizedData));
        dispatch(closeModal(ModalName.ADD_INSTITUTION));
        await dispatch(handleGetInstitutions());
        await dispatch(getUserInstitutions());

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: {
              title: 'Institution has been created',
              isSuccess: true,
            },
          })
        );
      },
      dispatch
    );
  };

/**
 * Update institution action
 */

export type TUpdateInstitution = (data: Partial<IInstitutionData>, institutionId: number) =>
  IUpdateInstitutionAction;

export type THandleUpdateInstitution = (data: Partial<IInstitutionDetails>) => Thunk<void>;

export const updateInstitutions: TUpdateInstitution = (data, institutionId) => ({
  type: ActionTypeKeys.UPDATE_INSTITUTION,
  payload: api.updateInstitution(data, institutionId),
});

export const handleUpdateInstitution: THandleUpdateInstitution = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const institutionId = activeItemIdSelector(state);
        const normalizedData = normalizeDataForSending(data);

        await dispatch(updateInstitutions(normalizedData, institutionId));
        await dispatch(handleGetInstitutions());
      },
      dispatch
    );
  };

/**
 * Delete institution action
 */

export type TDeleteInstitution = (id: number) => IDeleteInstitutionAction;
export type THandleDeleteInstitution = () => Thunk<void>;

export const deleteInstitution: TDeleteInstitution = id => ({
  type: ActionTypeKeys.DELETE_INSTITUTION,
  payload: api.deleteInstitution(id),
  meta: { id },
});

export const handleDeleteInstitution: THandleDeleteInstitution = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const id = activeItemIdSelector(state);

        await dispatch(deleteInstitution(id));
        dispatch(closeModal(ModalName.DETAILS_INSTITUTION));

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: {
              title: 'Institution has been deleted',
              message: `Institution ID: ${id}`,
              isSuccess: true,
            },
          })
        );
      },
      dispatch
    );
  };

/**
 * Reset institutions action
 */

export type TResetInstitutions = () => void;

export const resetInstitutions: TResetInstitutions = () => ({
  type: ActionTypeKeys.RESET_INSTITUTIONS,
});
