import {
  applicationConditionOptions,
  aprCalcTypesOptions,
  debitCreditIndicatorOptions,
  ProductType,
  productTypesOptions,
  transactionStatusOptions,
} from 'consts';
import { IStatement } from 'store';
import { dateUtil, stringsUtil } from 'utils';

import {
  ICommonTransaction,
  ICommonTransactionData,
  ISettlementFormValues,
  ISettleTransactionResponseData,
  ITransaction,
  ITransactionData,
  ITransactionsFilter,
  ITransactionsFilterToSend,
} from './types';

export const normalizeFilterQueryString = (data: ITransactionsFilter): string => {
  const {
    accountId,
    cardId,
    customerId,
    dateFrom,
    dateTo,
    product,
    transactionId,
    pageNumber,
  } = data;

  const normalizedData: ITransactionsFilterToSend = {
    account_id: accountId,
    card_id: cardId,
    customer_id: customerId,
    date_from: dateUtil.formatDateForSending(dateFrom),
    date_to: dateUtil.formatDateForSending(dateTo),
    product_id: product?.value,
    transaction_id: transactionId,
    page_number: pageNumber,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeStatementTransactionsQueryString = (data: IStatement): string => {
  const { accountId, productId, productType, startDate, endDate } = data;

  const dateFrom = dateUtil.formatDateForSending(startDate);
  const dateTo = dateUtil.formatDateForSending(endDate);

  const queryString = `account_id=${accountId}&product_type=${productType}&product_id=${productId}&date_from=${dateFrom}&date_to=${dateTo}`;

  return queryString;
};

export const normalizeCommonTransaction = (data: ICommonTransactionData): ICommonTransaction => {
  const {
    account_id,
    amount_settled,
    amount,
    currency,
    debit_credit_indicator,
    description,
    id,
    product_type_name,
    product_type,
    settled_date,
    status_name,
    status,
    transaction_datetime,
    transaction_type_description,
    transaction_type_id,
  } = data || {};

  return {
    id,
    accountId: account_id,
    productType: product_type,
    productTypeName: product_type_name,
    description,
    transactionTypeId: transaction_type_id,
    transactionTypeDescription: transaction_type_description,
    debitCredit: debitCreditIndicatorOptions.find(el => el.value === debit_credit_indicator)?.label,
    debitCreditIndicator: debit_credit_indicator,
    currency,
    amount: stringsUtil.numberToFixed(amount, 2),
    amountSettled: stringsUtil.numberToFixed(amount_settled, 2),
    transactionDatetime: transaction_datetime,
    settledDate: settled_date,
    status,
    statusName: status_name,
  };
};

export const normalizeTransactionsForReport = (
  data: Array<ICommonTransactionData>
): Array<ICommonTransaction> => {
  return data?.length && data.map(el =>  {
    const normalizedData = normalizeCommonTransaction(el);

    normalizedData['status'] = normalizedData['statusName'];
    normalizedData['productType'] = normalizedData['productTypeName'];
    normalizedData['transactionType'] = normalizedData['transactionTypeDescription'];
    normalizedData['debit /Credit'] = normalizedData['debitCredit'];

    delete(normalizedData.productTypeName);
    delete(normalizedData.statusName);
    delete(normalizedData.transactionTypeDescription);
    delete(normalizedData.debitCreditIndicator);
    delete(normalizedData.debitCredit);

    for (const key in normalizedData) {
      if (key.slice(-2) === 'Id' || key === 'id') {
        delete normalizedData[key];
      }
    }

    return normalizedData;
  });
};

export const normalizeCurrentTransaction = (data: ITransactionData): ITransaction => {
  const {
    // Common
    account_id,
    amount_settled,
    amount,
    currency,
    debit_credit_indicator,
    description,
    id,
    product_type_name,
    product_type,
    settled_date,
    status_name,
    status,
    transaction_datetime,
    transaction_type_description,
    transaction_type_id,

    // Revolving credits
    amount_in_original_currency,
    apr_calculation_method,
    apr_rate,
    balance_authorised_after,
    balance_authorised_before,
    balance_settled_after,
    balance_settled_before,
    card_id,
    fee_amount,
    fee_application_condition,
    fee_rate,
    institution_id,
    original_currency,
    parent_transaction_id,
    product_apr_id,
    product_fee_id,
    product_reward_id,
    reward_amount,
    reward_application_condition,
    reward_rate,
    source_endpoint_id,
    transaction_reference,

    // Loans
  } = data || {};

  const specificProductTypeFields = {
    [ProductType.LOAN]: {},
    [ProductType.REVOLVING_CREDIT]: {
      amountInOriginalCurrency: stringsUtil.numberToFixed(amount_in_original_currency, 2),
      aprCalculationMethod: aprCalcTypesOptions
        .find(el => el.value === apr_calculation_method)?.label,
      aprId: product_apr_id,
      aprRate: stringsUtil.numberToFixed(apr_rate, 2),
      balanceAuthorisedAfter: stringsUtil.numberToFixed(balance_authorised_after, 2),
      balanceAuthorisedBefore: stringsUtil.numberToFixed(balance_authorised_before, 2),
      balanceSettledAfter: stringsUtil.numberToFixed(balance_settled_after, 2),
      balanceSettledBefore: stringsUtil.numberToFixed(balance_settled_before, 2),
      cardId: card_id,
      feeAmount: stringsUtil.numberToFixed(fee_amount, 2),
      feeApplicationCondition: applicationConditionOptions
        .find(el => el.value === fee_application_condition)?.label,
      feeRate: stringsUtil.numberToFixed(fee_rate, 2),
      institutionId: institution_id,
      originalCurrency: original_currency,
      parentTransactionId: parent_transaction_id,
      productFeeId: product_fee_id,
      productRewardId: product_reward_id,
      rewardAmount: stringsUtil.numberToFixed(reward_amount, 2),
      rewardApplicationCondition: applicationConditionOptions
        .find(el => el.value === reward_application_condition)?.label,
      rewardRate: stringsUtil.numberToFixed(reward_rate, 2),
      sourceEndpointId: source_endpoint_id,
      transactionReference: transaction_reference,
    },
  };

  return {
    accountId: account_id,
    amount: stringsUtil.numberToFixed(amount, 2),
    amountSettled: stringsUtil.numberToFixed(amount_settled, 2),
    currency,
    debitCredit: debitCreditIndicatorOptions.find(el => el.value === debit_credit_indicator)?.label,
    debitCreditIndicator: debit_credit_indicator,
    description,
    id,
    productType: product_type,
    productTypeName: product_type_name,
    settledDate: settled_date,
    status,
    statusName: status_name,
    transactionDatetime: transaction_datetime,
    transactionTypeDescription: transaction_type_description,
    transactionTypeId: transaction_type_id,
    ...specificProductTypeFields[product_type],
  };
};

/**
 * Settle transaction utils
 */

export const normalizeSettlementFormValues = (
  data: ICommonTransactionData
): ISettlementFormValues => {
  const {
    account_id,
    amount,
    debit_credit_indicator,
    description,
    id,
    product_type_name,
    product_type,
    transaction_type_description,
  } = data || {};

  return {
    accountId: account_id,
    amount,
    debitCredit: debitCreditIndicatorOptions.find(el => el.value === debit_credit_indicator)?.label,
    description,
    productType: product_type,
    productTypeName: product_type_name,
    transactionId: id,
    transactionTypeDescription: transaction_type_description,
  };
};

export const normalizeSettleTransactionResult = (
  data: ISettleTransactionResponseData,
  productType: string
) => {
  const {
    // common
    status,
    amount_settled,
    id,
    account_id,
    amount,
    currency,
    description,
    transaction_datetime,
    transaction_type_id,

    // revolving credit
    settled_date,
    balance_settled_before,
    balance_settled_after,

    // loan
    principal_after_credit,
    principal_before_credit,
  } = data || {};

  const specificProductTypeFields = {
    [ProductType.LOAN]: [
      ['Principal before credit', stringsUtil.numberToFixed(principal_before_credit, 2)],
      ['Principal after credit', stringsUtil.numberToFixed(principal_after_credit, 2)],
    ],
    [ProductType.REVOLVING_CREDIT]: [
      ['Balance settled before', stringsUtil.numberToFixed(balance_settled_before, 2)],
      ['Balance settled after', stringsUtil.numberToFixed(balance_settled_after, 2)],
      ['Settled date', settled_date],
    ],
  };

  return [
    ...specificProductTypeFields[productType],
    ['Transaction ID', id],
    ['Account ID', account_id],
    ['Transaction type ID', transaction_type_id],
    ['Description', description],
    ['Transaction datetime', transaction_datetime],
    ['Currency', currency],
    ['Amount', stringsUtil.numberToFixed(amount, 2)],
    ['Amount settled', stringsUtil.numberToFixed(amount_settled, 2)],
    ['Product type', productTypesOptions.find(el => el.value === productType)?.label],
    ['Status', transactionStatusOptions.find(el => el.value === status)?.label],
  ];
};
