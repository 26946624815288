import { ProductType } from 'consts';
// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
import { IApiCallConfig } from 'types';
// import { throttleUtil } from 'utils';

// import {
//   loanRuleMock,
//   revCreditRuleMock,
// } from './mock';
import { IProductRuleData } from './types';

/**
 * Get product rule API
 */
export const getProductRule = (
  queryString: string,
  productType: string
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-rules?${queryString}`,
      // mock: loanRuleMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/product-rules?${queryString}`,
      // mock: revCreditRuleMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Create product rule API
 */

export const addProductRule = (
  data: IProductRuleData,
  productType: string
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-rules`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/product-rules`,
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 1000);

  const { path } = config[productType];
  return apiClientService.post(path, { data });
};

/**
 * Update product rule API
 */

export const updateProductRule = (
  data: Partial<IProductRuleData>,
  queryString: string,
  productType: string
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-rules?${queryString}`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/product-rules?${queryString}`,
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 1000);

  const { path } = config[productType];
  return apiClientService.put(path, { data });
};

/**
 * Delete product rule API
 */

export const deleteProductRule = (
  queryString: string,
  productType: string
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/product-rules?${queryString}`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/product-rules?${queryString}`,
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 1000);

  const { path } = config[productType];
  return apiClientService.delete(path);
};
