import React from 'react';
import ReactTable, { ComponentDecoratorProps, ReactTableDefaults } from 'react-table';

import { TableNoData } from './../../Layout';
import { TableWrapper } from './TableWrapper';

interface ITable extends Partial<ComponentDecoratorProps> {
  className?: string;
  columns: Array<object>;
  data: any; // ImmutableArray<object>
  isHeader?: boolean;
  isScrollbar?: boolean;
  isSmaller?: boolean;
  pageSize?: number;
  style?: object;
  title?: string;
}

export const Table: React.FC<ITable> = props => {
  const {
    data,
    isHeader = true,
    isScrollbar = true,
    isSmaller,
    pageSize,
  } = props;

  return (
    <TableWrapper
      isSmaller={isSmaller}
      isScrollbar={isScrollbar}
      isNoData={!data?.length}
    >
      <ReactTable
        {...props as ITable}
        minRows={0}
        showPagination={data?.length > pageSize}
        showPageSizeOptions={false}
        defaultPageSize={pageSize}
        resizable={isScrollbar}
        NoDataComponent={TableNoData}
        TheadComponent={
          isHeader && data?.length
            ? ReactTableDefaults.TheadComponent
            : () => null
        }
      />
    </TableWrapper>
  );
};
