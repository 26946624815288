import styled from 'theme';

const Container = styled.div`
  max-width: 1560px;
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`;

export default Container;
