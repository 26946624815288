import React from 'react';

import { Box } from '@rebass/grid';

import { List } from 'components';
import { IRevCreditIllustrationRequest, THandleIllustrateRevCredit } from 'store';

import RevolvingCreditIllustrationForm from './RevolvingCreditIllustrationForm';

interface IRevolvingCreditIllustration {
  illustrate: THandleIllustrateRevCredit;
  illustration: Array<Array<string | number>>;
  initialFormValues: Partial<IRevCreditIllustrationRequest>;
  isLoading: boolean;
}

const RevolvingCreditIllustration: React.FC<IRevolvingCreditIllustration> = ({
  illustrate,
  illustration,
  initialFormValues,
  isLoading,
}) => (
  <>
    <RevolvingCreditIllustrationForm
      illustrate={illustrate}
      initialValues={initialFormValues}
      isLoading={isLoading}
    />

    {illustration && (
      <Box mt="15px" mb="10px">
        <List items={illustration} />
      </Box>
    )}
  </>
);

export default RevolvingCreditIllustration;
