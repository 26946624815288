import Immutable, { ImmutableObject } from 'seamless-immutable';
import { ActionTypeKeys, TAccountsAction } from './actionTypes';
import { IAccountsState } from './types';

export const accountsInitialState: ImmutableObject<IAccountsState> = Immutable({
  pageNumber: null,
  totalPages: null,
  totalResults: null,
  accounts: Immutable([]),
  currentAccount: null,
  transactionResult: null,
});

const accountsReducer = (state = accountsInitialState, action: TAccountsAction) => {
  switch (action.type) {
    case ActionTypeKeys.FILTER_ACCOUNTS_FULFILLED:
      return state
        .set('accounts', action.payload.accounts)
        .set('pageNumber', action.payload.page_number)
        .set('totalPages', action.payload.total_pages)
        .set('totalResults', action.payload.total_results);

    case ActionTypeKeys.GET_ACCOUNT_FULFILLED:
      return state.set('currentAccount', action.payload.account);

    case ActionTypeKeys.MAKE_TRANSACTION_FULFILLED:
      return state.set('transactionResult', action.payload.transaction_result);

    case ActionTypeKeys.RESET_ACCOUNTS:
      return state = accountsInitialState;

    case ActionTypeKeys.RESET_ACCOUNT:
      return state.set('currentAccount', null);

    case ActionTypeKeys.RESET_TRANSACTION_RESULT:
      return state.set('transactionResult', null);

    default: return state;
  }
};

export default accountsReducer;
