import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';
import { userInstitutionsOptionsSelector } from 'store/domains/profile';

import { ActionTypeKeys } from './actionTypes';
import { normalizeData, normalizeDetails } from './utils';

export const defaultInterfacesSelector = (state: IStoreState) =>
  state.admin.interfaces.interfaces;

export const interfacesSelector = createSelector(
  defaultInterfacesSelector,
  userInstitutionsOptionsSelector,
  (items, institutions) => items?.map(item => {
    const institution = institutions?.find(el => el.value === item.institution_id);

    return normalizeData(item, institution);
  })
);

export const isInterfacesLoadedSelector = createSelector(
  defaultInterfacesSelector,
  data => data?.length > 0
);

/**
 * Current interface selectors
 */

export const defaultCurrentInterfaceSelector = (state: IStoreState) =>
  state.admin.interfaces.currentInterface;

export const currentInterfaceSelector = createSelector(
  defaultCurrentInterfaceSelector,
  userInstitutionsOptionsSelector,
  (currentInterface, institutions) => {
    return {
      ...normalizeDetails(currentInterface),
      institutionId: institutions?.find(el => el.value === currentInterface?.institution_id),
    };
  }
);

/**
 * Interface loading selectors
 */

export const isFilteringInterfacesSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_INTERFACES,
]);

export const isGettingInterfaceSelector = createLoadingSelector([
  ActionTypeKeys.GET_INTERFACE,
]);

export const isAddingInterfaceSelector = createLoadingSelector([
  ActionTypeKeys.ADD_INTERFACE,
]);

export const isUpdatingInterfaceSelector = createLoadingSelector([
  ActionTypeKeys.UPDATE_INTERFACE,
]);

export const isDeletingInterfaceSelector = createLoadingSelector([
  ActionTypeKeys.DELETE_INTERFACE,
]);
