import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  ActionButtons,
  Button,
  CheckboxField,
  InputField,
} from 'components';

import { FormName, IconName } from 'consts';

import {
  ISysProp,
  THandleAddSysProp,
  THandleDeleteSysProp,
  THandleUpdateSysProp,
} from 'store';

import { formErrorUtil } from 'utils';

interface ISystemPropertyForm {
  addSystemProperty: THandleAddSysProp;
  deleteSystemProperty: THandleDeleteSysProp;
  initialValues: ISysProp;
  isAdding: boolean;
  isDeleting: boolean;
  isEditMode?: boolean;
  isReadOnly?: boolean;
  isUpdating: boolean;
  onCancel: () => void;
  updateSystemProperty: THandleUpdateSysProp;
}

type TSystemPropertyForm = ISystemPropertyForm & InjectedFormProps<{}, ISystemPropertyForm>;

const SystemPropertyForm: React.FC<TSystemPropertyForm> = ({
  addSystemProperty,
  deleteSystemProperty,
  dirty,
  handleSubmit,
  initialValues,
  isAdding,
  isDeleting,
  isEditMode,
  isReadOnly,
  isUpdating,
  onCancel,
  pristine,
  updateSystemProperty,
}) => {
  const submitFormAction = React.useMemo(
    () => isEditMode ? updateSystemProperty : addSystemProperty,
    [isEditMode, updateSystemProperty, addSystemProperty]
  );

  const isReadOnlyField = React.useMemo(
    () => isReadOnly || isAdding || isUpdating || isDeleting,
    [isReadOnly, isAdding, isUpdating, isDeleting]
  );

  return (
    <form onSubmit={isReadOnly ? null : handleSubmit(submitFormAction)}>
      <Flex
        alignItems="flex-end"
        flexWrap="wrap"
        mx="-8px"
      >
        <Box width={[isEditMode ? 2 / 3 : 1 / 2]} p="8px">
          <Field
            id="id"
            name="id"
            placeholder="Enter Property Name"
            component={InputField}
            label="Property Name"
            disabled={isEditMode || isReadOnlyField}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>

        {isEditMode && (
          <Box width={[1 / 3]} p="8px">
            <Field
              id="lastDatetime"
              name="lastDatetime"
              component={InputField}
              label="Last Date Time"
              disabled={true}
            />
          </Box>
        )}

        <Box width={[1 / 2]} p="8px">
          <Field
            id="currentValue"
            name="currentValue"
            placeholder="Enter Current Value"
            component={InputField}
            label="Current Value"
            disabled={isReadOnlyField}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>

        {isEditMode && (
          <Box width={[1 / 2]} p="8px">
            <Field
              id="previousValue"
              name="previousValue"
              component={InputField}
              label="Previous Value"
              disabled={true}
            />
          </Box>
        )}

        <Box width={[1]} p="8px">
          <Field
            id="lockedFlag"
            name="lockedFlag"
            component={CheckboxField}
            label="Locked"
            disabled={isReadOnlyField}
          />
        </Box>
      </Flex>

      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt="15px"
      >
        <Box>
          {isEditMode && !isReadOnly && (
            <Button
              text="Delete"
              iconName={IconName.DELETE}
              type="reset"
              withConfirmation={true}
              confirmationText={ `Delete system property "${initialValues?.id}"?`}
              onClick={deleteSystemProperty}
              isLoading={isDeleting}
            />
          )}
        </Box>
        <ActionButtons
          okText="Save"
          cancelText="Close"
          onCancel={onCancel}
          withCancelConfirmation={dirty}
          disabledOk={pristine}
          hideOk={isReadOnly}
          isLoadingOk={isAdding || isUpdating}
        />
      </Flex>
    </form >
  );
};

export default reduxForm<{}, ISystemPropertyForm>({
  form: FormName.ADD_SYSTEM_PROPERTY,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SystemPropertyForm);
