import { commonStatusOptions } from 'consts';
import { dateUtil, stringsUtil } from 'utils';

import {
  ILoanIllustrationProductItemResp,
  ILoanIllustrationRequest,
  ILoanIllustrationRequestToSend,
  ILoanIllustrationScheduleItem,
  ILoanIllustrationScheduleItemResp,
  IRevCreditIllustrationData,
  IRevCreditIllustrationRequest,
  IRevCreditIllustrationRequestToSend,
} from './types';

/**
 * Loan
 */

export const normalizeLoanIllustrationForSending = (data: ILoanIllustrationRequest) => {
  const {
    apr,
    numberOfInstalments,
    principal,
    startDate,
  } = data;

  const normalizedData: Partial<ILoanIllustrationRequestToSend> = {
    apr,
    number_of_instalments: numberOfInstalments,
    principal,
    start_date: dateUtil.formatDateForSending(startDate),
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeLoanIllustrationSchedule = (
  data: ILoanIllustrationScheduleItemResp
): ILoanIllustrationScheduleItem => {
  const {
    interest_repaid,
    principal_repaid,
    principal_after_repayment,
    principal_before_repayment,
    repayment_amount,
    repayment_date,
    sequence_number,
  } = data || {};

  return {
    interestRepaid: stringsUtil.numberToFixed(interest_repaid, 2),
    principalRepaid: stringsUtil.numberToFixed(principal_repaid, 2),
    principalAfterRepayment: stringsUtil.numberToFixed(principal_after_repayment, 2),
    principalBeforeRepayment: stringsUtil.numberToFixed(principal_before_repayment, 2),
    repaymentAmount: stringsUtil.numberToFixed(repayment_amount, 2),
    repaymentDate: repayment_date,
    sequenceNumber: sequence_number,
  };
};

export const normalizeLoanIllustrationProduct = (
  product: ILoanIllustrationProductItemResp,
  numOfInstalments: number,
  principal: number,
  startDate: string,
  totalInterest: number
) => {
  if (!product) {
    return null;
  }

  const {
    id,
    product_type_name,
    name,
    description,
    statement_cycle_type_name,
    currency_code,
    status,
  } = product;

  return [
    ['Product ID', id],
    ['Product type', product_type_name],
    ['Product name', name],
    ['Product description', description],
    ['Statement cycle type', statement_cycle_type_name],
    ['Currency', currency_code],
    ['Product status', commonStatusOptions.find(el => el.value === status)?.label],
    ['Number of instalments', numOfInstalments],
    ['Principal', stringsUtil.numberToFixed(principal, 2)],
    ['Start date', startDate],
    ['Total interest', stringsUtil.numberToFixed(totalInterest, 2)],
  ];
};

/**
 * Revolving credit
 */

export const normalizeRevCreditIllustrationForSending = (data: IRevCreditIllustrationRequest) => {
  const { maximumMonths, repaymentAmount } = data;

  const normalizedData: Partial<IRevCreditIllustrationRequestToSend> = {
    maximum_months: maximumMonths,
    repayment_amount: repaymentAmount,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeRevCreditIllustration = (illustration: IRevCreditIllustrationData) => {
  if (!illustration) {
    return null;
  }

  const {
    current_balance,
    interest_rate,
    months,
    repayment,
    total_interest,
  } = illustration;

  return [
    ['Current balance', stringsUtil.numberToFixed(current_balance, 2)],
    ['Repayment', stringsUtil.numberToFixed(repayment, 2)],
    ['Interest rate', stringsUtil.numberToFixed(interest_rate, 2)],
    ['Total interest', stringsUtil.numberToFixed(total_interest, 2)],
    ['Months', months],
  ];
};
