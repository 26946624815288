import { ProductType } from 'consts';

import {
  activeItemIdSelector,
  activeProductTypeSelector,
  IStatement,
} from 'store';
import { Thunk } from 'types';
import { downloadUtil, errorDecoratorUtil } from 'utils';

import { filterTransactions } from './../transactions';
import {
  normalizeStatementTransactionsQueryString,
  normalizeTransactionsForReport,
} from './../transactions/utils';

import {
  ActionTypeKeys,
  IFilterStatementsAction,
  IGetStatementAction,
  IGetStatementAprsAction,
} from './actionTypes';

import * as api from './api';

import { IStatementsFilter } from './types';

import {
  normalizeFilterQueryString,
  normalizeStatementAprsForReport,
  normalizeStatementForReport,
} from './utils';

/**
 * Filter statements action
 */

export type TFilterStatements = (queryString: string, productType: string | number) =>
  IFilterStatementsAction;

export type THandleFilterStatements = (data: IStatementsFilter) => Thunk<void>;

export const filterStatements: TFilterStatements = (queryString, productType) => ({
  type: ActionTypeKeys.FILTER_STATEMENTS,
  payload: api.filterStatements(queryString, productType),
});

export const handleFilterStatements: THandleFilterStatements = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterQueryString(data);
        const productType = data?.productType?.value;

        await dispatch(filterStatements(queryString, productType));
      },
      dispatch
    );
  };

/**
 * Get statement action
 */

export type TGetStatement = (id: number, productType: string) => IGetStatementAction;
export type THandleGetStatement = () => Thunk<void>;

export const getStatement: TGetStatement = (id, productType) => ({
type: ActionTypeKeys.GET_STATEMENT,
payload: api.getStatement(id, productType),
});

export const handleGetStatement: THandleGetStatement = () => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();

      const id = activeItemIdSelector(state);
      const productType = activeProductTypeSelector(state);

      await dispatch(getStatement(id, productType));
    },
    dispatch
    );
  };

/**
 * Get statement APRs action
 */

export type TGetStatementAprs = (statementId: number) => IGetStatementAprsAction;
export type THandleGetStatementAprs = () => Thunk<void>;

export const getStatementAprs: TGetStatementAprs = statementId => ({
  type: ActionTypeKeys.GET_STATEMENT_APRS,
  payload: api.getStatementAprs(statementId),
});

export const handleGetStatementAprs: THandleGetStatementAprs = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const statementId = activeItemIdSelector(state);

        await dispatch(getStatementAprs(statementId));
      },
      dispatch
    );
  };

/**
 * Download statement action
 */

export type THandleDownloadStatement = (statementData: IStatement) => Thunk<void>;

export const handleDownloadStatement: THandleDownloadStatement = statementData =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const { id, productType } = statementData;
        const transactionsQueryString = normalizeStatementTransactionsQueryString(statementData);

        const data: any[] = [];

        const loadedData = await Promise.all([
          dispatch(getStatement(id, productType)),
          dispatch(filterTransactions(transactionsQueryString, productType)),
          productType === ProductType.REVOLVING_CREDIT && dispatch(getStatementAprs(id)),
        ]) as any[];

        loadedData.forEach(item => data.push(item.value));

        const statement = data.find(el => el?.statement)?.statement;
        const statementAprs = data.find(el => el?.statement_aprs)?.statement_aprs;
        const transactions = data.find(el => el?.transactions)?.transactions;

        downloadUtil.downloadStatementPDF({
          fileName: `statement_${statement.account_id}_${statement.statement_date || statement.end_date}`.replace(/\//g, ''),
          mainTitle: `Statement # ${statement.sequence_number}`,
          statement: normalizeStatementForReport(statement),
          tables: [
            {
              title: 'Transactions',
              items: normalizeTransactionsForReport(transactions),
            },
            {
              title: 'Accrued interest',
              items: normalizeStatementAprsForReport(statementAprs),
            },
          ],
        });
      },
      dispatch
    );
  };

/**
 * Reset statements action
 */

export type TResetStatements = () => void;

export const resetStatements: TResetStatements = () => ({
  type: ActionTypeKeys.RESET_STATEMENTS,
});

/**
 * Reset statement action
 */

export type TResetStatement = () => void;

export const resetStatement: TResetStatement = () => ({
  type: ActionTypeKeys.RESET_STATEMENT,
});

/**
 * Reset statement APRs action
 */

export type TResetStatementAprs = () => void;

export const resetStatementAprs: TResetStatementAprs = () => ({
  type: ActionTypeKeys.RESET_STATEMENT_APRS,
});
