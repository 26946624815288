import React from 'react';

import styled from 'theme';

import { Button } from './../Button';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn-wrapper {
    position: relative;

    &:first-child {
      margin-right: 7px;
    }
  }
`;

interface IActionButtons {
  cancelConfirmationText?: string;
  cancelConfirmationTitle?: string;
  cancelIconName?: string;
  cancelText?: string;
  disabledCancel?: boolean;
  disabledOk?: boolean;
  focusedButton?: 'ok' | 'cancel' | 'none';
  hideCancel?: boolean;
  hideOk?: boolean;
  isLoadingOk?: boolean;
  isAccentOk?: boolean;
  okText?: string;
  onCancel?: () => void;
  onOk?: () => void;
  withCancelConfirmation?: boolean;
}

const ActionButtons: React.FC<IActionButtons> = ({
  cancelConfirmationText = 'You have unsaved changes.',
  cancelConfirmationTitle = 'Close the window?',
  cancelIconName,
  cancelText = 'Cancel',
  disabledCancel = false,
  disabledOk = false,
  focusedButton = 'ok',
  hideCancel,
  hideOk,
  isLoadingOk,
  isAccentOk,
  okText = 'Ok',
  onCancel,
  onOk,
  withCancelConfirmation = false,
}) => (
  <Wrapper>
    {!hideOk && (
      <div className="btn-wrapper">
        <Button
          text={okText}
          disabled={disabledOk}
          onClick={onOk}
          isFocused={focusedButton === 'ok'}
          isLoading={isLoadingOk}
          classNames={isAccentOk ? ['is-accent'] : []}
        />
      </div>
    )}
    {!hideCancel && (
      <div className="btn-wrapper">
        <Button
          text={cancelText}
          onClick={onCancel}
          isFocused={focusedButton === 'cancel'}
          type="reset"
          iconName={cancelIconName}
          disabled={disabledCancel}
          withConfirmation={withCancelConfirmation}
          confirmationText={cancelConfirmationText}
          confirmationTitle={cancelConfirmationTitle}
        />
      </div>
    )}
  </Wrapper>
);

export default ActionButtons;
