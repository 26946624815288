import React from 'react';
import { ImmutableArray } from 'seamless-immutable';

import { Box, Flex } from '@rebass/grid';

import { Button } from 'components';

import {
  IProductPenalty,
  THandleAddProductPenalty,
  THandleDeleteProductPenalty,
  THandleGetProductPenalties,
  TResetPayments,
} from 'store';

import { ISelectValue } from 'types';

import PenaltiesTable from './PenaltiesTable';
import PenaltyForm from './PenaltyForm';

interface IPenalties {
  addPenalty: THandleAddProductPenalty;
  applicationCondition: ISelectValue;
  deletePenalty: THandleDeleteProductPenalty;
  getPenalties: THandleGetProductPenalties;
  isAdding: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  isReadOnly: boolean;
  onCancel: () => void;
  penalties: ImmutableArray<IProductPenalty>;
  resetPayments: TResetPayments;
}

const Penalties: React.FC<IPenalties> = ({
  addPenalty,
  applicationCondition,
  deletePenalty,
  getPenalties,
  isAdding,
  isDeleting,
  isLoading,
  isReadOnly,
  onCancel,
  penalties,
  resetPayments,
}) => {
  React.useEffect(
    () => {
      getPenalties();
      return () => resetPayments();
    },
    [getPenalties, resetPayments]
  );

  return (
    <>
      {!isReadOnly && (
        <PenaltyForm
          addPenalty={addPenalty}
          applicationCondition={applicationCondition}
          isDisabled={isAdding}
          isAdding={isAdding}
        />
      )}

      <Box mt="30px">
        <PenaltiesTable
          deletePenalty={deletePenalty}
          isDeleting={isDeleting}
          isLoading={isLoading}
          isReadOnly={isReadOnly}
          penalties={penalties}
        />
      </Box>

      <Flex
        justifyContent="flex-end"
        mt="15px"
      >
        <Button
          text="Close"
          onClick={onCancel}
        />
      </Flex>
    </>
  );
};

export default Penalties;
