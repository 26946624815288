export enum TransactionTypeId {
  UNSPECIFIED = 0,
  FUNDS_DISBURSEMENT = 7,
  FEE_INTERNAL = 9,
  INTEREST_PAYABLE = 10,
  LIMIT_ADJUSTMENT = 14,
  PENALTIES_INTERNAL = 17,
  REPAYMENT_DIRECT_DEBIT = 24,
  RESERVED_1 = 26,
  RESERVED_2 = 29,
}

export const disabledTransactionTypesList = [
  TransactionTypeId.FEE_INTERNAL,
  TransactionTypeId.INTEREST_PAYABLE,
  TransactionTypeId.LIMIT_ADJUSTMENT,
  TransactionTypeId.PENALTIES_INTERNAL,
  TransactionTypeId.REPAYMENT_DIRECT_DEBIT,
  TransactionTypeId.RESERVED_1,
  TransactionTypeId.RESERVED_2,
  TransactionTypeId.UNSPECIFIED,
];

export enum DebitCreditIndicator {
  CREDIT = 'C',
  DEBIT = 'D',
  NOT_APPLICABLE = 'U',
}

export const debitCreditIndicatorOptions = [
  { value: DebitCreditIndicator.DEBIT, label: 'Debit' },
  { value: DebitCreditIndicator.CREDIT, label: 'Credit' },
  { value: DebitCreditIndicator.NOT_APPLICABLE, label: 'Not applicable' },
];

export enum TransactionStatus {
  PENDING = 'P',
  SETTLED = 'S',
  CHARGED_BACK = 'C',
  INVALID = 'I',
}

export const transactionStatusOptions = [
  { value: TransactionStatus.PENDING, label: 'Pending' },
  { value: TransactionStatus.SETTLED, label: 'Settled' },
  { value: TransactionStatus.CHARGED_BACK, label: 'Charged back' },
  { value: TransactionStatus.INVALID, label: 'Invalid' },
];
