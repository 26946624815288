export enum ModalType {
  CONFIRMATION = 'confirmationModal',
  DETAILS = 'detailsModal',
  MESSAGE = 'messageModal',
}

export enum ModalName {
  CONFIRMATION = 'ConfirmationModal',
  MESSAGE = 'MessageModal',

  CHANGE_PASSWORD = 'ChangePasswordModal',
  LOGIN_CODE_2FA = 'LoginCode2faModal',
  REGISTER_2FA = 'Register2faModal',

  ADD_PRODUCT = 'AddProductModal',
  DETAILS_PRODUCT = 'DetailsProductModal',
  UPDATE_PRODUCT_APR = 'UpdateProductAPRModal',

  ADD_CUSTOMER = 'AddCustomerModal',
  DETAILS_CUSTOMER = 'DetailsCustomerModal',

  ADD_ACCOUNT = 'AddAccountModal',
  DETAILS_ACCOUNT = 'DetailsAccountModal',
  MANUAL_TRANSACTION = 'ManualTransactionModal',
  MANUAL_TRANSACTION_RESULT = 'ManualTransactionResultModal',

  STATEMENT = 'StatementModal',

  TRANSACTION = 'TransactionModal',
  SETTLE_TRANSACTION = 'SettleTransactionModal',
  SETTLE_TRANSACTION_RESULT = 'SettleTransactionResultModal',

  ADD_INSTITUTION = 'AddInstitutionModal',
  DETAILS_INSTITUTION = 'DetailsInstitutionModal',

  ADD_ENDPOINT = 'AddEndPointModal',
  DETAILS_ENDPOINT = 'DetailsEndpointModal',

  ADD_INTERFACE = 'AddInterfaceModal',
  DETAILS_INTERFACE = 'DetailsInterfaceModal',

  ADD_SYSTEM_PROPERTY = 'AddSystemPropertyModal',
  DETAILS_SYSTEM_PROPERTY = 'DetailsSystemPropertyModal',

  ADD_USER = 'AddUserModal',
  DETAILS_USER = 'DetailsUserModal',

  ADD_USERS_GROUP = 'AddUsersGroupModal',
  DETAILS_USERS_GROUP = 'DetailsUsersGroupModal',

  API_CALL = 'ApiCallModal',
}
