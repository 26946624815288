import { getFormValues, reset as resetForm  } from 'redux-form';

import { FormName, ModalName } from 'consts';
import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import {
  activeItemIdSelector,
  activeProductTypeSelector,
  closeModal,
  defaultCurrentAccountSelector,
  openModal,
} from 'store';

import {
  ActionTypeKeys,
  IAddAccountAction,
  IFilterAccountsAction,
  IGetAccountAction,
  IMakeTransactionAction,
  IUpdateAccountAction,
} from './actionTypes';

import * as api from './api';

import {
  IAccount,
  IAccountData,
  IAccountsFilter,
} from './types';

import {
  IManualTransactionFromValues,
  IManualTransactionRequest,
} from './typesManualTransaction';

import {
  normalizeDataForCreating,
  normalizeDataForUpdating,
  normalizeFilterQueryString,
} from './utils';

import { normalizeManualTransactionValuesForSending } from './utilsManualTransaction';

/**
 * Filter accounts action
 */

export type TFilterAccounts = (queryString: string, productType: string | number) =>
  IFilterAccountsAction;

export type THandleFilterAccounts = (data: IAccountsFilter) => Thunk<void>;

export const filterAccounts: TFilterAccounts = (queryString, productType) => ({
  type: ActionTypeKeys.FILTER_ACCOUNTS,
  payload: api.filterAccounts(queryString, productType),
});

export const handleFilterAccounts: THandleFilterAccounts = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterQueryString(data);
        const productType = data?.productType?.value;

        await dispatch(filterAccounts(queryString, productType));
      },
      dispatch
    );
  };

/**
 * Add account action
 */

export type TAddAccount = (
  data: Partial<IAccountData>,
  productType: string | number
) => IAddAccountAction;

export type THandleAddAccount = (data: Partial<IAccount>) => Thunk<void>;

export const addAccount: TAddAccount = (data, productType) => ({
   type: ActionTypeKeys.ADD_ACCOUNT,
   payload: api.addAccount(data, productType),
 });

export const handleAddAccount: THandleAddAccount = data => async dispatch => {
   errorDecoratorUtil.withErrorHandler(
     async () => {
       const productType = data.productType.value;
       const normalizedData = normalizeDataForCreating(data);

       const res = await dispatch(addAccount(normalizedData, productType)) as any;
       dispatch(closeModal(ModalName.ADD_ACCOUNT));

       dispatch(openModal({
         name: ModalName.MESSAGE,
         payload: {
           title: 'Account has been created',
           message: `Account ID: ${res.value.account_id}`,
           isSuccess: true,
         },
       }));
     },
     dispatch
   );
 };

/**
 * Update account action
 */

export type TUpdateAccount = (
  data: Partial<IAccountData>,
  accountId: number,
  productType: string
) => IUpdateAccountAction;

export type THandleUpdateAccount = (data: Partial<IAccount>) => Thunk<void>;

export const updateAccount: TUpdateAccount = (data, accountId, productType) => ({
  type: ActionTypeKeys.UPDATE_ACCOUNT,
  payload: api.updateAccount(data, accountId, productType),
});

export const handleUpdateAccount: THandleUpdateAccount = data => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();
      const accountId = activeItemIdSelector(state);
      const currentData = defaultCurrentAccountSelector(state);
      const productType = activeProductTypeSelector(state);
      const normalizedData = normalizeDataForUpdating(data, currentData);

      await dispatch(updateAccount(normalizedData, accountId, productType));
      await dispatch(getAccount(accountId, productType));
    },
    dispatch
  );
};

/**
 * Get account action
 */

export type TGetAccount = (accountId: number, productType: string) => IGetAccountAction;
export type THandleGetAccount = () => Thunk<void>;

export const getAccount: TGetAccount = (accountId, productType) => ({
  type: ActionTypeKeys.GET_ACCOUNT,
  payload: api.getAccount(accountId, productType),
});

export const handleGetAccount: THandleGetAccount = () => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();

      const accountId = activeItemIdSelector(state);
      const productType = activeProductTypeSelector(state);

      await dispatch(getAccount(accountId, productType));
    },
    dispatch
    );
  };

/**
 * Make manual transaction action
 */

export type TMakeTransaction = (
  data: IManualTransactionRequest,
  productType: string
) => IMakeTransactionAction;

export type THandleMakeTransaction = () => Thunk<void>;

export const makeTransaction: TMakeTransaction = (data, productType) => ({
   type: ActionTypeKeys.MAKE_TRANSACTION,
   payload: api.makeTransaction(data, productType),
 });

export const handleMakeTransaction: THandleMakeTransaction = () =>
   async (dispatch, getState) => {
     errorDecoratorUtil.withErrorHandler(
       async () => {
         const formValues = getFormValues(FormName.MANUAL_TRANSACTION);
         const state = getState();
         const productType = activeProductTypeSelector(state);

         const normalizedData = normalizeManualTransactionValuesForSending(
           formValues(state) as IManualTransactionFromValues
         );

         await dispatch(makeTransaction(normalizedData, productType));

         dispatch(openModal({ name: ModalName.MANUAL_TRANSACTION_RESULT }));
         dispatch(resetForm(FormName.MANUAL_TRANSACTION));
       },
       dispatch
     );
   };

/**
 * Reset accounts action
 */

export type TResetAccounts = () => void;

export const resetAccounts: TResetAccounts = () => ({
  type: ActionTypeKeys.RESET_ACCOUNTS,
});

/**
 * Reset account action
 */

export type TResetAccount = () => void;

export const resetAccount: TResetAccount = () => ({
  type: ActionTypeKeys.RESET_ACCOUNT,
});

/**
 * Reset transaction result action
 */

export type TResetTransactionResult = () => void;

export const resetTransactionResult: TResetTransactionResult = () => ({
  type: ActionTypeKeys.RESET_TRANSACTION_RESULT,
});
