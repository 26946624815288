import React from 'react';

import { Modal } from 'components';
import { ModalName } from 'consts';
import { IWithModal, withModal } from 'HOCs';

import { AccountForm } from './../forms';

interface IAddAccountModal extends IWithModal {}

const modalName = ModalName.ADD_ACCOUNT;

const AddAccountModal: React.FC<IAddAccountModal> = ({ closeModal }) => (
  <Modal
    name={modalName}
    title="Create account"
    containerWidth="740px"
    minContainerHeight="500px"
  >
    <AccountForm onCancel={() => closeModal(modalName)} />
  </Modal>
);

export default withModal(AddAccountModal);
