import { ImmutableArray } from 'seamless-immutable';

import { ISelectValue } from 'types';
import { stringsUtil } from 'utils';
import {
  IDictionaryEventDataElemsFilter,
  IDictionaryEventDataElemsFilterToSend,
  IDictionaryEventsFilter,
  IDictionaryEventsFilterToSend,
  IIdNamePair,
} from './types';

export const valueLabelParse = (data: ImmutableArray<IIdNamePair>): Array<ISelectValue> => {
  return data.asMutable().map(el => {
    const { id, name } = el;

    return {
      value: id,
      label: name,
    };
  });
};

export const normalizeFilterEventDataElemsQueryString = (
  data: IDictionaryEventDataElemsFilter
): string => {
  const { eventId } = data || {};

  const normalizedData: IDictionaryEventDataElemsFilterToSend = {
    event_id: eventId?.value || null,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeFilterEventsQueryString = (data: IDictionaryEventsFilter): string => {
  const { all, productType } = data || {};

  const normalizedData: IDictionaryEventsFilterToSend = {
    all: all || null,
    product_type: productType?.value || null,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};
