import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import { FormName } from 'consts';
import TransactionsFilter from './TransactionsFilter';

import { IStoreState } from 'store';

const formSelector = formValueSelector(FormName.FILTER);

const mapStateToProps = (state: IStoreState) => ({
  currentProductType: formSelector(state, "productType"),
});

export default connect(
  mapStateToProps
)(TransactionsFilter);
