import React from 'react';

import { Box, Flex } from '@rebass/grid';

import { Button, HorizontalLine, Text, UserIcon } from 'components';
import { IconName, ModalName, UserStatus } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import { IProfile } from 'store';
import styled from 'theme';

const UserIconStyled = styled(UserIcon)`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.gray};
`;

interface IHome extends IWithModal {
  profile: IProfile;
}

const Home: React.FC<IHome> = ({
  openModal,
  profile,
}) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="calc(100vh - 230px)"
    >

      <Box>
        <Box mb="5px">
          <UserIconStyled size="40" />
          <Text bold={true} fontSize="16px">{profile?.fullName}</Text>
          <Text fontSize="16px">{profile?.username}</Text>

          <HorizontalLine />

          <Text>{profile.institutionName}</Text>
        </Box>

        {profile?.lastActivity && (
          <Text light={true}>Your last activity: {profile?.lastActivity}</Text>
        )}
      </Box>

      {profile?.status === UserStatus.REGISTRATION_PENDING && (
        <Box mt="50px">
          <Text
            fontSize="14px"
            accentColor={true}
          >
            Complete registration in by enabling second authentication
          </Text>

          <Flex
            alignItems="center"
            justifyContent="center"
            mt="15px"
          >
            <Button
              text="Enable second factor authentication"
              iconName={IconName.SMARTPHONE}
              onClick={() => openModal({ name: ModalName.REGISTER_2FA })}
            />
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default withModal(Home);
