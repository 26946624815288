import React from 'react';

import { Box, Flex } from '@rebass/grid';
import { InjectedFormProps, reduxForm } from 'redux-form';

import {
  ActionButtons,
  Button,
} from 'components';
import { FormName, IconName } from 'consts';
import {
  IProduct,
  THandleCloneProduct,
  THandleDeleteProduct,
  THandleGetProduct,
  THandleUpdateProduct,
} from 'store';
import GeneralProductFields from './../GeneralProductFields';

interface IGeneralProductForm {
  activeProductType: string;
  cloneProduct: THandleCloneProduct;
  deleteProduct: THandleDeleteProduct;
  getProduct: THandleGetProduct;
  initialValues: IProduct;
  isCloning: boolean;
  isDeleting: boolean;
  isLocked: boolean;
  isReadOnly: boolean;
  isUpdating: boolean;
  onCancel: () => void;
  updateProduct: THandleUpdateProduct;
}

type TGeneralProductForm = IGeneralProductForm & InjectedFormProps<{}, IGeneralProductForm>;

const GeneralProductForm: React.FC<TGeneralProductForm> = ({
  activeProductType,
  cloneProduct,
  deleteProduct,
  dirty,
  getProduct,
  handleSubmit,
  initialValues,
  isCloning,
  isDeleting,
  isLocked,
  isReadOnly,
  isUpdating,
  onCancel,
  pristine,
  updateProduct,
}) => {
  React.useEffect(
    () => {
      getProduct();
    },
    [getProduct]
  );

  return (
    <form onSubmit={isReadOnly ? null : handleSubmit(updateProduct)}>
      <GeneralProductFields
        initialValues={initialValues}
        isEditMode={true}
        isLoading={isDeleting || isUpdating || isCloning}
        isLocked={isLocked}
        isReadOnly={isReadOnly}
        productType={activeProductType}
        statementCycleType={initialValues?.statementCycleType}
      />

      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt="15px"
      >
        <Flex alignItems="center">
          {!isReadOnly && !isLocked && (
            <Button
              text="Delete"
              iconName={IconName.DELETE}
              type="reset"
              isLoading={isDeleting}
              withConfirmation={true}
              confirmationText="Delete the product?"
              onClick={deleteProduct}
            />
          )}

          {!isReadOnly && (
            <Box ml="7px">
              <Button
                text="Clone"
                iconName={IconName.CLONE}
                onClick={cloneProduct}
                type="reset"
                isLoading={isCloning}
              />
            </Box>
          )}
        </Flex>

        <ActionButtons
          okText="Save"
          cancelText="Close"
          onCancel={onCancel}
          withCancelConfirmation={dirty}
          disabledOk={pristine}
          hideOk={isReadOnly}
          isLoadingOk={isUpdating}
        />
      </Flex>
    </form>
  );
};

export default reduxForm<{}, IGeneralProductForm>({
  form: FormName.GENERAL_PRODUCT,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(GeneralProductForm);
