import { reset as resetForm } from 'redux-form';

import { FormName, ModalName } from 'consts';
import { activeItemIdSelector, closeModal, defaultCurrentUserSelector, openModal } from 'store';
import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';
import {
  ActionTypeKeys,
  IAddUserAction,
  IFilterUsersAction,
  IGetUserAction,
  IUpdateUserAction,
} from './actionTypes';

import * as api from './api';

import {
  IUserData,
  IUserDetails,
  IUsersFilter,
} from './types';

import {
  normalizeDataForCreating,
  normalizeDataForUpdating,
  normalizeFilterQueryString,
} from './utils';

/**
 * Filter users action
 */

export type TFilterUsers = (queryString: string) => IFilterUsersAction;
export type THandleFilterUsers = (data: IUsersFilter) => Thunk<void>;

export const filterUsers: TFilterUsers = queryString => ({
  type: ActionTypeKeys.FILTER_USERS,
  payload: api.filterUsers(queryString),
});

export const handleFilterUsers: THandleFilterUsers = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterQueryString(data);

        await dispatch(filterUsers(queryString));
      },
      dispatch
    );
  };

/**
 * Add user action
 */

export type TAddUser = (data: Partial<IUserData>) => IAddUserAction;
export type THandleAddUser = (data: Partial<IUserDetails>) => Thunk<void>;

export const addUser: TAddUser = data => ({
  type: ActionTypeKeys.ADD_USER,
  payload: api.addUser(data),
});

export const handleAddUser: THandleAddUser = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const normalizedData = normalizeDataForCreating(data);

        const res = (await dispatch(
          addUser(normalizedData)
        )) as any;

        dispatch(closeModal(ModalName.ADD_USER));

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: {
              title: 'User has been created',
              message: `User ID: ${res.value.user_id}`,
              isSuccess: true,
            },
          })
        );
      },
      dispatch
    );
  };

/**
 * Get user action
 */

export type TGetUser = (id: number) => IGetUserAction;
export type THandleGetUser = () => Thunk<void>;

export const getUser: TGetUser = id => ({
  type: ActionTypeKeys.GET_USER,
  payload: api.getUser(id),
});

export const handleGetUser: THandleGetUser = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const id = activeItemIdSelector(state);

        await dispatch(getUser(id));
      },
      dispatch
    );
  };

/**
 * Update user action
 */

export type TUpdateUser = (data: Partial<IUserData>, userId: number) =>
  IUpdateUserAction;

export type THandleUpdateUser = (data: Partial<IUserDetails>) => Thunk<void>;

export const updateUser: TUpdateUser = (data, userId) => ({
  type: ActionTypeKeys.UPDATE_USER,
  payload: api.updateUser(data, userId),
});

export const handleUpdateUser: THandleUpdateUser = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const userId = activeItemIdSelector(state);
        const currentData = defaultCurrentUserSelector(state);
        const normalizedData = normalizeDataForUpdating(data, currentData);

        await dispatch(updateUser(normalizedData, userId));
        await dispatch(getUser(userId));

        dispatch(resetForm(FormName.USER));
      },
      dispatch
    );
  };

/**
 * Reset users action
 */

export type TResetUsers = () => void;

export const resetUsers: TResetUsers = () => ({
  type: ActionTypeKeys.RESET_USERS,
});
