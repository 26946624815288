import React from 'react';

import { Modal, PersonIcon, Tabs, TabsPanel } from 'components';
import { ModalName } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import { THandleGetCustomer, TResetCustomer } from 'store';

import { CustomerForm } from './../../forms';

interface IDetailsCustomerModal extends IWithModal {
  getCustomer: THandleGetCustomer;
  isFormDirty: boolean;
  isLoading: boolean;
  resetCustomer: TResetCustomer;
}

const modalName = ModalName.DETAILS_CUSTOMER;

const DetailsCustomerModal: React.FC<IDetailsCustomerModal> = ({
  closeModal,
  getCustomer,
  isFormDirty,
  isLoading,
  isReadOnlyPage,
  resetCustomer,
}) => {
  React.useEffect(
    () => {
      getCustomer();
      return () => resetCustomer();
    },
    [getCustomer, resetCustomer]
  );

  const handleOnCancel = React.useCallback(
    () => closeModal(modalName),
    [closeModal]
  );

  return (
    <Modal
      name={modalName}
      isLoading={isLoading}
      title="Customer"
      containerWidth="1010px"
      minContainerHeight="837px"
      withCloseConfirmation={isFormDirty}
    >
      <Tabs>
        <TabsPanel
          title="General"
          Icon={<PersonIcon size="20" />}
          withConfirmation={isFormDirty}
        >
          <CustomerForm
            isEditMode={true}
            isReadOnly={isReadOnlyPage}
            onCancel={handleOnCancel}
          />
        </TabsPanel>
      </Tabs>
    </Modal>
  );
};

export default withModal(DetailsCustomerModal);
