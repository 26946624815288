import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import { Button, CheckboxField, HorizontalLine, SelectField } from 'components';
import { FormName, IconName } from 'consts';
import { IUsersGroup, THandleAddUsersGroupPermissions } from 'store';
import { ISelectValue } from 'types';

interface IEditGroupPermissionForm {
  addGroupPermission: THandleAddUsersGroupPermissions;
  isAdding: boolean;
  isLoadingPermissions: boolean;
  uiItemsOptions: Array<ISelectValue>;
  usersGroup: IUsersGroup;
}

type TEditGroupPermissionForm = IEditGroupPermissionForm &
  InjectedFormProps<{}, IEditGroupPermissionForm>;

const EditUsersGroupPermissionsForm: React.FC<TEditGroupPermissionForm> = ({
  addGroupPermission,
  usersGroup,
  handleSubmit,
  isAdding,
  isLoadingPermissions,
  pristine,
  uiItemsOptions,
}) => {
  // eslint-disable-next-line
  const handleSubmitForm = React.useCallback(
    handleSubmit(data => addGroupPermission({
      usersGroupId: usersGroup?.id,
      ...data,
    })),
    [handleSubmit, addGroupPermission, usersGroup]
  );

  return (
    <form onSubmit={handleSubmitForm}>
      <Flex
        alignItems="flex-end"
        flexWrap="wrap"
        mx="-8px"
        mb="15px"
      >
        <Box width={[4 / 5]} p="8px">
          <Field
            id="uiItems"
            name="uiItems"
            placeholder="Select UI Item"
            component={SelectField}
            label="UI Item"
            isMulti={true}
            isDisabled={isAdding || isLoadingPermissions}
            options={uiItemsOptions}
          />
        </Box>
        <Box width={[1 / 5]} p="8px">
          <Field
            id="permission"
            name="permission"
            component={CheckboxField}
            label={'"Write" Allowed'}
            disabled={isAdding}
          />
        </Box>

        < HorizontalLine />

        <Flex
          width={[1]}
          justifyContent="flex-end"
          p="8px"
        >
          <Button
            iconName={IconName.ADD}
            text="Add to the group"
            disabled={pristine}
            isLoading={isAdding}
          />
        </Flex>
      </Flex>
    </form >
  );
};

export default reduxForm<{}, IEditGroupPermissionForm>({
  form: FormName.ADD_USERS_GROUP_PERMISSIONS,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(EditUsersGroupPermissionsForm);
