import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import { ActionButtons, InputField } from 'components';
import { FormName } from 'consts';
import { IAuthCode, THandleProvideAuthKey } from 'store';
import { formErrorUtil } from 'utils';

interface ICodeForm {
  isLoading: boolean;
  onCancel: () => void;
  provideAuthKey: THandleProvideAuthKey;
}

type TCodeForm = ICodeForm & InjectedFormProps<{}, ICodeForm>;

const CodeForm: React.FC<TCodeForm> = ({
  handleSubmit,
  isLoading,
  onCancel,
  provideAuthKey,
}) => (
  <form onSubmit={handleSubmit(data => provideAuthKey(data as IAuthCode))}>
    <Flex alignItems="center">
      <Box width="110px" mr="15px">
        <Field
          id="code"
          name="code"
          placeholder="Enter code"
          component={InputField}
          disabled={isLoading}
          autoFocus={true}
          isNumber={true}
          validate={[
            formErrorUtil.isRequired,
            formErrorUtil.isInteger,
            formErrorUtil.exactNumberValue6,
          ]}
        />
      </Box>
      <ActionButtons
        okText="Log In"
        isLoadingOk={isLoading}
        isAccentOk={true}
        onCancel={onCancel}
      />
    </Flex>
  </form>
);

export default reduxForm<{}, ICodeForm>({
  form: FormName.LOGIN_CODE,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(CodeForm);
