import React from 'react';
import { ImmutableArray } from 'seamless-immutable';

import { Box } from '@rebass/grid';
import { Table, TableCell, TableHeader, withSpinner } from 'components';
import { ILoanIllustrationScheduleItem } from 'store';
import { ITableCell } from 'types';

type TCell<T extends keyof ILoanIllustrationScheduleItem> =
  ITableCell<ILoanIllustrationScheduleItem[T]>;

const tableColumns = [
  {
    maxWidth: 110,
    accessor: 'sequenceNumber',
    Header: <TableHeader title="Sequence Number" />,
    Cell: (props: TCell<'sequenceNumber'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 110,
    accessor: 'interestRepaid',
    Header: <TableHeader title="Interest Repaid" />,
    Cell: (props: TCell<'interestRepaid'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 110,
    accessor: 'principalRepaid',
    Header: <TableHeader title="Principal Repaid" />,
    Cell: (props: TCell<'principalRepaid'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 200,
    accessor: 'principalBeforeRepayment',
    Header: <TableHeader title="Principal Before Repayment" />,
    Cell: (props: TCell<'principalBeforeRepayment'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 200,
    accessor: 'principalAfterRepayment',
    Header: <TableHeader title="Principal After Repayment" />,
    Cell: (props: TCell<'principalAfterRepayment'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 120,
    accessor: 'repaymentAmount',
    Header: <TableHeader title="Repayment Amount" />,
    Cell: (props: TCell<'repaymentAmount'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 110,
    accessor: 'repaymentDate',
    Header: <TableHeader title="Repayment Date" />,
    Cell: (props: TCell<'repaymentDate'>) => (
      <TableCell
        value={props.value}
        isDate={true}
      />
    ),
  },
];

interface ILoanIllustrationTable {
  data: ImmutableArray<ILoanIllustrationScheduleItem>;
}

const LoanIllustrationTable: React.FC<ILoanIllustrationTable> = ({ data }) => (
  <Box pb="10px">
    <Table
      data={data}
      columns={tableColumns}
      pageSize={12}
    />
  </Box>
);

export default withSpinner()(LoanIllustrationTable);
