import React from 'react';
import { Field } from 'redux-form';

import { Box } from '@rebass/grid';

import { InputField, MaskField, NewLine, SelectField } from 'components';
import { DateFormat, MaskFormat } from 'consts';
import { IEndpointsFilter, THandleFilterEndpoints } from 'store';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface IApiCallsFilter {
  endpointsFilterValues: IEndpointsFilter;
  endpointsOptions: Array<ISelectValue>;
  filterEndpoints: THandleFilterEndpoints;
  institutionsOptions: Array<ISelectValue>;
  isDisabled: boolean;
  isLoadingEndpoints: boolean;
}

const ApiCallsFilter: React.FC<IApiCallsFilter> = ({
  endpointsFilterValues,
  endpointsOptions,
  filterEndpoints,
  institutionsOptions,
  isDisabled,
  isLoadingEndpoints,
}) => {
  React.useEffect(
    () => {
      if (endpointsFilterValues) {
        filterEndpoints(endpointsFilterValues);
      }
    },
    [filterEndpoints, endpointsFilterValues]
  );

  return (
    <>
      <Box width="360px" p="8px">
        <Field
          id="institutionId"
          name="institutionId"
          component={SelectField}
          label="Institution"
          options={institutionsOptions}
          placeholder="Select Institution"
          isClearable={false}
          isDisabled={isDisabled}
          isRequired={true}
          validate={[formErrorUtil.isRequired]}
        />
      </Box>
      <Box width="360px" p="8px">
        <Field
          id="endpointId"
          name="endpointId"
          component={SelectField}
          label="Endpoint"
          options={endpointsOptions}
          placeholder="Select endpoint"
          isLoading={isLoadingEndpoints}
          isDisabled={isDisabled}
          isRequired={true}
          validate={[formErrorUtil.isRequired]}
        />
      </Box>

      <NewLine />

      <Box width="180px" p="8px">
        <Field
          id="dateFrom"
          name="dateFrom"
          component={MaskField}
          label="Date From"
          placeholder={DateFormat.DATE}
          mask={MaskFormat.DATE}
          disabled={isDisabled}
          isRequired={true}
          validate={[
            formErrorUtil.isRequired,
            formErrorUtil.isDate,
          ]}
        />
      </Box>
      <Box width="180px" p="8px">
        <Field
          id="dateTo"
          name="dateTo"
          component={MaskField}
          label="Date To"
          placeholder={DateFormat.DATE}
          mask={MaskFormat.DATE}
          disabled={isDisabled}
          isRequired={true}
          validate={[
            formErrorUtil.isRequired,
            formErrorUtil.isDate,
          ]}
        />
      </Box>
      <Box width="360px" p="8px">
        <Field
          id="apiName"
          name="apiName"
          component={InputField}
          label="API Name"
          placeholder="Enter api name"
          disabled={isDisabled}
        />
      </Box>
    </>
  );
};

export default ApiCallsFilter;
