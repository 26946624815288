import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';
import { activeItemIdSelector, activeProductTypeSelector } from 'store/domains/utils';
import { ActionTypeKeys } from './actionTypes';
import {
  normalizeCommonTransaction,
  normalizeCurrentTransaction,
  normalizeSettlementFormValues,
  normalizeSettleTransactionResult,
} from './utils';

/** Transactions selectors */

export const defaultTransactionsSelector = (state: IStoreState) =>
  state.ledger.transactions.transactions;

export const transactionsSelector = createSelector(
  defaultTransactionsSelector,
  data => data?.map(el => normalizeCommonTransaction(el))
);

export const transactionsTotalPagesSelector = (state: IStoreState) =>
  state.ledger.transactions.totalPages;

export const transactionsTotalResultsSelector = (state: IStoreState) =>
  state.ledger.transactions.totalResults;

export const transactionsCurrentPageSelector = (state: IStoreState) =>
  state.ledger.transactions.pageNumber;

/** Current transaction selectors */

export const defaultCurrentTransactionSelector = (state: IStoreState) =>
  state.ledger.transactions.currentTransaction;

export const currentTransactionSelector = createSelector(
  defaultCurrentTransactionSelector,
  transaction => normalizeCurrentTransaction(transaction)
);

/**
 * Settle transaction selectors
 */

export const transactionForSettlementSelector = createSelector(
  defaultTransactionsSelector,
  activeItemIdSelector,
  activeProductTypeSelector,
  (transactions, transactionId, productType) => {
    const currentTransaction = transactions
      .find(transaction =>
          transaction.id === transactionId && transaction.product_type === productType);

    return normalizeSettlementFormValues(currentTransaction);
  }
);

export const defaultSettleTransactionResultSelector = (state: IStoreState) =>
  state.ledger.transactions.settlementResult;

export const settleTransactionResultSelector = createSelector(
  defaultSettleTransactionResultSelector,
  activeProductTypeSelector,
  (data, productType) => normalizeSettleTransactionResult(data, productType)
);

/**
 * Transactions loading selectors
 */

export const isLoadingTransactionsSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_TRANSACTIONS,
]);

export const isLoadingTransactionSelector = createLoadingSelector([
  ActionTypeKeys.GET_TRANSACTION,
]);

export const isSettlingTrLoadingSelector = createLoadingSelector([
  ActionTypeKeys.SETTLE_TRANSACTION,
]);
