import { Box, Flex } from '@rebass/grid';
import { CheckedBoxIcon, UncheckedBoxIcon } from 'components/Icons';
import React from 'react';
import styled from 'theme';
import { scrollbarCss } from 'theme/styles';

const ListWrapper = styled.div`
  overflow-x: auto;
  ${scrollbarCss};

  table {
    font-size: 13px;
    list-style-type: none;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
  }

  td {
    padding: 5px 10px;
    transition: all .1s linear;
    min-width: 120px;
    vertical-align: top;

    &:first-child {
      color: ${({ theme }) => theme.colors.darkGray};
      max-width: 25%;
    }
  }

  tr {
    transition: all .1s linear;

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.lighterGray};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.lightGray};
      box-shadow: ${({ theme }) => theme.shadows.bottomBox};
    }
  }
`;

const CheckboxIcon: React.FC<{ isChecked: boolean }> = ({ isChecked }) => {
  return (
    <Flex alignItems="center">
      {isChecked ? (<CheckedBoxIcon />) : (<UncheckedBoxIcon />)}
      <Box pl="5px">{isChecked ? 'YES' : 'NO'}</Box>
    </Flex>
  );
};

interface IList {
  items: Array<Array<string | number | boolean>>;
}

const List: React.FC<IList> = ({ items }) => {

  return (
    <ListWrapper>
      <table>
        <tbody>
          {items?.map((item, index) => (
            <tr key={index}>
              {item.map((el, ind) => (
                <td key={ind}>
                  {typeof el === 'boolean' ? (<CheckboxIcon isChecked={el} />) : el || '-'}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </ListWrapper>
  );
};

export default List;
