import React from 'react';
import Editor from 'react-simple-code-editor';

import { highlight, languages } from 'prismjs/components/prism-core';

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import './prism.css';

import { EditorWrapper } from './EditorWrapper';

interface IHighlightCode extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  fontSize?: number;
  height?: string;
  isScrollbarBottom?: boolean;
  minHeight?: string;
  padding?: number;
  setCursorCurrentPosition?: () => void;
  whiteSpacePre?: boolean;
}

const HighlightCode: React.FC<IHighlightCode> = ({
  fontSize,
  height,
  id,
  isScrollbarBottom,
  minHeight,
  name,
  onChange,
  padding = 10,
  placeholder,
  disabled,
  setCursorCurrentPosition,
  value,
  whiteSpacePre = true,
}) => {
  React.useEffect(
    () => {
      const wrapper = wrapperRef.current;

      if (isScrollbarBottom && wrapper) {
        wrapper.scrollTop = wrapper.scrollHeight;
      }
    },
    [isScrollbarBottom]
  );

  const wrapperRef = React.useRef(null);

  const handleChange = React.useCallback(
    (code: any) => onChange ? onChange(code) : null,
    [onChange]
  );

  const addFocusClass = () => {
    wrapperRef.current.classList.add('is-focus');
  };

  const removeFocusClass = () => {
    wrapperRef.current.classList.remove('is-focus');
  };

  return (
    <EditorWrapper
      ref={wrapperRef}
      height={height}
      minHeight={minHeight}
      whiteSpacePre={whiteSpacePre}
      className={disabled ? 'is-disabled' : ''}
    >
      <Editor
        value={value.toString()}
        onValueChange={handleChange}
        highlight={code => highlight(code, languages.js)}
        textareaId={id}
        name={name}
        placeholder={placeholder}
        onFocus={addFocusClass}
        onBlur={removeFocusClass}
        onKeyUp={setCursorCurrentPosition}
        onClick={setCursorCurrentPosition}
        tabSize={4}
        padding={padding}
        className="editor"
        disabled={disabled}
        style={{
          overflow: 'visible',
          fontFamily: '"Roboto Mono", monospace',
          fontSize: fontSize ? fontSize : 8.5,
        }}
      />
    </EditorWrapper>
  );
};

export default HighlightCode;
