// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
// import { throttleUtil } from 'utils';

// import {
//   usersGroupMembersMock,
//   usersGroupPermissionsMock,
//   usersGroupsMock,
// } from './mock';
import {
  IUsersGroupData,
  IUsersGroupMemberDataToSend,
  IUsersGroupPermissionData,
  IUsersGroupPermissionDataToSend,
} from './types';

/**
 * Get users groups API
 */
export const getUsersGroups = () =>
  // throttleUtil.getDataAfter(usersGroupsMock, 500);
  apiClientService.get('users-groups');

/**
 * Add users group API
 */
export const addUsersGroup = (data: Partial<IUsersGroupData>) =>
  // throttleUtil.getDataAfter(successResponseMock, 1000);
  apiClientService.post('users-groups', { data });

/**
 * Update users group API
 */
export const updateUsersGroup = (data: Partial<IUsersGroupData>, groupId: number) =>
  // throttleUtil.getDataAfter(successResponseMock, 1000);
  apiClientService.put(`users-groups/${groupId}`, { data });

// ---------------------------------------------------------------------

/**
 * Get users group members
 */
export const getUsersGroupMembers = (groupId: number) =>
  // throttleUtil.getDataAfter(usersGroupMembersMock, 500);
  apiClientService.get(`users-group-members?user_group_id=${groupId}`);

/**
 * Add a user to the users group
 */
export const addUsersGroupMember = (data: IUsersGroupMemberDataToSend) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.post('users-group-members', { data });

/**
 * Delete a user from the users group
 */
export const deleteUsersGroupMember = (groupId: number, userId: number) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.delete(`users-group-members?user_group_id=${groupId}&user_id=${userId}`);

// ---------------------------------------------------------------------

/**
 * Get users group permissions API
 */
export const getUsersGroupPermissions = (groupId: number) =>
  // throttleUtil.getDataAfter(usersGroupPermissionsMock, 500);
  apiClientService.get(`users-group-permissions?user_group_id=${groupId}`);

/**
 * Add users group permissions API
 */
export const addGroupPermission = (data: IUsersGroupPermissionDataToSend) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.post('users-group-permissions', { data });

/**
 * Update users group permission API
 */
export const updateUsersGroupPermission = (
  groupId: number,
  uiItem: string,
  data: Partial<IUsersGroupPermissionData>
) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.put(
    `users-group-permissions?user_group_id=${groupId}&ui_item=${uiItem}`,
    { data }
  );

/**
 * Delete users group permission API
 */
export const deleteUsersGroupPermission = (groupId: number, uiItem: string) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.delete(
    `users-group-permissions?user_group_id=${groupId}&ui_item=${uiItem}`
  );
