import { ModalName } from 'consts';

import SettleTransactionModal from './SettleTransactionModal';
import SettleTransactionResultModal from './SettleTransactionResultModal';
import TransactionModal from './TransactionModal';

export const transactionsModals = {
  [ModalName.SETTLE_TRANSACTION_RESULT]: (<SettleTransactionResultModal />),
  [ModalName.SETTLE_TRANSACTION]: (<SettleTransactionModal />),
  [ModalName.TRANSACTION]: (<TransactionModal />),
};
