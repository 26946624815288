import React from 'react';

import { Modal, PersonIcon } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';
import { UserForm } from './../../forms';

import { IUserDetails, THandleGetUser } from 'store';

interface IDetailsUserModal extends IWithModal {
  getUser: THandleGetUser;
  isFormDirty: boolean;
  isLoading: boolean;
  userDetails: Partial<IUserDetails>;
}

const modalName = ModalName.DETAILS_USER;

const DetailsUserModal: React.FC<IDetailsUserModal> = ({
  closeModal,
  getUser,
  isFormDirty,
  isLoading,
  isReadOnlyPage,
  userDetails,
}) => {
  React.useEffect(
    () => {
      getUser();
    },
    [getUser]
  );

  return (
    <Modal
      name={modalName}
      title="User"
      containerWidth="600px"
      Icon={<PersonIcon size="26" />}
      isLoading={isLoading}
      withCloseConfirmation={isFormDirty}
    >
      <UserForm
        onCancel={() => closeModal(modalName)}
        isEditMode={true}
        initialValues={userDetails}
        isReadOnly={isReadOnlyPage}
      />
    </Modal>
  );
};

export default withModal(DetailsUserModal);
