import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  ActionButtons,
  InputField,
  NumberFormatField,
  ProductTypeIcons,
  Text,
} from 'components';

import { FormName, ProductType } from 'consts';
import { ISettlementFormValues, THandleSettleTransaction } from 'store';
import { formErrorUtil } from 'utils';

interface ISettleTransactionForm {
  isDisabled?: boolean;
  onCancel: () => void;
  settleTransaction: THandleSettleTransaction;
  initialValues: ISettlementFormValues;
}

type TSettleTransactionForm = ISettleTransactionForm
  & InjectedFormProps<{}, ISettleTransactionForm>;

const SettleTransactionForm: React.FC<TSettleTransactionForm> = ({
  dirty,
  handleSubmit,
  initialValues,
  isDisabled,
  onCancel,
  settleTransaction,
}) => {
  return (
    <>
      {initialValues.description && (
        <Box m="5px 0 20px">
          <Text bold={true} fontSize="16px">{initialValues.description}</Text>
          <Text light={true}>{`${initialValues.transactionTypeDescription}, ${initialValues.debitCredit}`}</Text>

          <Flex my="7px" alignItems="center">
            <Box>{ProductTypeIcons[initialValues.productType]}</Box>
            <Text>{initialValues.productTypeName}</Text>
          </Flex>
        </Box>
      )}

      <form onSubmit={handleSubmit(settleTransaction)}>
        <Flex alignItems="flex-end" mx="-8px">
          <Box width={[1 / 3]} p="8px">
            <Field
              id="accountId"
              name="accountId"
              label="Account ID"
              component={InputField}
              isNumber={true}
              disabled={true}
            />
          </Box>
          <Box width={[1 / 3]} p="8px">
            <Field
              id="transactionId"
              name="transactionId"
              label="Transaction ID"
              component={InputField}
              isNumber={true}
              disabled={true}
            />
          </Box>
          <Box width={[1 / 3]} p="8px">
            <Field
              id="amount"
              name="amount"
              label="Amount"
              component={NumberFormatField}
              placeholder="0.00"
              fixedDecimalScale={true}
              decimalScale={2}
              disabled={isDisabled || initialValues?.productType === ProductType.LOAN}
              autoFocus={initialValues?.productType === ProductType.REVOLVING_CREDIT}
              validate={[
                formErrorUtil.isRequired,
                formErrorUtil.isNumber,
                formErrorUtil.isStrictPositive,
              ]}
            />
          </Box>
        </Flex>
        <Flex
          justifyContent="flex-end"
          mt="20px"
        >
          <ActionButtons
            okText="Settle"
            disabledOk={isDisabled}
            onCancel={onCancel}
            withCancelConfirmation={dirty}
          />
        </Flex>
      </form>
    </>
  );
};

export default reduxForm<{}, ISettleTransactionForm>({
  form: FormName.SETTLE_TRANSACTION,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(SettleTransactionForm);
