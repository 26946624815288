// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
// import { throttleUtil } from 'utils';

// import { permissionsMock, profileMock, userInstitutionsMock } from './mock';
import { IChangePasswordToSend } from './types';

/**
 * Get profile API
 */
export const getProfile = () =>
  // throttleUtil.getDataAfter(profileMock, 500);
  apiClientService.get('profile');

/**
 * Get permissions API
 */
export const getPermissions = () =>
  // throttleUtil.getDataAfter(permissionsMock, 500);
  apiClientService.get('profile/permissions');

/**
 * Get user institutions API
 */
export const getUserInstitutions = () =>
  // throttleUtil.getDataAfter(userInstitutionsMock, 500);
  apiClientService.get('profile/institutions');

/**
 * Change password API
 */
export const changePassword = (data: IChangePasswordToSend) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.put('/profile/password/change', { data });
