import React from 'react';

import { Modal, PersonAddIcon } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';
import { UserForm } from './../../forms';

interface IAddUserModal extends IWithModal {
  isFormDirty: boolean;
}

const modalName = ModalName.ADD_USER;

const AddUserModal: React.FC<IAddUserModal> = ({
  closeModal,
  isFormDirty,
}) => {
  return (
    <Modal
      name={modalName}
      title="Create user"
      containerWidth="600px"
      Icon={<PersonAddIcon size="26" />}
      withCloseConfirmation={isFormDirty}
    >
      <UserForm
        onCancel={() => closeModal(modalName)}
        initialValues={{ requires2faFlag: true }}
      />
    </Modal>
  );
};

export default withModal(AddUserModal);
