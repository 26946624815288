import { ProductType } from 'consts';
import { apiClientService } from 'services';
import { IApiCallConfig } from 'types';
// import { throttleUtil } from 'utils';

// import {
//   cloneProductMock,
//   createProductMock,
//   loanProductMock,
//   loanProductsListMock,
//   revCreditProductMock,
//   revolvingCreditProductsListMock,
// } from './mock';

import { IProductData } from './types';

/**
 * Filter products API
 */
export const getProductsList = (queryString: string, productType: string | number) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/products?${queryString}`,
      // mock: loanProductsListMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/products?${queryString}`,
      // mock: revolvingCreditProductsListMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Get product details API
 */
export const getProduct = (productId: number, productType: string) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/products/${productId}`,
      // mock: loanProductMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/products/${productId}`,
      // mock: revCreditProductMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Update product details API
 */
export const updateProduct = (
  data: Partial<IProductData>,
  productId: number,
  productType: string
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/products/${productId}`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/products/${productId}`,
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 1000);

  const { path } = config[productType];
  return apiClientService.put(path, { data });
};

/**
 * Create product API
 */
export const addProduct = (
  data: IProductData,
  productType: string | number
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: 'il/products',
      // mock: createProductMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: 'rc/products',
      // mock: createProductMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.post(path, { data });
};

/**
 * Clone product API
 */
export const cloneProduct = (
  productId: number,
  productType: string | number
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/clone-product/${productId}`,
      // mock: cloneProductMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/clone-product/${productId}`,
      // mock: cloneProductMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.post(path);
};

/**
 * Delete product API
 */
export const deleteProduct = (
  productId: number,
  productType: string
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/products/${productId}`,
      // mock: successResponseMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/products/${productId}`,
      // mock: successResponseMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 1000);

  const { path } = config[productType];
  return apiClientService.delete(path);
};
