import { TApiResponse } from 'types';

import { ILoanIllustrationResp, IRevCreditIllustrationResp } from './types';

export enum ActionTypeKeys {
  ILLUSTRATE_LOAN = 'products/illustration/ILLUSTRATE_LOAN',
  ILLUSTRATE_LOAN_FULFILLED = 'products/illustration/ILLUSTRATE_LOAN_FULFILLED',
  ILLUSTRATE_LOAN_REJECTED = 'products/illustration/ILLUSTRATE_LOAN_REJECTED',

  ILLUSTRATE_REVOLVING_CREDIT = 'products/illustration/ILLUSTRATE_REVOLVING_CREDIT',
  ILLUSTRATE_REVOLVING_CREDIT_FULFILLED = 'products/illustration/ILLUSTRATE_REVOLVING_CREDIT_FULFILLED',
  ILLUSTRATE_REVOLVING_CREDIT_REJECTED = 'products/illustration/ILLUSTRATE_REVOLVING_CREDIT_REJECTED',

  RESET_PRODUCT_ILLUSTRATION = 'products/illustration/RESET_PRODUCT_ILLUSTRATION',
}

/**
 * Loan illustration
 */
export interface IIllustrateLoanAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ILLUSTRATE_LOAN;
}

export interface IIllustrateLoanFulfilledAction {
  readonly payload: ILoanIllustrationResp;
  readonly type: ActionTypeKeys.ILLUSTRATE_LOAN_FULFILLED;
}

export interface IIllustrateLoanRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.ILLUSTRATE_LOAN_REJECTED;
}

/**
 * Revolving credit illustration
 */

export interface IIllustrateRevCreditAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ILLUSTRATE_REVOLVING_CREDIT;
}

export interface IIllustrateRevCreditFulfilledAction {
  readonly payload: IRevCreditIllustrationResp;
  readonly type: ActionTypeKeys.ILLUSTRATE_REVOLVING_CREDIT_FULFILLED;
}

export interface IIllustrateRevCreditRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.ILLUSTRATE_REVOLVING_CREDIT_REJECTED;
}

/**
 * Reset illustration
 */

export interface IResetProductIllustrationAction {
  readonly type: ActionTypeKeys.RESET_PRODUCT_ILLUSTRATION;
}

export type TIllustrationAction =
  | IIllustrateLoanFulfilledAction
  | IIllustrateRevCreditFulfilledAction
  | IResetProductIllustrationAction  ;
