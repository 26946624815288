import React, { ReactChild } from 'react';

interface ITabsPanel {
  children: React.ReactNode;
  hintIfDisabled?: string;
  Icon?: ReactChild;
  isDisabled?: boolean;
  isHidden?: boolean;
  isLoading?: boolean;
  title: string;
  withConfirmation?: boolean;
}

const TabsPanel: React.FC<ITabsPanel> = ({ children }) => (
  <div>{children}</div>
);

export default TabsPanel;
