import styled from 'theme';

interface IButtonWrapper {
  hasIcon?: boolean;
  size?: string;
  width?: string;
}

export const ButtonWrapper = styled.button<IButtonWrapper>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 10px 5px;
  width: ${({ width }) => width || 'auto'};
  color: ${({ theme }) => theme.colors.gray};
  font-size: ${({ size }) => size ? `${size}px` : '13px'};
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: .2pt;
  line-height: 1.3;
  outline: 0;
  user-select: none;
  cursor: pointer;
  transition: all .1s linear;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.lighterGray};
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 2px;

  .text-wrapper {
    position: relative;
  }

  .icon {
    margin-right: 2px;
  }

  &.text-left {
    justify-content: flex-start;
  }

  &:hover:not(:disabled),
  &.is-focused:not(:disabled):hover {
    color: ${({ theme }) => theme.colors.accent};
    box-shadow: ${({ theme }) => theme.shadows.normalBox};
  }

  &.no-text-transform {
    text-transform: none;
    font-weight: 400;
  }

  &:disabled {
    background-color: transparent;
    cursor: default;

    .text-wrapper,
    .icon {
      opacity: .5;
    }
  }

  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
    }
    40% {
      color: black;
      text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
    }
    60% {
      text-shadow: .25em 0 0 black, .5em 0 0 rgba(0,0,0,0);
    }
    80%, 100% {
      text-shadow: .25em 0 0 black, .5em 0 0 black;
    }
  }

  @keyframes dotsWhite {
    0%, 20% {
      color: rgba(255,255,255,0);
      text-shadow: .25em 0 0 rgba(255,255,255,0), .5em 0 0 rgba(255,255,255,0);
    }
    40% {
      color: white;
      text-shadow: .25em 0 0 rgba(255,255,255,0), .5em 0 0 rgba(255,255,255,0);
    }
    60% {
      text-shadow: .25em 0 0 white, .5em 0 0 rgba(255,255,255,0);
    }
    80%, 100% {
      text-shadow: .25em 0 0 white, .5em 0 0 white;
    }
  }

  .is-loading:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
    opacity: .5;
  }

  &.no-border {
    background-color: transparent;
    border: 0;

    &:hover:not(:disabled),
    &.is-focused:not(:disabled):hover {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &.is-accent {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.accent};
    border: 1px solid ${({ theme }) => theme.colors.accent};

    &:hover:not(:disabled),
    &.is-focused:not(:disabled):hover {
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.darkAccent};
      box-shadow: ${({ theme }) => theme.shadows.bottomBox};
    }

    &:disabled {
      opacity: .7;
    }

    .is-loading:after {
      animation: dotsWhite 1s steps(5, end) infinite;
    }
  }

  &.is-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin: 0;
    padding: 0;
    font-size: 0;
    border-radius: 50%;

    > * {
      margin: 0;
    }
  }
`;
