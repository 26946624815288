import React from 'react';

import { GroupAddIcon, Modal } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';
import { AddUsersGroupForm } from './../../forms';

interface IAddUserModal extends IWithModal {
  isFormDirty: boolean;
}

const modalName = ModalName.ADD_USERS_GROUP;

const AddUsersGroupModal: React.FC<IAddUserModal> = ({
  closeModal,
  isFormDirty,
}) => (
  <Modal
    name={modalName}
    title="Create user group"
    containerWidth="400px"
    Icon={<GroupAddIcon size="26" />}
    withCloseConfirmation={isFormDirty}
  >
    <AddUsersGroupForm onCancel={() => closeModal(modalName)} />
  </Modal>
);

export default withModal(AddUsersGroupModal);
