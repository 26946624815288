import React from 'react';

import { Flex } from '@rebass/grid';

import { Button, List, Modal } from 'components';
import { ModalName, ModalType } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import { TResetTransactionResult } from 'store';

interface ISettleTransactionResultModal extends IWithModal {
  resetResult: TResetTransactionResult;
  settleTransactionResult: Array<Array<string | number>>;
}

const modalName = ModalName.SETTLE_TRANSACTION_RESULT;

const SettleTransactionResultModal: React.FC<ISettleTransactionResultModal> = ({
  closeAllModals,
  settleTransactionResult,
  resetResult,
}) => {
  React.useEffect(
    () => {
      return () => resetResult();
    },
    [resetResult]
  );

  return (
    <Modal
      name={modalName}
      type={ModalType.MESSAGE}
      title="Success"
      isSuccessIcon={true}
      containerWidth="500px"
      hideCloseIcon={true}
    >

      <List items={settleTransactionResult} />

      <Flex
        justifyContent="flex-end"
        mt="15px"
      >
        <Button
          onClick={closeAllModals}
          text="Close"
        />
      </Flex>
    </Modal>
  );
};

export default withModal(SettleTransactionResultModal);
