import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import { ActionTypeKeys, IFilterUiSessionsAction } from './actionTypes';
import * as api from './api';
import { IUiSessionsFilter } from './types';
import { normalizeFilterQueryString } from './utils';

/**
 * Filter UI sessions action
 */

export type TFilterUiSessions = (queryString: string) => IFilterUiSessionsAction;
export type THandleFilterUiSessions = (data: IUiSessionsFilter) => Thunk<void>;

export const filterUiSessions: TFilterUiSessions = queryString => ({
  type: ActionTypeKeys.FILTER_UI_SESSIONS,
  payload: api.filterUiSessions(queryString),
});

export const handleFilterUiSessions: THandleFilterUiSessions = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterQueryString(data);

        await dispatch(filterUiSessions(queryString));
      },
      dispatch
    );
  };

/**
 * Reset UI sessions action
 */

export type TResetUiSessions = () => void;

export const resetUiSessions: TResetUiSessions = () => ({
  type: ActionTypeKeys.RESET_UI_SESSIONS,
});
