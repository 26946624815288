export const basePath = '/ui/';

export enum PermissionType {
  READ_ONLY = 'R',
  READ_WRITE = 'W',
}

export const permissionTypesOptions = [
  { value: PermissionType.READ_ONLY, label: 'Read only' },
  { value: PermissionType.READ_WRITE, label: 'Read and Write' },
];

export enum ScreenType {
  MENU_CHILD = 'm',
  MENU_PARENT = 'M',
  SEPARATOR = 'S',
}

export enum ScreenName {
  // Ledger
  ACCOUNTS = 'ledger/accounts',
  CARDS = 'ledger/cards',
  CURRENCY_RATES = 'ledger/currency_rates',
  CUSTOMERS = 'ledger/customers',
  MANUAL_TRANSACTION = 'ledger/manual_transaction',
  PRODUCTS = 'product_designer/products',
  SETTLE_TRANSACTION = 'ledger/settle_transaction',
  STATEMENTS = 'ledger/statements',
  TRANSACTIONS = 'ledger/transactions',

  // Administration
  DICTIONARIES_COUNTRIES = 'administration/dictionaries/countries',
  DICTIONARIES_CURRENCIES = 'administration/dictionaries/currencies',
  DICTIONARIES_EVENT_DATA_ELEMENTS = 'administration/dictionaries/event_data_elements',
  DICTIONARIES_EVENTS = 'administration/dictionaries/events',
  DICTIONARIES_TRANSACTION_TYPES = 'administration/dictionaries/transaction_types',
  ENDPOINTS = 'administration/endpoints',
  INSTITUTIONS = 'administration/institutions',
  INTERFACES = 'administration/interfaces',
  SYSTEM_PROPERTIES = 'administration/system_properties',
  USERS_GROUPS = 'administration/permissions/user_groups',

  // Audit
  API_CALLS = 'audit/api_calls',
  SYSTEM_MONITOR = 'audit/system_monitor',
  UI_SESSIONS = 'audit/ui_sessions',
  USERS = 'administration/permissions/users',
  USERS_ACTIVITY = 'audit/users_activity',

  // Reports
  CUSTOMER_STATISTICS = 'reports/customer_statistics',
  PRODUCT_STATISTICS = 'reports/product_statistics',
  TRANSACTION_SUMMARY = 'reports/transactions_summary',
}

export const disabledScreensList = [
  ScreenName.CARDS,
  ScreenName.CURRENCY_RATES,
  ScreenName.MANUAL_TRANSACTION,
  ScreenName.SETTLE_TRANSACTION,
  ScreenName.SYSTEM_MONITOR,
  ScreenName.CUSTOMER_STATISTICS,
  ScreenName.PRODUCT_STATISTICS,
  ScreenName.TRANSACTION_SUMMARY,
];

export const screensListFull = [
  'administration',
  'administration/dictionaries',
  'administration/dictionaries/countries',
  'administration/dictionaries/currencies',
  'administration/dictionaries/events',
  'administration/dictionaries/event_data_elements',
  'administration/dictionaries/separator1',
  'administration/dictionaries/separator2',
  'administration/dictionaries/transaction_types',
  'administration/endpoints',
  'administration/institutions',
  'administration/interfaces',
  'administration/permissions',
  'administration/permissions/users',
  'administration/permissions/user_groups',
  'administration/separator2',
  'administration/separator3',
  'administration/system_properties',
  'audit',
  'audit/api_calls',
  'audit/separator1',
  'audit/separator2',
  'audit/system_monitor',
  'audit/ui_sessions',
  'audit/users_activity',
  'ledger',
  'ledger/accounts',
  'ledger/cards',
  'ledger/currency_rates',
  'ledger/customers',
  'ledger/manual_transaction',
  'ledger/separator1',
  'ledger/separator2',
  'ledger/separator3',
  'ledger/separator_4',
  'ledger/settle_transaction',
  'ledger/statements',
  'ledger/transactions',
  'product_designer',
  'product_designer/products',
  'reports',
  'reports/customer_statistics',
  'reports/product_statistics',
  'reports/transactions_summary',
];
