import React from 'react';

import { Modal, ProductTypeIcons, Tabs, TabsPanel } from 'components';
import { ModalName, ProductType } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import {
  IProduct,
  TResetPayments,
  TResetProduct,
  TResetProductIllustration,
  TResetProductRule,
} from 'store';
import { dateUtil } from 'utils';

import { GeneralProductForm } from 'containers/ProductDesigner/Products/General';

import Rules from 'containers/ProductDesigner/Products/Rules';

import Aprs from 'containers/ProductDesigner/Products/Aprs';
import Fees from 'containers/ProductDesigner/Products/Fees';
import Penalties from 'containers/ProductDesigner/Products/Penalties';
import Rewards from 'containers/ProductDesigner/Products/Rewards';

import LoanIllustration from 'containers/ProductDesigner/Products/LoanIllustration';

interface IDetailsProductModal extends IWithModal {
  currentProduct: IProduct;
  currentProductType: string | number;
  isLoading: boolean;
  isProductDetailsFormDirty: boolean;
  resetPayments: TResetPayments;
  resetProduct: TResetProduct;
  resetProductIllustration: TResetProductIllustration;
  resetProductRule: TResetProductRule;
}

const modalName = ModalName.DETAILS_PRODUCT;
const todayDate = dateUtil.todayDate();

const DetailsProductModal: React.FC<IDetailsProductModal> = ({
  closeModal,
  currentProduct,
  currentProductType,
  isLoading,
  isLockedItem,
  isReadOnlyPage,
  resetPayments,
  resetProduct,
  resetProductIllustration,
  resetProductRule,
}) => {
  React.useEffect(
    () => {
      return () => {
        resetProduct();
        resetPayments();
        resetProductRule();
        resetProductIllustration();
      };
    },
    [
      resetProduct,
      resetPayments,
      resetProductRule,
      resetProductIllustration,
    ]
  );

  const productType = React.useMemo(
    () => {
      return {
        isLoan: currentProductType === ProductType.LOAN,
        isRevolvingCredit: currentProductType === ProductType.REVOLVING_CREDIT,
        type: currentProductType,
      };
    },
    [currentProductType]
  );

  const handleCloseModal = React.useCallback(
    () => closeModal(modalName),
    [closeModal]
  );

  return (
    <Modal
      containerWidth="950px"
      minContainerHeight="710px"
      name={modalName}
      title={`${currentProduct?.name || ''}`}
      isLoading={isLoading}
    >
       <Tabs>
        <TabsPanel
          title="General"
          Icon={ProductTypeIcons[currentProductType]}
        >
          <GeneralProductForm
            isReadOnly={isReadOnlyPage}
            isLocked={isLockedItem}
            onCancel={handleCloseModal}
          />
        </TabsPanel>

        <TabsPanel title="Rules">
          <Rules
            isReadOnly={isLockedItem || isReadOnlyPage}
            onCancel={handleCloseModal}
            productType={productType}
          />
        </TabsPanel>

        <TabsPanel
          title="APRs"
          isHidden={!productType.isRevolvingCredit}
        >
          <Aprs
            isReadOnly={isLockedItem || isReadOnlyPage}
            onCancel={handleCloseModal}
          />
        </TabsPanel>

        <TabsPanel title="Fees">
          <Fees
            isReadOnly={isLockedItem || isReadOnlyPage}
            onCancel={handleCloseModal}
            productType={productType}
          />
        </TabsPanel>

        <TabsPanel
          title="Penalties"
          isHidden={!productType.isLoan}
        >
          <Penalties
            isReadOnly={isLockedItem || isReadOnlyPage}
            onCancel={handleCloseModal}
          />
        </TabsPanel>

        <TabsPanel title="Rewards">
          <Rewards
            isReadOnly={isLockedItem || isReadOnlyPage}
            onCancel={handleCloseModal}
          />
        </TabsPanel>

        <TabsPanel
          title="Illustration"
          isHidden={!productType.isLoan}
        >
          <LoanIllustration
            mode="product"
            initialFormValues={{
              apr: currentProduct?.maxApr,
              principal: null,
              productId: currentProduct?.id,
              productName: currentProduct?.name,
              startDate: todayDate,
              numberOfInstalments: currentProduct?.defaultInstalments,
            }}
          />
        </TabsPanel>
      </Tabs>
    </Modal>
  );
};

export default withModal(DetailsProductModal);
