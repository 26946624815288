import { TableCell, TableHeader } from 'components';
import { ICommonStatement } from 'store';
import { ITableCell } from 'types';

type TCell<T extends keyof ICommonStatement> = ITableCell<ICommonStatement[T]>;

export const tableColumns = [
  {
    maxWidth: 65,
    Header: <TableHeader title="Sequence Number" />,
    accessor: 'sequenceNumber',
    Cell: (props: TCell<'sequenceNumber'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 70,
    Header: <TableHeader title="Statement ID" />,
    accessor: 'id',
    Cell: (props: TCell<'id'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 70,
    Header: <TableHeader title="Customer ID" />,
    accessor: 'customerId',
    Cell: (props: TCell<'customerId'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 250,
    Header: <TableHeader title="First Name" />,
    accessor: 'firstName',
    Cell: (props: TCell<'firstName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 250,
    Header: <TableHeader title="Last Name" />,
    accessor: 'lastName',
    Cell: (props: TCell<'lastName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 70,
    Header: <TableHeader title="Account ID" />,
    accessor: 'accountId',
    Cell: (props: TCell<'accountId'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 120,
    Header: <TableHeader title="Product Type" />,
    accessor: 'productTypeName',
    Cell: (props: TCell<'productTypeName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 300,
    Header: <TableHeader title="Product Name" />,
    accessor: 'productName',
    Cell: (props: TCell<'productName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 80,
    Header: <TableHeader title="Start Date" />,
    accessor: 'startDate',
    Cell: (props: TCell<'startDate'>) => (
      <TableCell
        value={props.value}
        isDate={true}
      />
    ),
  },
  {
    maxWidth: 80,
    Header: <TableHeader title="End Date" />,
    accessor: 'endDate',
    Cell: (props: TCell<'endDate'>) => (
      <TableCell
        value={props.value}
        isDate={true}
      />
    ),
  },
  {
    maxWidth: 100,
    Header: <TableHeader title="Repayment Amount" />,
    accessor: 'repaymentAmount',
    Cell: (props: TCell<'repaymentAmount'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 80,
    Header: <TableHeader title="Status" />,
    accessor: 'status',
    Cell: (props: TCell<'status'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 150,
    Header: <TableHeader title="Repayment Status" />,
    accessor: 'repaymentStatusName',
    Cell: (props: TCell<'repaymentStatusName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
];
