import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Root from './Root';

import {
  handleGetPermissions,
  handleGetProfile,
  handleGetUserInstitutions,
  handleLogout,
  isActiveUserSelector,
  isAuthRegistrationPendingSelector,
  isLoadedPermissionsSelector,
  isLoggingOutSelector,
  isPermissionsLoadingSelector,
  isProfileLoadingSelector,
  IStoreState,
  visiblePermissionsListSelector,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  isActiveUser: isActiveUserSelector(state),
  isAuthRegistrationPending: isAuthRegistrationPendingSelector(state),
  isLoadedPermissions: isLoadedPermissionsSelector(state),
  isLoading: isProfileLoadingSelector(state)
    || isPermissionsLoadingSelector(state)
    || isLoggingOutSelector(state),
  visiblePermissionsList: visiblePermissionsListSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getInstitutions: handleGetUserInstitutions,
    getPermissions: handleGetPermissions,
    getProfile: handleGetProfile,
    logout: handleLogout,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root);
