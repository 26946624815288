import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  Button,
  EllipseLabel,
  InputField,
  Logo,
  PasswordField,
  Text,
} from 'components';
import { FormName } from 'consts';
import { ILoginRequest, THandleLogin } from 'store';
import styled from 'theme';
import { formErrorUtil } from 'utils';

const envName =  window.location.hostname.split('.')[0];

const FormWrapper = styled(Flex)`
  margin: 0 auto;

  .password-wrapper {
    position: relative;
    margin-bottom: 15px;

    .caps-lock {
      position: absolute;
      right: 0;
      top: 0;
      text-transform: uppercase;
    }
  }
`;

interface ILogin {
  isLogging: boolean;
  isMessageModal: boolean;
  login: THandleLogin;
}

type TLogin = ILogin & InjectedFormProps<{}, ILogin>;

const Login: React.FC<TLogin> = ({
  handleSubmit,
  isLogging,
  isMessageModal,
  login,
}) => {
  const [isCapsLock, setIsCapsLock] = React.useState(false);

  // eslint-disable-next-line
  const handleSubmitForm = React.useCallback(
    handleSubmit(data => setTimeout(() => login(data as ILoginRequest), 500)),
    [handleSubmit, login]
  );

  return (
    <FormWrapper
      flexDirection="column"
      justifyContent="center"
      width="300px"
      height="calc(100vh - 210px)"
    >
      <Flex
        mb="15px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Logo />
        <Box pb="5px">
          <EllipseLabel text={envName} />
        </Box>
      </Flex>

      <form onSubmit={handleSubmitForm}>
        <Field
          id="loginUsername"
          name="loginUsername"
          placeholder="Enter user name"
          component={InputField}
          disabled={isMessageModal || isLogging}
          label="Login"
          validate={[formErrorUtil.isRequired]}
        />

        <div className="password-wrapper">
          {isCapsLock && (
            <Text light={true} className="caps-lock">Caps lock</Text>
          )}
          <Field
            id="loginPassword"
            name="loginPassword"
            placeholder="Enter password"
            component={PasswordField}
            disabled={isMessageModal || isLogging}
            label="Password"
            validate={[formErrorUtil.isRequired]}
            onKeyUp={(e: MouseEvent) => setIsCapsLock(e.getModifierState('CapsLock'))}
          />
        </div>

        <Box mt="35px">
          <Button
            text="Log in"
            width="100%"
            classNames={['is-accent']}
            isLoading={isLogging}
          />
        </Box>
      </form>
    </FormWrapper>
  );
};

export default reduxForm<{}, ILogin>({
  form: FormName.LOGIN,
  destroyOnUnmount: true,
  enableReinitialize: true,
})((Login));
