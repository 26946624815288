import { ModalName } from 'consts';

import AddAccountModal from './AddAccountModal';
import DetailsAccountModal from './DetailsAccountModal';
import ManualTransactionModal from './ManualTransactionModal';
import ManualTransactionResultModal from './ManualTransactionResultModal';

export const accountsModals = {
  [ModalName.ADD_ACCOUNT]: (<AddAccountModal />),
  [ModalName.DETAILS_ACCOUNT]: (<DetailsAccountModal />),
  [ModalName.MANUAL_TRANSACTION_RESULT]: (<ManualTransactionResultModal />),
  [ModalName.MANUAL_TRANSACTION]: (<ManualTransactionModal />),
};
