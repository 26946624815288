import React from 'react';
import { Field } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  HorizontalLine,
  InputField,
  MaskField,
  NumberFormatField,
  SelectField,
} from 'components';
import { DateFormat, MaskFormat, productTypesOptions } from 'consts';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

import { statusesConfig } from './statusesConfig';

interface IAccountGeneralFields {
  currentAccountStatus: string | number;
  institutionsOptions: Array<ISelectValue>;
  isDisabledProductSelection: boolean;
  isEditMode: boolean;
  isPreviousStatus: boolean;
  isProductsLoading: boolean;
  isReadOnly: boolean;
  isStatementId: boolean;
  productsOptions: Array<ISelectValue>;
  statusesOptions: Array<ISelectValue>;
}

const AccountGeneralFields: React.FC<IAccountGeneralFields> = ({
  currentAccountStatus,
  institutionsOptions,
  isDisabledProductSelection,
  isEditMode,
  isPreviousStatus,
  isProductsLoading,
  isReadOnly,
  isStatementId,
  productsOptions,
  statusesOptions,
}) => {
  const accountStatusOptions = React.useMemo(
    () => {
      const options: Array<ISelectValue> = JSON.parse(JSON.stringify(statusesOptions));

      const currentState = statusesConfig.find(el => el.from === (currentAccountStatus || null));
      const statusesTo = currentState?.to;

      if (statusesTo) {
        options.map(option =>
          statusesTo.find((status) => status === option.value)
            ? (option.isDisabled = false)
            : (option.isDisabled = true)
        );
      }

      return options;
    },
    [
    currentAccountStatus,
    statusesOptions,
  ]);

  return (
    <Flex
      alignItems="flex-end"
      flexWrap="wrap"
      mx="-8px"
    >
      {isEditMode && (
        <>
          <Box width="360px" p="8px">
            <Field
              id="firstName"
              name="firstName"
              label="First Name"
              component={InputField}
              disabled={true}
            />
          </Box>
          <Box width="360px" p="8px">
            <Field
              id="lastName"
              name="lastName"
              label="Last Name"
              component={InputField}
              disabled={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="id"
              name="id"
              label="Account ID"
              placeholder="ID"
              component={InputField}
              disabled={true}
              isNumber={true}
            />
          </Box>
        </>
      )}

      <Box width="360px" p="8px">
        <Field
          id="institution"
          name="institution"
          component={SelectField}
          label="Institution"
          placeholder="Select Institution"
          options={institutionsOptions}
          isDisabled={isEditMode || isReadOnly}
          isClearable={false}
          validate={[formErrorUtil.isRequired]}
        />
      </Box>
      <Box width="360px" p="8px">
        <Field
          id="accountReference"
          name="accountReference"
          component={InputField}
          label="Account Reference"
          placeholder="Enter Reference"
          disabled={isReadOnly}
        />
      </Box>
      <Box width="120px" p="8px">
        <Field
          id="customerId"
          name="customerId"
          label="Customer ID"
          placeholder="ID"
          component={InputField}
          disabled={isEditMode || isReadOnly}
          isNumber={true}
          validate={[
            formErrorUtil.isRequired,
            formErrorUtil.isInteger,
            formErrorUtil.isStrictPositive,
          ]}
        />
      </Box>

      {isEditMode && (
        <HorizontalLine />
      )}

      <Box width="240px" p="8px">
        <Field
          id="status"
          name="status"
          component={SelectField}
          label="Status"
          placeholder="Select Status"
          options={accountStatusOptions}
          isDisabled={isReadOnly}
          validate={[formErrorUtil.isRequired]}
        />
      </Box>

      {isEditMode && (
        <>
          {isPreviousStatus && (
            <Box width="240px" p="8px">
              <Field
                id="previousStatusName"
                name="previousStatusName"
                label="Previous Status"
                placeholder="-"
                component={InputField}
                disabled={true}
              />
          </Box>
          )}
          <Box width="240px" p="8px">
            <Field
              id="repaymentStatus"
              name="repaymentStatus"
              label="Repayment Status"
              component={InputField}
              disabled={true}
            />
          </Box>
        </>
      )}

      {isEditMode && isStatementId && (
        <Box width="120px" p="8px">
          <Field
            id="statementId"
            name="statementId"
            label="Statement ID"
            component={InputField}
            disabled={true}
            isNumber={true}
          />
        </Box>
      )}

      <Box width="120px" p="8px">
        <Field
          id="balanceLimit"
          name="balanceLimit"
          label="Balance Limit"
          component={NumberFormatField}
          placeholder="0.00"
          fixedDecimalScale={true}
          decimalScale={2}
          disabled={isReadOnly}
          validate={[formErrorUtil.isRequired, formErrorUtil.isPositive]}
        />
      </Box>

      {isEditMode && (
        <Box width="120px" p="8px">
          <Field
            id="productId"
            name="productId"
            label="Product ID"
            placeholder="ID"
            component={InputField}
            disabled={true}
            isNumber={true}
          />
        </Box>
      )}

      <Box width="240px" p="8px">
        <Field
          id="productType"
          name="productType"
          component={SelectField}
          label="Product Type"
          placeholder="Select Type"
          options={productTypesOptions}
          isDisabled={isEditMode || isReadOnly}
          validate={[formErrorUtil.isRequired]}
        />
      </Box>

      {!isEditMode && (
        <Box width="360px" p="8px">
          <Field
            id="product"
            name="product"
            component={SelectField}
            label="Product Name"
            placeholder="Select Product"
            isDisabled={isEditMode || isReadOnly || isDisabledProductSelection}
            options={productsOptions}
            validate={[formErrorUtil.isRequired]}
            isLoading={isProductsLoading}
            hint={isDisabledProductSelection ? 'Select product type' : ''}
          />
        </Box>
      )}

      {isEditMode && (
        <Box width="360px" p="8px">
          <Field
            id="productName"
            name="productName"
            label="Product Name"
            component={InputField}
            disabled={true}
          />
        </Box>
      )}

      {isEditMode && (
        <>
          <HorizontalLine />
          <Box width="120px" p="8px">
            <Field
              id="dateCreated"
              name="dateCreated"
              label="Date Created"
              component={MaskField}
              placeholder={DateFormat.DATE}
              mask={MaskFormat.DATE}
              disabled={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="dateClosed"
              name="dateClosed"
              label="Date Closed"
              component={MaskField}
              placeholder="-"
              mask={MaskFormat.DATE}
              disabled={true}
            />
          </Box>
        </>
      )}
    </Flex>
  );
};

export default AccountGeneralFields;
