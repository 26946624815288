import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { isDirty } from 'redux-form';

import { FormName } from 'consts';
import DetailsCustomerModal from './DetailsCustomerModal';

import {
  handleGetCustomer,
  isGettingCustomerSelector,
  IStoreState,
  resetCustomer,
} from 'store';

const isCustomerFormDirty = isDirty(FormName.CUSTOMER);

const mapStateToProps = (state: IStoreState) => ({
  isFormDirty: isCustomerFormDirty(state),
  isLoading: isGettingCustomerSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getCustomer: handleGetCustomer,
    resetCustomer,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsCustomerModal);
