import React from 'react';
import { BaseFieldProps, WrappedFieldProps } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import styled from 'theme';

import { Label } from './../../Text';
import { Hint } from './../../Utils';

import { componentUtil } from 'utils';

export const InputFieldWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .field {
    user-select: all;
  }

  .is-red {
    color: ${({ theme }) => theme.colors.red};
  }
`;

const ErrorWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 2px;
  font-size: 9px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.red};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

interface IInputField extends Partial<BaseFieldProps> {
  focusOnLabelClick?: boolean;
  hint?: string | React.ReactChild;
  hintPosition?: 'top' | 'right' | 'bottom' | 'left';
  id?: string;
  accentLabel?: boolean;
  invalid?: boolean;
  isRequired?: boolean;
  label?: string;
  placeholder?: string;
  preventBlur?: boolean | undefined;
  showErrors?: boolean;
  type?: string;
  updateFieldOnChange?: (...args: any[]) => void;
  validateOnChange?: boolean;
}

interface IInputWrapper {
  render: (
    invalid: boolean,
    preventBlur: boolean | undefined
  ) => React.ReactNode;
}

export type FieldProps = WrappedFieldProps & IInputField;

const InputWrapper: React.FC<IInputWrapper & FieldProps> = ({
  focusOnLabelClick = false,
  hint,
  hintPosition = 'top',
  id,
  accentLabel,
  invalid: defaultInvalid,
  isRequired,
  label,
  preventBlur,
  render,
  showErrors = true,
  validateOnChange,
  meta: {
    touched,
    error,
    submitFailed,
  },
}) => {
  let invalid = false;

  if (validateOnChange) {
    invalid = typeof error === 'string' || defaultInvalid;
  } else {
    invalid = (error === true && submitFailed)
      || (typeof error === 'string' && touched) || defaultInvalid;
  }

  return (
    <InputFieldWrapper className="input-field">
      {label && (
        <Label
          htmlFor={focusOnLabelClick ? id : null}
          invalid={invalid}
          isAccent={accentLabel}
        >
          <Flex
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <span>
              {label}
              {isRequired && (<span className="is-red">*</span>)}
            </span>
            {hint && (
              <Box ml="2px">
                <Hint
                  text={hint}
                  position={hintPosition}
                />
              </Box>
            )}
          </Flex>
        </Label>
      )}
      <div className="field">
        {render(invalid, preventBlur)}
      </div>
      {invalid && error && showErrors && (
        <ErrorWrapper>{error}</ErrorWrapper>
      )}
    </InputFieldWrapper>
  );
};

export const withFormField = <OriginalProps extends {}>(
  Component: React.ComponentType<OriginalProps & IInputField>
): React.ComponentType<OriginalProps & FieldProps> =>
  class WithFormField extends
    React.Component<OriginalProps & FieldProps> {

    static displayName = `WithFormField(${componentUtil.getDisplayName(Component)})`;

    render() {
      return (
        <InputWrapper
          {...this.props}
          render={this.renderComponent}
        />
      );
    }

    renderComponent = (invalid: boolean) => {
      return (
        <Component
          {...this.props.input}
          {...this.props}
          invalid={invalid}
          onBlur={this.onBlur}
        />
      );
    }

    onBlur = () =>
      this.props.preventBlur
        ? ''
        : setTimeout(() => this.props.input.onBlur(this.props.input.value))
  };
