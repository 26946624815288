import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { formValueSelector } from 'redux-form';

import ManualTransactionModal from './ManualTransactionModal';

import { FormName } from 'consts';

import {
  currencyNumsOptionsSelector,
  currenciesOptionsSelector,
  handleGetDictionaryCurrencies,
  handleMakeTransaction,
  initialValuesForManualTransactionSelector,
  isCurrenciesLoadingSelector,
  isManualTransactionLoading,
  IStoreState,
} from 'store';

const formSelector = formValueSelector(FormName.MANUAL_TRANSACTION);

const mapStateToProps = (state: IStoreState) => ({
  currenciesNumOptions: currencyNumsOptionsSelector(state),
  currenciesOptions: currenciesOptionsSelector(state),
  initialValues: initialValuesForManualTransactionSelector(state),
  isCurrenciesLoading: isCurrenciesLoadingSelector(state),
  isLoading: isManualTransactionLoading(state),
  transactionType: formSelector(state, 'transactionType'),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    makeTransaction: handleMakeTransaction,
    getCurrencies: handleGetDictionaryCurrencies,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualTransactionModal);
