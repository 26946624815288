import React from 'react';

import { Flex } from '@rebass/grid';

import { Button, Modal, Text } from 'components';
import { ModalName, ModalType } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import { IPayloadMessageModal } from 'store';

interface IMessageModal extends IWithModal {
  payloadMessageModal: IPayloadMessageModal;
}

const modalName = ModalName.MESSAGE;

const MessageModal: React.FC<IMessageModal> = ({
  payloadMessageModal,
  closeModal,
}) => {
  const { isError, isSuccess, onClose } = payloadMessageModal || {};

  return (
    <Modal
      containerWidth="450px"
      isInfoIcon={isError}
      isSuccessIcon={isSuccess}
      hideCloseIcon={onClose}
      name={modalName}
      title={payloadMessageModal?.title}
      type={ModalType.MESSAGE}
    >

      <Text fontSize="14px">{payloadMessageModal?.message}</Text>

      <Flex
        justifyContent="flex-end"
        mt="15px"
      >
        <Button
          text="Close"
          onClick={onClose ? onClose : () => closeModal(modalName)}
        />
      </Flex>
    </Modal>
  );
};

export default withModal(MessageModal);
