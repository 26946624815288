import { createSelector } from 'reselect';

import { userStatusWith2faOptions } from 'consts';

import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';
import { userInstitutionsOptionsSelector } from 'store/domains/profile';
import { ActionTypeKeys } from './actionTypes';
import { normalizeData } from './utils';

export const defaultUsersSelector = (state: IStoreState) => state.admin.users.users;

export const usersSelector = createSelector(
  defaultUsersSelector,
  userInstitutionsOptionsSelector,
  (users, institutions) => users?.map(user => {
    const institution = institutions?.find(el => el.value === user.institution_id);

    return normalizeData(user, institution);
  })
);

export const usersOptionsSelector = createSelector(
  defaultUsersSelector,
  data => data?.asMutable().map(el => {
    return {
      value: el.username,
      label: `${el.first_name} ${el.last_name} (${el.username})`,
    };
  })
);

export const usersOptionsByIdSelector = createSelector(
  defaultUsersSelector,
  data => data?.asMutable().map(el => {
    return {
      value: el.id,
      label: `${el.first_name} ${el.last_name} (${el.username})`,
    };
  })
);

/**
 * Current user selectors
 */

export const defaultCurrentUserSelector = (state: IStoreState) =>
  state.admin.users.currentUser;

export const currentUserSelector = createSelector(
  defaultCurrentUserSelector,
  userInstitutionsOptionsSelector,
  (currentUser, institutions) => {
    const institution = institutions?.find(el => el.value === currentUser?.institution_id);
    const status = userStatusWith2faOptions.find(el => el.value === currentUser?.status);

    return {
      ...normalizeData(currentUser),
      status,
      institution,
    };
  }
);

/**
 * Users loading selectors
 */

export const isFilteringUsersSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_USERS,
]);

export const isAddingUserSelector = createLoadingSelector([
  ActionTypeKeys.ADD_USER,
]);

export const isGettingUserSelector = createLoadingSelector([
  ActionTypeKeys.GET_USER,
]);

export const isUpdatingUserSelector = createLoadingSelector([
  ActionTypeKeys.UPDATE_USER,
]);
