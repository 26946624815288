import React from 'react';

import styled from 'theme';

interface IContainer {
  isFixed?: boolean;
  text?: string;
}

const Container = styled.div<IContainer>`
  position: ${({ isFixed }) => isFixed ? 'fixed' : 'absolute'};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.whiteOpacity};
  backdrop-filter: blur(1px);
  z-index: 100;

  @keyframes dots {
    0%, 20% {
      color: rgba(0,0,0,0);
      text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
    }
    40% {
      color: black;
      text-shadow: .25em 0 0 rgba(0,0,0,0), .5em 0 0 rgba(0,0,0,0);
    }
    60% {
      text-shadow: .25em 0 0 black, .5em 0 0 rgba(0,0,0,0);
    }
    80%, 100% {
      text-shadow: .25em 0 0 black, .5em 0 0 black;
    }
  }

  .text {
    margin-top: 15px;
    color: ${({ theme }) => theme.colors.darkGray};
    font-weight: 500;
    font-size: 14px;
    letter-spacing: .8pt;
    text-transform: uppercase;
    z-index: 100;

    &:after {
      content: '.';
      animation: dots 1s steps(5, end) infinite;
    }
  }
`;

interface ICircle {
  color?: string;
  size?: string | number;
}

const Circle = styled.svg<ICircle>`
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  animation: rotate 1s linear infinite;
  z-index: 3;
  width: ${({ size }) => size ? (isNaN(Number(size)) ? size : `${size}px`) : '50px'};
  height: ${({ size }) => size ? (isNaN(Number(size)) ? size : `${size}px`) : '50px'};
  circle {
    stroke: ${({ color, theme }) => color || theme.colors.accent};
    stroke-linecap: round;
    stroke-dasharray: 45;
  }
`;

export const Spinner: React.FC<IContainer & ICircle> = ({
  color,
  isFixed,
  size,
  text,
}) => (
    <Container isFixed={isFixed}>
      {text
        ? (<div className="text">{text}</div>)
        : (
          <Circle className="spinner" viewBox="0 0 50 50" size={size} color={color}>
            <circle cx="25" cy="25" r="20" fill="none" strokeWidth="3" />
          </Circle>
        )
      }
    </Container>
  );
