import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Register2faModal from './Register2faModal';

import {
  authCodeSelector,
  authDataUrlSelector,
  currentAuthRegistrationStepSelector,
  handleConfirmAuthKey,
  IStoreState,
  setCurrentAuthRegistrationStep,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  currentStep: currentAuthRegistrationStepSelector(state),
  code: authCodeSelector(state),
  dataUrl: authDataUrlSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    setCurrentAuthRegistrationStep,
    confirmAuthKey: handleConfirmAuthKey,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register2faModal);
