import React from 'react';

import { Box, Flex } from '@rebass/grid';
import { Field } from 'redux-form';

import { InputField, SelectField } from 'components';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface IUsersGroupFields {
  institutionsOptions: Array<ISelectValue>;
  isEditMode?: boolean;
  isReadOnly?: boolean;
}

const UsersGroupFields: React.FC<IUsersGroupFields> = ({
  institutionsOptions,
  isEditMode,
  isReadOnly,
}) => (
  <Flex flexWrap="wrap" mx="-8px">

    {!isEditMode && (
      <Box width={[1]} p="8px">
        <Field
          id="institutionId"
          name="institutionId"
          placeholder="Select Institution"
          component={SelectField}
          label="Institution"
          options={institutionsOptions}
          isDisabled={isEditMode || isReadOnly}
          isClearable={false}
          validate={[formErrorUtil.isRequired]}
        />
      </Box>
    )}

    {isEditMode && (
      <Box width={[1 / 2]} p="8px">
        <Field
          id="institutionName"
          name="institutionName"
          component={InputField}
          label="Institution"
          disabled={true}
        />
      </Box>
    )}

    <Box width={[isEditMode ? 1 / 2 : 1]} p="8px">
      <Field
        id="name"
        name="name"
        component={InputField}
        label="Group Name"
        placeholder="Enter Group Name"
        disabled={isReadOnly}
        validate={[
          formErrorUtil.isRequired,
          formErrorUtil.isAlphaNumeric,
        ]}
      />
    </Box>
  </Flex>
);

export default UsersGroupFields;
