import { createSelector } from 'reselect';

import { ProductType } from 'consts';
import { IStoreState } from 'store';
import {
  currenciesOptionsSelector,
  currencyNumsOptionsSelector,
  dictionaryAccountStatusesOptionsSelector,
  dictionaryRepaymentMethodsOptionsSelector,
  dictionaryRepaymentTypesOptionsSelector,
} from 'store/domains/admin';
import { createLoadingSelector } from 'store/domains/loader';
import {
  activeItemIdSelector,
  activeProductTypeSelector,
} from 'store/domains/utils';

import { ActionTypeKeys } from './actionTypes';
import {
  normalizeCommonAccount,
  normalizeCurrentAccount,
} from './utils';
import {
  normalizeInitialValuesForManualTransaction,
  normalizeManualTransactionResult,
} from './utilsManualTransaction';

/** Accounts selectors */

export const defaultAccountsSelector = (state: IStoreState) => state.ledger.accounts.accounts;

export const accountsSelector = createSelector(
  defaultAccountsSelector,
  accounts => accounts?.map(account => normalizeCommonAccount(account))
);

export const accountsTotalPagesSelector = (state: IStoreState) =>
  state.ledger.accounts.totalPages;

export const accountsTotalResultsSelector = (state: IStoreState) =>
  state.ledger.accounts.totalResults;

export const accountsCurrentPageSelector = (state: IStoreState) =>
  state.ledger.accounts.pageNumber;

/** Current account selectors */

export const defaultCurrentAccountSelector = (state: IStoreState) =>
  state.ledger.accounts.currentAccount;

export const currentAccountSelector = createSelector(
  defaultCurrentAccountSelector,
  dictionaryRepaymentMethodsOptionsSelector,
  dictionaryRepaymentTypesOptionsSelector,
  dictionaryAccountStatusesOptionsSelector,
  (
    currentAccount,
    repaymentMethodsOptions,
    repaymentTypesOptions,
    accountStatusesOptions
  ) => normalizeCurrentAccount(
    currentAccount,
    repaymentMethodsOptions,
    repaymentTypesOptions,
    accountStatusesOptions
  )
);

/**
 * Manual transaction selectors
 */

export const defaultManualTransactionResultSelector = (state: IStoreState) =>
  state.ledger.accounts.transactionResult;

export const initialValuesForManualTransactionSelector = createSelector(
  defaultAccountsSelector,
  activeItemIdSelector,
  activeProductTypeSelector,
  currenciesOptionsSelector,
  currencyNumsOptionsSelector,
  (accounts, accountId, productType, currenciesOptions, currenciesNumOptions) => {
    const currentAccount = accounts?.find(account => account.id === accountId
        && account.product_type === productType);
    const currentCurrenciesOptions = productType === ProductType.LOAN
      ? currenciesOptions
      : currenciesNumOptions;

    return normalizeInitialValuesForManualTransaction(currentAccount, currentCurrenciesOptions);
  }
);

export const manualTransactionResultSelector = createSelector(
  defaultManualTransactionResultSelector,
  activeProductTypeSelector,
  (data, productType) => normalizeManualTransactionResult(data, productType)
);

/**
 * Account loading selectors
 */

export const isAccountLoadingSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_ACCOUNTS,
]);

export const isGetAccountSelector = createLoadingSelector([
  ActionTypeKeys.GET_ACCOUNT,
]);

export const isAddingAccountSelector = createLoadingSelector([
  ActionTypeKeys.ADD_ACCOUNT,
]);

export const isUpdatingAccountSelector = createLoadingSelector([
  ActionTypeKeys.UPDATE_ACCOUNT,
]);

export const isManualTransactionLoading = createLoadingSelector([
  ActionTypeKeys.MAKE_TRANSACTION,
]);
