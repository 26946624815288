import {
  commonStatusOptions,
  Flag,
  loanRepaymentCalcTypesOptions,
  ProductType,
  productTypesOptions,
  statementCycleTypesOptions,
} from 'consts';

import {
  ICommonProduct,
  ICommonProductData,
  IProduct,
  IProductData,
  IProductsFilter,
  IProductsFilterToSend,
} from './types';

import { ISelectValue } from 'types';
import { stringsUtil } from 'utils';

export const normalizeFilterQueryString = (data: IProductsFilter): string => {
  const { status, pageNumber } = data || {};

  const normalizedData: IProductsFilterToSend = {
    status: status?.value,
    page_number: pageNumber,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeCommonProduct = (data: ICommonProductData): ICommonProduct => {
  const {
    currency_code,
    description,
    id,
    locked_flag,
    name,
    product_type_name,
    product_type,
    statement_cycle_type_name,
    status,
  } = data || {};

  const statusOption = commonStatusOptions.find((el) => el.value === status);

  return {
    currencyCode: currency_code,
    description,
    id,
    lockedFlag: locked_flag === Flag.YES,
    name,
    productType: product_type,
    productTypeName: product_type_name,
    statementCycleType: statement_cycle_type_name,
    status: statusOption?.label,
  };
};

export const normalizeCurrentProduct = (
  data: IProductData,
  currencyCodesOptions: Array<ISelectValue>,
): IProduct => {
  const {
    // common
    currency_code,
    description,
    id,
    institution_id,
    institution_name,
    locked_flag,
    name,
    product_type,
    statement_cycle_type_id,
    status,

    // revolving credit
    default_statement_cycle_parameter,
    minimum_repayment_amount,
    minimum_repayment_rate,
    number_of_days_to_make_repayment,
    repayment_period_is_statement_cycle,
    immediate_chargeback,
    apply_rejected_chargeback_interest,

    // loan
    allow_overpayment,
    default_instalments,
    forgive,
    max_apr,
    max_instalments,
    max_loan,
    min_apr,
    min_instalments,
    min_loan,
    repayment_calculation_type,
  } = data || {};

  const specificProductTypeFields = {
    [ProductType.LOAN]: {
      allowOverpayment: allow_overpayment === Flag.YES,
      defaultInstalments: default_instalments,
      forgive,
      maxApr: max_apr,
      maxInstalments: max_instalments,
      maxLoan: max_loan,
      minApr: min_apr,
      minInstalments: min_instalments,
      minLoan: min_loan,
      repaymentCalculationType: loanRepaymentCalcTypesOptions
        .find((el) => el.value === repayment_calculation_type),
    },
    [ProductType.REVOLVING_CREDIT]: {
      defaultStatementCycleParameter: default_statement_cycle_parameter,
      minimumRepaymentAmount: minimum_repayment_amount,
      minimumRepaymentRate: minimum_repayment_rate,
      numberOfDaysToMakeRepayment: number_of_days_to_make_repayment,
      repaymentPeriodIsStatementCycle: repayment_period_is_statement_cycle === Flag.YES,
      immediateChargeback: immediate_chargeback === Flag.YES,
      applyRejectedChargebackInterest: apply_rejected_chargeback_interest === Flag.YES,
    },
  };

  return {
    currencyCode: currencyCodesOptions?.find(el => el.value === currency_code),
    description,
    id,
    institution: { value: institution_id, label: institution_name },
    institutionId: institution_id,
    institutionName: institution_name,
    lockedFlag: locked_flag === Flag.YES,
    name,
    productType: productTypesOptions.find((el) => el.value === product_type),
    statementCycleType: statementCycleTypesOptions
      .find((el) => el.value === statement_cycle_type_id),
    status: commonStatusOptions.find((el) => el.value === status),
    ...specificProductTypeFields[product_type],
  };
};

const normalizeCurrentProductForSending = (data: Partial<IProduct>): IProductData => {
  const {
    // common
    currencyCode,
    description,
    lockedFlag,
    name,
    productType,
    statementCycleType,
    status,

    // revolving credit
    defaultStatementCycleParameter,
    minimumRepaymentAmount,
    minimumRepaymentRate,
    numberOfDaysToMakeRepayment,
    repaymentPeriodIsStatementCycle,
    immediateChargeback,
    applyRejectedChargebackInterest,

    // loan
    allowOverpayment,
    defaultInstalments,
    forgive,
    maxApr,
    maxInstalments,
    maxLoan,
    minApr,
    minInstalments,
    minLoan,
    repaymentCalculationType,
  } = data || {};

  const specificProductTypeFields = {
    [ProductType.LOAN]: {
      allow_overpayment: allowOverpayment ? Flag.YES : Flag.NO,
      default_instalments: stringsUtil.toNumber(defaultInstalments),
      forgive: stringsUtil.toNumber(forgive),
      max_apr: stringsUtil.toNumber(maxApr),
      max_instalments: stringsUtil.toNumber(maxInstalments),
      max_loan: stringsUtil.toNumber(maxLoan),
      min_apr: stringsUtil.toNumber(minApr),
      min_instalments: stringsUtil.toNumber(minInstalments),
      min_loan: stringsUtil.toNumber(minLoan),
      repayment_calculation_type: repaymentCalculationType?.value,
    },
    [ProductType.REVOLVING_CREDIT]: {
      default_statement_cycle_parameter: stringsUtil.toNumber(defaultStatementCycleParameter),
      minimum_repayment_amount: stringsUtil.toNumber(minimumRepaymentAmount),
      minimum_repayment_rate: stringsUtil.toNumber(minimumRepaymentRate),
      number_of_days_to_make_repayment: stringsUtil.toNumber(numberOfDaysToMakeRepayment),
      repayment_period_is_statement_cycle: repaymentPeriodIsStatementCycle ? Flag.YES : Flag.NO,
      immediate_chargeback: immediateChargeback ? Flag.YES : Flag.NO,
      apply_rejected_chargeback_interest: applyRejectedChargebackInterest ? Flag.YES : Flag.NO,
    },
  };

  return {
    currency_code: currencyCode?.value,
    description,
    locked_flag: lockedFlag ? Flag.YES : Flag.NO,
    name,
    product_type: productType?.value,
    statement_cycle_type_id: statementCycleType?.value,
    status: status?.value,
    ...specificProductTypeFields[productType?.value],
  };
};

export const normalizeCurrentProductForCreating = (data: IProduct): IProductData => {
  const { institution } = data || {};

  const normalizedData = normalizeCurrentProductForSending(data);

  return {
    institution_id: institution?.value,
    ...normalizedData,
  };
};

export const normalizeCurrentProductForUpdating = (
  data: Partial<IProduct>,
  currentData: IProductData
): Partial<IProductData> => {
  const normalizedData = normalizeCurrentProductForSending(data);
  const dataForSending = stringsUtil.getObjectDiff(normalizedData, currentData);

  return dataForSending;
};
