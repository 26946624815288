import React from 'react';

import { Box } from '@rebass/grid';

import { ActionButtons, Modal, Text } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName, ModalType } from 'consts';

import { IPayloadConfirmationModal } from 'store';

interface IConfirmationModal extends IWithModal {
  payloadConfirmModal: IPayloadConfirmationModal;
}

const modalName = ModalName.CONFIRMATION;

const ConfirmationModal: React.FC<IConfirmationModal> = ({
  closeModal,
  payloadConfirmModal,
}) => {
  const handleConfirm = React.useCallback(
    () => {
      const confirmationAction = payloadConfirmModal?.confirmationAction;

      confirmationAction();
      closeModal(modalName);
    },
    [payloadConfirmModal, closeModal]
  );

  return (
    <Modal
      name={modalName}
      title={payloadConfirmModal?.confirmationTitle || 'Are you sure?'}
      containerWidth="400px"
      type={ModalType.CONFIRMATION}
    >
      {payloadConfirmModal?.confirmationText && (
        <Box mb="10px">
          <Text fontSize="14px">{payloadConfirmModal?.confirmationText}</Text>
        </Box>
      )}
      <ActionButtons
        onCancel={() => closeModal(modalName)}
        onOk={handleConfirm}
      />
    </Modal>
  );
};

export default withModal(ConfirmationModal);
