import { TApiResponse } from 'types';
import {
  ICommonTransactionsData,
  ISettleTransactionResponse,
  ITransactionDataResp,
} from './types';

export enum ActionTypeKeys {
  /** Filter transactions action type keys */
  FILTER_TRANSACTIONS = 'transactions/FILTER_TRANSACTIONS',
  FILTER_TRANSACTIONS_FULFILLED = 'transactions/FILTER_TRANSACTIONS_FULFILLED',
  FILTER_TRANSACTIONS_REJECTED = 'transactions/FILTER_TRANSACTIONS_REJECTED',

  /** Get transaction action type keys */
  GET_TRANSACTION = 'transactions/GET_TRANSACTION',
  GET_TRANSACTION_FULFILLED = 'transactions/GET_TRANSACTION_FULFILLED',
  GET_TRANSACTION_REJECTED = 'transactions/GET_TRANSACTION_REJECTED',

  /** Settle transaction action types keys */
  SETTLE_TRANSACTION = 'transactions/SETTLE_TRANSACTION',
  SETTLE_TRANSACTION_FULFILLED = 'transactions/SETTLE_TRANSACTION_FULFILLED',
  SETTLE_TRANSACTION_REJECTED = 'transactions/SETTLE_TRANSACTION_REJECTED',

  /** Reset settle transaction result action types keys */
  RESET_SETTLE_TRANSACTION_RESULT = 'transactions/RESET_SETTLE_TRANSACTION_RESULT',

  /** Reset action types keys */
  RESET_TRANSACTIONS = 'transactions/RESET_TRANSACTIONS',
  RESET_TRANSACTION = 'transactions/RESET_TRANSACTION',
}

/** Filter transactions action interfaces */

export interface IFilterTransactionsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_TRANSACTIONS;
}

export interface IFilterTransactionsFulfilledAction {
  readonly payload: ICommonTransactionsData;
  readonly type: ActionTypeKeys.FILTER_TRANSACTIONS_FULFILLED;
}

export interface IFilterTransactionsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_TRANSACTIONS_REJECTED;
}

/** Get transaction action interfaces */

export interface IGetTransactionAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_TRANSACTION;
}

export interface IGetTransactionFulfilledAction {
  readonly payload: ITransactionDataResp;
  readonly type: ActionTypeKeys.GET_TRANSACTION_FULFILLED;
}

export interface IGetTransactionRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_TRANSACTION_REJECTED;
}

/** Reset transactions action interfaces */

export interface IResetTransactionsAction {
  readonly type: ActionTypeKeys.RESET_TRANSACTIONS;
}

/** Reset transaction action interfaces */

export interface IResetTransactionAction {
  readonly type: ActionTypeKeys.RESET_TRANSACTION;
}

/** Settle transaction action interfaces */

export interface ISettleTransactionAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.SETTLE_TRANSACTION;
}

export interface ISettleTransactionFulfilledAction {
  readonly payload: ISettleTransactionResponse;
  readonly type: ActionTypeKeys.SETTLE_TRANSACTION_FULFILLED;
}

export interface ISettleTransactionRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.SETTLE_TRANSACTION_REJECTED;
}

/** Reset settle transaction result action interfaces */
export interface IResetSettleTransactionResultAction {
  readonly type: ActionTypeKeys.RESET_SETTLE_TRANSACTION_RESULT;
}

export type TTransactionsAction =
  | IFilterTransactionsFulfilledAction
  | IGetTransactionFulfilledAction
  | IResetTransactionsAction
  | ISettleTransactionFulfilledAction
  | IResetTransactionAction
  | IResetSettleTransactionResultAction;
