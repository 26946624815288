import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import { ActionButtons, Button } from 'components';
import { FormName, IconName } from 'consts';
import { EndpointFields } from './../../components';

import {
  THandleAddEndpoint,
  THandleDeleteEndpoint,
  THandleGetDictionaryEndpointTypes,
  THandleUpdateEndpoint,
} from 'store';

import { ISelectValue } from 'types';

interface IEndpointForm {
  addEndpoint: THandleAddEndpoint;
  currentEndpointName?: string;
  deleteEndpoint: THandleDeleteEndpoint;
  endpointTypesOptions: Array<ISelectValue>;
  getDictionaryEndpointTypes: THandleGetDictionaryEndpointTypes;
  institutionsOptions: Array<ISelectValue>;
  isAdding: boolean;
  isEditMode?: boolean;
  isLoadingTypesSelector: boolean;
  isReadOnly?: boolean;
  isUpdating: boolean;
  onCancel: () => void;
  updateEndpoint: THandleUpdateEndpoint;
}

type TEndpointForm = IEndpointForm & InjectedFormProps<{}, IEndpointForm>;

const EndpointForm: React.FC<TEndpointForm> = ({
  addEndpoint,
  currentEndpointName,
  deleteEndpoint,
  dirty,
  endpointTypesOptions,
  getDictionaryEndpointTypes,
  handleSubmit,
  institutionsOptions,
  isAdding,
  isEditMode,
  isLoadingTypesSelector,
  isReadOnly,
  isUpdating,
  onCancel,
  pristine,
  updateEndpoint,
}) => {
  React.useEffect(
    () => {
      getDictionaryEndpointTypes();
    },
    [getDictionaryEndpointTypes]
  );

  const submitFormAction = React.useMemo(
    () => isEditMode ? updateEndpoint : addEndpoint,
    [isEditMode, updateEndpoint, addEndpoint]
  );

  return (
    <form onSubmit={isReadOnly ? null : handleSubmit(submitFormAction)}>
      <EndpointFields
        endpointTypesOptions={endpointTypesOptions}
        institutionsOptions={institutionsOptions}
        isEditMode={isEditMode}
        isLoadingTypesSelector={isLoadingTypesSelector}
        isReadOnly={isReadOnly || isUpdating || isAdding}
      />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt="10px"
      >
        <Box>
          {isEditMode && !isReadOnly && (
            <Button
              text="Delete"
              iconName={IconName.DELETE}
              type="reset"
              withConfirmation={true}
              confirmationText={`Delete endpoint "${currentEndpointName}"?`}
              onClick={deleteEndpoint}
            />
          )}
        </Box>
        <ActionButtons
          okText="Save"
          cancelText="Close"
          onCancel={onCancel}
          disabledOk={pristine}
          withCancelConfirmation={dirty}
          hideOk={isReadOnly}
          isLoadingOk={isUpdating || isAdding}
        />
      </Flex>
    </form >
  );
};

export default reduxForm<{}, IEndpointForm>({
  form: FormName.ENDPOINT,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(EndpointForm);
