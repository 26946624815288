import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Box } from '@rebass/grid';

import { ActionButtons } from 'components';
import { FormName } from 'consts';
import { THandleAddUsersGroup } from 'store';

import { UsersGroupFields } from './../../components';

interface IAddUsersGroupForm {
  addUsersGroup: THandleAddUsersGroup;
  isAdding: boolean;
  onCancel: () => void;
}

type TAddUsersGroupForm = IAddUsersGroupForm & InjectedFormProps<{}, IAddUsersGroupForm>;

const AddUsersGroupForm: React.FC<TAddUsersGroupForm> = ({
  addUsersGroup,
  dirty,
  handleSubmit,
  isAdding,
  onCancel,
  pristine,
}) => (
  <form onSubmit={handleSubmit(addUsersGroup)}>
    <UsersGroupFields isReadOnly={isAdding} />

    <Box mt="10px">
      <ActionButtons
        okText="Save"
        cancelText="Close"
        onCancel={onCancel}
        withCancelConfirmation={dirty}
        disabledOk={pristine}
        isLoadingOk={isAdding}
      />
    </Box>
  </form>
);

export default reduxForm<{}, IAddUsersGroupForm>({
  form: FormName.ADD_USERS_GROUP,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AddUsersGroupForm);
