import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Flex } from '@rebass/grid';

import {
  ActionButtons,
  HorizontalLine,
  T3,
} from 'components';
import { FormName, ProductType } from 'consts';
import { ISelectValue } from 'types';

import {
  IAccount,
  THandleAddAccount,
  THandleFilterProductsForSelection,
  THandleGetDictionaryAccountStatuses,
  THandleGetDictionaryRepaymentMethods,
  THandleGetDictionaryRepaymentTypes,
  THandleUpdateAccount,
} from 'store';

import {
  AccountGeneralFields,
  LoanFields,
  RevolvingCreditFields,
} from './../../components';

interface IAccountForm {
  addAccount: THandleAddAccount;
  currentAccountStatus?: string | number;
  currentProductType?: ISelectValue;
  currentRepaymentType?: ISelectValue;
  filterProductsForSelection: THandleFilterProductsForSelection;
  getAccountStatuses: THandleGetDictionaryAccountStatuses;
  getRepaymentMethods: THandleGetDictionaryRepaymentMethods;
  getRepaymentTypes: THandleGetDictionaryRepaymentTypes;
  initialValues?: IAccount;
  institutionsOptions: Array<ISelectValue>;
  isAdding: boolean;
  isEditMode?: boolean;
  isProductsLoading: boolean;
  isReadOnly?: boolean;
  isRepaymentMethodsLoading: boolean;
  isRepaymentTypesLoading: boolean;
  isUpdating: boolean;
  onCancel: () => void;
  productsOptions: Array<ISelectValue>;
  repaymentMethodsOptions: Array<ISelectValue>;
  repaymentTypesOptions: Array<ISelectValue>;
  statusesOptions: Array<ISelectValue>;
  updateAccount: THandleUpdateAccount;
}

type TAccountForm = IAccountForm & InjectedFormProps<{}, IAccountForm>;

const AccountForm: React.FC<TAccountForm> = ({
  addAccount,
  currentAccountStatus,
  currentProductType,
  currentRepaymentType,
  dirty,
  filterProductsForSelection,
  getAccountStatuses,
  getRepaymentMethods,
  getRepaymentTypes,
  handleSubmit,
  initialValues,
  institutionsOptions,
  isAdding,
  isEditMode,
  isProductsLoading,
  isReadOnly,
  isRepaymentMethodsLoading,
  isRepaymentTypesLoading,
  isUpdating,
  onCancel,
  productsOptions,
  repaymentMethodsOptions,
  repaymentTypesOptions,
  statusesOptions,
  updateAccount,
}) => {
  const productType = React.useMemo(
    () => {
      const type = currentProductType?.value;

      return {
        isLoan: type === ProductType.LOAN,
        isRevolvingCredit: type === ProductType.REVOLVING_CREDIT,
      };
    },
    [currentProductType]
  );

  React.useEffect(
    () => {
      Promise.all([
        getAccountStatuses(),
        getRepaymentMethods(),
        getRepaymentTypes(),
      ]);
    },
    [
      getAccountStatuses,
      getRepaymentMethods,
      getRepaymentTypes,
    ]
  );

  React.useEffect(
    () => {
      if (currentProductType && !isEditMode) {
        filterProductsForSelection({ productType: currentProductType?.value });
      }
    },
    [currentProductType, filterProductsForSelection, isEditMode]
  );

  // eslint-disable-next-line
  const handleSubmitForm = React.useCallback(
    handleSubmit(isEditMode ? updateAccount : addAccount),
    [handleSubmit, updateAccount, addAccount, isEditMode]
  );

  return (
    <form onSubmit={isReadOnly ? null : handleSubmitForm}>
      <AccountGeneralFields
        currentAccountStatus={currentAccountStatus}
        institutionsOptions={institutionsOptions}
        isDisabledProductSelection={!currentProductType}
        isEditMode={isEditMode}
        isPreviousStatus={productType.isLoan}
        isProductsLoading={isProductsLoading}
        isReadOnly={isReadOnly || isAdding || isUpdating}
        isStatementId={productType.isLoan}
        productsOptions={productsOptions}
        statusesOptions={statusesOptions}
      />

      {productType.isRevolvingCredit && (
        <>
          <HorizontalLine />
          <T3>Details</T3>
          <RevolvingCreditFields
            currentAccount={initialValues}
            currentRepaymentType={currentRepaymentType}
            isEditMode={isEditMode}
            isReadOnly={isReadOnly || isAdding || isUpdating}
            isRepaymentMethodsLoading={isRepaymentMethodsLoading}
            isRepaymentTypesLoading={isRepaymentTypesLoading}
            repaymentMethodsOptions={repaymentMethodsOptions}
            repaymentTypesOptions={repaymentTypesOptions}
          />
        </>
      )}

      {productType.isLoan && (
        <>
          <HorizontalLine />
          <T3>Details</T3>
          <LoanFields
            isEditMode={isEditMode}
            isReadOnly={isReadOnly || isAdding || isUpdating }
          />
        </>
      )}

      <Flex
        justifyContent="flex-end"
        mt="15px"
      >
        <ActionButtons
          okText="Save"
          cancelText="Close"
          onCancel={onCancel}
          withCancelConfirmation={dirty}
          hideOk={isReadOnly}
          isLoadingOk={isAdding || isUpdating}
        />
      </Flex>
    </form >
  );
};

export default reduxForm<{}, IAccountForm>({
  form: FormName.ACCOUNT,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AccountForm);
