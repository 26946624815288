import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { isDirty } from 'redux-form';

import SettleTransactionModal from './SettleTransactionModal';

import { FormName } from 'consts';

import {
  handleSettleTransaction,
  isSettlingTrLoadingSelector,
  IStoreState,
  transactionForSettlementSelector,
} from 'store';

const dirtySettleTransactionForm = isDirty(FormName.SETTLE_TRANSACTION);

const mapStateToProps = (state: IStoreState) => ({
  isDirtySettleTransactionForm: dirtySettleTransactionForm(state),
  isLoading: isSettlingTrLoadingSelector(state),
  transactionForSettlement: transactionForSettlementSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    settleTransaction: handleSettleTransaction,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettleTransactionModal);
