import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';

import { ActionTypeKeys } from './actionTypes';
import { normalizeData } from './utils';

export const defaultUsersActivitySelector = (state: IStoreState) =>
  state.audit.usersActivity.usersActivity;

export const usersActivitySelector = createSelector(
  defaultUsersActivitySelector,
  data => data?.map(el => normalizeData(el))
);

export const usersActivityTotalPagesSelector = (state: IStoreState) =>
  state.audit.usersActivity.totalPages;

export const usersActivityTotalResultsSelector = (state: IStoreState) =>
  state.audit.usersActivity.totalResults;

export const usersActivityCurrentPageSelector = (state: IStoreState) =>
  state.audit.usersActivity.pageNumber;

export const isUsersActivityLoadingSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_USERS_ACTIVITY,
]);
