import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { formValueSelector } from 'redux-form';

import { FormName } from 'consts';
import Rewards from './Rewards';

import {
  handleAddProductReward,
  handleDeleteProductReward,
  handleGetProductRewards,
  isProductRewardAddingSelector,
  isProductRewardDeletingSelector,
  isProductRewardsLoadingSelector,
  IStoreState,
  productRewardsSelector,
  resetPayments,
} from 'store';

const formSelector = formValueSelector(FormName.PRODUCT_REWARD);

const mapStateToProps = (state: IStoreState) => ({
  applicationCondition: formSelector(state, 'rewardApplicationCondition'),
  isAdding: isProductRewardAddingSelector(state),
  isDeleting: isProductRewardDeletingSelector(state),
  isRewardsLoading: isProductRewardsLoadingSelector(state),
  rewards: productRewardsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    addReward: handleAddProductReward,
    deleteReward: handleDeleteProductReward,
    getRewards: handleGetProductRewards,
    resetPayments,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rewards);
