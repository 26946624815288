import React from 'react';
import { CellInfo } from 'react-table';
import { ImmutableArray } from 'seamless-immutable';

import { Flex } from '@rebass/grid';

import { Button, Table, TableCell, withSpinner } from 'components';
import { IconName } from 'consts';

import {
  IUsersGroup,
  IUsersGroupMember,
  THandleDeleteUsersGroupMember,
  THandleGetUsersGroupMembers,
} from 'store';

import { ITableCell } from 'types';

interface IUsersGroupMembers {
  deleteUsersGroupMember: THandleDeleteUsersGroupMember;
  getUsersGroupMembers: THandleGetUsersGroupMembers;
  isDeleting: boolean;
  isReadOnly: boolean;
  members: ImmutableArray<IUsersGroupMember>;
  usersGroup: IUsersGroup;
}

type TCell<T extends keyof IUsersGroupMember> = ITableCell<IUsersGroupMember[T]>;

export const UsersGroupMembers: React.FC<IUsersGroupMembers> = ({
  deleteUsersGroupMember,
  getUsersGroupMembers,
  isDeleting,
  isReadOnly,
  members,
  usersGroup,
}) => {
  React.useEffect(
    () => {
      getUsersGroupMembers(usersGroup);
    },
    [getUsersGroupMembers, usersGroup]
  );

  const tableColumns = React.useMemo(
    () => [
      {
        minWidth: 600,
        accessor: 'fullName',
        Cell: (props: TCell<'fullName'>) => (
          <TableCell value={props.value} />
        ),
      },
      {
        maxWidth: 65,
        accessor: 'deleteButton',
        Cell: (cellInfo: CellInfo) => (
          <Flex
            justifyContent="center"
            width="100%"
            p="4px"
          >
            <Button
              iconName={IconName.DELETE}
              title="Remove"
              size="10"
              classNames={['is-circle']}
              withConfirmation={true}
              disabled={isReadOnly || isDeleting}
              confirmationText={`Remove user "${cellInfo.original.fullName}" from the users group "${usersGroup.name}"?`}
              onClick={() => deleteUsersGroupMember(
                cellInfo.original.usersGroupId,
                cellInfo.original.userId
              )}
            />
          </Flex>
        ),
      },
    ],
    [deleteUsersGroupMember, isDeleting, isReadOnly, usersGroup]
  );

  return (
    <Table
      data={members}
      columns={tableColumns}
      isHeader={false}
      pageSize={10}
    />
  );
};

export default withSpinner()(UsersGroupMembers);
