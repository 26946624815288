
import { getFormValues } from 'redux-form';

import { FormName } from 'consts';
import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import {
  ActionTypeKeys,
  IIllustrateLoanAction,
  IIllustrateRevCreditAction,
} from './actionTypes';
import * as api from './api';
import {
  ILoanIllustrationRequest,
  IRevCreditIllustrationRequest,
} from './types';
import {
  normalizeLoanIllustrationForSending,
  normalizeRevCreditIllustrationForSending,
} from './utils';

export type TIllustrateLoan = (productId: number | string, queryString: string) =>
  IIllustrateLoanAction;

export type THandleIllustrateLoan = () => Thunk<void>;

export const illustrateLoan: TIllustrateLoan = (productId, queryString) => ({
  type: ActionTypeKeys.ILLUSTRATE_LOAN,
  payload: api.illustrateLoan(productId, queryString),
});

export const handleIllustrateLoan: THandleIllustrateLoan = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();

        const formValues = getFormValues(FormName.PRODUCT_LOAN_ILLUSTRATION);
        const filterValues = formValues(state) as ILoanIllustrationRequest;

        const productId = filterValues.productId;

        const queryString = normalizeLoanIllustrationForSending(filterValues);

        await dispatch(illustrateLoan(productId, queryString));
      },
      dispatch
    );
  };

export type TIllustrateRevCredit = (accountId: number | string, queryString: string) =>
  IIllustrateRevCreditAction;

export type THandleIllustrateRevCredit = () => Thunk<void>;

export const illustrateRevCredit: TIllustrateRevCredit = (accountId, queryString) => ({
  type: ActionTypeKeys.ILLUSTRATE_REVOLVING_CREDIT,
  payload: api.illustrateRevCredit(accountId, queryString),
});

export const handleIllustrateRevCredit: THandleIllustrateRevCredit = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();

        const formValues = getFormValues(FormName.PRODUCT_REVOLVING_CREDIT_ILLUSTRATION);
        const filterValues = formValues(state) as IRevCreditIllustrationRequest;

        const accountId = filterValues.accountId;

        const queryString = normalizeRevCreditIllustrationForSending(filterValues);

        await dispatch(illustrateRevCredit(accountId, queryString));
      },
      dispatch
    );
  };

export type TResetProductIllustration = () => void;

export const resetProductIllustration: TResetProductIllustration = () => ({
  type: ActionTypeKeys.RESET_PRODUCT_ILLUSTRATION,
});
