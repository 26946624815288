import React from 'react';

import { Modal } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';
import { EndpointForm } from './../../forms';

interface IAddEndpointModal extends IWithModal {
  isFormDirty: boolean;
}

const modalName = ModalName.ADD_ENDPOINT;

const AddEndpointModal: React.FC<IAddEndpointModal> = ({
  closeModal,
  isFormDirty,
}) => {
  return (
    <Modal
      name={modalName}
      title="Create endpoint"
      containerWidth="600px"
      withCloseConfirmation={isFormDirty}
    >
      <EndpointForm onCancel={() => closeModal(modalName)} />
    </Modal>
  );
};

export default withModal(AddEndpointModal);
