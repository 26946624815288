import { ModalName, } from 'consts';

import { activeItemIdSelector, closeModal, isInterfacesLoadedSelector, openModal } from 'store';
import {
  ActionTypeKeys,
  IAddInterfaceAction,
  IDeleteInterfaceAction,
  IFilterInterfacesAction,
  IGetInterfaceAction,
  IUpdateInterfaceAction,
} from './actionTypes';

import * as api from './api';

import {
  IInterfaceData,
  IInterfaceDetails,
  IInterfacesFilter,
} from './types';

import { normalizeDataForSending, normalizeFilterQueryString } from './utils';

import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

/**
 * Filter interfaces action
 */

export type TFilterInterfaces = (queryString: string) => IFilterInterfacesAction;
export type THandleFilterInterfaces = (data: IInterfacesFilter) => Thunk<void>;

export const filterInterfaces: TFilterInterfaces = queryString => ({
  type: ActionTypeKeys.FILTER_INTERFACES,
  payload: api.filterInterfaces(queryString),
});

export const handleFilterInterfaces: THandleFilterInterfaces = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const queryString = normalizeFilterQueryString(data);

        if (!isInterfacesLoadedSelector(state)) {
          await dispatch(filterInterfaces(queryString));
        }
      },
      dispatch
    );
  };

/**
 * Add interface action
 */

export type TAddInterface = (data: Partial<IInterfaceData>) => IAddInterfaceAction;
export type THandleAddInterface = (data: Partial<IInterfaceDetails>) => Thunk<void>;

export const addInterface: TAddInterface = data => ({
  type: ActionTypeKeys.ADD_INTERFACE,
  payload: api.addInterface(data),
});

export const handleAddInterface: THandleAddInterface = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const normalizedData = normalizeDataForSending(data);

        const res = (await dispatch(
          addInterface(normalizedData)
        )) as any;

        dispatch(closeModal(ModalName.ADD_INTERFACE));

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: {
              title: 'Interface has been created',
              message: `Interface ID: ${res.value.interface_id}`,
              isSuccess: true,
            },
          })
        );
      },
      dispatch
    );
  };

/**
 * Get interface action
 */

export type TGetInterface = (id: number) => IGetInterfaceAction;
export type THandleGetInterface = () => Thunk<void>;

export const getInterface: TGetInterface = id => ({
  type: ActionTypeKeys.GET_INTERFACE,
  payload: api.getInterface(id),
});

export const handleGetInterface: THandleGetInterface = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const id = activeItemIdSelector(state);

        await dispatch(getInterface(id));
      },
      dispatch
    );
  };

/**
 * Delete interface action
 */

export type TDeleteInterface = (id: number) => IDeleteInterfaceAction;
export type THandleDeleteInterface = () => Thunk<void>;

export const deleteInterface: TDeleteInterface = id => ({
  type: ActionTypeKeys.DELETE_INTERFACE,
  payload: api.deleteInterface(id),
  meta: { id },
});

export const handleDeleteInterface: THandleDeleteInterface = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const id = activeItemIdSelector(state);

        await dispatch(deleteInterface(id));
        dispatch(closeModal(ModalName.DETAILS_INTERFACE));

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: {
              title: 'Interface has been deleted',
              message: `Interface ID: ${id}`,
              isSuccess: true,
            },
          })
        );
      },
      dispatch
    );
  };

/**
 * Update interface action
 */

export type TUpdateInterface = (data: Partial<IInterfaceData>, interfaceId: number) =>
  IUpdateInterfaceAction;

export type THandleUpdateInterface = (data: Partial<IInterfaceDetails>) => Thunk<void>;

export const updateInterface: TUpdateInterface = (data, interfaceId) => ({
  type: ActionTypeKeys.UPDATE_INTERFACE,
  payload: api.updateInterface(data, interfaceId),
});

export const handleUpdateInterface: THandleUpdateInterface = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const interfaceId = activeItemIdSelector(state);
        const normalizedData = normalizeDataForSending(data);

        await dispatch(updateInterface(normalizedData, interfaceId));
        await dispatch(getInterface(interfaceId));
      },
      dispatch
    );
  };

/**
 * Reset interfaces action
 */

export type TResetInterfaces = () => void;

export const resetInterfaces: TResetInterfaces = () => ({
  type: ActionTypeKeys.RESET_INTERFACES,
});
