import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';

import { ActionTypeKeys } from './actionTypes';
import {
  normalizeCommonStatement,
  normalizeCurrentStatement,
  normalizeStatementAprs,
} from './utils';

/**
 * Statements selectors
 */

export const defaultStatementsSelector = (state: IStoreState) => state.ledger.statements.statements;

export const statementsSelector = createSelector(
  defaultStatementsSelector,
  statements => statements?.map(statement => normalizeCommonStatement(statement))
);

export const statementsTotalPagesSelector = (state: IStoreState) =>
  state.ledger.statements.totalPages;

export const statementsTotalResultsSelector = (state: IStoreState) =>
  state.ledger.statements.totalResults;

export const statementsCurrentPageSelector = (state: IStoreState) =>
  state.ledger.statements.pageNumber;

/** Current statement selectors */

export const defaultCurrentStatementSelector = (state: IStoreState) =>
  state.ledger.statements.currentStatement;

export const currentStatementSelector = createSelector(
  defaultCurrentStatementSelector,
  statement => normalizeCurrentStatement(statement)
);

/**
 * Statement APRs selectors
 */

export const defaultStatementAprsSelector = (state: IStoreState) =>
  state.ledger.statements.statementAprs;

export const statementAprsSelector = createSelector(
  defaultStatementAprsSelector,
  data => data?.map(el => normalizeStatementAprs(el))
);

/**
 * Statement loading selectors
 */

export const isStatementsLoadingSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_STATEMENTS,
]);

export const isStatementLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_STATEMENT,
  ActionTypeKeys.GET_STATEMENT_APRS,
]);
