import React from 'react';
import { Field } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  CheckboxField,
  HorizontalLine,
  InputField,
  NumberFormatField,
  SelectField,
  TextareaField,
} from 'components';

import {
  CommonStatus,
  commonStatusOptions,
  CycleType,
  ProductType,
  productTypesOptions,
  statementCycleTypesOptions,
} from 'consts';

import {
  IProduct,
  THandleGetDictionaryCurrencies,
} from 'store';

import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

import ILDetailsFields from './ILDetailsFields';
import RCDetailsFields from './RCDetailsFields';

interface IGeneralProductFields {
  currenciesOptions: Array<ISelectValue>;
  getDictionaryCurrencies: THandleGetDictionaryCurrencies;
  initialValues?: IProduct;
  institutionsOptions: Array<ISelectValue>;
  isCurrenciesLoading: boolean;
  isEditMode?: boolean;
  isLoading?: boolean;
  isLocked?: boolean;
  isReadOnly?: boolean;
  productType: string | number;
  statementCycleType: ISelectValue;
}

const GeneralProductFields: React.FC<IGeneralProductFields> = ({
  currenciesOptions,
  getDictionaryCurrencies,
  initialValues,
  institutionsOptions,
  isCurrenciesLoading,
  isEditMode = false,
  isLoading,
  isLocked,
  isReadOnly,
  productType,
  statementCycleType,
}) => {
  React.useEffect(
    () => {
      getDictionaryCurrencies();
    },
    [getDictionaryCurrencies]
);

  const isILProductType = React.useMemo(
    () => productType === ProductType.LOAN,
    [productType]
  );

  const isRCProductType = React.useMemo(
    () => productType === ProductType.REVOLVING_CREDIT,
    [productType]
  );

  const statementCycleParameterValidation = React.useMemo(
    () => {
      const validation = [formErrorUtil.isInteger];

      const config = {
        [CycleType.MONTHLY]: formErrorUtil.rangeValue(1, 28, 'for monthly type'),
        [CycleType.BI_MONTHLY]: formErrorUtil.rangeValue(1, 28, 'for bi-monthly type'),
        [CycleType.WEEKLY]: formErrorUtil.rangeValue(1, 7, 'for weekly type'),
        [CycleType.BI_WEEKLY]: formErrorUtil.rangeValue(1, 7, 'for bi-weekly type'),
        [CycleType.FIXED_NUMBER_OF_DAYS]: formErrorUtil
          .rangeValue(1, 250, 'for fixed number of days'),
      };

      return statementCycleType
        ? [config[statementCycleType.value], ...validation]
        : validation;
    },
    [statementCycleType]
  );

  const statusOptions = React.useMemo(
    () => {
      const statusesConfig: Array<{ from: CommonStatus; to: CommonStatus[]; }> = [
        { from: CommonStatus.ACTIVE, to: [CommonStatus.ACTIVE, CommonStatus.INACTIVE] },
        {
          from: CommonStatus.INACTIVE,
          to: [
            CommonStatus.INACTIVE,
            CommonStatus.ACTIVE,
            CommonStatus.DELETED,
          ],
        },
        { from: null, to: [CommonStatus.ACTIVE] }, // creating product
      ];

      const options: ISelectValue[] = JSON.parse(JSON.stringify(commonStatusOptions));

      const currentStatus = initialValues?.status?.value || null;
      const currentState = statusesConfig.find(el => el.from === currentStatus);
      const statusesTo = currentState ? currentState.to : [];

      options.map(option => statusesTo.find(el => el === option.value)
        ? option.isDisabled = false
        : option.isDisabled = true
      );

      return options;
    },
    [initialValues]
  );

  return (
    <Flex
      alignItems="flex-end"
      flexWrap="wrap"
      mx="-8px"
    >
      {!isEditMode && (
        <Box width="300px" p="8px">
          <Field
            id="institution"
            name="institution"
            component={SelectField}
            label="Institution"
            placeholder="Select Institution"
            options={institutionsOptions}
            isDisabled={isEditMode || isLoading || isReadOnly}
            isClearable={false}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>
      )}

      {isEditMode && (
        <>
          <Box width="300px" p="8px">
            <Field
              id="institutionName"
              name="institutionName"
              component={InputField}
              label="Institution"
              disabled={true}
            />
          </Box>
          <Box width="150px" p="8px">
            <Field
              id="id"
              name="id"
              component={InputField}
              label="ID"
              disabled={true}
              isNumber={true}
            />
          </Box>
        </>
      )}

      <HorizontalLine />

      <Flex alignItems="flex-start">
        <Flex
          width="300px"
          flexWrap="wrap"
          alignItems="flex-end"
        >
          <Box width={[1]} p="8px">
            <Field
              id="productType"
              name="productType"
              component={SelectField}
              label="Product Type"
              placeholder="Select Type"
              options={productTypesOptions}
              isDisabled={isEditMode || isLoading || isReadOnly}
              validate={[formErrorUtil.isRequired]}
            />
          </Box>
          <Box width={[1]} p="8px">
            <Field
              id="currencyCode"
              name="currencyCode"
              component={SelectField}
              label="Currency"
              placeholder="Select Currency"
              options={currenciesOptions}
              isLoading={isCurrenciesLoading}
              isDisabled={isLoading || isLocked || isReadOnly}
              validate={[formErrorUtil.isRequired]}
            />
          </Box>
          <Box width={[1]} p="8px">
            <Field
              id="status"
              name="status"
              component={SelectField}
              label="Status"
              placeholder="Select Status"
              options={statusOptions}
              isDisabled={isEditMode ? isLoading || isReadOnly : true}
              validate={[formErrorUtil.isRequired]}
            />
          </Box>
        </Flex>

        <Flex
          width="630px"
          flexWrap="wrap"
          alignItems="flex-end"
        >
          <Box width={[1]} p="8px">
            <Field
              id="name"
              name="name"
              placeholder="Enter Name"
              component={InputField}
              label="Name"
              disabled={isLoading || isLocked || isReadOnly}
              validate={[formErrorUtil.isRequired]}
            />
          </Box>
          <Box width={[1]} p="8px">
            <Field
              id="description"
              name="description"
              placeholder="Enter Description"
              component={TextareaField}
              label="Description"
              height={30}
              disabled={isLoading || isLocked || isReadOnly}
              validate={[formErrorUtil.isRequired]}
            />
          </Box>
          <Box width="150px" p="8px">
            <Field
              id="statementCycleType"
              name="statementCycleType"
              component={SelectField}
              label="Statement Cycle Type"
              placeholder="Select Type"
              options={statementCycleTypesOptions}
              isDisabled={isLoading || isLocked || isReadOnly}
              validate={[formErrorUtil.isRequired]}
            />
          </Box>

          {isRCProductType && (
            <Box width="150px" p="8px">
              <Field
                id="defaultStatementCycleParameter"
                name="defaultStatementCycleParameter"
                component={NumberFormatField}
                placeholder="0"
                fixedDecimalScale={true}
                decimalScale={0}
                label="Default Cycle Parameter"
                disabled={isLoading || isLocked || isReadOnly}
                validate={[
                  formErrorUtil.isRequired,
                  ...statementCycleParameterValidation,
                ]}
              />
            </Box>
          )}
        </Flex>
      </Flex>

      <Box width={[1]} p="8px">
        <Field
          id="lockedFlag"
          name="lockedFlag"
          component={CheckboxField}
          label="Locked"
          disabled={isLoading || isLocked || isReadOnly}
        />
      </Box>
      
      <HorizontalLine />

      {isRCProductType && (
        <RCDetailsFields
          isReadOnly={isLoading || isLocked || isReadOnly}
          statementCycleParameterValidation={statementCycleParameterValidation}
        />
      )}

      {isILProductType && (
        <ILDetailsFields
          isEditMode={isEditMode}
          isReadOnly={isLoading || isLocked || isReadOnly}
        />
      )}
    </Flex>
  );
};

export default GeneralProductFields;
