import { Flag, userStatusWith2faOptions } from 'consts';
import { ISelectValue } from 'types';
import { stringsUtil } from 'utils';

import { IUserData, IUserDetails, IUsersFilter, IUsersFilterToSend } from './types';

export const normalizeFilterQueryString = (data: IUsersFilter): string => {
  const { institutionId, status } = data || {};

  const normalizedData: IUsersFilterToSend = {
    institution_id: institutionId?.value || null,
    status: status?.value || null,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

const normalizeDataForSending = (data: Partial<IUserDetails>) => {
  const {
    email,
    firstName,
    lastName,
    password,
    requires2faFlag,
    status,
  } = data || {};

  return {
    email,
    first_name: firstName,
    last_name: lastName,
    password: password ? password : null,
    requires_2fa_flag: requires2faFlag ? Flag.YES : Flag.NO,
    status: status?.value,
  };
};

export const normalizeDataForCreating = (data: Partial<IUserDetails>): Partial<IUserData>  => {
  const { institution, username } = data;

  return {
    ...normalizeDataForSending(data),
    institution_id: institution?.value,
    username,
  };
};

export const normalizeDataForUpdating = (
  data: Partial<IUserDetails>,
  currentData: IUserData
): Partial<IUserData>  => {
  const normalizedData = normalizeDataForSending(data);
  const dataForSending = stringsUtil.getObjectDiff(normalizedData, currentData);

  const hasPassword = data?.password;

  if (!hasPassword) {
    delete dataForSending['password'];
  }

  return dataForSending;
};

export const normalizeData = (data: Partial<IUserData>, institution?: ISelectValue) => {
  const {
    datetime_of_last_login,
    email,
    first_name,
    id,
    last_name,
    password_entry_counter,
    requires_2fa_flag,
    status,
    username,
  } = data || {};

  const userStatus = userStatusWith2faOptions.find(el => el.value === status);

  return {
    datetimeOfLastLogin: datetime_of_last_login,
    email,
    firstName: first_name,
    id,
    institution: institution?.label,
    lastName: last_name,
    passwordEntryCounter: password_entry_counter,
    requires2faFlag: requires_2fa_flag === Flag.YES,
    status: userStatus?.label,
    username,
  };
};
