import React from 'react';
import { Field } from 'redux-form';

import { Box } from '@rebass/grid';
import { CheckboxField, NewLine, NumberFormatField, SelectField, T3 } from 'components';
import { loanRepaymentCalcTypesOptions } from 'consts';
import { formErrorUtil } from 'utils';

interface IILDetailsFields {
  isEditMode: boolean;
  isReadOnly: boolean;
}

const ILDetailsFields: React.FC<IILDetailsFields> = ({
  isEditMode,
  isReadOnly,
}) => (
  <>
    <Box width={[1]} p="8px">
      <T3>Product details</T3>
    </Box>

    <Box width="300px" p="8px">
      <Field
        id="repaymentCalculationType"
        name="repaymentCalculationType"
        component={SelectField}
        label="Repayment Calculation Type"
        placeholder="Select Method"
        options={loanRepaymentCalcTypesOptions}
        isDisabled={isReadOnly}
        validate={[formErrorUtil.isRequired]}
      />
    </Box>
    <Box width="150px" p="8px">
      <Field
        id="minInstalments"
        name="minInstalments"
        component={NumberFormatField}
        placeholder="0"
        fixedDecimalScale={true}
        decimalScale={0}
        label="Min Instalments"
        disabled={isReadOnly}
        validate={[formErrorUtil.isStrictPositive, formErrorUtil.isRequired]}
      />
    </Box>
    <Box width="150px" p="8px">
      <Field
        id="maxInstalments"
        name="maxInstalments"
        component={NumberFormatField}
        placeholder="0"
        label="Max Instalments"
        fixedDecimalScale={true}
        decimalScale={0}
        disabled={isReadOnly}
        validate={[formErrorUtil.isStrictPositive, formErrorUtil.isRequired]}
      />
    </Box>
    <Box width="150px" p="8px">
      <Field
        id="defaultInstalments"
        name="defaultInstalments"
        component={NumberFormatField}
        placeholder="0"
        fixedDecimalScale={true}
        decimalScale={0}
        label="Default Instalments"
        disabled={isReadOnly}
        validate={[formErrorUtil.isStrictPositive, formErrorUtil.isRequired]}
      />
    </Box>

    <NewLine />

    <Box width="150px" p="8px">
      <Field
        id="minApr"
        name="minApr"
        component={NumberFormatField}
        placeholder="0.00"
        fixedDecimalScale={true}
        decimalScale={2}
        label="Min APR"
        disabled={isReadOnly}
        validate={[formErrorUtil.isRequired, formErrorUtil.isPositive]}
      />
    </Box>
    <Box width="150px" p="8px">
      <Field
        id="maxApr"
        name="maxApr"
        component={NumberFormatField}
        placeholder="0.00"
        fixedDecimalScale={true}
        decimalScale={2}
        label="Max APR"
        disabled={isReadOnly}
        validate={[formErrorUtil.isRequired, formErrorUtil.isStrictPositive]}
      />
    </Box>
    <Box width="150px" p="8px">
      <Field
        id="minLoan"
        name="minLoan"
        component={NumberFormatField}
        placeholder="0.00"
        fixedDecimalScale={true}
        decimalScale={2}
        label="Min Loan"
        disabled={isReadOnly}
        validate={[formErrorUtil.isRequired, formErrorUtil.isStrictPositive]}
      />
    </Box>
    <Box width="150px" p="8px">
      <Field
        id="maxLoan"
        name="maxLoan"
        component={NumberFormatField}
        placeholder="0.00"
        fixedDecimalScale={true}
        decimalScale={2}
        label="Max Loan"
        disabled={isReadOnly}
        validate={[formErrorUtil.isRequired, formErrorUtil.isStrictPositive]}
      />
    </Box>
    <Box width="150px" p="8px">
      <Field
        id="forgive"
        name="forgive"
        component={NumberFormatField}
        placeholder="0.00"
        fixedDecimalScale={true}
        decimalScale={2}
        label="Forgive"
        hint="Maximum amount to forgive"
        disabled={isReadOnly}
        validate={[formErrorUtil.isPositive]}
      />
    </Box>
    <Box width={[1]} p="8px">
      <Field
        id="allowOverpayment"
        name="allowOverpayment"
        component={CheckboxField}
        label="Allow Overpayment"
        disabled={true}
      />
    </Box>
  </>
);

export default ILDetailsFields;
