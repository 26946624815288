import { connect } from 'react-redux';

import UpdateProductAPRModal from './UpdateProductAPRModal';

import { IStoreState, payloadUpdateProductAPRModalSelector } from 'store';

const mapStateToProps = (state: IStoreState) => ({
  productAPRDetails: payloadUpdateProductAPRModalSelector(state),
});

export default connect(
  mapStateToProps
)(UpdateProductAPRModal);
