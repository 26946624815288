import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';
import { ActionTypeKeys } from './actionTypes';
import {
  normalizeLoanIllustrationProduct,
  normalizeLoanIllustrationSchedule,
  normalizeRevCreditIllustration,
} from './utils';

/**
 * Loan
 */

export const defaultLoanIllustrationScheduleSelector = (state: IStoreState) =>
  state.productDesigner.productIllustration.loanIllustration.schedule;

export const loanIllustrationScheduleSelector = createSelector(
 defaultLoanIllustrationScheduleSelector,
 data => data?.map(el => normalizeLoanIllustrationSchedule(el))
);

export const defaultLoanIllustrationProductSelector = (state: IStoreState) =>
 state.productDesigner.productIllustration.loanIllustration.product;

export const defaultLoanIllustrationNumOfInstalmentsSelector = (state: IStoreState) =>
  state.productDesigner.productIllustration.loanIllustration.number_of_instalments;

export const defaultLoanIllustrationPrincipalSelector = (state: IStoreState) =>
  state.productDesigner.productIllustration.loanIllustration.principal;

export const defaultLoanIllustrationStartDateSelector = (state: IStoreState) =>
  state.productDesigner.productIllustration.loanIllustration.start_date;

export const defaultLoanIllustrationTotalInterestSelector = (state: IStoreState) =>
  state.productDesigner.productIllustration.loanIllustration.total_interest;

export const loanIllustrationGeneralSelector = createSelector(
   defaultLoanIllustrationProductSelector,
   defaultLoanIllustrationNumOfInstalmentsSelector,
   defaultLoanIllustrationPrincipalSelector,
   defaultLoanIllustrationStartDateSelector,
   defaultLoanIllustrationTotalInterestSelector,
   (product,
    numOfInstalments,
    principal,
    startDate,
    totalInterest) => normalizeLoanIllustrationProduct(
    product,
    numOfInstalments,
    principal,
    startDate,
    totalInterest
  )
 );

export const isLoanIllustrationLoadingSelector = createLoadingSelector([
 ActionTypeKeys.ILLUSTRATE_LOAN,
]);

/**
 * Revolving credit
 */

export const defaultRevCreditIllustrationSelector = (state: IStoreState) =>
  state.productDesigner.productIllustration.revCreditIllustration;

export const revCreditTransIllustrationSelector = createSelector(
  defaultRevCreditIllustrationSelector,
  data => normalizeRevCreditIllustration(data)
);

export const isRevCreditIllustrationLoadingSelector = createLoadingSelector([
  ActionTypeKeys.ILLUSTRATE_REVOLVING_CREDIT,
]);
