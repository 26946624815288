import React from 'react';
import { CellInfo } from 'react-table';

import { TableCell } from './TableCell';

import { ISelectValue } from 'types';

interface IEditableTableSelectCell {
  cellInfo: CellInfo;
  isEditable?: boolean;
  isSmaller?: boolean;
  selectOptions?: Array<ISelectValue>;
  updateAction?: (data: object) => void;
}

export const EditableTableSelectCell: React.FC<IEditableTableSelectCell> = ({
  cellInfo,
  isEditable = true,
  isSmaller,
  selectOptions,
  updateAction,
}) => {
  const handleSelectValue = React.useCallback(
    (value: ISelectValue) => {
      if (value) {
        updateAction({ ...cellInfo.original, [cellInfo.column.id]: value });
      }
    },
    [cellInfo, updateAction]
  );

  return (
    <TableCell
      value={cellInfo.value}
      isEditable={isEditable}
      isSmaller={isSmaller}
      defaultSelectValue={cellInfo.value}
      isSelect={true}
      selectLabel={cellInfo.value?.label}
      selectOptions={selectOptions}
      onSelectChange={handleSelectValue}
    />
  );
};
