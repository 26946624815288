import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Header from './Header';

import {
  handleLogout,
  isActiveUserSelector,
  IStoreState,
  permissionsSelector,
  profileSelector,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  isActiveUser: isActiveUserSelector(state),
  permissions: permissionsSelector(state),
  profile: profileSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    logout: handleLogout,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
