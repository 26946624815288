import { combineReducers } from 'redux-seamless-immutable';

import productGeneralReducer from './products/general/reducer';
import productIllustrationReducer from './products/illustration/reducer';
import productPaymentsReducer from './products/payments/reducer';
import productRulesReducer from './products/rules/reducer';

const productDesignerReducer = combineReducers({
  productGeneral: productGeneralReducer,
  productIllustration: productIllustrationReducer,
  productPayments: productPaymentsReducer,
  productRules: productRulesReducer,
});

export default productDesignerReducer;
