import React from 'react';

import { Box } from '@rebass/grid';

import { Modal, Text } from 'components';
import { ModalName } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import { storageUtil } from 'utils';

import { CodeForm } from './forms';

const modalName = ModalName.LOGIN_CODE_2FA;

interface IRegister2faModal extends IWithModal {}

const Register2faModal: React.FC<IRegister2faModal> = ({
  closeModal,
}) => (
  <Modal
    title="Enter second factor code"
    name={modalName}
    containerWidth="320px"
    hideCloseIcon={true}
  >
    <Box mb="10px">
      <Text>Enter the code generated by the authenticator app</Text>
    </Box>

    <CodeForm
      onCancel={() => {
        closeModal(modalName);
        storageUtil.clear();
      }}
    />
  </Modal>
);

export default withModal(Register2faModal);
