import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { defaultDictionaryCurrenciesSelector } from 'store/domains/admin/dictionaries';
import { createLoadingSelector } from 'store/domains/loader';
import { activeItemIdSelector } from 'store/domains/utils';

import { ActionTypeKeys } from './actionTypes';
import { normalizeData, normalizeDetails } from './utils';

export const defaultInstitutionsSelector = (state: IStoreState) =>
  state.admin.institutions.institutions;

export const institutionsSelector = createSelector(
  defaultInstitutionsSelector,
  data => data?.map(el => normalizeData(el))
);

/** Current institution selectors */

export const currentInstitutionSelector = createSelector(
  defaultInstitutionsSelector,
  activeItemIdSelector,
  defaultDictionaryCurrenciesSelector,
  (institutions, currentId, currencies) => {
    const currentInst = institutions.find(el => el.id === currentId);

    return normalizeDetails(currentInst, currencies);
  }
);

/** Institution loading selectors */

export const isUpdatingInstitutionSelector = createLoadingSelector([
  ActionTypeKeys.UPDATE_INSTITUTION,
]);

export const isAddingInstitutionSelector = createLoadingSelector([
  ActionTypeKeys.ADD_INSTITUTION,
]);

export const isDeletingInstitutionSelector = createLoadingSelector([
  ActionTypeKeys.DELETE_INSTITUTION,
]);

export const isGettingInstitutionsSelector = createLoadingSelector([
  ActionTypeKeys.GET_INSTITUTIONS,
]);
