import {
  DebitCreditIndicator,
  ProductType,
} from 'consts';
import { apiClientService } from 'services';
import { IApiCallConfig } from 'types';
import { stringsUtil } from 'utils';

// import { throttleUtil } from 'utils';

// import {
//   loanSettleTransactionResultMock,
//   loanTransactionMock,
//   revolvingCreditSettleTransactionResultMock,
//   revolvingCreditTransactionMock,
//   transactionsMock,
// } from './mock';

import { ISettlementFormValues } from './types';

/**
 * Filter transactions API
 */
export const filterTransactions = (queryString: string, productType: string | number) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/transactions?${queryString}`,
      // mock: transactionsMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/transactions?${queryString}`,
      // mock: transactionsMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Get transaction details API
 */
export const getTransaction = (
  transactionId: number,
  productType: string,
  debitCreditIndicator: DebitCreditIndicator
) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/transactions/${transactionId}?debit_credit_indicator=${debitCreditIndicator}`,
      // mock: loanTransactionMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/transactions/${transactionId}`,
      // mock: revolvingCreditTransactionMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Settle transaction API
 */
export const settleTransaction = (
  data: ISettlementFormValues,
  debitCreditIndicator: DebitCreditIndicator
) => {
  const { amount, transactionId, productType } = data;

  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/settle-transaction/${transactionId}?debit_credit_indicator=${debitCreditIndicator}`,
      // mock: loanSettleTransactionResultMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/settle-transaction/${transactionId}`,
      // mock: revolvingCreditSettleTransactionResultMock,
      payload: {
        data: { amount_settled: stringsUtil.toNumber(amount) },
      },
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path, payload } = config[productType];
  return apiClientService.post(path, payload);
};
