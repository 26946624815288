import { ModalName } from 'consts';

import UpdateProductAPRModal from './Aprs/UpdateProductAPRModal';
import DetailsProductModal from './DetailsProductModal';
import AddProductModal from './General/AddProductModal';

export const productModals = {
  [ModalName.ADD_PRODUCT]: (<AddProductModal />),
  [ModalName.DETAILS_PRODUCT]: (<DetailsProductModal />),
  [ModalName.UPDATE_PRODUCT_APR]: (<UpdateProductAPRModal />),
};
