import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import ApiCallModal from './ApiCallModal';

import {
  apiCallSelector,
  handleGetApiCall,
  isGettingApiCallSelector,
  IStoreState,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  apiCall: apiCallSelector(state),
  isLoading: isGettingApiCallSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getApiCall: handleGetApiCall,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiCallModal);
