import React from 'react';
import { Field } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import { InputField, MaskField, NewLine, SelectField } from 'components';
import { DateFormat, MaskFormat, ProductType, productTypesOptions } from 'consts';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface ITransactionsFilter {
  currentProductType: ISelectValue;
  isDisabled: boolean;
}

const TransactionsFilter: React.FC<ITransactionsFilter> = ({
  currentProductType,
  isDisabled,
}) => {
  const isDisabledCardId = React.useMemo(
    () => currentProductType?.value !== ProductType.REVOLVING_CREDIT,
    [currentProductType]
  );

  return (
    <Flex alignItems="flex-start" flexWrap="wrap">
      <Box width="150px" p="8px">
        <Field
          id="customerId"
          name="customerId"
          component={InputField}
          label="Customer ID"
          placeholder="Enter ID"
          isNumber={true}
          disabled={isDisabled}
          validate={[
            formErrorUtil.isInteger,
            formErrorUtil.isStrictPositive,
          ]}
        />
      </Box>
      <Box width="150px" p="8px">
        <Field
          id="accountId"
          name="accountId"
          component={InputField}
          label="Account ID"
          placeholder="Enter ID"
          isNumber={true}
          disabled={isDisabled}
          validate={[
            formErrorUtil.isInteger,
            formErrorUtil.isStrictPositive,
          ]}
        />
      </Box>
      <Box width="240px" p="8px">
        <Field
          id="productType"
          name="productType"
          component={SelectField}
          label="Product Type"
          placeholder="Select Type"
          options={productTypesOptions}
          isDisabled={isDisabled}
          isRequired={true}
          validate={[
            formErrorUtil.isRequired,
          ]}
        />
      </Box>
      <NewLine />
      <Box width="150px" p="8px">
        <Field
          id="transactionId"
          name="transactionId"
          component={InputField}
          label="Transaction ID"
          placeholder="Enter ID"
          isNumber={true}
          disabled={isDisabled}
          validate={[
            formErrorUtil.isInteger,
            formErrorUtil.isStrictPositive,
          ]}
        />
      </Box>
      <Box width="150px" p="8px">
        <Field
          id="cardId"
          name="cardId"
          component={InputField}
          label="Card ID"
          placeholder="Enter ID"
          isNumber={true}
          disabled={isDisabled || isDisabledCardId}
          validate={[
            formErrorUtil.isInteger,
            formErrorUtil.isStrictPositive,
          ]}
        />
      </Box>
      <Box width="120px" p="8px">
        <Field
          id="dateFrom"
          name="dateFrom"
          component={MaskField}
          label="Date from"
          placeholder={DateFormat.DATE}
          mask={MaskFormat.DATE}
          disabled={isDisabled}
          validate={[
            formErrorUtil.isDate,
          ]}
        />
      </Box>
      <Box width="120px" p="8px">
        <Field
          id="dateTo"
          name="dateTo"
          component={MaskField}
          label="Date to"
          placeholder={DateFormat.DATE}
          mask={MaskFormat.DATE}
          disabled={isDisabled}
          validate={[
            formErrorUtil.isDate,
          ]}
        />
      </Box>
    </Flex>
  );
};

export default TransactionsFilter;
