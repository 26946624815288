import React from 'react';

import { Flex } from '@rebass/grid';

import {
  Button,
  Modal,
  Tabs,
  TabsPanel,
  TransactionIcon,
} from 'components';

import {
  DebitCreditIndicator,
  ModalName,
  ProductType,
  TransactionStatus,
} from 'consts';

import {
  ITransaction,
  THandleGetTransaction,
  TResetTransaction,
} from 'store';

import LoanIllustration from 'containers/ProductDesigner/Products/LoanIllustration';

import { IWithModal, withModal } from 'HOCs';

import { TransactionForm } from './../../forms';

interface ITransactionModal extends IWithModal {
  currentTransaction: ITransaction;
  getTransaction: THandleGetTransaction;
  isLoading: boolean;
  resetTransaction: TResetTransaction;
}

const modalName = ModalName.TRANSACTION;

const TransactionModal: React.FC<ITransactionModal> = ({
  closeModal,
  currentTransaction,
  getTransaction,
  isLoading,
  resetTransaction,
}) => {
  React.useEffect(
    () => {
      getTransaction();
      return () => resetTransaction();
    },
    [getTransaction, resetTransaction]
  );

  const productType = React.useMemo(
    () => {
      return {
        isLoan: currentTransaction?.productType === ProductType.LOAN,
        isRevolvingCredit: currentTransaction?.productType === ProductType.REVOLVING_CREDIT,
        typeName: currentTransaction?.productTypeName,
        type: currentTransaction?.productType,
      };
    },
    [currentTransaction]
  );

  const isConvertibleToLoan = React.useMemo(
    () => currentTransaction?.productType === ProductType.REVOLVING_CREDIT
      && currentTransaction?.debitCreditIndicator === DebitCreditIndicator.DEBIT
      && currentTransaction?.status === TransactionStatus.SETTLED,
    [currentTransaction]
  );

  return (
    <Modal
      name={modalName}
      containerWidth="1015px"
      isLoading={isLoading}
    >

      <Tabs>
        <TabsPanel
          title="Transaction"
          Icon={<TransactionIcon size="16" />}
        >
          <TransactionForm
            initialValues={currentTransaction}
            productType={productType}
          />
        </TabsPanel>

        <TabsPanel
          title="Convert to Loan"
          isHidden={!isConvertibleToLoan}
          isDisabled={true}
        >
          <LoanIllustration
            mode="transaction"
            initialFormValues={null}
          />
        </TabsPanel>
      </Tabs>

      <Flex
        justifyContent="flex-end"
        mt="15px"
      >
        <Button
          text="Close"
          onClick={() => closeModal(modalName)}
        />
      </Flex>
    </Modal>
  );
};

export default withModal(TransactionModal);
