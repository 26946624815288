import React from 'react';

import { Flex } from '@rebass/grid';

import styled from 'theme';

import { Hint } from './../../Utils';
import { TabTitle } from './TabTitle';

import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';

const TabsWrapper = styled.div`
  margin: 0 -15px 10px;
  box-shadow: ${({ theme }) => theme.shadows.bottomBox};
`;

interface ITabs extends IWithModal {
  activeTab?: number;
  children: React.ReactNode;
}

const Tabs: React.FC<ITabs> = ({
  activeTab = 0,
  children,
  openModal,
}) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(activeTab);

  const tab = React.useMemo(
    () => {
      const items = children as Array<object>;

      return items.length
        ? children[activeTabIndex]
        : children;
    },
    [activeTabIndex, children]
  );

  return (
    <>
      <TabsWrapper>
        <Flex flexWrap="wrap">
          {React.Children.map(children, (child, i) => {
            if (!child) {
              return null;
            }

            const {
              title,
              hintIfDisabled,
              isDisabled,
              isLoading,
              withConfirmation,
              isHidden,
              Icon,
            } = child['props'];

            const handleClick = () =>
              withConfirmation
                ? openModal({
                  name: ModalName.CONFIRMATION,
                  payload: {
                    confirmationAction: () => setActiveTabIndex(i),
                    confirmationTitle: 'Switch the tab?',
                    confirmationText: 'You have unsaved changes.',
                  },
                })
                : setActiveTabIndex(i);

            return !isHidden && (
              <TabTitle
                className={i === activeTabIndex && 'is-active'}
                isDisabled={isDisabled || isLoading}
                onClick={(isDisabled || i === activeTabIndex) ? null : handleClick}
              >
                {Icon && (<div className="icon">{Icon}</div>)}
                <div className="title">{isLoading ? `${title}...` : title}</div>
                {hintIfDisabled && isDisabled && (
                  <Hint
                    text={hintIfDisabled}
                    icon={false}
                    position="bottom"
                  />
                )}
              </TabTitle>
            );
          })}
        </Flex>
      </TabsWrapper>
      <div>{tab}</div>
    </>
  );
};

export default withModal(Tabs);
