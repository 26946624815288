import React from 'react';

import { Flex } from '@rebass/grid';

import { Button, List, Modal } from 'components';
import { ModalName, ModalType } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import { TResetTransactionResult } from 'store';

interface IManualTransactionResultModal extends IWithModal {
  resetTransactionResult: TResetTransactionResult;
  manualTransactionResult: Array<Array<string | number>>;
}

const modalName = ModalName.MANUAL_TRANSACTION_RESULT;

const ManualTransactionResultModal: React.FC<IManualTransactionResultModal> = ({
  closeModal,
  manualTransactionResult,
  resetTransactionResult,
}) => {
  React.useEffect(
    () => {
      return () => resetTransactionResult();
    },
    [resetTransactionResult]
  );

  return (
    <Modal
      name={modalName}
      type={ModalType.MESSAGE}
      title="Success"
      isSuccessIcon={true}
      containerWidth="500px"
      hideCloseIcon={true}
    >

      <List items={manualTransactionResult} />

      <Flex
        justifyContent="flex-end"
        mt="15px"
      >
        <Button
          onClick={() => closeModal(modalName)}
          text="Close"
        />
      </Flex>
    </Modal>
  );
};

export default withModal(ManualTransactionResultModal);
