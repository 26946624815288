export enum StatementsStatus {
  ACTIVE = 'A',
  NOT_ACTIVE = 'I',
  RELEASED = 'R',
}

export const statementsStatusOptions = [
  { value: StatementsStatus.ACTIVE, label: 'Active' },
  { value: StatementsStatus.NOT_ACTIVE, label: 'Not active' },
  { value: StatementsStatus.RELEASED, label: 'Released' },
];
