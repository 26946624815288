import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import EditUsersGroupPermissionsForm from './EditUsersGroupPermissionsForm';

import {
  currentUsersGroupSelector,
  handleAddUsersGroupPermission,
  isAddingUsersGroupPermissionsSelector,
  isLoadingUsersGroupPermissionsSelector,
  IStoreState,
  usersGroupUiItemsSelector,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  isAdding: isAddingUsersGroupPermissionsSelector(state),
  isLoadingPermissions: isLoadingUsersGroupPermissionsSelector(state),
  uiItemsOptions: usersGroupUiItemsSelector(state),
  usersGroup: currentUsersGroupSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    addGroupPermission: handleAddUsersGroupPermission,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUsersGroupPermissionsForm);
