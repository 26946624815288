import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { formValueSelector } from 'redux-form';

import { FormName } from 'consts';

import ApiCallsFilter from './ApiCallsFilter';

import {
  endpointsOptionsSelector,
  handleFilterEndpoints,
  isFilteringEndpointsSelector,
  IStoreState,
} from 'store';

const formSelector = formValueSelector(FormName.FILTER);

const mapStateToProps = (state: IStoreState) => ({
  endpointsFilterValues: formSelector(state, 'institutionId'),
  endpointsOptions: endpointsOptionsSelector(state),
  isLoadingEndpoints: isFilteringEndpointsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    filterEndpoints: handleFilterEndpoints,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiCallsFilter);
