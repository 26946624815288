// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
// import { throttleUtil } from 'utils';

// import { interfaceMock, interfacesMock } from './mock';
import { IInterfaceData } from './types';

/**
 * Filter interfaces API
 */
export const filterInterfaces = (queryString: string) =>
  // throttleUtil.getDataAfter(interfacesMock, 500);
  apiClientService.get(`interfaces?${queryString}`);

/**
 * Get interface details API
 */
export const getInterface = (interfaceId: number) =>
  // throttleUtil.getDataAfter(interfaceMock, 500);
  apiClientService.get(`interfaces/${interfaceId}`);

/**
 * Delete interface details API
 */
export const deleteInterface = (interfaceId: number) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.delete(`interfaces/${interfaceId}`);

/**
 * Update interface details API
 */
export const updateInterface = (data: Partial<IInterfaceData>, interfaceId: number) =>
  // throttleUtil.getDataAfter(successResponseMock, 1000);
  apiClientService.put(`interfaces/${interfaceId}`, { data });

/**
 * Create interface API
 */
export const addInterface = (data: Partial<IInterfaceData>) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.post('interfaces', { data });
