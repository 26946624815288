import Immutable, * as seamlessImmutable from 'seamless-immutable';

import { ActionTypeKeys, TUsersActivityAction } from './actionTypes';
import { IUsersActivityState } from './types';

export const usersActivityInitialState:
  seamlessImmutable.ImmutableObject<IUsersActivityState> = Immutable({
    usersActivity: Immutable([]),
    pageNumber: null,
    totalPages: null,
    totalResults: null,
  });

const usersActivityReducer = (state = usersActivityInitialState, action: TUsersActivityAction) => {
  switch (action.type) {

    case ActionTypeKeys.FILTER_USERS_ACTIVITY_FULFILLED:
      return state
        .set('usersActivity', action.payload.users_audit)
        .set('pageNumber', action.payload.page_number)
        .set('totalPages', action.payload.total_pages)
        .set('totalResults', action.payload.total_results);

    case ActionTypeKeys.RESET_USERS_ACTIVITY:
      return state = usersActivityInitialState;

    default:
      return state;
  }
};

export default usersActivityReducer;
