import React from 'react';
import { ImmutableArray } from 'seamless-immutable';

import styled from 'theme';

import { HorizontalLine, List } from 'components';

import { IStatementApr } from 'store';

const LightText = styled.div`
  padding: 0 10px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 16px;
`;

interface IStatementAprsTable {
  data: ImmutableArray<IStatementApr>;
}

const StatementAprsTable: React.FC<IStatementAprsTable> = ({
  data,
}) => {
  const tableData = React.useMemo(
    () => {
      const arrayData = Array.from(data);

      return [
        ['Product APR ID', ...arrayData.map(el => el.productAprId)],
        ['Description', ...arrayData.map(el => el.description)],
        ['Rate, %', ...arrayData.map(el => el.rate)],
        ['Interest calculation start date', ...arrayData.map(el => el.interestCalcStartDate)],
        ['Repayment date', ...arrayData.map(el => el.repaymentDate)],
        ['Always charge interest', ...arrayData.map(el => el.alwaysChargeInterest)],
        ['Allow residual interest', ...arrayData.map(el => el.allowResidualInterest)],
        ['Repaid', ...arrayData.map(el => el.repaidFlag)],

        ['Accrued interest', ...arrayData.map(el => el.accruedInterest)],
        ['Accrued interest repaid', ...arrayData.map(el => el.accruedInterestRepaid)],

        ['Previous accrued interest', ...arrayData.map(el => el.prevAccruedInterest)],
        ['Previous accrued interest repaid', ...arrayData.map(el => el.prevAccruedInterestRepaid)],

        ['Outstanding accrued interest', ...arrayData.map(el => el.outsdAccruedInterest)],
        [
          'Outstanding accrued interest repaid',
          ...arrayData.map(el => el.outsdAccruedInterestRepaid),
        ],

        ['Statement unpaid principal', ...arrayData.map(el => el.stmntUnpaidPrincipal)],
        ['Statement repaid principal', ...arrayData.map(el => el.stmntRepaidPrincipal)],

        [
          'Previous statement unpaid principal',
          ...arrayData.map(el => el.prevStmntUnpaidPrincipal),
        ],
        [
          'Previous statement repaid principal',
          ...arrayData.map(el => el.prevStmntRepaidPrincipal),
        ],

        [
          'Outstanding statement unpaid principal',
          ...arrayData.map(el => el.outsdStmntUnpaidPrincipal),
        ],
        [
          'Outstanding statement repaid principal',
          ...arrayData.map(el => el.outsdStmntRepaidPrincipal),
        ],
      ];
    },
    [data]
  );

  return (
    <>
      {data?.length
        ? (<List items={tableData} />)
        : (
            <>
              <LightText>No statement APRs found</LightText>
              <HorizontalLine/>
            </>
          )
      }
    </>
  );
};

export default StatementAprsTable;
