import { Flag, ProductType, statementsStatusOptions } from 'consts';

import {
  ICommonStatement,
  ICommonStatementData,
  IStatement,
  IStatementApr,
  IStatementAprData,
  IStatementData,
  IStatementsFilter,
  IStatementsFilterToSend,
} from './types';

import { dateUtil, stringsUtil } from 'utils';

export const normalizeFilterQueryString = (data: IStatementsFilter): string => {
  const {
    accountId,
    customerId,
    dateFrom,
    dateTo,
    firstName,
    lastName,
    pageNumber,
    product,
  } = data;

  const normalizedData: IStatementsFilterToSend = {
    account_id: accountId,
    customer_id: customerId,
    date_from: dateUtil.formatDateForSending(dateFrom),
    date_to: dateUtil.formatDateForSending(dateTo),
    first_name: firstName,
    last_name: lastName,
    product_id: product?.value,
    page_number: pageNumber,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeCommonStatement = (data: ICommonStatementData): ICommonStatement => {
  const {
    account_id,
    customer_id,
    end_date,
    first_name,
    id,
    last_name,
    product_id,
    product_name,
    product_type_name,
    product_type,
    repayment_status_name,
    repayment_status,
    sequence_number,
    start_date,
    status,
    // Revolving credits
    repayment_minimum_amount,
    // Loans
    cycle_expected_repayment,
  } = data;

  const specificProductTypeFields = {
    [ProductType.LOAN]: {
      repaymentAmount: stringsUtil.numberToFixed(cycle_expected_repayment, 2),
    },
    [ProductType.REVOLVING_CREDIT]: {
      repaymentAmount: stringsUtil.numberToFixed(repayment_minimum_amount, 2),
    },
  };

  return {
    accountId: account_id,
    customerId: customer_id,
    endDate: end_date,
    firstName: first_name,
    id,
    lastName: last_name,
    productId: product_id,
    productName: product_name,
    productType: product_type,
    productTypeName: product_type_name,
    repaymentStatus: repayment_status,
    repaymentStatusName: repayment_status_name,
    sequenceNumber: sequence_number,
    startDate: start_date,
    status: statementsStatusOptions.find(el => el.value === status)?.label,
    ...specificProductTypeFields[product_type],
  };
};

export const normalizeCurrentStatement = (data: IStatementData): IStatement => {
  const {
  // Common
  account_id,
  account_reference,
  address_country_code,
  address_line1,
  address_line2,
  address_line3,
  address_line4,
  address_post_code,
  address_town,
  customer_id,
  end_date,
  first_name,
  id,
  institution_id,
  institution_name,
  last_name,
  overpayment,
  product_id,
  product_name,
  product_type_name,
  product_type,
  repayment_status_name,
  repayment_status,
  sequence_number,
  start_date,
  status,
  total_credits,
  total_debits,
  total_interest,
  total_fees,

  // Revolving credits
  balance_close,
  balance_open,
  date_of_last_update,
  first_transaction_id,
  last_transaction_id,
  over_limit,
  repayment_actual_amount_due,
  repayment_due_date,
  repayment_minimum_amount,
  repayment_minimum_calculated,
  repayment_minimum_percentage,
  statement_date,
  total_overdue_repayments,

  // Loans
  cycle_expected_repayments,
  total_penalties,
  outstanding_penalties,
  cycle_unpaid_penalties,
  cycle_repaid_penalties,
  new_penalties,
  outstanding_fees,
  cycle_unpaid_fees,
  cycle_repaid_fees,
  new_fees,
  outstanding_interest,
  cycle_posted_interest,
  cycle_unpaid_interest,
  cycle_repaid_interest,
  accrued_interest,
  new_interest,
  total_overpayments,
  cycle_overpayments,
  total_repaid_principal,
  outstanding_principal,
  cycle_repaid_principal,
  total_rewards,
  cycle_rewards,
  new_rewards,
  total_repayments,
  cycle_repayments,
  new_repayments,
  } = data || {};

  const startDate = start_date?.split(' ')[0];
  const endDate = end_date?.split(' ')[0];

  return {
    accountId: account_id,
    accountReference: account_reference,
    customerId: customer_id,
    firstName: first_name,
    lastName: last_name,
    addressLine1: address_line1,
    addressLine2: address_line2,
    addressLine3: address_line3,
    addressLine4: address_line4,
    addressTown: address_town,
    addressPostCode: address_post_code,
    addressCountryCode: address_country_code,

    institutionId: institution_id,
    institutionName: institution_name,

    productId: product_id,
    productName: product_name,
    productType: product_type,
    productTypeName: product_type_name,

    id,
    sequenceNumber: sequence_number,
    status: statementsStatusOptions.find(el => el.value === status)?.label,
    repaymentStatus: repayment_status,
    repaymentStatusName: repayment_status_name,
    balanceOpen: stringsUtil.numberToFixed(balance_open, 2),
    balanceClose: stringsUtil.numberToFixed(balance_close, 2),
    startDate,
    endDate,
    repaymentDueDate: repayment_due_date,
    statementDate: statement_date,
    overLimit: stringsUtil.numberToFixed(over_limit, 2),
    overpayment: stringsUtil.numberToFixed(overpayment, 2),
    repaymentActualAmountDue: stringsUtil.numberToFixed(repayment_actual_amount_due, 2),
    repaymentMinimumAmount: stringsUtil.numberToFixed(repayment_minimum_amount, 2),
    repaymentMinimumCalculated: stringsUtil.numberToFixed(repayment_minimum_calculated, 2),
    repaymentMinimumPercentage: stringsUtil.numberToFixed(repayment_minimum_percentage, 2),

    cycleExpectedRepayments: stringsUtil.numberToFixed(cycle_expected_repayments, 2),
    totalPenalties: stringsUtil.numberToFixed(total_penalties, 2),
    outstandingPenalties: stringsUtil.numberToFixed(outstanding_penalties, 2),
    cycleUnpaidPenalties: stringsUtil.numberToFixed(cycle_unpaid_penalties, 2),
    cycleRepaidPenalties: stringsUtil.numberToFixed(cycle_repaid_penalties, 2),
    newPenalties: stringsUtil.numberToFixed(new_penalties, 2),
    outstandingFees: stringsUtil.numberToFixed(outstanding_fees, 2),
    cycleUnpaidFees: stringsUtil.numberToFixed(cycle_unpaid_fees, 2),
    cycleRepaidFees: stringsUtil.numberToFixed(cycle_repaid_fees, 2),
    newFees: stringsUtil.numberToFixed(new_fees, 2),
    outstandingInterest: stringsUtil.numberToFixed(outstanding_interest, 2),
    cyclePostedInterest: stringsUtil.numberToFixed(cycle_posted_interest, 2),
    cycleUnpaidInterest: stringsUtil.numberToFixed(cycle_unpaid_interest, 2),
    cycleRepaidInterest: stringsUtil.numberToFixed(cycle_repaid_interest, 2),
    accruedInterest: stringsUtil.numberToFixed(accrued_interest, 2),
    newInterest: stringsUtil.numberToFixed(new_interest, 2),
    totalOverpayments: stringsUtil.numberToFixed(total_overpayments, 2),
    cycleOverpayments: stringsUtil.numberToFixed(cycle_overpayments, 2),
    totalRepaidPrincipal: stringsUtil.numberToFixed(total_repaid_principal, 2),
    outstandingPrincipal: stringsUtil.numberToFixed(outstanding_principal, 2),
    cycleRepaidPrincipal: stringsUtil.numberToFixed(cycle_repaid_principal, 2),
    totalRewards: stringsUtil.numberToFixed(total_rewards, 2),
    cycleRewards: stringsUtil.numberToFixed(cycle_rewards, 2),
    newRewards: stringsUtil.numberToFixed(new_rewards, 2),
    totalRepayments: stringsUtil.numberToFixed(total_repayments, 2),
    cycleRepayments: stringsUtil.numberToFixed(cycle_repayments, 2),
    newRepayments: stringsUtil.numberToFixed(new_repayments, 2),

    totalDebits: stringsUtil.numberToFixed(total_debits, 2),
    totalCredits: stringsUtil.numberToFixed(total_credits, 2),
    totalInterest: stringsUtil.numberToFixed(total_interest, 2),
    totalFees: stringsUtil.numberToFixed(total_fees, 2),
    totalOverdueRepayments: stringsUtil.numberToFixed(total_overdue_repayments, 2),
    firstTransactionId: first_transaction_id,
    lastTransactionId: last_transaction_id,

    dateOfLastUpdate: date_of_last_update,
  };
};

export const normalizeStatementForReport = (data: IStatementData) => {
  const normalizedData = normalizeCurrentStatement(data);

  normalizedData['repaymentStatus'] = normalizedData['repaymentStatusName'];
  normalizedData['productType'] = normalizedData['productTypeName'];

  delete(normalizedData.productTypeName);
  delete(normalizedData.repaymentStatusName);
  delete(normalizedData.sequenceNumber);

  for (const key in normalizedData) {
    if (!normalizedData[key] || key.slice(-2) === 'Id' || key === 'id') {
      delete normalizedData[key];
    }
  }

  return normalizedData;
};

export const normalizeStatementAprs = (data: IStatementAprData): IStatementApr => {
  const {
    accrued_interest_repaid,
    accrued_interest,
    allow_residual_interest,
    always_charge_interest,
    description,
    interest_calc_start_date,
    outsd_accrued_interest_repaid,
    outsd_accrued_interest,
    outsd_stmnt_repaid_principal,
    outsd_stmnt_unpaid_principal,
    prev_accrued_interest_repaid,
    prev_accrued_interest,
    prev_stmnt_repaid_principal,
    prev_stmnt_unpaid_principal,
    product_apr_id,
    rate,
    repaid_flag,
    repayment_date,
    statement_id,
    stmnt_repaid_principal,
    stmnt_unpaid_principal,
  } = data || {};

  return {
    statementId: statement_id,
    productAprId: product_apr_id,

    description,
    rate: stringsUtil.numberToFixed(rate, 2),
    repaymentDate: repayment_date,

    repaidFlag: repaid_flag === Flag.YES,
    alwaysChargeInterest: always_charge_interest === Flag.YES,
    allowResidualInterest: allow_residual_interest === Flag.YES,
    interestCalcStartDate: interest_calc_start_date,

    stmntUnpaidPrincipal: stringsUtil.numberToFixed(stmnt_unpaid_principal, 5),
    stmntRepaidPrincipal: stringsUtil.numberToFixed(stmnt_repaid_principal, 5),

    prevStmntUnpaidPrincipal: stringsUtil.numberToFixed(prev_stmnt_unpaid_principal, 5),
    prevStmntRepaidPrincipal: stringsUtil.numberToFixed(prev_stmnt_repaid_principal, 5),

    accruedInterest: stringsUtil.numberToFixed(accrued_interest, 5),
    accruedInterestRepaid: stringsUtil.numberToFixed(accrued_interest_repaid, 5),

    prevAccruedInterest: stringsUtil.numberToFixed(prev_accrued_interest, 5),
    prevAccruedInterestRepaid: stringsUtil.numberToFixed(prev_accrued_interest_repaid, 5),

    outsdAccruedInterest: stringsUtil.numberToFixed(outsd_accrued_interest, 5),
    outsdAccruedInterestRepaid: stringsUtil.numberToFixed(outsd_accrued_interest_repaid, 5),

    outsdStmntUnpaidPrincipal: stringsUtil.numberToFixed(outsd_stmnt_unpaid_principal, 5),
    outsdStmntRepaidPrincipal: stringsUtil.numberToFixed(outsd_stmnt_repaid_principal, 5),

  };
};

export const normalizeStatementAprsForReport = (data: Array<IStatementAprData>) => {
  return data?.length && data.map(el =>  {
    const normalizedData = normalizeStatementAprs(el);

    // tslint:disable-next-line: forin
    for (const key in normalizedData) {
      if (key.slice(-2) === 'Id' || key === 'id') {
        delete normalizedData[key];
      }

      if (normalizedData[key] === true) {
        normalizedData[key] = 'Y';
      }

      if (normalizedData[key] === false) {
        normalizedData[key] = 'N';
      }
    }

    return normalizedData;
  });
};
