import { connect } from 'react-redux';

import UsersGroupFields from './UsersGroupFields';

import {
  IStoreState,
  userInstitutionsOptionsSelector,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  institutionsOptions: userInstitutionsOptionsSelector(state),
});

export default connect(
  mapStateToProps
)(UsersGroupFields);
