// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
// import { throttleUtil } from 'utils';

// import { sysPropsMock } from './mock';
import { ISysPropData } from './types';

/**
 * Filter system properties API
 */
export const filterSysProps = (queryString: string) =>
  // throttleUtil.getDataAfter(sysPropsMock, 500);
  apiClientService.get(`system-properties?${queryString}`);

/**
 * Add system property API
 */
export const addSysProp = (data: Partial<ISysPropData>) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.post('system-properties', { data });

/**
 * Update system property API
 */
export const updateSysProp = (data: Partial<ISysPropData>, sysPropName: string | number) =>
  // throttleUtil.getDataAfter(successResponseMock, 100);
  apiClientService.put(`system-properties/${sysPropName}`, { data });

/**
 * Delete system property API
 */
export const deleteSysProp = (sysPropName: string | number) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.delete(`system-properties/${sysPropName}`);
