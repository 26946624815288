import Immutable, { ImmutableObject } from 'seamless-immutable';

import { ActionTypeKeys, TProductRuleAction } from './actionTypes';
import { IProductRulesState } from './types';

export const productRulesInitialState: ImmutableObject<IProductRulesState> = Immutable({
  currentProductRule: null,
});

const productRulesReducer = (state = productRulesInitialState, action: TProductRuleAction) => {
  switch (action.type) {
    case ActionTypeKeys.GET_PRODUCT_RULE_FULFILLED:
      return state.set('currentProductRule', action.payload.product_rule);

    case ActionTypeKeys.GET_PRODUCT_RULE_REJECTED:
      const errorMessage = action.payload.body.response_status.error_message;

      return (errorMessage.includes('Product rule is not found') || errorMessage.includes('No product rule found'))
        ? state.set('currentProductRule', { ...action.meta.data, script: null })
        : state;

    case ActionTypeKeys.DELETE_PRODUCT_RULE_FULFILLED:
      return state.set('currentProductRule', { ...action.meta.data, script: null });

    case ActionTypeKeys.RESET_PRODUCT_RULE:
      return state = productRulesInitialState;

    default: return state;
  }
};

export default productRulesReducer;
