import { AccountStatus } from 'consts';

interface IStatusesConfig {
    from: AccountStatus;
    to: Array<AccountStatus>;
}

export const statusesConfig: Array<IStatusesConfig> = [
    {
      from: AccountStatus.ACTIVE,
      to: [
        AccountStatus.ACTIVE,
        AccountStatus.INACTIVE,
        AccountStatus.HOLD,
        AccountStatus.CLOSED,
      ],
    },
    {
      from: AccountStatus.INACTIVE,
      to: [
        AccountStatus.INACTIVE,
        AccountStatus.ACTIVE,
        AccountStatus.HOLD,
        AccountStatus.CLOSED,
      ],
    },
    {
      from: AccountStatus.HOLD,
      to: [
        AccountStatus.HOLD,
        AccountStatus.ACTIVE,
        AccountStatus.INACTIVE,
      ],
    },
    {
      from: AccountStatus.OVERLIMIT,
      to: [AccountStatus.OVERLIMIT, AccountStatus.ACTIVE],
    },
    {
      from: AccountStatus.CLOSED,
      to: [AccountStatus.CLOSED, AccountStatus.DELETED],
    },
    {
      from: AccountStatus.MATURED,
      to: [AccountStatus.MATURED, AccountStatus.CLOSED],
    },
    {
      from: AccountStatus.OVERPAID,
      to: [AccountStatus.OVERPAID, AccountStatus.ACTIVE],
    },
    {
      from: AccountStatus.ARREARS,
      to: [AccountStatus.ARREARS, AccountStatus.ACTIVE],
    },
    {
      from: AccountStatus.DELINQUENT,
      to: [AccountStatus.DELINQUENT, AccountStatus.DEBT_COLLECTION],
    },
    {
      from: AccountStatus.DEBT_COLLECTION,
      to: [
        AccountStatus.DEBT_COLLECTION,
        AccountStatus.ACTIVE,
        AccountStatus.WRITTEN_OFF,
      ],
    },
    {
      from: AccountStatus.WRITTEN_OFF,
      to: [AccountStatus.WRITTEN_OFF, AccountStatus.DELETED],
    },
    {
      from: AccountStatus.DELETED,
      to: [AccountStatus.DELETED],
    },
    {
      from: null,
      to: [AccountStatus.ACTIVE, AccountStatus.INACTIVE],
    }, // creating account
];
