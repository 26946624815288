import Immutable, { ImmutableObject } from 'seamless-immutable';

import { ActionTypeKeys, TDictionaryAction } from './actionTypes';
import { IDictionariesState } from './types';

export const dictionariesInitialState: ImmutableObject<IDictionariesState> = Immutable({
  accountStatuses: Immutable([]),
  customerStatuses: Immutable([]),
  countries: Immutable([]),
  currencies: Immutable([]),
  endpointTypes: Immutable([]),
  eventDataElems: Immutable([]),
  events: Immutable([]),
  interfaceTypes: Immutable([]),
  transactionTypes: Immutable([]),
  repaymentMethods: Immutable([]),
  repaymentTypes: Immutable([]),
});

const dictionariesReducer = (state = dictionariesInitialState, action: TDictionaryAction) => {
  switch (action.type) {
    case ActionTypeKeys.GET_DICTIONARY_ACCOUNT_STATUSES_FULFILLED:
      return state.set('accountStatuses', action.payload.account_statuses);

    case ActionTypeKeys.GET_DICTIONARY_CUSTOMER_STATUSES_FULFILLED:
      return state.set('customerStatuses', action.payload.customer_statuses);

    case ActionTypeKeys.GET_DICTIONARY_ENDPOINT_TYPES_FULFILLED:
      return state.set('endpointTypes', action.payload.endpoint_types);

    case ActionTypeKeys.GET_DICTIONARY_INTERFACE_TYPES_FULFILLED:
      return state.set('interfaceTypes', action.payload.interface_types);

    case ActionTypeKeys.GET_DICTIONARY_TRANSACTION_TYPES_FULFILLED:
      return state.set('transactionTypes', action.payload.transaction_types);

    case ActionTypeKeys.GET_DICTIONARY_COUNTRIES_FULFILLED:
      return state.set('countries', action.payload.countries);

    case ActionTypeKeys.GET_DICTIONARY_CURRENCIES_FULFILLED:
      return state.set('currencies', action.payload.currencies);

    case ActionTypeKeys.FILTER_DICTIONARY_EVENT_DATA_ELEMS_FULFILLED:
      return state.set('eventDataElems', action.payload.event_data_elements);

    case ActionTypeKeys.FILTER_DICTIONARY_EVENTS_FULFILLED:
      return state.set('events', action.payload.events);

    case ActionTypeKeys.GET_DICTIONARY_REPAYMENT_METHODS_FULFILLED:
      return state.set('repaymentMethods', action.payload.repayment_methods);

    case ActionTypeKeys.GET_DICTIONARY_REPAYMENT_TYPES_FULFILLED:
      return state.set('repaymentTypes', action.payload.repayment_types);

    default: return state;
  }
};

export default dictionariesReducer;
