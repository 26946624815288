import React from 'react';
import { CellInfo } from 'react-table';

import { Flex } from '@rebass/grid';

import styled from 'theme';

import {
  Button,
  CheckBoxTableCell,
  Table,
  TableCell,
  TableHeader,
  withSpinner,
} from 'components';

import { IconName, ModalName } from 'consts';

import {
  IProductApr,
  THandleGetProductAprs,
  THandleUpdateProductApr,
  TOpenModal,
} from 'store';

import { ITableCell } from 'types';

const ArrowButtonWrapper = styled.div`
  position: relative;
  width: 20px;
  height: 13px;
  overflow: hidden;

  .button {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -20px;

    &:disabled {
      opacity: .7;
      pointer-events: none;
    }
  }
`;

type TCell<T extends keyof IProductApr> = ITableCell<IProductApr[T]>;

interface IAprsTable {
  getProductAprs: THandleGetProductAprs;
  isLoading: boolean;
  isReadOnly: boolean;
  openModal: TOpenModal;
  productAprs: Array<IProductApr>;
  updateProductApr: THandleUpdateProductApr;
}

const AprsTable: React.FC<IAprsTable> = ({
  getProductAprs,
  isLoading,
  isReadOnly,
  openModal,
  productAprs,
  updateProductApr,
}) => {
  React.useEffect(
    () => {
      getProductAprs();
    },
    [getProductAprs]
  );

  const isEditableCell = React.useMemo(
    () => !isReadOnly && !isLoading,
    [isReadOnly, isLoading]
  );

  const handleOpenModal = React.useCallback(
    (cellInfo: CellInfo) => {
      openModal({
        name: ModalName.UPDATE_PRODUCT_APR,
        payload: { details: cellInfo.original },
      });
    },
    [openModal]
  );

  const tableColumns = React.useMemo(
    () => [
      {
        maxWidth: 75,
        accessor: 'repaymentPriority',
        Header: <TableHeader title="Repayment Priority" />,
        Cell: (cellInfo: CellInfo) => (
          <>
            <TableCell
              value={cellInfo.value}
              isNumber={true}
            />
            {isEditableCell && (
              <Flex
                flexDirection="column"
                alignItems="center"
                p="2px 5px 5px 0"
              >
                <ArrowButtonWrapper>
                  {!cellInfo.original.defaultFlag && (
                    <Button
                      iconName={IconName.ARROW_UP}
                      title="Move up"
                      classNames={['no-border']}
                      disabled={cellInfo.index === 0 || !isEditableCell}
                      onClick={
                        () => updateProductApr({
                          ...cellInfo.original,
                          repaymentPriority: cellInfo.original.repaymentPriority - 1,
                        })
                      }
                    />
                  )}
                </ArrowButtonWrapper>
                <ArrowButtonWrapper>
                  {!cellInfo.original.defaultFlag && (
                    <Button
                      iconName={IconName.ARROW_DOWN}
                      title="Move down"
                      classNames={['no-border']}
                      disabled={(cellInfo.index === productAprs?.length - 2) || !isEditableCell}
                      onClick={
                        () => updateProductApr({
                          ...cellInfo.original,
                          repaymentPriority: cellInfo.original.repaymentPriority + 1,
                        })
                      }
                    />
                  )}
                </ArrowButtonWrapper>
              </Flex>
            )}
          </>
        ),
      },
      {
        maxWidth: 75,
        accessor: 'productAprId',
        Header: <TableHeader title="APR ID" />,
        Cell: (props: TCell<'productAprId'>) => (
          <TableCell
            value={props.value}
            isNumber={true}
          />
        ),
      },
      {
        maxWidth: 300,
        accessor: 'description',
        Header: <TableHeader title="Description" />,
        Cell: (props: TCell<'description'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 120,
        accessor: 'calculationMethodName',
        Header: <TableHeader title="Calculation Method" />,
        Cell: (props: TCell<'calculationMethodName'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 75,
        accessor: 'rate',
        Header: <TableHeader title="Rate %" />,
        Cell: (props: TCell<'rate'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 75,
        accessor: 'aprTypeName',
        Header: <TableHeader title="APR Type" />,
        Cell: (props: TCell<'aprTypeName'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 75,
        accessor: 'spendTypeDesc',
        Header: <TableHeader title="Spend Type" />,
        Cell: (props: TCell<'spendTypeDesc'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 75,
        Header: <TableHeader title="Always Charge Interest" />,
        accessor: 'alwaysChargeInterest',
        Cell: (cellInfo: CellInfo) => (
          <CheckBoxTableCell isChecked={cellInfo.value === true} />
        ),
      },
      {
        maxWidth: 75,
        Header: <TableHeader title="Allow Residual Interest" />,
        accessor: 'allowResidualInterest',
        Cell: (cellInfo: CellInfo) => (
          <CheckBoxTableCell isChecked={cellInfo.value === true} />
        ),
      },
      {
        maxWidth: 75,
        Header: <TableHeader title="Default" />,
        accessor: 'defaultFlag',
        Cell: (cellInfo: CellInfo) => (
          <CheckBoxTableCell isChecked={cellInfo.value === true} />
        ),
      },
      {
        maxWidth: 50,
        accessor: 'editButton',
        Cell: (cellInfo: CellInfo) => isEditableCell && !cellInfo.original.defaultFlag && (
          <Flex
            justifyContent="center"
            width="100%"
            p="4px"
          >
            <Button
              iconName={IconName.DETAILS}
              title="Edit"
              classNames={['is-circle']}
              onClick={() => handleOpenModal(cellInfo)}
            />
          </Flex>
        ),
      },
    ],
    [
      handleOpenModal,
      isEditableCell,
      productAprs,
      updateProductApr,
    ]
  );

  return (
    <Table
      data={productAprs}
      columns={tableColumns}
      pageSize={10}
    />
  );
};

export default withSpinner()(AprsTable);
