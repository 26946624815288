import React from 'react';
import { ImmutableArray } from 'seamless-immutable';

import { Box } from '@rebass/grid';

import { List, T3 } from 'components';
import {
  ILoanIllustrationRequest,
  ILoanIllustrationScheduleItem,
  THandleIllustrateLoan,
} from 'store';

import LoanIllustrationForm from './LoanIllustrationForm';
import LoanIllustrationTable from './LoanIllustrationTable';

interface ILoanIllustration {
  illustrateLoanProduct: THandleIllustrateLoan;
  initialFormValues: ILoanIllustrationRequest;
  isIllustrationLoading: boolean;
  loanIllustrationProduct: Array<Array<string | number>>;
  loanIllustrationSchedule: ImmutableArray<ILoanIllustrationScheduleItem>;
  mode: 'product' | 'account' | 'transaction';
}

const LoanIllustration: React.FC<ILoanIllustration> = ({
  illustrateLoanProduct,
  initialFormValues,
  isIllustrationLoading,
  loanIllustrationProduct,
  loanIllustrationSchedule,
  mode,
}) => (
  <>
    <LoanIllustrationForm
      illustrateLoanProduct={illustrateLoanProduct}
      initialValues={initialFormValues}
      isIllustrationLoading={isIllustrationLoading}
      mode={mode}
    />

    {loanIllustrationProduct && (
      <Box mt="15px" mb="10px">
        <List items={loanIllustrationProduct} />
      </Box>
    )}

    <Box mt="15px" mb="10px">
      <T3>Schedule</T3>
      <LoanIllustrationTable
        data={loanIllustrationSchedule}
        isLoading={isIllustrationLoading}
      />
    </Box>
  </>
);

export default LoanIllustration;
