import {
  ProductType,
  productTypesOptions,
  RepaymentType,
} from 'consts';
import { ISelectValue } from 'types';
import { stringsUtil } from 'utils';

import {
  IAccount,
  IAccountData,
  IAccountsFilter,
  IAccountsFilterToSend,
  ICommonAccount,
  ICommonAccountData,
} from './types';

export const normalizeFilterQueryString = (data: IAccountsFilter): string => {
  const {
    accountId,
    accountReference,
    customerId,
    firstName,
    lastName,
    pageNumber,
  } = data || {};

  const normalizedData: IAccountsFilterToSend = {
    account_id: accountId,
    account_reference: accountReference,
    customer_id: customerId,
    first_name: firstName,
    last_name: lastName,
    page_number: pageNumber,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeCommonAccount = (data: ICommonAccountData): ICommonAccount => {
  const {
    balance_limit,
    currency_code,
    currency_numeric_code,
    customer_id,
    date_closed,
    date_created,
    first_name,
    id,
    last_name,
    product_id,
    product_name,
    product_type_name,
    product_type,
    repayment_status_name,
    repayment_status,
    status_name,
    status,
  } = data;

  return {
    balanceLimit: stringsUtil.numberToFixed(balance_limit, 2),
    currencyCode: currency_code,
    currencyNumericCode: currency_numeric_code,
    customerId: customer_id,
    dateClosed: date_closed,
    dateCreated: date_created,
    firstName: first_name,
    id,
    lastName: last_name,
    productId: product_id,
    productName: product_name,
    productType: product_type,
    productTypeName: product_type_name,
    repaymentStatus: repayment_status,
    repaymentStatusName: repayment_status_name,
    status,
    statusName: status_name,
  };
};

export const normalizeCurrentAccount = (
  data: IAccountData,
  repaymentMethodsOptions: Array<ISelectValue>,
  repaymentTypesOptions: Array<ISelectValue>,
  accountStatusesOptions: Array<ISelectValue>
): IAccount => {
  const {
    // Common
    account_reference,
    balance_limit,
    customer_id,
    date_closed,
    date_created,
    first_name,
    id,
    institution_id,
    institution_name,
    last_name,
    product_id,
    product_name,
    product_type,
    repayment_status,
    status,

    // Revolving credits
    amount_overdue,
    amount_overpaid,
    balance_authorised,
    balance_settled,
    number_of_times_overdue_cycles,
    repayment_amount_due,
    repayment_fixed_amount,
    repayment_method_id,
    repayment_type_id,
    statement_cycle_parameter,
    total_overdue_amount,

    // Loans
    alternate_apr,
    disbursement_date,
    interest_posted,
    number_of_instalments,
    original_loan_amount,
    previous_status_name,
    previous_status,
    principal,
    standard_apr,
    statement_id,
  } = data || {};

  const specificProductTypeFields = {
    [ProductType.LOAN]: {
      alternateApr: stringsUtil.numberToFixed(alternate_apr, 2),
      disbursementDate: disbursement_date,
      interestPosted: stringsUtil.numberToFixed(interest_posted, 2),
      numberOfInstalments: number_of_instalments,
      originalLoanAmount: stringsUtil.numberToFixed(original_loan_amount, 2),
      previousStatus: previous_status,
      previousStatusName: previous_status_name,
      principal: stringsUtil.numberToFixed(principal, 2),
      standardApr: stringsUtil.numberToFixed(standard_apr, 2),
      statementId: statement_id,
    },
    [ProductType.REVOLVING_CREDIT]: {
      amountOverdue: stringsUtil.numberToFixed(amount_overdue, 2),
      amountOverpaid: stringsUtil.numberToFixed(amount_overpaid, 2),
      balanceAuthorised: stringsUtil.numberToFixed(balance_authorised, 2),
      balanceSettled: stringsUtil.numberToFixed(balance_settled, 2),
      numberOfTimesOverdueCycles: number_of_times_overdue_cycles,
      repaymentAmountDue: stringsUtil.numberToFixed(repayment_amount_due, 2),
      repaymentFixedAmount: stringsUtil.numberToFixed(repayment_fixed_amount, 2),
      repaymentMethod: repaymentMethodsOptions.find(el => el.value === repayment_method_id),
      repaymentType: repaymentTypesOptions.find(el => el.value === repayment_type_id),
      statementCycleParameter: statement_cycle_parameter,
      totalOverdueAmount: stringsUtil.numberToFixed(total_overdue_amount, 2),
    },
  };

  return {
    accountReference: account_reference,
    balanceLimit: stringsUtil.numberToFixed(balance_limit, 2),
    customerId: customer_id,
    dateClosed: date_closed,
    dateCreated: date_created,
    firstName: first_name,
    id,
    institutionId: institution_id,
    institutionName: institution_name,
    institution: { value: institution_id, label: institution_name },
    lastName: last_name,
    product: { value: product_id, label: product_name },
    productId: product_id,
    productName: product_name,
    productType: productTypesOptions.find(el => el.value === product_type),
    repaymentStatus: repayment_status,
    status: accountStatusesOptions.find(el => el.value === status),
    ...specificProductTypeFields[product_type],

  };
};

const normalizeDataForSending = (data: Partial<IAccount>): Partial<IAccountData> => {
  const {
    // Common
    accountReference,
    balanceLimit,
    productType,
    status,

    // Revolving credits
    repaymentFixedAmount,
    repaymentMethod,
    repaymentType,
    statementCycleParameter,

    // Loans
    alternateApr,
    standardApr,
  } = data || {};

  const specificProductTypeFields = {
    [ProductType.LOAN]: {
      alternate_apr: stringsUtil.toNumber(alternateApr),
      standard_apr: stringsUtil.toNumber(standardApr),
    },
    [ProductType.REVOLVING_CREDIT]: {
      repayment_fixed_amount: repaymentType?.value === RepaymentType.FIXED_AMOUNT
        ? stringsUtil.toNumber(repaymentFixedAmount)
        : null,
      repayment_method_id: repaymentMethod?.value,
      repayment_type_id: repaymentType?.value,
      statement_cycle_parameter: stringsUtil.toNumber(statementCycleParameter),
    },
  };

  return {
    account_reference: accountReference || null,
    balance_limit: stringsUtil.toNumber(balanceLimit),
    status: status?.value,
    ...specificProductTypeFields[productType.value],
  };
};

export const normalizeDataForCreating = (data: Partial<IAccount>): Partial<IAccountData>  => {
  const {
    customerId,
    institution,
    product,
  } = data;

  return {
    customer_id: stringsUtil.toNumber(customerId),
    institution_id: institution?.value,
    product_id: product?.value,
    ...normalizeDataForSending(data),
  };
};

export const normalizeDataForUpdating = (
  data: Partial<IAccount>,
  currentData: IAccountData
): Partial<IAccountData>  => {
  const normalizedData = normalizeDataForSending(data);
  const dataForSending = stringsUtil.getObjectDiff(normalizedData, currentData);

  const isFixedAmountRepaymentType = normalizedData
    ?.repayment_type_id === RepaymentType.FIXED_AMOUNT;

  if (!isFixedAmountRepaymentType) {
    delete dataForSending['repayment_fixed_amount'];
  }

  return dataForSending;
};
