import { lazy } from 'react';

import { ScreenName } from 'consts';

// tslint:disable: max-line-length
// tslint:disable: no-shadowed-variable
const Countries = lazy(() => import('containers/Admin').then(({ Countries }) => ({ default: Countries })));
const Currencies = lazy(() => import('containers/Admin').then(({ Currencies }) => ({ default: Currencies })));
const Endpoints = lazy(() => import('containers/Admin').then(({ Endpoints }) => ({ default: Endpoints })));
const EventDataElems = lazy(() => import('containers/Admin').then(({ EventDataElems }) => ({ default: EventDataElems })));
const Events = lazy(() => import('containers/Admin').then(({ Events }) => ({ default: Events })));
const Institutions = lazy(() => import('containers/Admin').then(({ Institutions }) => ({ default: Institutions })));
const Interfaces = lazy(() => import('containers/Admin').then(({ Interfaces }) => ({ default: Interfaces })));
const SystemProperties = lazy(() => import('containers/Admin').then(({ SystemProperties }) => ({ default: SystemProperties })));
const TransactionTypes = lazy(() => import('containers/Admin').then(({ TransactionTypes }) => ({ default: TransactionTypes })));
const Users = lazy(() => import('containers/Admin').then(({ Users }) => ({ default: Users })));
const UsersGroups = lazy(() => import('containers/Admin').then(({ UsersGroups }) => ({ default: UsersGroups })));

const Products = lazy(() => import('containers/ProductDesigner').then(({ Products }) => ({ default: Products })));

const ApiCalls = lazy(() => import('containers/Audit').then(({ ApiCalls }) => ({ default: ApiCalls })));
const UiSessions = lazy(() => import('containers/Audit').then(({ UiSessions }) => ({ default: UiSessions })));
const UsersActivity = lazy(() => import('containers/Audit').then(({ UsersActivity }) => ({ default: UsersActivity })));

const Accounts = lazy(() => import('containers/Ledger').then(({ Accounts }) => ({ default: Accounts })));
const Customers = lazy(() => import('containers/Ledger').then(({ Customers }) => ({ default: Customers })));
const Statements = lazy(() => import('containers/Ledger').then(({ Statements }) => ({ default: Statements })));
const Transactions = lazy(() => import('containers/Ledger').then(({ Transactions }) => ({ default: Transactions })));

export const screensList = {
  [ScreenName.ACCOUNTS]: <Accounts />,
  [ScreenName.API_CALLS]: <ApiCalls />,
  [ScreenName.CUSTOMERS]: <Customers />,
  [ScreenName.DICTIONARIES_COUNTRIES]: <Countries />,
  [ScreenName.DICTIONARIES_CURRENCIES]: <Currencies />,
  [ScreenName.DICTIONARIES_EVENT_DATA_ELEMENTS]: <EventDataElems />,
  [ScreenName.DICTIONARIES_EVENTS]: <Events />,
  [ScreenName.DICTIONARIES_TRANSACTION_TYPES]: <TransactionTypes />,
  [ScreenName.ENDPOINTS]: <Endpoints />,
  [ScreenName.INSTITUTIONS]: <Institutions />,
  [ScreenName.INTERFACES]: <Interfaces />,
  [ScreenName.PRODUCTS]: <Products />,
  [ScreenName.STATEMENTS]: <Statements />,
  [ScreenName.SYSTEM_PROPERTIES]: <SystemProperties />,
  [ScreenName.TRANSACTIONS]: <Transactions />,
  [ScreenName.UI_SESSIONS]: <UiSessions />,
  [ScreenName.USERS_ACTIVITY]: <UsersActivity />,
  [ScreenName.USERS_GROUPS]: <UsersGroups />,
  [ScreenName.USERS]: <Users />,
};
