import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';
import { ActionTypeKeys } from './actionTypes';
import { normalizeData } from './utils';

export const defaultUiSessionsSelector = (state: IStoreState) => state.audit.uiSessions.uiSessions;

export const uiSessionsSelector = createSelector(
  defaultUiSessionsSelector,
  data => data?.map(el => normalizeData(el))
);

export const uiSessionsTotalPagesSelector = (state: IStoreState) =>
  state.audit.uiSessions.totalPages;

export const uiSessionsTotalResultsSelector = (state: IStoreState) =>
  state.audit.uiSessions.totalResults;

export const uiSessionsCurrentPageSelector = (state: IStoreState) =>
  state.audit.uiSessions.pageNumber;

export const isUiSessionsLoadingSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_UI_SESSIONS,
]);
