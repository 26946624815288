import { ImmutableArray } from 'seamless-immutable';

import {
  ApplicationCondition,
  applicationConditionOptions,
  aprCalcTypesOptions,
  aprTypesOptions,
  Flag,
  ProductType,
} from 'consts';

import { stringsUtil } from 'utils';

import {
  IProductApr,
  IProductAprData,
  IProductFee,
  IProductFeeData,
  IProductPenalty,
  IProductPenaltyData,
  IProductReward,
  IProductRewardData,
} from './types';

/**
 * Fee
 */

export const normalizeProductFee = (
  data: IProductFeeData,
  productType: string
): IProductFee => {
  const {
    // common
    amount,
    description,
    fee_application_condition,
    product_id,
    rate,

    // Revolving credit
    apr_description,
    apr_id,
    product_fee_id,

    // Loan
    id,
  } = data || {};

  const specificProductTypeFields = {
    [ProductType.LOAN]: {
      feeId: id,
    },
    [ProductType.REVOLVING_CREDIT]: {
      aprDescription: apr_description,
      aprId: apr_id,
      feeId: product_fee_id,
    },
  };

  return {
    amount: stringsUtil.numberToFixed(amount, 2),
    description,
    feeApplicationCondition: applicationConditionOptions
      .find(el => el.value === fee_application_condition),
    feeApplicationConditionName: applicationConditionOptions
      .find(el => el.value === fee_application_condition)?.label,
    productId: product_id,
    rate: stringsUtil.numberToFixed(rate, 2),
    ...specificProductTypeFields[productType],
  };
};

export const normalizeProductFeeForSending = (
  data: Partial<IProductFee>,
  productId: number,
  productType: string
): Partial<IProductFeeData> => {
  const {
    amount,
    feeApplicationCondition,
    apr,
    description,
    rate,
  } = data || {};

  const applicationConditionValue = feeApplicationCondition?.value;

  const specificProductTypeFields = {
    [ProductType.LOAN]: {},
    [ProductType.REVOLVING_CREDIT]: {
      apr_id: apr?.value || null,
    },
  };

  return {
    amount: applicationConditionValue === ApplicationCondition.APPLY_ONLY_RATE
      ? null
      : stringsUtil.toNumber(amount),
    description,
    fee_application_condition: applicationConditionValue,
    product_id: productId,
    rate: applicationConditionValue === ApplicationCondition.APPLY_ONLY_FIXED_AMOUNT
      ? null
      : stringsUtil.toNumber(rate),
    ...specificProductTypeFields[productType],
  };
};

/**
 * Rewards
 */

export const normalizeProductReward = (
  data: IProductRewardData,
  productType: string
): IProductReward => {
  const {
    // common
    amount,
    description,
    product_id,
    rate,
    reward_application_condition,

    // Revolving credit
    product_reward_id,

    // Loan
    id,
  } = data || {};

  const specificProductTypeFields = {
    [ProductType.LOAN]: {
      rewardId: id,
    },
    [ProductType.REVOLVING_CREDIT]: {
      rewardId: product_reward_id,
    },
  };

  return {
    amount: stringsUtil.numberToFixed(amount, 2),
    description,
    productId: product_id,
    rate: stringsUtil.numberToFixed(rate, 2),
    rewardApplicationCondition: applicationConditionOptions
      .find(el => el.value === reward_application_condition),
    rewardApplicationConditionName: applicationConditionOptions
      .find(el => el.value === reward_application_condition)?.label,
    ...specificProductTypeFields[productType],
  };
};

export const normalizeProductRewardForSending = (
  data: Partial<IProductReward>,
  productId: number,
  productType: string
): Partial<IProductRewardData> => {
  const {
    amount,
    rewardApplicationCondition,
    description,
    rate,
  } = data || {};

  const applicationConditionValue = rewardApplicationCondition?.value;

  return {
    amount: applicationConditionValue === ApplicationCondition.APPLY_ONLY_RATE
      ? null
      : stringsUtil.toNumber(amount),
    description,
    product_id: productId,
    rate: applicationConditionValue === ApplicationCondition.APPLY_ONLY_FIXED_AMOUNT
      ? null
      : stringsUtil.toNumber(rate),
    reward_application_condition: applicationConditionValue,
  };
};

/**
 * Penalties
 */

export const normalizeProductPenalty = (
  data: IProductPenaltyData,
  productType: string
): IProductPenalty => {
  const {
    // common
    amount,
    description,
    penalty_application_condition,
    product_id,
    rate,

    // Revolving credit
    product_penalty_id,

    // Loan
    id,
  } = data || {};

  const specificProductTypeFields = {
    [ProductType.LOAN]: {
      penaltyId: id,
    },
    [ProductType.REVOLVING_CREDIT]: {
      penaltyId: product_penalty_id,
    },
  };

  return {
    amount: stringsUtil.numberToFixed(amount, 2),
    description,
    penaltyApplicationCondition: applicationConditionOptions
      .find(el => el.value === penalty_application_condition),
    penaltyApplicationConditionName: applicationConditionOptions
      .find(el => el.value === penalty_application_condition)?.label,
    productId: product_id,
    rate: stringsUtil.numberToFixed(rate, 2),
    ...specificProductTypeFields[productType],
  };
};

export const normalizeProductPenaltyForSending = (
  data: Partial<IProductPenalty>,
  productId: number,
  productType: string
): Partial<IProductPenaltyData> => {
  const {
    amount,
    penaltyApplicationCondition,
    description,
    rate,
  } = data || {};

  const applicationConditionValue = penaltyApplicationCondition?.value;

  return {
    amount: applicationConditionValue === ApplicationCondition.APPLY_ONLY_RATE
      ? null
      : stringsUtil.toNumber(amount),
    description,
    product_id: productId,
    rate: applicationConditionValue === ApplicationCondition.APPLY_ONLY_FIXED_AMOUNT
      ? null
      : stringsUtil.toNumber(rate),
    penalty_application_condition: applicationConditionValue,
  };
};

/**
 * APRs
 */

const sortByRepaymentPriority = (items: Array<IProductAprData>) => items.sort((a, b) =>
  a.repayment_priority > b.repayment_priority
    ? 1
    : a.repayment_priority < b.repayment_priority
      ? -1
      : null
);

export const normalizeProductAprs = (data: ImmutableArray<IProductAprData>): Array<IProductApr> => {
  const sortedItems = sortByRepaymentPriority(Array.from(data));

  return sortedItems?.map(item => {
    const {
      allow_residual_interest,
      always_charge_interest,
      apr_type,
      calculation_method,
      default_flag,
      description,
      product_apr_id,
      product_id,
      rate,
      repayment_priority,
      spend_type_desc,
    } = item || {};

    const calculationMethod = aprCalcTypesOptions.find(el => el.value === calculation_method);
    const aprType = aprTypesOptions.find(el => el.value === apr_type);

    return {
      allowResidualInterest: allow_residual_interest === Flag.YES,
      alwaysChargeInterest: always_charge_interest === Flag.YES,
      aprType,
      aprTypeName: aprType?.label,
      calculationMethod,
      calculationMethodName: calculationMethod?.label,
      defaultFlag: default_flag === Flag.YES,
      description,
      productAprId: product_apr_id,
      productId: product_id,
      rate: stringsUtil.numberToFixed(rate, 2),
      repaymentPriority: repayment_priority,
      spendTypeDesc: spend_type_desc,
    };
  });
};

export const normalizeFormValuesProductAprForSending = (
  data: Partial<IProductApr>,
  productId: number
): Partial<IProductAprData> => {

  const {
    allowResidualInterest,
    alwaysChargeInterest,
    aprType,
    calculationMethod,
    description,
    productAprId,
    rate,
    spendTypeDesc,
  } = data || {};

  return {
    allow_residual_interest: allowResidualInterest ? Flag.YES : Flag.NO,
    always_charge_interest: alwaysChargeInterest ? Flag.YES : Flag.NO,
    apr_type: aprType?.value,
    calculation_method: calculationMethod?.value,
    description,
    product_apr_id: productAprId,
    spend_type_desc: spendTypeDesc,
    product_id: productId,
    rate: stringsUtil.toNumber(rate),
  };
};

export const normalizeProductApForSending = (
  data: Partial<IProductApr>
): Partial<IProductAprData> => {
  const { productId, repaymentPriority } = data || {};

  return {
    ...normalizeFormValuesProductAprForSending(data, productId),
    repayment_priority: repaymentPriority,
  };
};
