import { getFormValues } from 'redux-form';

import {
  DebitCreditIndicator,
  FormName,
  ModalName,
} from 'consts';

import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import {
  activeDebitCreditIndicatorSelector,
  activeItemIdSelector,
  activeProductTypeSelector,
  IStatement,
  openModal,
} from 'store';

import {
  ActionTypeKeys,
  IFilterTransactionsAction,
  IGetTransactionAction,
  ISettleTransactionAction,
} from './actionTypes';

import * as api from './api';

import {
  ISettlementFormValues,
  ITransactionsFilter,
} from './types';

import {
  normalizeFilterQueryString,
  normalizeStatementTransactionsQueryString,
} from './utils';

/**
 * Filter transactions action
 */
export type TFilterTransactions = (queryString: string, productType: string | number) =>
  IFilterTransactionsAction;
export type THandleFilterTransactions = (data: ITransactionsFilter) => Thunk<void>;

export const filterTransactions: TFilterTransactions = (queryString, productType) => ({
  type: ActionTypeKeys.FILTER_TRANSACTIONS,
  payload: api.filterTransactions(queryString, productType),
});

export const handleFilterTransactions: THandleFilterTransactions = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterQueryString(data);
        const productType = data?.productType?.value;

        await dispatch(filterTransactions(queryString, productType));
      },
      dispatch
    );
  };

/**
 * Filter statement transactions action
 */
export type THandleFilterStatementTransactions = (data: IStatement) => Thunk<void>;

export const handleFilterStatementTransactions: THandleFilterStatementTransactions = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeStatementTransactionsQueryString(data);
        const productType = data?.productType;

        await dispatch(filterTransactions(queryString, productType));
      },
      dispatch
    );
  };

/**
 * Get transaction action
 */

export type TGetTransaction = (
  transactionId: number,
  productType: string,
  debitCreditIndicator: DebitCreditIndicator
) => IGetTransactionAction;
export type THandleGetTransaction = () => Thunk<void>;

export const getTransaction: TGetTransaction = (
  transactionId,
  productType,
  debitCreditIndicator
) => ({
  type: ActionTypeKeys.GET_TRANSACTION,
  payload: api.getTransaction(transactionId, productType, debitCreditIndicator),
});

export const handleGetTransaction: THandleGetTransaction = () => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();

      const transactionId = activeItemIdSelector(state);
      const productType = activeProductTypeSelector(state);
      const debitCreditIndicator = activeDebitCreditIndicatorSelector(state);

      await dispatch(getTransaction(transactionId, productType, debitCreditIndicator));
    },
    dispatch
    );
  };

/**
 * Settle transaction action
 */

export type TSettleTransaction = (
  data: ISettlementFormValues,
  debitCreditIndicator: DebitCreditIndicator
) => ISettleTransactionAction;
export type THandleSettleTransaction = () => Thunk<void>;

export const settleTransaction: TSettleTransaction = (data, debitCreditIndicator) => ({
  type: ActionTypeKeys.SETTLE_TRANSACTION,
  payload: api.settleTransaction(data, debitCreditIndicator),
});

export const handleSettleTransaction: THandleSettleTransaction = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();

        const formValues = getFormValues(FormName.SETTLE_TRANSACTION);
        const data = formValues(state) as ISettlementFormValues;

        const debitCreditIndicator = activeDebitCreditIndicatorSelector(state);

        await dispatch(settleTransaction(data, debitCreditIndicator));

        dispatch(openModal({ name: ModalName.SETTLE_TRANSACTION_RESULT }));
      },
      dispatch
    );
  };

/**
 * Reset transactions action
 */

export type TResetTransactions = () => void;

export const resetTransactions: TResetTransactions = () => ({
   type: ActionTypeKeys.RESET_TRANSACTIONS,
});

/**
 * Reset transaction action
 */

export type TResetTransaction = () => void;

export const resetTransaction: TResetTransaction = () => ({
  type: ActionTypeKeys.RESET_TRANSACTION,
});

/**
 * Reset settle transaction result action
 */

export type TResetSettleTransactionResult = () => void;

export const resetSettleTransactionResult: TResetSettleTransactionResult = () => ({
  type: ActionTypeKeys.RESET_SETTLE_TRANSACTION_RESULT,
});
