import { PermissionType } from 'consts';

import {
  IUsersGroupDetails,
  IUsersGroupPermission,
  IUsersGroupPermissionDataToSend,
  IUsersGroupPermissionRequest,
} from './types';

export const normalizeUsersGroupForSending = (data: Partial<IUsersGroupDetails>) => {
  const { id, institutionId, name } = data || {};

  return {
    id,
    institution_id: institutionId?.value,
    name,
  };
};

export const normalizePermissionForSending = (
  data: Partial<IUsersGroupPermissionRequest>
): IUsersGroupPermissionDataToSend => {
  const { uiItems, usersGroupId, permission } = data || {};

  return {
    user_group_id: usersGroupId,
    ui_items: uiItems?.map(item => item.value),
    permission: permission ? PermissionType.READ_WRITE : PermissionType.READ_ONLY,
  };
};

export const normalizePermissionForUpdating = (
  data: Partial<IUsersGroupPermission>
): Partial<IUsersGroupPermissionDataToSend> => {
  const { permission } = data || {};

  return {
    permission: permission ? PermissionType.READ_WRITE : PermissionType.READ_ONLY,
  };
};

export const prepareUiItemLabel = (pathname: string) => {
  if (!pathname) {
    return '';
  }

  const arr = pathname.split('/');

  const label = arr
    .slice(arr.length - 1)
    .join('/')
    .replace(/_/g, ' ')
    .replace(/^./, str => str.toUpperCase());

  return label;
};
