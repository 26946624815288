import { Thunk, VoidPromiseThunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import * as api from './api';

import {
  ActionTypeKeys,
  IFilterDictionaryEventDataElemsAction,
  IFilterDictionaryEventsAction,
  IGetDictionaryAccountStatusesAction,
  IGetDictionaryCountriesAction,
  IGetDictionaryCurrenciesAction,
  IGetDictionaryCustomerStatusesAction,
  IGetDictionaryEndpointTypesAction,
  IGetDictionaryInterfaceTypesAction,
  IGetDictionaryRepaymentMethodsAction,
  IGetDictionaryRepaymentTypesAction,
  IGetDictionaryTransactionTypesAction,
} from './actionTypes';

import {
  isAccountStatusesLoadedSelector,
  isCountriesLoadedSelector,
  isCurrenciesLoadedSelector,
  isEndpointTypesLoadedSelector,
  isInterfaceTypesLoadedSelector,
  isRepaymentMethodsLoadedSelector,
  isRepaymentTypesLoadedSelector,
  isTransTypesLoadedSelector,
} from 'store';

import { IDictionaryEventDataElemsFilter, IDictionaryEventsFilter } from './types';
import {
  normalizeFilterEventDataElemsQueryString,
  normalizeFilterEventsQueryString
} from './utils';

/**
 * Get account statuses action
 */
export type TGetDictionaryAccountStatuses = () => IGetDictionaryAccountStatusesAction;
export type THandleGetDictionaryAccountStatuses = VoidPromiseThunk;

export const getDictionaryAccountStatuses: TGetDictionaryAccountStatuses = () => ({
  type: ActionTypeKeys.GET_DICTIONARY_ACCOUNT_STATUSES,
  payload: api.getDictionaryAccountStatuses(),
});

export const handleGetDictionaryAccountStatuses: THandleGetDictionaryAccountStatuses = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();

        if (!isAccountStatusesLoadedSelector(state)) {
          await dispatch(getDictionaryAccountStatuses());
        }
      },
      dispatch
    );
  };

/**
 * Get customer statuses action
 */
export type TGetDictionaryCustomerStatuses = () => IGetDictionaryCustomerStatusesAction;
export type THandleGetDictionaryCustomerStatuses = VoidPromiseThunk;

export const getDictionaryCustomerStatuses: TGetDictionaryCustomerStatuses = () => ({
  type: ActionTypeKeys.GET_DICTIONARY_CUSTOMER_STATUSES,
  payload: api.getDictionaryCustomerStatuses(),
});

export const handleGetDictionaryCustomerStatuses: THandleGetDictionaryCustomerStatuses = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        await dispatch(getDictionaryCustomerStatuses());
      },
      dispatch
    );
  };

/**
 * Get endpoint types action
 */
export type TGetDictionaryEndpointTypes = () => IGetDictionaryEndpointTypesAction;
export type THandleGetDictionaryEndpointTypes = VoidPromiseThunk;

export const getDictionaryEndpointTypes: TGetDictionaryEndpointTypes = () => ({
  type: ActionTypeKeys.GET_DICTIONARY_ENDPOINT_TYPES,
  payload: api.getDictionaryEndpointTypes(),
});

export const handleGetDictionaryEndpointTypes: THandleGetDictionaryEndpointTypes = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();

        if (!isEndpointTypesLoadedSelector(state)) {
          await dispatch(getDictionaryEndpointTypes());
        }
      },
      dispatch
    );
  };

/**
 * Get interfaces types action
 */
export type TGetDictionaryInterfaceTypes = () => IGetDictionaryInterfaceTypesAction;
export type THandleGetDictionaryInterfaceTypes = VoidPromiseThunk;

export const getDictionaryInterfaceTypes: TGetDictionaryInterfaceTypes = () => ({
  type: ActionTypeKeys.GET_DICTIONARY_INTERFACE_TYPES,
  payload: api.getDictionaryInterfaceTypes(),
});

export const handleGetDictionaryInterfaceTypes: THandleGetDictionaryInterfaceTypes = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();

        if (!isInterfaceTypesLoadedSelector(state)) {
          await dispatch(getDictionaryInterfaceTypes());
        }
      },
      dispatch
    );
  };

/**
 * Get transaction types action
 */
export type TGetDictionaryTransactionTypes = () => IGetDictionaryTransactionTypesAction;
export type THandleGetDictionaryTransactionTypes = VoidPromiseThunk;

export const getDictionaryTransactionTypes: TGetDictionaryTransactionTypes = () => ({
  type: ActionTypeKeys.GET_DICTIONARY_TRANSACTION_TYPES,
  payload: api.getDictionaryTransactionTypes(),
});

export const handleGetDictionaryTransactionTypes: THandleGetDictionaryTransactionTypes = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();

        if (!isTransTypesLoadedSelector(state)) {
          await dispatch(getDictionaryTransactionTypes());
        }
      },
      dispatch
    );
  };

/**
 * Get countries action
 */
export type TGetDictionaryCountries = () => IGetDictionaryCountriesAction;
export type THandleGetDictionaryCountries = VoidPromiseThunk;

export const getDictionaryCountries: TGetDictionaryCountries = () => ({
  type: ActionTypeKeys.GET_DICTIONARY_COUNTRIES,
  payload: api.getDictionaryCountries(),
});

export const handleGetDictionaryCountries: THandleGetDictionaryCountries = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();

        if (!isCountriesLoadedSelector(state)) {
          await dispatch(getDictionaryCountries());
        }
      },
      dispatch
    );
  };

/**
 * Get currencies action
 */
export type TGetDictionaryCurrencies = () => IGetDictionaryCurrenciesAction;
export type THandleGetDictionaryCurrencies = VoidPromiseThunk;

export const getDictionaryCurrencies: TGetDictionaryCurrencies = () => ({
  type: ActionTypeKeys.GET_DICTIONARY_CURRENCIES,
  payload: api.getDictionaryCurrencies(),
});

export const handleGetDictionaryCurrencies: THandleGetDictionaryCurrencies = () =>
async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();

        if (!isCurrenciesLoadedSelector(state)) {
          await dispatch(getDictionaryCurrencies());
        }
      },
      dispatch
    );
  };

/**
 * Filter event data elements actions
 */
export type TFilterDictionaryEventDataElems = (queryString: string) =>
  IFilterDictionaryEventDataElemsAction;

export type THandleFilterDictionaryEventDataElems = (data: IDictionaryEventDataElemsFilter) =>
  Thunk<void>;

export const filterDictionaryEventDataElems: TFilterDictionaryEventDataElems = queryString => ({
  type: ActionTypeKeys.FILTER_DICTIONARY_EVENT_DATA_ELEMS,
  payload: api.filterDictionaryEventDataElems(queryString),
});

export const handleFilterDictionaryEventDataElems: THandleFilterDictionaryEventDataElems = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterEventDataElemsQueryString(data);

        await dispatch(filterDictionaryEventDataElems(queryString));
      },
      dispatch
    );
  };

/**
 * Filter events action
 */
export type TFilterDictionaryEvents = (queryString: string) => IFilterDictionaryEventsAction;
export type THandleFilterDictionaryEvents = (data: IDictionaryEventsFilter) => Thunk<void>;

export const filterDictionaryEvents: TFilterDictionaryEvents = queryString => ({
  type: ActionTypeKeys.FILTER_DICTIONARY_EVENTS,
  payload: api.filterDictionaryEvents(queryString),
});

export const handleFilterDictionaryEvents: THandleFilterDictionaryEvents = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterEventsQueryString(data);

        await dispatch(filterDictionaryEvents(queryString));
      },
      dispatch
    );
  };

/**
 * Get repayment methods action
 */
export type TGetDictionaryRepaymentMethods = () => IGetDictionaryRepaymentMethodsAction;
export type THandleGetDictionaryRepaymentMethods = VoidPromiseThunk;

export const getDictionaryRepaymentMethods: TGetDictionaryRepaymentMethods = () => ({
  type: ActionTypeKeys.GET_DICTIONARY_REPAYMENT_METHODS,
  payload: api.getDictionaryRepaymentMethods(),
});

export const handleGetDictionaryRepaymentMethods: THandleGetDictionaryRepaymentMethods = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();

        if (!isRepaymentMethodsLoadedSelector(state)) {
          await dispatch(getDictionaryRepaymentMethods());
        }
      },
      dispatch
    );
  };

/**
 * Get repayment types action
 */
export type TGetDictionaryRepaymentTypes = () => IGetDictionaryRepaymentTypesAction;
export type THandleGetDictionaryRepaymentTypes = VoidPromiseThunk;

export const getDictionaryRepaymentTypes: TGetDictionaryRepaymentTypes = () => ({
  type: ActionTypeKeys.GET_DICTIONARY_REPAYMENT_TYPES,
  payload: api.getDictionaryRepaymentTypes(),
});

export const handleGetDictionaryRepaymentTypes: THandleGetDictionaryRepaymentTypes = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();

        if (!isRepaymentTypesLoadedSelector(state)) {
          await dispatch(getDictionaryRepaymentTypes());
        }
      },
      dispatch
    );
  };
