import React from 'react';

import { Flex } from '@rebass/grid';

import { Button, Dropdown, DropdownOption, Text, UserIcon } from 'components';
import { IconName, ModalName } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import { THandleLogout } from 'store';
import styled from 'theme';

const UserIconStyled = styled(UserIcon)`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.gray};
`;

interface IUserDropdown extends IWithModal {
  isChangingPasswordAvailable: boolean;
  logout: THandleLogout;
  userFullName: string;
}

const UserDropdown: React.FC<IUserDropdown> = ({
  isChangingPasswordAvailable,
  logout,
  openModal,
  userFullName,
}) => (
  <Dropdown
    selectable={false}
    dropdownListPosition="right"
    ToggleButtonComponent={(
      <Flex alignItems="center">
        <UserIconStyled size="22" />
        <Text
          bold={true}
          letterSpacing=".2pt"
          upperCase={true}
        >
          { userFullName }
        </Text>
      </Flex>
    )}
  >
    <DropdownOption isDisabled={!isChangingPasswordAvailable}>
      <Button
        text="Change password"
        onClick={() => openModal({ name: ModalName.CHANGE_PASSWORD })}
        width="100%"
        classNames={['no-text-transform no-border text-left']}
        iconName={IconName.SETTINGS}
      />
    </DropdownOption>

    <DropdownOption>
      <Button
        text="Log out"
        onClick={logout}
        width="100%"
        classNames={['no-text-transform no-border text-left']}
        iconName={IconName.LOGOUT}
      />
    </DropdownOption>
  </Dropdown>
);

export default withModal(UserDropdown);
