import { IResponseStatus, TApiResponse } from 'types';
import { IEndpointDataResp, IEndpoints } from './types';

export enum ActionTypeKeys {
  /** Add endpoint action type keys */
  ADD_ENDPOINT = 'endpoints/ADD_ENDPOINT',
  ADD_ENDPOINT_FULFILLED = 'endpoints/ADD_ENDPOINT_FULFILLED',
  ADD_ENDPOINT_REJECTED = 'endpoints/ADD_ENDPOINT_REJECTED',

  /** Get endpoint action type keys */
  GET_ENDPOINT = 'endpoints/GET_ENDPOINT',
  GET_ENDPOINT_FULFILLED = 'endpoints/GET_ENDPOINT_FULFILLED',
  GET_ENDPOINT_REJECTED = 'endpoints/GET_ENDPOINT_REJECTED',

  /** Delete endpoint action type keys */
  DELETE_ENDPOINT = 'endpoints/DELETE_ENDPOINT',
  DELETE_ENDPOINT_FULFILLED = 'endpoints/DELETE_ENDPOINT_FULFILLED',
  DELETE_ENDPOINT_REJECTED = 'endpoints/DELETE_ENDPOINT_REJECTED',

  /** Update endpoint action type keys */
  UPDATE_ENDPOINT = 'endpoints/UPDATE_ENDPOINT',
  UPDATE_ENDPOINT_FULFILLED = 'endpoints/UPDATE_ENDPOINT_FULFILLED',
  UPDATE_ENDPOINT_REJECTED = 'endpoints/UPDATE_ENDPOINT_REJECTED',

  /** Filter endpoints action type keys */
  FILTER_ENDPOINTS = 'endpoints/FILTER_ENDPOINTS',
  FILTER_ENDPOINTS_FULFILLED = 'endpoints/FILTER_ENDPOINTS_FULFILLED',
  FILTER_ENDPOINTS_REJECTED = 'endpoints/FILTER_ENDPOINTS_REJECTED',

  /** Reset endpoints action type keys */
  RESET_ENDPOINTS = 'endpoints/RESET_ENDPOINTS',
}

/** Add endpoint action interfaces */

export interface IAddEndpointAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ADD_ENDPOINT;
}

export interface IAddEndpointFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.ADD_ENDPOINT_FULFILLED;
}

export interface IAddEndpointRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.ADD_ENDPOINT_REJECTED;
}

/** Get endpoint action interfaces */

export interface IGetEndpointAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_ENDPOINT;
}

export interface IGetEndpointFulfilledAction {
  readonly payload: IEndpointDataResp;
  readonly type: ActionTypeKeys.GET_ENDPOINT_FULFILLED;
}

export interface IGetEndpointRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_ENDPOINT_REJECTED;
}

/** Delete endpoint action interfaces */

export interface IDeleteEndpointAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.DELETE_ENDPOINT;
}

export interface IDeleteEndpointFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.DELETE_ENDPOINT_FULFILLED;
  readonly meta: { id: number };
}

export interface IDeleteEndpointRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.DELETE_ENDPOINT_REJECTED;
}

/** Update endpoint action interfaces */

export interface IUpdateEndpointAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.UPDATE_ENDPOINT;
}

export interface IUpdateEndpointFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.UPDATE_ENDPOINT_FULFILLED;
}

export interface IUpdateEndpointRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.UPDATE_ENDPOINT_REJECTED;
}

/** Filter endpoints action interfaces */

export interface IFilterEndpointsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_ENDPOINTS;
}

export interface IFilterEndpointsFulfilledAction {
  readonly payload: IEndpoints;
  readonly type: ActionTypeKeys.FILTER_ENDPOINTS_FULFILLED;
}

export interface IFilterEndpointsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_ENDPOINTS_REJECTED;
}

/** Reset endpoints action interfaces */

export interface IResetEndpointsAction {
  readonly type: ActionTypeKeys.RESET_ENDPOINTS;
}

export type TEndpointAction =
  | IAddEndpointFulfilledAction
  | IDeleteEndpointFulfilledAction
  | IFilterEndpointsFulfilledAction
  | IGetEndpointFulfilledAction
  | IResetEndpointsAction
  | IUpdateEndpointFulfilledAction;
