import React from 'react';
import { ImmutableArray } from 'seamless-immutable';

import { Box, Flex } from '@rebass/grid';

import { Button, Modal, Tabs, TabsPanel, TransactionIcon } from 'components';
import { IconName, ModalName, ProductType } from 'consts';
import { IWithModal, withModal } from 'HOCs';

import { StatementAprsTable, TransactionsTable } from './../../components';
import { StatementForm } from './../../forms';

import {
  IStatement,
  IStatementApr,
  ITransaction,
  THandleDownloadStatement,
  THandleFilterStatementTransactions,
  THandleGetStatement,
  THandleGetStatementAprs,
  TResetStatement,
  TResetStatementAprs,
  TResetTransactions,
} from 'store';

interface IStatementModal extends IWithModal {
  activeProductType: string;
  currentStatement: IStatement;
  downloadStatement: THandleDownloadStatement;
  filterTransactions: THandleFilterStatementTransactions;
  getStatement: THandleGetStatement;
  getStatementAprs: THandleGetStatementAprs;
  isLoading: boolean;
  resetStatement: TResetStatement;
  resetStatementAprs: TResetStatementAprs;
  resetTransactions: TResetTransactions;
  statementAprs: ImmutableArray<IStatementApr>;
  statementTransactions: ImmutableArray<ITransaction>;
}

const modalName = ModalName.STATEMENT;

const StatementModal: React.FC<IStatementModal> = ({
  activeProductType,
  closeModal,
  currentStatement,
  downloadStatement,
  filterTransactions,
  getStatement,
  getStatementAprs,
  isLoading,
  resetStatement,
  resetStatementAprs,
  resetTransactions,
  statementAprs,
  statementTransactions,
}) => {
  React.useEffect(
    () => {
      getStatement();
    },
    [getStatement]
  );

  React.useEffect(
    () => {
      if (currentStatement.id) {
        filterTransactions(currentStatement);
      }
    },
    [
      filterTransactions,
      currentStatement,
    ]
  );

  React.useEffect(
    () => {
      if (activeProductType === ProductType.REVOLVING_CREDIT) {
        getStatementAprs();
      }
    },
    [
      activeProductType,
      getStatementAprs,
    ]
  );

  React.useEffect(
    () => {
      return () => {
        resetStatement();
        resetStatementAprs();
        resetTransactions();
      };
    },
    [
      resetStatement,
      resetStatementAprs,
      resetTransactions,
    ]
  );

  const productType = React.useMemo(
    () => {
      return {
        isLoan: activeProductType === ProductType.LOAN,
        isRevolvingCredit: activeProductType === ProductType.REVOLVING_CREDIT,
      };
    },
    [activeProductType]
  );

  return (
    <Modal
      name={modalName}
      containerWidth="1170px"
      minContainerHeight="500px"
      isLoading={isLoading}
    >
      <Tabs>

        <TabsPanel title={`Statement #${currentStatement?.sequenceNumber || ''}`}>
          <StatementForm
            initialValues={currentStatement}
            productType={productType}
          />
        </TabsPanel>

        <TabsPanel
          title="Accrued Interest"
          isHidden={!productType.isRevolvingCredit}
        >
          <Box my="20px">
            <StatementAprsTable data={statementAprs} />
          </Box>
        </TabsPanel>

        <TabsPanel
          title="Transactions"
          Icon={<TransactionIcon size="16" />}
        >
          <Box my="20px">
            <TransactionsTable data={statementTransactions} />
          </Box>
        </TabsPanel>

      </Tabs>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        mt="20px"
      >
        <Box mr="7px">
          <Button
            text="Download"
            iconName={IconName.FILE_PDF}
            type="reset"
            onClick={() => downloadStatement(currentStatement)}
          />
        </Box>
        <Button
          text="Close"
          type="reset"
          onClick={() => closeModal(modalName)}
        />
      </Flex>
    </Modal>
  );
};

export default withModal(StatementModal);
