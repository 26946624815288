import { createSelector } from 'reselect';

import { IStoreState } from 'store';

import { createLoadingSelector } from 'store/domains/loader';
import { ActionTypeKeys } from './actionTypes';

export const currentAuthRegistrationStepSelector = (state: IStoreState) =>
  state.login.currentAuthRegistrationStep;

export const defaultAuthCodeSelector = (state: IStoreState) => state.login.authKey?.secret_key;

export const authCodeSelector = createSelector(
  defaultAuthCodeSelector,
  code => code?.match(/.{1,4}/g).join(' ')
);

export const authDataUrlSelector = (state: IStoreState) => state.login.authKey?.data_url;

/**
 * Loading login selectors
 */

export const isLoggingInSelector = createLoadingSelector([
  ActionTypeKeys.LOGIN,
]);

export const isLoggingOutSelector = createLoadingSelector([
  ActionTypeKeys.LOGOUT,
]);

export const isProvidingAuthKeySelector = createLoadingSelector([
  ActionTypeKeys.PROVIDE_AUTH_KEY,
]);

export const isGeneratingAuthKeySelector = createLoadingSelector([
  ActionTypeKeys.GENERATE_AUTH_KEY,
]);
