import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import DetailsAccountModal from './DetailsAccountModal';

import {
  activeProductTypeSelector,
  currentAccountSelector,
  handleGetAccount,
  isGetAccountSelector,
  IStoreState,
  resetAccount,
  resetProductIllustration,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  currentAccount: currentAccountSelector(state),
  isLoading: isGetAccountSelector(state),
  productType: activeProductTypeSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getAccount: handleGetAccount,
    resetAccount,
    resetProductIllustration,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsAccountModal);
