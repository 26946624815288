import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import DetailsProductModal from './DetailsProductModal';

import {
  activeProductTypeSelector,
  currentProductSelector,
  isProductLoadingSelector,
  IStoreState,
  resetPayments,
  resetProduct,
  resetProductIllustration,
  resetProductRule,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  currentProduct: currentProductSelector(state),
  currentProductType: activeProductTypeSelector(state),
  isLoading: isProductLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetPayments,
    resetProduct,
    resetProductIllustration,
    resetProductRule,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsProductModal);
