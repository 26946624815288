import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { theme } from 'theme';

import { dateUtil } from 'utils';

const formatKey = (key: string) =>
  key.replace(/([A-Z])/g, ' $1')
    .toLocaleLowerCase()
    .replace(/^./, str => str.toUpperCase())
    .replace(/Institution id/, 'Institution');

const formatValue = (value: string) => (value === null || value === undefined) ? '-' : value;

export const downloadStatementPDF = (data: {
  fileName: string,
  mainTitle: string,
  statement: object;
  tables: Array<{
    title: string;
    items: Array<object>;
  }>,
}) => {
  const { fileName, mainTitle, statement, tables } = data;

  const dateTime = dateUtil.todayDateTime();

  /**
   * Orientation: landscape or portrait
   */
  const doc = new jsPDF('portrait', 'pt', 'a4') as any;

  /**
   * Name of .pdf file.
   * For example, the result name will be statement_1_25042020.pdf
   */
  doc.setProperties({ title: fileName });

  /**
   * Title of document
   */
  doc.setFontSize(13);
  doc.setTextColor(theme.colors.darkGray);
  doc.text(30, 33, mainTitle);

  /**
   * Datetime of generated file
   * e.g. Created at 26/03/2022 13:06:24
   */
  doc.setFontSize(8);
  doc.setTextColor(theme.colors.gray);
  doc.text(30, 49, `Created at ${dateTime}`);

  /**
   * Horizontal line
   */
  doc.setLineWidth(0.3);
  doc.setDrawColor(153, 153, 153);
  doc.line(30, 57, 570, 60);

  doc.setFontSize(9);
  doc.setTextColor(theme.colors.black);

  Object.entries(statement).forEach((el, j) => {
    doc.setTextColor(theme.colors.black);
    doc.text(30, 85 + 13 * j, `${formatKey(el[0])} `);
    doc.setTextColor(theme.colors.darkGray);
    doc.text(175, 85 + 13 * j, `${formatValue(el[1])}`);
  });

  /**
   * Tables
   */

  doc.addPage(null, 'l');

  tables.forEach((table, i) => {
    const { title, items } = table;

    if (items?.length) {
      const tableHead = Object.keys(items[0]).map(key => formatKey(formatValue(key)));

      const tableBody = items.map(item => Object.values(item));

      const prevTableFinalY = doc.previousAutoTable.finalY;

      const startY = () => prevTableFinalY ? prevTableFinalY + 35 : 45;

      const tableContent = {
        head: [tableHead],
        body: tableBody,
        startY: startY(),
        margin: { right: 10, left: 20 },
        styles: {
          minCellWidth: 40,
          fontSize: 7,
        },
        headStyles: {
          fontStyle: 'normal',
          fillColor:  [119, 136, 153], // gray
          textColor: [0, 0, 0, 0], // black
        },
        didParseCell: (tableData: {
          section: string;
          cell: {
            raw: number;
            styles: { halign: string };
          };
        }) => {
          const isHead = tableData.section === 'head';
          const isBody = tableData.section === 'body';
          const value = isBody && tableData.cell.raw;
          const isNumber = !isNaN(value);
          const styles = tableData.cell.styles;

          if (isBody && isNumber) {
            styles.halign = 'right';
          } else if (isHead) {
            styles.halign = 'center';
          } else {
            styles.halign = 'left';
          }
        },
      };

      // set title for first table
      if (!prevTableFinalY) {
        doc.setFontSize(13);
        doc.setTextColor(theme.colors.darkGray);
        doc.text(
          30,
          35,
          title
        );
      }

      // set titles for next tables
      if (prevTableFinalY) {
        doc.setFontSize(13);
        doc.setTextColor(theme.colors.darkGray);
        doc.text(
          30,
          doc.previousAutoTable.finalY + 26,
          title
        );
      }

      doc.autoTable(tableContent);
    }
  });

  /**
   * Page number at the bottom right corner
   */
  doc.setTextColor(theme.colors.gray);
  doc.setFontSize(7);

  const pageCount = doc.internal.getNumberOfPages();

  for (let i = 0; i < pageCount; i++) {
    doc.setPage(i);
    doc.text(
      i === 1 ? 570 : 820,
      i === 1 ? 820 : 570,
      `${doc.internal.getCurrentPageInfo().pageNumber}/${pageCount}`,
      null,
      null,
      'right'
    );
  }

  doc.save(`${fileName}.pdf`);
};
