import { actionTypesConfig } from 'consts';
import { IDictionaryEvent } from 'store/domains/admin';
import { stringsUtil } from 'utils';

import {
  IProductRule,
  IProductRuleData,
  IProductRulesFilter,
  IProductRulesFilterToSend,
} from './types';

export const normalizeFilterQueryString = (
  data: Partial<IProductRulesFilter>,
  productId: number
  ): string => {
  const { actionType, eventId } = data || {};

  const normalizedData: IProductRulesFilterToSend = {
    action_type: actionType?.value,
    event_id: eventId?.value,
    product_id: productId,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeProductRule = (
  data: IProductRuleData,
  events: Array<IDictionaryEvent>,
  productType: string
) => {
  const { action_type, event_id, product_id, script } = data || {};

  return {
    productId: product_id,
    actionType: actionTypesConfig
      .find(el => el.value === action_type && el.productType === productType),
    eventId: events
      ?.find(el => el.value === event_id && el.productType === productType),
    script,
    productType,
  };
};

export const normalizeProductRuleForSending = (
  data: Partial<IProductRule>,
  productId: number
) => {
  const { actionType, eventId, script } = data || {};

  return {
    action_type: actionType?.value,
    event_id: eventId?.value,
    product_id: productId,
    script,
  };
};
