import React from 'react';
import { ImmutableArray } from 'seamless-immutable';

import { Box, Flex } from '@rebass/grid';

import { Button } from 'components';

import {
  IProductFee,
  THandleAddProductFee,
  THandleDeleteProductFee,
  THandleGetProductAprs,
  THandleGetProductFees,
  TResetPayments,
} from 'store';

import { ISelectValue } from 'types';

import FeeForm from './FeeForm';
import FeesTable from './FeesTable';

interface IFees {
  addFee: THandleAddProductFee;
  applicationCondition: ISelectValue;
  aprsOptions: ImmutableArray<ISelectValue>;
  deleteFee: THandleDeleteProductFee;
  fees: ImmutableArray<IProductFee>;
  getAprs: THandleGetProductAprs;
  getFees: THandleGetProductFees;
  isAdding: boolean;
  isAprsLoading: boolean;
  isDeleting: boolean;
  isLoading: boolean;
  isReadOnly: boolean;
  onCancel: () => void;
  productType: { isLoan: boolean; isRevolvingCredit: boolean; type: string | number; };
  resetPayments: TResetPayments;
}

const Fees: React.FC<IFees> = ({
  addFee,
  applicationCondition,
  aprsOptions,
  deleteFee,
  fees,
  getAprs,
  getFees,
  isAdding,
  isAprsLoading,
  isDeleting,
  isLoading,
  isReadOnly,
  onCancel,
  productType,
  resetPayments,
}) => {
  React.useEffect(
    () => {
      Promise.all([
        getFees(),
        productType.isRevolvingCredit && getAprs(),
      ]);
    },
    [getFees, getAprs, productType]
  );

  React.useEffect(
    () => {
      return () => resetPayments();
    },
    [resetPayments]
  );

  return (
    <>
      {!isReadOnly && (
        <FeeForm
          addFee={addFee}
          applicationCondition={applicationCondition}
          aprsOptions={aprsOptions}
          isAprsLoading={isAprsLoading}
          isAprsVisible={productType.isRevolvingCredit}
          isDisabled={isAdding}
          isAdding={isAdding}
        />
      )}

      <Box mt="30px">
        <FeesTable
          deleteFee={deleteFee}
          fees={fees}
          isAprsVisible={productType.isRevolvingCredit}
          isDeleting={isDeleting}
          isLoading={isLoading}
          isReadOnly={isReadOnly}
        />
      </Box>

      <Flex
        justifyContent="flex-end"
        mt="15px"
      >
        <Button
          text="Close"
          onClick={onCancel}
        />
      </Flex>
    </>
  );
};

export default Fees;
