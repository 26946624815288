export enum UiSessionStatus {
  AUTHENTICATION_PENDING = 'P',
  INVALID = 'I',
  REGISTRATION_PENDING = 'F',
  VALID = 'V',
}

export const uiSessionsStatusOptions = [
  { value: UiSessionStatus.VALID, label: 'Valid' },
  { value: UiSessionStatus.INVALID, label: 'Invalid' },
  {
    value: UiSessionStatus.REGISTRATION_PENDING,
    label: '2FA registration pending',
  },
  {
    value: UiSessionStatus.AUTHENTICATION_PENDING,
    label: '2FA authentication pending',
  },
];
