import { IUserDataResp, IUsers } from './types';

import { IResponseStatus, TApiResponse } from 'types';

export enum ActionTypeKeys {
  /** Add user action type keys */
  ADD_USER = 'users/ADD_USER',
  ADD_USER_FULFILLED = 'users/ADD_USER_FULFILLED',
  ADD_USER_REJECTED = 'users/ADD_USER_REJECTED',

  /** Get user action type keys */
  GET_USER = 'users/GET_USER',
  GET_USER_FULFILLED = 'users/GET_USER_FULFILLED',
  GET_USER_REJECTED = 'users/GET_USER_REJECTED',

  /** Update user action type keys */
  UPDATE_USER = 'users/UPDATE_USER',
  UPDATE_USER_FULFILLED = 'users/UPDATE_USER_FULFILLED',
  UPDATE_USER_REJECTED = 'users/UPDATE_USER_REJECTED',

  /** Filter users action type keys */
  FILTER_USERS = 'users/FILTER_USERS',
  FILTER_USERS_FULFILLED = 'users/FILTER_USERS_FULFILLED',
  FILTER_USERS_REJECTED = 'users/FILTER_USERS_REJECTED',

  /** Reset users action type keys */
  RESET_USERS = 'users/RESET_USERS',
}

/** Add user action interfaces */

export interface IAddUserAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ADD_USER;
}

export interface IAddUserFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.ADD_USER_FULFILLED;
}

export interface IAddUserRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.ADD_USER_REJECTED;
}

/** Get user action interfaces */

export interface IGetUserAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_USER;
}

export interface IGetUserFulfilledAction {
  readonly payload: IUserDataResp;
  readonly type: ActionTypeKeys.GET_USER_FULFILLED;
}

export interface IGetUserRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_USER_REJECTED;
}

/** Update user action interfaces */

export interface IUpdateUserAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.UPDATE_USER;
}

export interface IUpdateUserFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.UPDATE_USER_FULFILLED;
}

export interface IUpdateUserRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.UPDATE_USER_REJECTED;
}

/** Filter users action interfaces */

export interface IFilterUsersAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_USERS;
}

export interface IFilterUsersFulfilledAction {
  readonly payload: IUsers;
  readonly type: ActionTypeKeys.FILTER_USERS_FULFILLED;
}

export interface IFilterUsersRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_USERS_REJECTED;
}

/** Reset users action interfaces */

export interface IResetUsersAction {
  readonly type: ActionTypeKeys.RESET_USERS;
}

export type TUserAction =
  | IAddUserFulfilledAction
  | IFilterUsersFulfilledAction
  | IGetUserFulfilledAction
  | IUpdateUserFulfilledAction
  | IResetUsersAction;
