import { identificationTypesOptions } from 'consts';
import { ISelectValue } from 'types';
import { dateUtil, stringsUtil } from 'utils';

import {
  ICommonCustomer,
  ICommonCustomerData,
  ICustomer,
  ICustomerData,
  ICustomersFilter,
  ICustomersFilterToSend,
} from './types';

export const normalizeFilterQueryString = (data: ICustomersFilter): string => {
  const {
    customerId,
    customerReference,
    firstName,
    institutionId,
    lastName,
    pageNumber,
  } = data || {};

  const normalizedData: ICustomersFilterToSend = {
    customer_id: customerId,
    customer_reference: customerReference,
    first_name: firstName,
    institution_id: institutionId?.value,
    last_name: lastName,
    page_number: pageNumber,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeCommonCustomer = (data: ICommonCustomerData): ICommonCustomer => {
  const {
    customer_reference,
    date_of_birth,
    email,
    first_name,
    id,
    institution_id,
    institution_name,
    last_name,
    mobile_phone_number,
    status,
    status_name,
  } = data || {};

  return {
    customerReference: customer_reference,
    dateOfBirth: date_of_birth,
    email,
    firstName: first_name,
    id,
    institutionId: institution_id,
    institutionName: institution_name,
    lastName: last_name,
    mobilePhoneNumber: mobile_phone_number,
    status,
    statusName: status_name,
  };
};

export const normalizeCurrentCustomer = (
  data: ICustomerData,
  countriesOptions: Array<ISelectValue>,
  customerStatusOptions: Array<ISelectValue>
): ICustomer => {
  const {
    address_country_code,
    address_county_state,
    address_line1,
    address_line2,
    address_line3,
    address_line4,
    address_post_code,
    address_town,
    base_currency_code,
    customer_limit,
    customer_reference,
    date_closed,
    date_created,
    date_of_birth,
    email,
    first_name,
    id,
    identification_number,
    identification_type,
    institution_id,
    institution_name,
    last_name,
    mobile_phone_number,
    nationality_country_code,
    status,
  } = data || {};

  return {
    addressCountryCode: countriesOptions?.find(el => el.value === address_country_code),
    addressCountyState: address_county_state,
    addressLine1: address_line1,
    addressLine2: address_line2,
    addressLine3: address_line3,
    addressLine4: address_line4,
    addressPostCode: address_post_code,
    addressTown: address_town,
    baseCurrencyCode: base_currency_code,
    customerLimit: customer_limit,
    customerReference: customer_reference,
    dateClosed: date_closed,
    dateCreated: date_created,
    dateOfBirth: date_of_birth,
    email,
    firstName: first_name,
    id,
    identificationNumber: identification_number,
    identificationType: identificationTypesOptions?.find(el => el.value === identification_type),
    institutionId: institution_id,
    institutionName: institution_name,
    lastName: last_name,
    mobilePhoneNumber: mobile_phone_number,
    nationalityCountryCode: countriesOptions?.find(el => el.value === nationality_country_code),
    status: customerStatusOptions.find(el => el.value === status),
  };
};

const normalizeDataForSending = (data: Partial<ICustomer>): Partial<ICustomerData>  => {
  const {
    addressCountryCode,
    addressCountyState,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    addressPostCode,
    addressTown,
    customerLimit,
    dateOfBirth,
    email,
    firstName,
    identificationNumber,
    identificationType,
    lastName,
    mobilePhoneNumber,
    nationalityCountryCode,
    status,
  } = data;

  return {
    address_country_code: addressCountryCode?.value,
    address_county_state: addressCountyState,
    address_line1: addressLine1,
    address_line2: addressLine2,
    address_line3: addressLine3,
    address_line4: addressLine4,
    address_post_code: addressPostCode,
    address_town: addressTown,
    customer_limit: stringsUtil.toNumber(customerLimit),
    date_of_birth: dateUtil.formatDateForSending(dateOfBirth),
    email,
    first_name: firstName,
    identification_number: identificationNumber,
    identification_type: identificationType?.value,
    last_name: lastName,
    mobile_phone_number: mobilePhoneNumber,
    nationality_country_code: nationalityCountryCode?.value,
    status: status?.value,
  };
};

export const normalizeDataForCreating = (data: Partial<ICustomer>): Partial<ICustomerData>  => {
  const { institution } = data;

  return {
    ...normalizeDataForSending(data),
    institution_id: institution?.value,
  };
};

export const normalizeDataForUpdating = (
  data: Partial<ICustomer>,
  currentData: ICustomerData
): Partial<ICustomerData>  => {
  const normalizedData = normalizeDataForSending(data);
  const dataForSending = stringsUtil.getObjectDiff(normalizedData, currentData);

  return dataForSending;
};
