import { ImmutableArray } from 'seamless-immutable';

import { commonStatusOptions, Flag } from 'consts';
import { IDictionaryCurrencyData } from 'store';
import { dateUtil } from 'utils';
import { IInstitutionData, IInstitutionDetails } from './types';

export const normalizeDataForSending = (data: Partial<IInstitutionDetails>) => {
  const {
    status,
    sftpLocation,
    sftpPublicKey,
    baseCurrency,
    nextOperationDate,
    institutionName,
  } = data || {};

  return {
    base_currency: baseCurrency?.value,
    institution_name: institutionName,
    name: institutionName,
    next_operation_date: dateUtil.formatDateForSending(nextOperationDate),
    sftp_location: sftpLocation,
    sftp_public_key: sftpPublicKey,
    status: status?.value,
  };
};

export const normalizeData = (data: IInstitutionData) => {
  const {
    current_operation_date,
    id,
    master_institution_flag,
    institution_name,
    next_operation_date,
    sftp_location,
    sftp_public_key,
    status,
  } = data || {};

  return {
    currentOperationDate: current_operation_date,
    id,
    institutionName: institution_name,
    masterInstitutionFlag: master_institution_flag === Flag.YES,
    nextOperationDate: next_operation_date,
    sftpLocation: sftp_location,
    sftpPublicKey: sftp_public_key,
    status: commonStatusOptions.find(el => el.value === status)?.label,
  };
};

export const normalizeDetails = (
  data: IInstitutionData,
  currencies: ImmutableArray<IDictionaryCurrencyData>
) => {
  const { status, base_currency } = data || {};
  const currency = currencies.find(el => el.numeric_code === base_currency);

  return {
    ...normalizeData(data),
    status: commonStatusOptions.find(el => el.value === status),
    baseCurrency: {
      value: currency?.numeric_code,
      label: `${currency?.currency_code || ''} - ${currency?.name || ''}`,
    },
  };
};
