import React from 'react';

import styled from 'theme';

interface IWrapper {
  isGreen?: boolean;
}

const Wrapper = styled.div<IWrapper>`
  display: inline-block;
  padding: 3px 8px 2px;
  font-family: ${({ theme }) => theme.fonts.title};
  font-size: 10px;
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 7px;
  border: 1px solid ${({ isGreen, theme }) => isGreen ? theme.colors.green : theme.colors.accent};
  color: ${({ isGreen, theme }) => isGreen ? theme.colors.green : theme.colors.accent};
  text-transform: uppercase;
  letter-spacing: .7pt;
  box-shadow: ${({ theme }) => theme.shadows.aroundBox};
  user-select: none;
`;

interface IEllipseLabel {
  isGreen?: boolean;
  text: string;
}

const EllipseLabel: React.FC<IEllipseLabel> = ({ isGreen, text }) => {
  return (
    <Wrapper isGreen={isGreen}>
      {text}
    </Wrapper>
  );
};

export default EllipseLabel;
