import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';
import { activeItemIdSelector } from 'store/domains/utils';

import { ActionTypeKeys } from './actionTypes';
import { normalizeSystemProperty } from './utils';

export const defaultSysPropsSelector = (state: IStoreState) =>
  state.admin.systemProperties.sysProps;

export const sysPropsSelector = createSelector(
  defaultSysPropsSelector,
  data => data?.map(systemProperty => normalizeSystemProperty(systemProperty))
);

export const currentSysPropSelector = createSelector(
  sysPropsSelector,
  activeItemIdSelector,
  (data, currentId) => data.find(el => el.id === currentId)
);

/**
 * System properties loading selectors
 */

export const isSysPropUpdatingSelector = createLoadingSelector([
  ActionTypeKeys.UPDATE_SYS_PROP,
]);

export const isSysPropDeletingSelector = createLoadingSelector([
  ActionTypeKeys.DELETE_SYS_PROP,
]);

export const isSysPropAddingSelector = createLoadingSelector([
  ActionTypeKeys.ADD_SYS_PROP,
]);

export const isSysPropsFilteringSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_SYS_PROPS,
]);
