import React from 'react';

import { Modal } from 'components';

import { ModalName } from 'consts';

import { IWithModal, withModal } from 'HOCs';
import { ChangePasswordForm } from './forms';

import { THandleChangePassword } from 'store';

interface IChangePasswordModal extends IWithModal {
  changePassword: THandleChangePassword;
  isLoading: boolean;
}

const modalName = ModalName.CHANGE_PASSWORD;

const ChangePasswordModal: React.FC<IChangePasswordModal> = ({
  changePassword,
  closeModal,
  isLoading,
}) => {
  return (
    <Modal
      name={modalName}
      title="Change password"
      containerWidth="300px"
      isLoading={isLoading}
      loadingText="Changing password"
    >
      <ChangePasswordForm
        changePassword={changePassword}
        onCancel={() => closeModal(modalName)}
      />
    </Modal>
  );
};

export default withModal(ChangePasswordModal);
