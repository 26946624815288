import { Box } from '@rebass/grid';

import {
  AddIcon,
  ArrowDropDownIcon,
  ArrowDropUpIcon,
  CloneIcon,
  DeleteIcon,
  DownloadIcon,
  FilePdfIcon,
  LogoutIcon,
  MenuIcon,
  QrcodeIcon,
  RefreshIcon,
  SettingsIcon,
  SmartphoneIcon,
  TransactionIcon,
} from './../../Icons';

import { IconName } from 'consts';

export const icons = {
  [IconName.ADD]: (<Box mt="-3px"><AddIcon size="16" /></Box>),
  [IconName.ARROW_DOWN]: (<ArrowDropDownIcon size="25" />),
  [IconName.ARROW_UP]: (<ArrowDropUpIcon size="25" />),
  [IconName.CLONE]: (<CloneIcon size="15" />),
  [IconName.DELETE]: (<DeleteIcon size="18" />),
  [IconName.DETAILS]: (<Box mt="-2px"><MenuIcon size="16" /></Box>),
  [IconName.DOWNLOAD]: (<DownloadIcon size="16" />),
  [IconName.FILE_PDF]: (<FilePdfIcon size="20" />),
  [IconName.LOGOUT]: (<Box mt="-2px"><LogoutIcon size="16" /></Box>),
  [IconName.SETTINGS]: (<Box mt="-2px" ml="-2px"><SettingsIcon size="18" /></Box>),
  [IconName.QRCODE]: (<Box mt="-3px"><QrcodeIcon size="15" /></Box>),
  [IconName.REFRESH]: (<Box mt="-1px"><RefreshIcon size="18" /></Box>),
  [IconName.SMARTPHONE]: (<Box mt="-2px"><SmartphoneIcon size="18" /></Box>),
  [IconName.TRANSACTION]: (<Box mt="-2px"><TransactionIcon size="16" /></Box>),
};
