import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { formValueSelector } from 'redux-form';

import { FormName } from 'consts';

import {
  handleAddProductFee,
  handleDeleteProductFee,
  handleGetProductAprs,
  handleGetProductFees,
  isProductAprsLoadingSelector,
  isProductFeeAddingSelector,
  isProductFeeDeletingSelector,
  isProductFeesLoadingSelector,
  IStoreState,
  productAprsOptionsSelector,
  productFeesSelector,
  resetPayments,
} from 'store';

import Fees from './Fees';

const formSelector = formValueSelector(FormName.PRODUCT_FEE);

const mapStateToProps = (state: IStoreState) => ({
  applicationCondition: formSelector(state, 'feeApplicationCondition'),
  aprsOptions: productAprsOptionsSelector(state),
  fees: productFeesSelector(state),
  isAdding: isProductFeeAddingSelector(state),
  isAprsLoading: isProductAprsLoadingSelector(state),
  isDeleting: isProductFeeDeletingSelector(state),
  isLoading: isProductFeesLoadingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    addFee: handleAddProductFee,
    deleteFee: handleDeleteProductFee,
    getAprs: handleGetProductAprs,
    getFees: handleGetProductFees,
    resetPayments,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fees);
