import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import GeneralProductForm from './GeneralProductForm';

import {
  activeProductTypeSelector,
  currentProductSelector,
  handleCloneProduct,
  handleDeleteProduct,
  handleGetProduct,
  handleUpdateProduct,
  isProductCloningSelector,
  isProductDeletingSelector,
  isProductUpdatingSelector,
  IStoreState,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  activeProductType: activeProductTypeSelector(state),
  initialValues: currentProductSelector(state),
  isCloning: isProductCloningSelector(state),
  isDeleting: isProductDeletingSelector(state),
  isUpdating: isProductUpdatingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    cloneProduct: handleCloneProduct,
    deleteProduct: handleDeleteProduct,
    getProduct: handleGetProduct,
    updateProduct: handleUpdateProduct,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralProductForm);
