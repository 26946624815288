import React from 'react';

import { Modal } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';
import { SystemPropertyForm } from './../../forms';

interface IDetailsSystemPropertyModal extends IWithModal {
  isFormDirty: boolean;
}

const modalName = ModalName.DETAILS_SYSTEM_PROPERTY;

const DetailsSystemPropertyModal: React.FC<IDetailsSystemPropertyModal> = ({
  closeModal,
  isFormDirty,
  isReadOnlyPage,
  isLockedItem,
}) => {
  return (
    <Modal
      name={modalName}
      title="System property"
      containerWidth="600px"
      withCloseConfirmation={isFormDirty}
    >
      <SystemPropertyForm
        onCancel={() => closeModal(modalName)}
        isEditMode={true}
        isReadOnly={isReadOnlyPage || isLockedItem}
      />
    </Modal>
  );
};

export default withModal(DetailsSystemPropertyModal);
