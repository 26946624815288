import { activeItemIdSelector } from 'store';
import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import {
  ActionTypeKeys,
  IFilterApiCallsAction,
  IGetApiCallAction,
} from './actionTypes';
import * as api from './api';
import { IApiCallsFilter } from './types';
import { normalizeFilterQueryString } from './utils';

/**
 * Filter API calls action
 */

export type TFilterApiCalls = (queryString: string) => IFilterApiCallsAction;
export type THandleFilterApiCalls = (data: IApiCallsFilter) => Thunk<void>;

export const filterApiCalls: TFilterApiCalls = queryString => ({
  type: ActionTypeKeys.FILTER_API_CALLS,
  payload: api.filterApiCalls(queryString),
});

export const handleFilterApiCalls: THandleFilterApiCalls = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterQueryString(data);

        await dispatch(filterApiCalls(queryString));
      },
      dispatch
    );
  };

/**
 * Get API call action
 */

export type TGetApiCall = (id: number) => IGetApiCallAction;
export type THandleGetApiCall = () => Thunk<void>;

export const getApiCall: TGetApiCall = id => ({
  type: ActionTypeKeys.GET_API_CALL,
  payload: api.getApiCall(id),
});

export const handleGetApiCall: THandleGetApiCall = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const id = activeItemIdSelector(state);

        await dispatch(getApiCall(id));
      },
      dispatch
    );
  };

/**
 * Reset apiCalls action
 */

export type TResetApiCalls = () => void;

export const resetApiCalls: TResetApiCalls = () => ({
  type: ActionTypeKeys.RESET_API_CALLS,
});
