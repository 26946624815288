import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import AprsTable from './AprsTable';

import {
  handleGetProductAprs,
  handleUpdateProductApr,
  isProductAprsLoadingSelector,
  IStoreState,
  openModal,
  productAprsSelector,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  isLoading: isProductAprsLoadingSelector(state),
  productAprs: productAprsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getProductAprs: handleGetProductAprs,
    updateProductApr: handleUpdateProductApr,
    openModal,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AprsTable);
