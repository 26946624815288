import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { formValueSelector } from 'redux-form';

import { FormName } from 'consts';
import Penalties from './Penalties';

import {
  handleAddProductPenalty,
  handleDeleteProductPenalty,
  handleGetProductPenalties,
  isProductPenaltiesLoadingSelector,
  isProductPenaltyAddingSelector,
  isProductPenaltyDeletingSelector,
  IStoreState,
  productPenaltiesSelector,
  resetPayments,
} from 'store';

const formSelector = formValueSelector(FormName.PRODUCT_PENALTY);

const mapStateToProps = (state: IStoreState) => ({
  applicationCondition: formSelector(state, 'penaltyApplicationCondition'),
  isAdding: isProductPenaltyAddingSelector(state),
  isDeleting: isProductPenaltyDeletingSelector(state),
  isLoading: isProductPenaltiesLoadingSelector(state),
  penalties: productPenaltiesSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    addPenalty: handleAddProductPenalty,
    deletePenalty: handleDeleteProductPenalty,
    getPenalties: handleGetProductPenalties,
    resetPayments,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Penalties);
