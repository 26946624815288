import React from 'react';

import { Flex } from '@rebass/grid';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Button, PasswordField } from 'components';

import { IAuthPassword, THandleGenerateAuthKey } from 'store';

import { FormName } from 'consts';

import { formErrorUtil } from 'utils';

interface IPasswordForm {
  generateAuthKey: THandleGenerateAuthKey;
  isLoading: boolean;
}

type TPasswordForm = IPasswordForm & InjectedFormProps<{}, IPasswordForm>;

const PasswordForm: React.FC<TPasswordForm> = ({
  handleSubmit,
  generateAuthKey,
  pristine,
  isLoading,
}) => (
  <form onSubmit={handleSubmit(data => generateAuthKey(data as IAuthPassword))}>
    <Field
      id="password"
      name="password"
      placeholder="Enter Password"
      label="Password"
      component={PasswordField}
      autoComplete="new-password"
      validate={[formErrorUtil.isRequired]}
      autoFocus={true}
      disabled={isLoading}
    />
    <Flex justifyContent="flex-end">
      <Button
        text="Next"
        disabled={pristine}
        isLoading={isLoading}
      />
    </Flex>
  </form>
);

export default reduxForm<{}, IPasswordForm>({
  form: FormName.PASSWORD,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(PasswordForm);
