import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { isDirty } from 'redux-form';

import { FormName } from 'consts';

import DetailsUserModal from './DetailsUserModal';

import { currentUserSelector, handleGetUser, isGettingUserSelector, IStoreState } from 'store';

const dirty = isDirty(FormName.USER);

const mapStateToProps = (state: IStoreState) => ({
  isFormDirty: dirty(state),
  isLoading: isGettingUserSelector(state),
  userDetails: currentUserSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getUser: handleGetUser,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsUserModal);
