import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';

import { ActionTypeKeys } from './actionTypes';
import { normalizeData } from './utils';

/**
 * API calls selectors
 */

export const defaultApiCallsSelector = (state: IStoreState) => state.audit.apiCalls.apiCalls;

export const apiCallsSelector = createSelector(
  defaultApiCallsSelector,
  apiCalls => apiCalls?.map(apiCall => normalizeData(apiCall))
);

export const apiCallsTotalPagesSelector = (state: IStoreState) =>
  state.audit.apiCalls.totalPages;

export const apiCallsTotalResultsSelector = (state: IStoreState) =>
  state.audit.apiCalls.totalResults;

export const apiCallsCurrentPageSelector = (state: IStoreState) =>
  state.audit.apiCalls.pageNumber;

export const isLoadingApiCallsSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_API_CALLS,
]);

/**
 * Current API call selectors
 */

export const defaultCurrentApiCallSelector = (state: IStoreState) =>
  state.audit.apiCalls.currentApiCall;

export const apiCallSelector = createSelector(
  defaultCurrentApiCallSelector,
  currentApiCall => normalizeData(currentApiCall)
);

export const isGettingApiCallSelector = createLoadingSelector([
  ActionTypeKeys.GET_API_CALL,
]);
