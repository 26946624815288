import { apiClientService } from 'services';
// import { throttleUtil } from 'utils';

// import { loanIllustrationMock, revolvingCreditIllustrationMock } from './mock';

/**
 * Illustrate loan product API
 */
export const illustrateLoan = (productId: number | string, queryString: string) =>
  // throttleUtil.getDataAfter(loanIllustrationMock, 500);
  apiClientService.get(`il/products/illustrate/${productId}?${queryString}`);

/**
 * Illustrate revolving credit product API
 */
export const illustrateRevCredit = (accountId: number | string, queryString: string) =>
  // throttleUtil.getDataAfter(revolvingCreditIllustrationMock, 500);
  apiClientService.get(`rc/products/illustrate/${accountId}?${queryString}`);
