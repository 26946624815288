import styled from 'theme';

interface ILabel {
  active?: boolean;
  invalid?: boolean;
  isAccent?: boolean;
}

export const Label = styled.label<ILabel>`
  display: block;
  margin-bottom: 5px;
  font-size: 12px;
  color: ${({ theme, isAccent, invalid }) => invalid
    ? theme.colors.red
      : isAccent
        ? theme.colors.accent
        : theme.colors.darkGray
  };
`;

interface IText {
  accentColor?: boolean;
  bold?: boolean;
  fontSize?: string;
  letterSpacing?: string;
  light?: boolean;
  monoFontFamily?: boolean;
  upperCase?: boolean;
}

export const Text = styled.div<IText>`
  font-size: ${({ fontSize }) => fontSize ? fontSize : '12px'};
  color: ${({ theme, light, accentColor }) => light
    ? theme.colors.gray
      : accentColor
        ? theme.colors.accent
        : theme.colors.black
  };
  line-height: 1.5;

  ${({ bold }) => bold && `
    font-weight: 500;
  `};

  ${({ upperCase }) => upperCase && `
    text-transform: uppercase;
  `};

  ${({ monoFontFamily, theme }) => monoFontFamily && `
    font-family: ${theme.fonts.code};
  `};

  ${({ letterSpacing }) => letterSpacing && `
    letter-spacing: ${letterSpacing};
  `};
`;

interface ITitle {
  color?: string;
  textAlign?: string;
}

export const T2 = styled.h2<ITitle>`
  margin-bottom: 7px;
  font-size: 22px;
  font-family: ${({ theme }) => theme.fonts.title};
  text-align: ${({ textAlign }) => textAlign ? textAlign : 'left'};
  color: ${({ theme, color }) => color ? color : theme.colors.darkGray};

  svg {
    margin-bottom: 2px;
  }
`;

export const T3 = styled.h3<ITitle>`
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme, color }) => color ? color : theme.colors.darkGray};
`;
