import React from 'react';
import { CellInfo } from 'react-table';
import { ImmutableArray } from 'seamless-immutable';

import { Flex } from '@rebass/grid';

import {
  Button,
  Table,
  TableCell,
  TableHeader,
  withSpinner,
} from 'components';

import { IconName } from 'consts';

import {
  IProductFee,
  THandleDeleteProductFee,
} from 'store';

import { ITableCell } from 'types';

type TCell<T extends keyof IProductFee> = ITableCell<IProductFee[T]>;

interface IFeesTable {
  deleteFee: THandleDeleteProductFee;
  fees: ImmutableArray<IProductFee>;
  isAprsVisible: boolean;
  isDeleting: boolean;
  isReadOnly: boolean;
}

const FeesTable: React.FC<IFeesTable> = ({
  deleteFee,
  fees,
  isAprsVisible,
  isDeleting,
  isReadOnly,
}) => {
  const deleteTableColumn = React.useMemo(
    () => [
      {
        maxWidth: 50,
        accessor: 'deleteButton',
        Cell: (cellInfo: CellInfo) => !isReadOnly && (
          <Flex
            justifyContent="center"
            width="100%"
            p="4px"
          >
            <Button
              iconName={IconName.DELETE}
              title="Delete"
              withConfirmation={true}
              confirmationText={`Confirm you want to delete "${cellInfo.original.description}"?`}
              classNames={['is-circle']}
              onClick={() => deleteFee(cellInfo.original)}
              isLoading={isDeleting}
            />
          </Flex>
        ),
      },
    ],
    [deleteFee, isDeleting, isReadOnly]
  );

  const baseTableColumns = React.useMemo(
    () => [
      {
        maxWidth: 75,
        accessor: 'feeId',
        Header: <TableHeader title="Fee ID" />,
        Cell: (props: TCell<'feeId'>) => (
          <TableCell
            value={props.value}
            isNumber={true}
          />
        ),
      },
      {
        maxWidth: 400,
        accessor: 'description',
        Header: <TableHeader title="Description" />,
        Cell: (props: TCell<'description'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 75,
        accessor: 'rate',
        Header: <TableHeader title="Rate" />,
        Cell: (props: TCell<'rate'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 75,
        accessor: 'amount',
        Header: <TableHeader title="Amount" />,
        Cell: (props: TCell<'amount'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 400,
        accessor: 'feeApplicationConditionName',
        Header: <TableHeader title="Application Condition" />,
        Cell: (props: TCell<'feeApplicationConditionName'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
    ],
    []
  );

  const tableColumns = React.useMemo(
    () => isAprsVisible
      ? [
        ...baseTableColumns,
        {
          maxWidth: 60,
          accessor: 'aprId',
          Header: <TableHeader title="APR ID" />,
          Cell: (props: TCell<'aprId'>) => (
            <TableCell
              value={props.value}
            />
          ),
        },
        {
          maxWidth: 400,
          accessor: 'aprDescription',
          Header: <TableHeader title="APR Description" />,
          Cell: (props: TCell<'aprDescription'>) => (
            <TableCell
              value={props.value}
            />
          ),
        },
        ...deleteTableColumn,
      ]
      : [
        ...baseTableColumns,
        ...deleteTableColumn,
      ],
    [
      baseTableColumns,
      deleteTableColumn,
      isAprsVisible,
    ]
  );

  return (
    <Table
      columns={tableColumns}
      data={fees}
      pageSize={10}
    />
  );
};

export default withSpinner()(FeesTable);
