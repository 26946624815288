import { createSelector } from 'reselect';

import { permissionTypesOptions, screensListFull } from 'consts';
import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';
import { activeItemIdSelector } from 'store/domains/utils';

import { ActionTypeKeys } from './actionTypes';
import { prepareUiItemLabel } from './utils';

/**
 * Users groups selectors
 */

export const defaultUsersGroupsSelector = (state: IStoreState) => state.admin.userGroups.groups;

export const usersGroupsSelector = createSelector(
  defaultUsersGroupsSelector,
  data => data?.map(el => {

    const { id, name, institution_id, institution_name } = el;

    return {
      id,
      institutionId: institution_id,
      institutionName: institution_name,
      name,
    };
  })
);

export const currentUsersGroupSelector = createSelector(
  defaultUsersGroupsSelector,
  activeItemIdSelector,
  (groups, currentId) => {
    const currentGroup = groups?.find(group => group.id === currentId);

    const { id, name, institution_id, institution_name } = currentGroup || {};

    return {
      id,
      name,
      institutionId: institution_id,
      institutionName: institution_name,
    };
  }
);

export const currentUsersGroupNameSelector = createSelector(
  currentUsersGroupSelector,
  data => {
    const institutionName = data?.institutionName;
    const groupName = data?.name;

    return `${groupName} ${institutionName}`;
  }
);

export const isLoadingUsersGroupsSelector = createLoadingSelector([
  ActionTypeKeys.GET_USERS_GROUPS,
]);

export const isAddingUsersGroupSelector = createLoadingSelector([
  ActionTypeKeys.ADD_USERS_GROUP,
]);

export const isUpdatingUsersGroupSelector = createLoadingSelector([
  ActionTypeKeys.UPDATE_USERS_GROUP,
]);

/**
 * Users group members selectors
 */

export const defaultUsersGroupMembersSelector = (state: IStoreState) =>
  state.admin.userGroups.members;

export const usersGroupMembersSelector = createSelector(
  defaultUsersGroupMembersSelector,
  data => data?.map(el => {
    const { first_name, last_name, username, user_id, user_group_id} = el || {};

    return {
      fullName: `${first_name} ${last_name} ${username ? `(${username})` : ''}`,
      userId: user_id,
      username,
      usersGroupId: user_group_id,
    };
  })
);

export const isLoadingUsersGroupMembersSelector = createLoadingSelector([
  ActionTypeKeys.GET_USERS_GROUP_MEMBERS,
]);

export const isAddingUsersGroupMemberSelector = createLoadingSelector([
  ActionTypeKeys.ADD_USERS_GROUP_MEMBER,
]);

export const isDeletingUsersGroupMemberSelector = createLoadingSelector([
  ActionTypeKeys.DELETE_USERS_GROUP_MEMBER,
]);

/**
 * Users group permissions selectors
 */

export const defaultUsersGroupPermissionsSelector = (state: IStoreState) =>
  state.admin.userGroups.permissions;

export const usersGroupPermissionsSelector = createSelector(
  defaultUsersGroupPermissionsSelector,
  items => items?.map(item => {
    const permission = permissionTypesOptions.find(el => el.value === item.permission);
    const uiItemLabel = prepareUiItemLabel(item.ui_item);

    return {
      usersGroupId: item.user_group_id,
      uiItem: item.ui_item,
      uiItemLabel,
      permission,
    };
  })
);

export const usersGroupUiItemsSelector = createSelector(
  defaultUsersGroupPermissionsSelector,
  permissions => {
    const filteredScreens: Array<string> = [];

    screensListFull.forEach(screen => {
      const isPermission = permissions.find(permission => permission.ui_item === screen);

      if (!isPermission) {
        filteredScreens.push(screen);
      }
    });

    return filteredScreens?.map(el => {
      const value = el;
      const label = prepareUiItemLabel(el);

      return { value, label };
    });
  });

export const isLoadingUsersGroupPermissionsSelector = createLoadingSelector([
  ActionTypeKeys.GET_USERS_GROUP_PERMISSIONS,
]);

export const isAddingUsersGroupPermissionsSelector = createLoadingSelector([
  ActionTypeKeys.ADD_USERS_GROUP_PERMISSIONS,
]);

export const isDeletingUsersGroupPermissionSelector = createLoadingSelector([
  ActionTypeKeys.DELETE_USERS_GROUP_PERMISSION,
]);

export const isUpdatingUsersGroupPermissionSelector = createLoadingSelector([
  ActionTypeKeys.UPDATE_USERS_GROUP_PERMISSION,
]);
