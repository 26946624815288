import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';
import styled from 'styled-components';

import {
  HorizontalLine,
  InputField,
  NewLine,
  NumberFormatField,
  T3,
  TextareaField,
} from 'components';
import { FormName } from 'consts';

const LeftPartWrapper = styled(Flex)`
  border-right: 1px solid ${({ theme }) => theme.colors.lightGray};
  margin-right: 10px;
`;

interface ITransactionForm {
  productType: {
    isRevolvingCredit: boolean,
    isLoan: boolean,
    typeName: string,
    type: string;
  };
}

type TTransactionForm = ITransactionForm & InjectedFormProps<{}, ITransactionForm>;

const TransactionForm: React.FC<TTransactionForm> = ({ productType }) => (
  <form>
    <Flex
      alignItems="flex-end"
      flexWrap="wrap"
      mx="-8px"
    >
      <Flex alignItems="flex-start">
        <LeftPartWrapper
          alignItems="flex-end"
          flexWrap="wrap"
          width="630px"
        >
          <Box width="120px" p="8px">
            <Field
              id="id"
              name="id"
              component={InputField}
              label="Transaction ID"
              placeholder="-"
              disabled={true}
              isNumber={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="parentTransactionId"
              name="parentTransactionId"
              component={InputField}
              label="Parent Transaction ID"
              placeholder="-"
              disabled={true}
              isNumber={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="accountId"
              name="accountId"
              component={InputField}
              label="Account ID"
              placeholder="-"
              disabled={true}
              isNumber={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="sourceEndpointId"
              name="sourceEndpointId"
              component={InputField}
              label="Source Endpoint ID"
              placeholder="-"
              disabled={true}
              isNumber={true}
            />
          </Box>
          <Box width="140px" p="8px">
            <Field
              id="statusName"
              name="statusName"
              component={InputField}
              label="Status"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <Box width="360px" p="8px">
            <Field
              id="transactionTypeDescription"
              name="transactionTypeDescription"
              component={InputField}
              label="Transaction Type"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="debitCredit"
              name="debitCredit"
              component={InputField}
              label="Debit / Credit"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <Box width="140px" p="8px">
            <Field
              id="productTypeName"
              name="productTypeName"
              component={InputField}
              label="Product Type"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <Box width="360px" p="8px">
            <Field
              id="transactionReference"
              name="transactionReference"
              component={InputField}
              label="Transaction Reference"
              placeholder="-"
              disabled={true}
            />
          </Box>

          {productType.isRevolvingCredit && (
            <>
              <HorizontalLine />
              <T3>Balance</T3>
              <NewLine />
              <Box width="120px" p="8px">
                <Field
                  id="balanceSettledBefore"
                  name="balanceSettledBefore"
                  component={NumberFormatField}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  label="Balance Settled Before"
                  disabled={true}
                />
              </Box>
              <Box width="120px" p="8px">
                <Field
                  id="balanceSettledAfter"
                  name="balanceSettledAfter"
                  component={NumberFormatField}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  label="Balance Settled After"
                  disabled={true}
                />
              </Box>
              <Box width="120px" p="8px">
                <Field
                  id="balanceAuthorisedBefore"
                  name="balanceAuthorisedBefore"
                  component={NumberFormatField}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  label="Balance Authorised Before"
                  disabled={true}
                />
              </Box>
              <Box width="120px" p="8px">
                <Field
                  id="balanceAuthorisedAfter"
                  name="balanceAuthorisedAfter"
                  component={NumberFormatField}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  label="Balance Authorised After	"
                  disabled={true}
                />
              </Box>
            </>
          )}
        </LeftPartWrapper>

        <Flex
          alignItems="flex-end"
          flexWrap="wrap"
          width="380px"
        >
          <Box width="120px" p="8px">
            <Field
              id="amount"
              name="amount"
              component={NumberFormatField}
              placeholder="-"
              fixedDecimalScale={true}
              decimalScale={2}
              label="Amount"
              disabled={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="amountSettled"
              name="amountSettled"
              component={NumberFormatField}
              placeholder="-"
              fixedDecimalScale={true}
              decimalScale={2}
              label="Amount Settled"
              disabled={true}
            />
          </Box>

          {productType.isRevolvingCredit && (
            <Box width="120px" p="8px">
              <Field
                id="amountInOriginalCurrency"
                name="amountInOriginalCurrency"
                component={NumberFormatField}
                fixedDecimalScale={true}
                decimalScale={2}
                label="Amount in Original Currency"
                disabled={true}
              />
            </Box>
          )}

          <Box width="240px" p="8px">
            <Field
              id="transactionDatetime"
              name="transactionDatetime"
              component={InputField}
              label="Transaction Datetime"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="settledDate"
              name="settledDate"
              component={InputField}
              label="Settled Date"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <Box width="360px" p="8px">
            <Field
              id="description"
              name="description"
              component={TextareaField}
              height={92}
              label="Description"
              placeholder="-"
              disabled={true}
            />
          </Box>
        </Flex>
      </Flex>

      {productType.isRevolvingCredit && (
        <>
          <HorizontalLine />
          <Box width="360px" p="8px">
            <Field
              id="aprCalculationMethod"
              name="aprCalculationMethod"
              component={InputField}
              label="APR Calculation Method"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="aprRate"
              name="aprRate"
              component={NumberFormatField}
              fixedDecimalScale={true}
              decimalScale={2}
              label="APR Rate"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <NewLine />
          <Box width="360px" p="8px">
            <Field
              id="feeApplicationCondition"
              name="feeApplicationCondition"
              component={InputField}
              label="Fee Application Condition"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="feeRate"
              name="feeRate"
              component={NumberFormatField}
              fixedDecimalScale={true}
              decimalScale={2}
              label="Fee Rate"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="feeAmount"
              name="feeAmount"
              component={NumberFormatField}
              fixedDecimalScale={true}
              decimalScale={2}
              label="Fee Amount"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <NewLine />
          <Box width="360px" p="8px">
            <Field
              id="rewardApplicationCondition"
              name="rewardApplicationCondition"
              component={InputField}
              label="Reward Application Condition"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="rewardRate"
              name="rewardRate"
              component={NumberFormatField}
              fixedDecimalScale={true}
              decimalScale={2}
              label="Reward Rate"
              placeholder="-"
              disabled={true}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="rewardAmount"
              name="rewardAmount"
              component={NumberFormatField}
              fixedDecimalScale={true}
              decimalScale={2}
              label="Reward Amount"
              placeholder="-"
              disabled={true}
            />
          </Box>
        </>
      )}
    </Flex>
  </form>
);

export default reduxForm<{}, ITransactionForm>({
  form: FormName.TRANSACTION,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(TransactionForm);
