import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import SettleTransactionResultModal from './SettleTransactionResultModal';

import {
  IStoreState,
  resetSettleTransactionResult,
  settleTransactionResultSelector,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  settleTransactionResult: settleTransactionResultSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetResult: resetSettleTransactionResult,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettleTransactionResultModal);
