import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import CodeForm from './CodeForm';

import {
  handleProvideAuthKey,
  isProvidingAuthKeySelector,
  IStoreState,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  isLoading: isProvidingAuthKeySelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    provideAuthKey: handleProvideAuthKey,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CodeForm);
