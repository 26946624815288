import React from 'react';

import styled from 'theme';
import { highlightCss } from 'theme/styles';

import { Container } from '../../components/Layout';

import { dateUtil } from 'utils';

const Wrapper = styled.footer`
  padding: 70px 0 15px;
  text-align: center;

  .highlight {
    display: inline-block;
    padding-top: 12px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.darkGray};
    ${highlightCss}
  }
`;

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <div className="highlight">
          Paymentology © 2019 - {dateUtil.getCurrentYear()} All rights reserved.
        </div>
      </Container>
    </Wrapper>
  );
};

export default Footer;
