import 'react-table/react-table.css';

import styled from 'theme';
import { scrollbarCss } from 'theme/styles';

interface ITableStyled {
  isScrollbar?: boolean;
  isSmaller?: boolean;
  isNoData?: boolean;
}

export const TableWrapper = styled.div<ITableStyled>`
  .ReactTable {
    height: 100%;
    border: 1px solid ${({ theme }) => theme.colors.lighterGray};
    box-shadow: ${({ theme }) => theme.shadows.normalBox};
    overflow-x: ${({ isScrollbar }) => isScrollbar ? 'auto' : 'visible'};
    ${scrollbarCss};

    ${({ isNoData }) => isNoData && `
      overflow: hidden;
    `};

    .rt-table, .rt-tbody {
      display: block;
      overflow: visible;
    }

    ${({ isScrollbar }) => !isScrollbar && `
      .rt-tbody .rt-td {
        overflow: visible;
      }
    `};

    .rt-thead .rt-th, .rt-tbody .rt-td {
      padding: 0;
      overflow: visible;
      word-break: break-word;
    }

    .rt-thead .rt-th {
      border-right: 1px solid ${({ theme }) => theme.colors.lighterGray};
    }

    .rt-thead .rt-th,
    .rt-thead .rt-resizable-header,
    .rt-thead .rt-th.-cursor-pointer,
    .rt-tbody .rt-td {
      min-width: 50px;
    }

    .rt-thead .rt-th > div {
      display: flex;
      height: 100%;
    }

    .rt-resizer {
      width: 8px;
      right: -4px;
    }

    .rt-th:last-child .rt-resizer {
      display: none;
    }

    .rt-thead.-header {
      background-color: ${({ theme }) => theme.colors.lightGray};
      box-shadow: none;
    }

    .rt-thead .rt-th.-sort-asc {
      box-shadow: inset 0 3px 0 0 ${({ theme }) => theme.colors.gray};
    }

    .rt-thead .rt-th.-sort-desc {
      box-shadow: inset 0 -3px 0 0 ${({ theme }) => theme.colors.gray};
    }

    .rt-thead.-filters input {
      font-size: 13px;
      transition: all .1s linear;

      &:focus {
        border-color: ${({ theme }) => theme.colors.accent};
        border-radius: 2px;
      }
    }

    .rt-tbody {
      background-color: ${({ theme }) => theme.colors.white};
    }

    .rt-tbody .rt-td {
      display: flex;
      min-width: 50px;
      text-align: left;
    }

    .rt-tbody .rt-tr-group,
    .rt-tbody .rt-tr-group:last-child {
      position: relative;
      flex: 0 0 0;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lighterGray};
    }

    .rt-tr-group:hover {
      background-color: ${({ theme }) => theme.colors.lighterGray};
      box-shadow: ${({ theme }) => theme.shadows.normalBox};
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
    }

    .rt-tbody .rt-td {
      position: relative;
      border-right: none;
    }

    .-pagination {
      justify-content: flex-start;
      box-shadow: none;
      border-top: 0;
    }

    .-pagination .-pageInfo,
    .-pagination .-pageJump input {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.gray};

      ${({ isSmaller }) => isSmaller && `
        font-size: 10px;
      `};
    }

    .-pagination .-pageJump input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    .-pagination .-pageJump input:focus {
      border-color: ${({ theme }) => theme.colors.accent};
    }

    .-pagination .-pageInfo {
      margin: 3px 20px;
    }

    .-pagination .-btn {
      width: auto;
      background: transparent;
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: .2pt;
      color: ${({ theme }) => theme.colors.gray};
      font-weight: 500;
      line-height: 1.3;

      ${({ isSmaller }) => isSmaller && `
        font-size: 9px;
      `};
    }

    .-pagination .-previous,
    .-pagination .-next,
    .-pagination .-center {
      flex: none;
    }

    .-pagination .-btn:not([disabled]):hover {
      background: transparent;
      color: ${({ theme }) => theme.colors.accent};
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
`;
