import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Login from './Login';

import {
  handleLogin,
  isLoggingInSelector,
  isMessageModalSelector,
  IStoreState,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  isLogging: isLoggingInSelector(state),
  isMessageModal: isMessageModalSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    login: handleLogin,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
