import React from 'react';

import { Box, Flex } from '@rebass/grid';

import styled from 'theme';

import {
  Container,
  EllipseLabel,
  Logo,
  Navbar,
  Text,
} from 'components';

import {
  IPermission,
  IProfile,
  THandleLogout,
} from 'store';

import UserDropdown from './UserDropdown';

const envName =  window.location.hostname.split('.')[0];

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  min-height: 50px;
  padding: 7px 0;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.normalBox};
  font-size: 13px;
  white-space: nowrap;
  z-index: 100;

  .user {
    position: relative;
  }
`;

interface IHeader {
  isActiveUser: boolean;
  logout: THandleLogout;
  permissions: Array<IPermission>;
  profile: IProfile;
}

const Header: React.FC<IHeader> = ({
  isActiveUser,
  logout,
  permissions,
  profile,
}) => (
  <Wrapper>
    <Container>
      <Flex
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            mr="15px"
            pt="5px"
          >
            <Logo />
          </Box>

          {permissions && (
            <Navbar permissions={permissions} />
          )}
        </Flex>

        <Flex
          alignItems="center"
          ml="50px"
          className="user"
        >
          <Box mr="7px">
            <EllipseLabel
              text={envName}
              // isGreen={envName === 'localhost'}
            />
          </Box>

          <Box mr="20px">
            <Text>{profile?.institutionName}</Text>
          </Box>

          <UserDropdown
            userFullName={profile?.fullName}
            isChangingPasswordAvailable={isActiveUser}
            logout={logout}
          />
        </Flex>
      </Flex>
    </Container>
  </Wrapper>
);

export default Header;
