import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { formValueSelector } from 'redux-form';

import { FormName } from 'consts';

import CustomerForm from './CustomerForm';

import {
  countriesOptionsSelector,
  currentCustomerSelector,
  dictionaryCustomerStatusesOptionsSelector,
  handleAddCustomer,
  handleGetDictionaryCountries,
  handleGetDictionaryCustomerStatuses,
  handleUpdateCustomer,
  isAddingCustomerSelector,
  isCountriesLoadingSelector,
  isLoadingCustomerStatusesSelector,
  IStoreState,
  isUpdatingCustomerSelector,
  userInstitutionsOptionsSelector,
} from 'store';

const formSelector = formValueSelector(FormName.CUSTOMER);

const mapStateToProps = (state: IStoreState) => ({
  countryCodes: countriesOptionsSelector(state),
  countryCodeValue: formSelector(state, 'addressCountryCode'),
  customerStatusOptions: dictionaryCustomerStatusesOptionsSelector(state),
  identificationTypeValue: formSelector(state, 'identificationType'),
  initialValues: currentCustomerSelector(state),
  institutionsOptions: userInstitutionsOptionsSelector(state),
  isAdding: isAddingCustomerSelector(state),
  isCountryCodesLoading: isCountriesLoadingSelector(state),
  isLoadingCustomerStatuses: isLoadingCustomerStatusesSelector(state),
  isUpdating: isUpdatingCustomerSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    addCustomer: handleAddCustomer,
    loadCountryCodes: handleGetDictionaryCountries,
    loadCustomerStatuses: handleGetDictionaryCustomerStatuses,
    updateCustomer: handleUpdateCustomer,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerForm);
