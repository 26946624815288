import React from 'react';

import { Modal } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';
import { InstitutionForm } from './../../forms';

import { IInstitutionDetails } from 'store';

interface IDetailsInstitutionModal extends IWithModal {
  currentInstitution: IInstitutionDetails;
  isFormDirty: boolean;
}

const modalName = ModalName.DETAILS_INSTITUTION;

const DetailsInstitutionModal: React.FC<IDetailsInstitutionModal> = ({
  closeModal,
  currentInstitution,
  isFormDirty,
  isReadOnlyPage,
}) => {
  return (
    <Modal
      name={modalName}
      title="Institution"
      containerWidth="600px"
      withCloseConfirmation={isFormDirty}
    >
      <InstitutionForm
        initialValues={currentInstitution}
        onCancel={() => closeModal(modalName)}
        isReadOnly={isReadOnlyPage}
        isEditMode={true}
      />
    </Modal>
  );
};

export default withModal(DetailsInstitutionModal);
