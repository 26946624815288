import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { ActionButtons, InputField, PasswordField } from 'components';
import { FormName } from 'consts';
import { THandleChangePassword } from 'store';
import { formErrorUtil } from 'utils';

interface IChangePasswordForm {
  changePassword: THandleChangePassword;
  onCancel: () => void;
}

type TChangePasswordForm = IChangePasswordForm & InjectedFormProps<{}, IChangePasswordForm>;

const ChangePasswordForm: React.FC<TChangePasswordForm> = ({
  handleSubmit,
  changePassword,
  onCancel,
  pristine,
}) => (
  <form onSubmit={handleSubmit(changePassword)}>
    <Field
      id="currentPassword"
      name="currentPassword"
      label="Current Password"
      placeholder="Enter Current Password"
      component={PasswordField}
      validate={[formErrorUtil.isRequired]}
    />
    <Field
      id="newPassword"
      name="newPassword"
      label="New Password"
      placeholder="Enter New Password"
      component={PasswordField}
      validate={[
        formErrorUtil.isRequired,
        formErrorUtil.passwordsDoNotMatch,
      ]}
    />
    <Field
      id="code"
      name="code"
      label="2nd Factor Code"
      placeholder="Enter 2nd Factor Code"
      component={InputField}
      validate={[
        formErrorUtil.exactNumberValue6,
      ]}
    />
    <ActionButtons
      disabledOk={pristine}
      okText="Save"
      onCancel={onCancel}
    />
  </form>
);

export default reduxForm<{}, IChangePasswordForm>({
  form: FormName.CHANGE_PASSWORD,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(ChangePasswordForm);
