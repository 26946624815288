import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { basePath } from 'consts';

interface IPrivateRoute {
  component: React.ElementType;
  exact?: boolean;
  path: string;
  isLoggedIn: boolean;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({
  component: Component,
  exact,
  isLoggedIn,
  ...rest
}) => (
  <Route
    {...rest}
    exact={exact}
    render={props => (
      isLoggedIn
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: `${basePath}login`,
            state: { from: props.location },
          }}
        />
    )}
  />
);

export default PrivateRoute;
