import React from 'react';
import { ImmutableArray } from 'seamless-immutable';

import { Box, Flex } from '@rebass/grid';

import { Button } from 'components';

import {
  IProductReward,
  THandleAddProductReward,
  THandleDeleteProductReward,
  THandleGetProductRewards,
  TResetPayments,
} from 'store';

import { ISelectValue } from 'types';

import RewardForm from './RewardForm';
import RewardsTable from './RewardsTable';

interface IRewards {
  addReward: THandleAddProductReward;
  applicationCondition: ISelectValue;
  deleteReward: THandleDeleteProductReward;
  getRewards: THandleGetProductRewards;
  isReadOnly: boolean;
  isAdding: boolean;
  isDeleting: boolean;
  isRewardsLoading: boolean;
  onCancel: () => void;
  resetPayments: TResetPayments;
  rewards: ImmutableArray<IProductReward>;
}

const Rewards: React.FC<IRewards> = ({
  addReward,
  applicationCondition,
  deleteReward,
  getRewards,
  isReadOnly,
  isAdding,
  isDeleting,
  isRewardsLoading,
  onCancel,
  resetPayments,
  rewards,
}) => {
  React.useEffect(
    () => {
      getRewards();
      return() =>  resetPayments();
    },
    [getRewards, resetPayments]
  );

  return (
    <>
      {!isReadOnly && (
        <RewardForm
          addReward={addReward}
          applicationCondition={applicationCondition}
          isDisabled={isAdding}
          isAdding={isAdding}
        />
      )}

      <Box mt="30px">
        <RewardsTable
          deleteReward={deleteReward}
          isLoading={isRewardsLoading}
          isReadOnly={isReadOnly}
          isDeleting={isDeleting}
          rewards={rewards}
        />
      </Box>

      <Flex
        justifyContent="flex-end"
        mt="15px"
      >
        <Button
          text="Close"
          onClick={onCancel}
        />
      </Flex>
    </>
  );
};

export default Rewards;
