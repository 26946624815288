import Immutable, { ImmutableObject } from 'seamless-immutable';

import { ActionTypeKeys, TAuthAction } from './actionTypes';
import { IProfileState } from './types';

export const profileInitialState: ImmutableObject<IProfileState> = Immutable({
  institutions: Immutable([]),
  permissions: Immutable([]),
  profile: null,
});

const profileReducer = (state = profileInitialState, action: TAuthAction) => {
  switch (action.type) {
    case ActionTypeKeys.GET_USER_INSTITUTIONS_FULFILLED:
      return state.set('institutions', action.payload.institutions);

    case ActionTypeKeys.GET_PERMISSIONS_FULFILLED:
      return state.set('permissions', action.payload.permissions);

    case ActionTypeKeys.GET_PROFILE_FULFILLED:
      return state.set('profile', action.payload.profile);

    default: return state;
  }
};

export default profileReducer;
