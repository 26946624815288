import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import LoanIllustration from './LoanIllustration';

import {
  handleIllustrateLoan,
  isLoanIllustrationLoadingSelector,
  IStoreState,
  loanIllustrationGeneralSelector,
  loanIllustrationScheduleSelector,
  productsOptionsSelector,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  isIllustrationLoading: isLoanIllustrationLoadingSelector(state),
  loanIllustrationProduct: loanIllustrationGeneralSelector(state),
  loanIllustrationSchedule: loanIllustrationScheduleSelector(state),
  productsOptions: productsOptionsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    illustrateLoanProduct: handleIllustrateLoan,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanIllustration);
