import Immutable, * as seamlessImmutable from 'seamless-immutable';

import { ActionTypeKeys, TApiCallsAction } from './actionTypes';
import { IApiCallsState } from './types';

export const apiCallsInitialState: seamlessImmutable.ImmutableObject<IApiCallsState> = Immutable({
  apiCalls: Immutable([]),
  currentApiCall: null,
  pageNumber: null,
  totalPages: null,
  totalResults: null,
});

const apiCallsReducer = (state = apiCallsInitialState, action: TApiCallsAction) => {
  switch (action.type) {
    case ActionTypeKeys.FILTER_API_CALLS_FULFILLED:
      return state
        .set('apiCalls', action.payload.api_calls)
        .set('pageNumber', action.payload.page_number)
        .set('totalPages', action.payload.total_pages)
        .set('totalResults', action.payload.total_results);

    case ActionTypeKeys.GET_API_CALL_FULFILLED:
      return state.set('currentApiCall', action.payload.api_call);

    case ActionTypeKeys.RESET_API_CALLS:
      return state = apiCallsInitialState;

    default:
      return state;
  }
};

export default apiCallsReducer;
