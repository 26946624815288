import { ModalName } from 'consts';
import {
  activeItemIdSelector,
  closeModal,
  ISysProp,
  ISysPropData,
  ISysPropsFilter,
  openModal,
} from 'store';
import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import * as api from './api';

import {
  ActionTypeKeys,
  IAddSysPropAction,
  IDeleteSysPropAction,
  IFilterSysPropsAction,
  IUpdateSysPropAction,
} from './actionTypes';

import {
  normalizeFilterQueryString,
  normalizeSystemPropertyForSending,
} from './utils';

/**
 * Filter system properties action
 */

export type TFilterSysProps = (queryString: string) => IFilterSysPropsAction;
export type THandleFilterSysProps = (data: ISysPropsFilter) => Thunk<void>;

export const filterSysProps: TFilterSysProps = queryString => ({
  type: ActionTypeKeys.FILTER_SYS_PROPS,
  payload: api.filterSysProps(queryString),
});

export const handleFilterSysProps: THandleFilterSysProps = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterQueryString(data);

        await dispatch(filterSysProps(queryString));
      },
      dispatch
    );
  };

/**
 * Add system property action
 */

export type TAddSysProp = (data: Partial<ISysPropData>) => IAddSysPropAction;
export type THandleAddSysProp = (data: Partial<ISysProp>) => Thunk<void>;

export const addSysProp: TAddSysProp = data => ({
  type: ActionTypeKeys.ADD_SYS_PROP,
  payload: api.addSysProp(data),
});

export const handleAddSysProp: THandleAddSysProp = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const normalizedData = normalizeSystemPropertyForSending(data);

        const res = (await dispatch(addSysProp(normalizedData))) as any;

        dispatch(closeModal(ModalName.ADD_SYSTEM_PROPERTY));

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: {
              title: `System property "${res.value.system_property_name}" has been created`,
              isSuccess: true,
            },
          })
        );
      },
      dispatch
    );
  };

/**
 * Delete system property action
 */

export type TDeleteSysProp = (sysPropName: string | number) => IDeleteSysPropAction;
export type THandleDeleteSysProp = () => Thunk<void>;

export const deleteSysProp: TDeleteSysProp = sysPropName => ({
  type: ActionTypeKeys.DELETE_SYS_PROP,
  payload: api.deleteSysProp(sysPropName),
  meta: { sysPropName },
});

export const handleDeleteSysProp: THandleDeleteSysProp = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const sysPropName = activeItemIdSelector(state);

        await dispatch(deleteSysProp(sysPropName));
        dispatch(closeModal(ModalName.DETAILS_SYSTEM_PROPERTY));

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: {
              title: `System property "${sysPropName}" has been deleted`,
              isSuccess: true,
            },
          })
        );
      },
      dispatch
    );
  };

/**
 * Update system property action
 */

export type TUpdateSysProp = (data: Partial<ISysPropData>, sysPropName: number | string) =>
  IUpdateSysPropAction;

export type THandleUpdateSysProp = (data: Partial<ISysProp>) => Thunk<void>;

export const updateSysProp: TUpdateSysProp = (data, sysPropName) => ({
  type: ActionTypeKeys.UPDATE_SYS_PROP,
  payload: api.updateSysProp(data, sysPropName),
});

export const handleUpdateSysProp: THandleUpdateSysProp = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const sysPropName = activeItemIdSelector(state);

        const normalizedData = normalizeSystemPropertyForSending(data);

        await dispatch(updateSysProp(normalizedData, sysPropName));
        dispatch(closeModal(ModalName.DETAILS_SYSTEM_PROPERTY));

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: {
              title: `System property "${sysPropName}" has been updated`,
              isSuccess: true,
            },
          })
        );
      },
      dispatch
    );
  };

/**
 * Reset system properties action
 */

export type TResetSystemProperties = () => void;

export const resetSystemProperties: TResetSystemProperties = () => ({
  type: ActionTypeKeys.RESET_SYSTEM_PROPERTIES,
});
