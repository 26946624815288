import React from 'react';

import { Flex } from '@rebass/grid';

import { Button, Modal } from 'components';
import { ModalName } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import { IApiCall, THandleGetApiCall } from 'store';

import { ApiCallForm } from './../../forms';

interface IApiCallModal extends IWithModal {
  apiCall: IApiCall;
  getApiCall: THandleGetApiCall;
  isLoading: boolean;
}

const modalName = ModalName.API_CALL;

const ApiCallModal: React.FC<IApiCallModal> = ({
  apiCall,
  closeModal,
  getApiCall,
  isLoading,
}) => {
  React.useEffect(
    () => {
      getApiCall();
    },
    [getApiCall]
  );

  return (
    <Modal
      name={modalName}
      title="API call"
      containerWidth="1000px"
      isLoading={isLoading}
    >
      <ApiCallForm initialValues={apiCall} />

      <Flex
        justifyContent="flex-end"
        mt="10px"
      >
        <Button
          text="Close"
          onClick={() => closeModal(modalName)}
        />
      </Flex>
    </Modal>
  );
};

export default withModal(ApiCallModal);
