import Immutable, { ImmutableObject } from 'seamless-immutable';

import { ActionTypeKeys, TTransactionsAction } from './actionTypes';
import { ITransactionsState } from './types';

export const transactionsInitialState: ImmutableObject<ITransactionsState> = Immutable({
  pageNumber: null,
  totalPages: null,
  totalResults: null,
  transactions: Immutable([]),
  currentTransaction: null,
  settlementResult: null,
});

const transactionsReducer = (state = transactionsInitialState, action: TTransactionsAction) => {
  switch (action.type) {
    case ActionTypeKeys.FILTER_TRANSACTIONS_FULFILLED:
      return state
        .set('transactions', action.payload.transactions)
        .set('pageNumber', action.payload.page_number)
        .set('totalPages', action.payload.total_pages)
        .set('totalResults', action.payload.total_results);

    case ActionTypeKeys.GET_TRANSACTION_FULFILLED:
      return state.set('currentTransaction', action.payload.transaction);

    case ActionTypeKeys.SETTLE_TRANSACTION_FULFILLED:
      return state.set('settlementResult', action.payload.transaction);

    case ActionTypeKeys.RESET_TRANSACTIONS:
      return state = transactionsInitialState;

    case ActionTypeKeys.RESET_TRANSACTION:
      return state.set('currentTransaction', null);

    case ActionTypeKeys.RESET_SETTLE_TRANSACTION_RESULT:
      return state.set('settlementResult', null);

    default: return state;
  }
};

export default transactionsReducer;
