import { ProductTypeIcons, TableCell, TableHeader } from 'components';

import { ICommonAccount } from 'store';

import { ITableCell } from 'types';

type TCell<T extends keyof ICommonAccount> = ITableCell<ICommonAccount[T]>;

export const tableColumns = [
  {
    maxWidth: 100,
    Header: <TableHeader title="Account ID" />,
    accessor: 'id',
    Cell: (props: TCell<'id'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 100,
    Header: <TableHeader title="Customer ID" />,
    accessor: 'customerId',
    Cell: (props: TCell<'customerId'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 200,
    Header: <TableHeader title="First Name" />,
    accessor: 'firstName',
    Cell: (props: TCell<'firstName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 200,
    Header: <TableHeader title="Last Name" />,
    accessor: 'lastName',
    Cell: (props: TCell<'lastName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 140,
    Header: <TableHeader title="Product Type" />,
    accessor: 'productTypeName',
    Cell: (props: TCell<'productTypeName'>) => (
      <TableCell
        value={props.value}
        Icon={ProductTypeIcons[props.original.productType]}
      />
    ),
  },
  {
    maxWidth: 300,
    Header: <TableHeader title="Product Name" />,
    accessor: 'productName',
    Cell: (props: TCell<'productName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 120,
    Header: <TableHeader title="Balance Limit" />,
    accessor: 'balanceLimit',
    Cell: (props: TCell<'balanceLimit'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 120,
    Header: <TableHeader title="Repayment Status" />,
    accessor: 'repaymentStatusName',
    Cell: (props: TCell<'repaymentStatusName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 100,
    Header: <TableHeader title="Status" />,
    accessor: 'statusName',
    Cell: (props: TCell<'statusName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 100,
    Header: <TableHeader title="Date Created" />,
    accessor: 'dateCreated',
    Cell: (props: TCell<'dateCreated'>) => (
      <TableCell
        value={props.value}
        isDate={true}
      />
    ),
  },
  {
    maxWidth: 100,
    Header: <TableHeader title="Date Closed" />,
    accessor: 'dateClosed',
    Cell: (props: TCell<'dateClosed'>) => (
      <TableCell
        value={props.value}
        isDate={true}
      />
    ),
  },
];
