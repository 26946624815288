import React from 'react';
import { Field } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import { InputField, NewLine, SelectField } from 'components';
import { productTypesOptions } from 'consts';
import { formErrorUtil } from 'utils';

interface IAccountsFilter {
  isDisabled: boolean;
}

const AccountsFilter: React.FC<IAccountsFilter> = ({
  isDisabled,
}) => {
  return (
    <Flex alignItems="flex-start" flexWrap="wrap">
      <Box width="150px" p="8px">
        <Field
          id="accountId"
          name="accountId"
          component={InputField}
          label="Account ID"
          placeholder="ID"
          isNumber={true}
          disabled={isDisabled}
          validate={[
            formErrorUtil.isInteger,
            formErrorUtil.isStrictPositive,
          ]}
        />
      </Box>
      <Box width="200px" p="8px">
        <Field
          id="productType"
          name="productType"
          component={SelectField}
          label="Product Type"
          placeholder="Select Type"
          options={productTypesOptions}
          isDisabled={isDisabled}
          isRequired={true}
          validate={[
            formErrorUtil.isRequired,
          ]}
        />
      </Box>
      <Box width="360px" p="8px">
        <Field
          id="firstName"
          name="firstName"
          component={InputField}
          label="First Name"
          placeholder="Enter First Name"
          disabled={isDisabled}
        />
      </Box>

      <NewLine />

      <Box width="150px" p="8px">
        <Field
          id="customerId"
          name="customerId"
          component={InputField}
          label="Customer ID"
          placeholder="ID"
          isNumber={true}
          disabled={isDisabled}
          validate={[
            formErrorUtil.isInteger,
            formErrorUtil.isStrictPositive,
          ]}
        />
      </Box>
      <Box width="200px" p="8px">
        <Field
          id="accountReference"
          name="accountReference"
          component={InputField}
          label="Account Reference"
          placeholder="Reference"
          disabled={isDisabled}
        />
      </Box>
      <Box width="360px" p="8px">
        <Field
          id="lastName"
          name="lastName"
          component={InputField}
          label="Last Name"
          placeholder="Enter Last Name"
          disabled={isDisabled}
        />
      </Box>
    </Flex>
  );
};

export default AccountsFilter;
