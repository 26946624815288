import React from 'react';
import styled, { theme } from 'theme';

import { CheckIcon } from 'components/Icons';

const eventsColors = {
  pending: theme.colors.gray,
  submitted: theme.colors.accent,
  verified: theme.colors.green,
  completed: theme.colors.green,
  revoked: theme.colors.red,
  disputed: theme.colors.red,
  failed: theme.colors.red,
  default: theme.colors.lightGray,
};

const renderColor = (event: string) => {
  const eventColor = eventsColors[event.toLocaleLowerCase()];
  return eventColor || eventsColors.default;
};

interface ITimelineWrapper {
  event?: string;
}

const TimelineWrapper = styled.div<ITimelineWrapper>`
  display: flex;
  align-items: center;

  .date {
    font-size: 12px;
  }

  .event {
    font-size: 13px;
    font-weight: 500;
  }

  .circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 10px;
    color: ${theme.colors.white};
    border-radius: 50%;
    background-color: ${({ event }) => renderColor(event)};
    box-shadow: ${theme.shadows.aroundBox};
  }

  &:not(:first-child) {
    margin-top: 30px;

    .circle:before {
      content: "";
      width: 1px;
      height: 26px;
      background: ${eventsColors.default};
      display: block;
      position: absolute;
      bottom: calc(100% + 2px);
      left: 50%;
      margin-left: -1px;
    }
  }
`;

interface ITimeline {
  items: Array<{
    date: string;
    event: string;
  }>;
}

const Timeline: React.FC<ITimeline> = ({ items }) => (
  <div>
    {items?.map((item, index) => (
      <TimelineWrapper
        event={item.event}
        key={index}
      >
        <div className="date">{item.date}</div>
        <div className="circle" >
          <CheckIcon size="14" />
        </div>
        <div className="event">{item.event}</div>
      </TimelineWrapper>
    ))}
  </div>
);

export default Timeline;
