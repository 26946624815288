import { ProductType } from 'consts';
import { apiClientService } from 'services';
import { IApiCallConfig } from 'types';
// import { throttleUtil } from 'utils';

// import {
//   loanStatementMock,
//   revolvingCreditStatementMock,
//   statementAprsMock,
//   statementsMock,
// } from './mock';

/**
 * Filter statements API
 */
export const filterStatements = (queryString: string, productType: string | number) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/statements?${queryString}`,
      // mock: statementsMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/statements?${queryString}`,
      // mock: statementsMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Get statement details API
 */
export const getStatement = (statementId: number, productType: string) => {
  const config: { [key in ProductType]: IApiCallConfig } = {
    [ProductType.LOAN]: {
      path: `il/statements/${statementId}`,
      // mock: loanStatementMock,
    },
    [ProductType.REVOLVING_CREDIT]: {
      path: `rc/statements/${statementId}`,
      // mock: revolvingCreditStatementMock,
    },
  };

  // const { mock } = config[productType];
  // return throttleUtil.getDataAfter(mock, 500);

  const { path } = config[productType];
  return apiClientService.get(path);
};

/**
 * Get statement APRs API
 */

export const getStatementAprs = (statementId: number) =>
  // throttleUtil.getDataAfter(statementAprsMock, 500);
  apiClientService.get(`rc/statements-aprs?statement_id=${statementId}`);
