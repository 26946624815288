import { UserStatus } from 'consts';
import { createSelector } from 'reselect';
import { IStoreState } from 'store';

import { createLoadingSelector } from './../loader';
import { ActionTypeKeys } from './actionTypes';
import { normalizePermissions, normalizeProfile } from './utils';

/**
 * Profile selectors
 */

export const defaultProfileSelector = (state: IStoreState) => state.profile.profile;

export const profileSelector = createSelector(
  defaultProfileSelector,
  data => normalizeProfile(data)
);

export const isLoadedProfileSelector = createSelector(
  defaultProfileSelector,
  data => data?.username
);

export const isAuthRegistrationPendingSelector = createSelector(
  defaultProfileSelector,
  data => data?.status === UserStatus.REGISTRATION_PENDING
);

export const isActiveUserSelector = createSelector(
  defaultProfileSelector,
  data => data?.status === UserStatus.ACTIVE
);

export const isProfileLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_PROFILE,
]);

/**
 * Permissions selectors
 */

export const defaultPermissionsSelector = (state: IStoreState) => state.profile.permissions;

export const permissionsSelector = createSelector(
  defaultPermissionsSelector,
  data => normalizePermissions(data)
);

export const isLoadedPermissionsSelector = createSelector(
  defaultPermissionsSelector,
  data => data?.length
);

export const visiblePermissionsListSelector = createSelector(
  permissionsSelector,
  data => data?.map(el => el.id)
);

export const isPermissionsLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_PERMISSIONS,
]);

/**
 * User institutions selectors
 */

export const defaultUserInstitutionsSelector = (state: IStoreState) => state.profile.institutions;

export const userInstitutionsSelector = createSelector(
  defaultUserInstitutionsSelector,
  institutions => institutions?.map(institution => {
    return {
      id: institution.id,
      name: institution.name,
    };
  })
);

export const userInstitutionsOptionsSelector = createSelector(
  defaultUserInstitutionsSelector,
  data => data?.asMutable().map(el => {
    return {
      value: el.id,
      label: el.name,
    };
  })
);

export const isUserInstitutionsLoadedSelector = createSelector(
  defaultUserInstitutionsSelector,
  data => data?.length > 0
);

export const isUserInstitutionsLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_USER_INSTITUTIONS,
]);

/**
 * Change password selectors
 */

export const isChangingPasswordSelector = createLoadingSelector([
  ActionTypeKeys.CHANGE_PASSWORD,
]);
