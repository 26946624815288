import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import ManualTransactionModal from './ManualTransactionResultModal';

import {
  IStoreState,
  manualTransactionResultSelector,
  resetTransactionResult,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  manualTransactionResult: manualTransactionResultSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetTransactionResult,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualTransactionModal);
