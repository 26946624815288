// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
// import { throttleUtil } from 'utils';

// import { usersActivityMock } from './mock';

/**
 * Filter API calls API
 */
export const filterUsersActivity = (queryString: string) =>
  // throttleUtil.getDataAfter(apiCallsMock, 500);
  apiClientService.get(`users-activities?${queryString}`);
