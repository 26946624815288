import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import { HighlightCodeField, InputField } from 'components';
import { FormName } from 'consts';

interface IApiCallForm { }

type TApiCallForm = IApiCallForm & InjectedFormProps<{}, IApiCallForm>;

const ApiCallForm: React.FC<TApiCallForm> = () => (
  <form>
    <Flex
      alignItems="flex-end"
      flexWrap="wrap"
      mx="-8px"
    >
      <Box width={[1 / 6]} p="8px">
        <Field
          id="id"
          name="id"
          component={InputField}
          label="ID"
          placeholder="-"
          isNumber={true}
          disabled={true}
        />
      </Box>
      <Box width={[1 / 6]} p="8px">
        <Field
          id="eventDatetime"
          name="eventDatetime"
          component={InputField}
          label="Event Datetime"
          placeholder="-"
          disabled={true}
        />
      </Box>
      <Box width={[1 / 6]} p="8px">
        <Field
          id="endpointId"
          name="endpointId"
          component={InputField}
          label="Endpoint ID"
          placeholder="-"
          isNumber={true}
          disabled={true}
        />
      </Box>
      <Box width={[1 / 2]} p="8px">
        <Field
          id="endpointName"
          name="endpointName"
          component={InputField}
          label="Endpoint Name"
          placeholder="-"
          disabled={true}
        />
      </Box>
      <Box width={[1 / 2]} p="8px">
        <Field
          id="apiName"
          name="apiName"
          component={InputField}
          label="API Name"
          placeholder="-"
          disabled={true}
        />
      </Box>
      <Box width={[1 / 2]} p="8px">
        <Field
          id="institutionName"
          name="institutionName"
          component={InputField}
          label="Institution"
          placeholder="-"
          disabled={true}
        />
      </Box>
      <Box width={[1]}>
        <Flex alignItems="flex-start">
          <Box width={[1 / 2]} p="8px">
            <Field
              id="requestBody"
              name="requestBody"
              component={HighlightCodeField}
              label="Request Body"
              placeholder="-"
              fontSize={12}
              disabled={true}
            />
          </Box>
          <Box width={[1 / 2]} p="8px">
            <Field
              id="responseBody"
              name="responseBody"
              component={HighlightCodeField}
              label="Response Body"
              placeholder="-"
              fontSize={12}
              whiteSpacePre={false}
              disabled={true}
            />
          </Box>
        </Flex>
      </Box>
    </Flex>
  </form>
);

export default reduxForm<{}, IApiCallForm>({
  form: FormName.API_CALL,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(ApiCallForm);
