import { IResponseStatus, TApiResponse } from 'types';
import { IAuthKeyResp, ILoginResponse } from './types';

export enum ActionTypeKeys {
  LOGIN = 'login/LOGIN',
  LOGIN_FULFILLED = 'login/LOGIN_FULFILLED',
  LOGIN_REJECTED = 'login/LOGIN_REJECTED',

  LOGOUT = 'login/LOGOUT',
  LOGOUT_FULFILLED = 'login/LOGOUT_FULFILLED',
  LOGOUT_REJECTED = 'login/LOGOUT_REJECTED',

  SET_CURRENT_REGISTER_STEP = 'login/SET_CURRENT_REGISTER_STEP',

  GENERATE_AUTH_KEY = 'login/GENERATE_AUTH_KEY',
  GENERATE_AUTH_KEY_FULFILLED = 'login/GENERATE_AUTH_KEY_FULFILLED',
  GENERATE_AUTH_KEY_REJECTED = 'login/GENERATE_AUTH_KEY_REJECTED',

  CONFIRM_AUTH_KEY = 'login/CONFIRM_AUTH_KEY',
  CONFIRM_AUTH_KEY_FULFILLED = 'login/CONFIRM_AUTH_KEY_FULFILLED',
  CONFIRM_AUTH_KEY_REJECTED = 'login/CONFIRM_AUTH_KEY_REJECTED',

  PROVIDE_AUTH_KEY = 'login/PROVIDE_AUTH_KEY',
  PROVIDE_AUTH_KEY_FULFILLED = 'login/PROVIDE_AUTH_KEY_FULFILLED',
  PROVIDE_AUTH_KEY_REJECTED = 'login/PROVIDE_AUTH_KEY_REJECTED',
}

/** Login action interfaces */

export interface ILoginAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.LOGIN;
}

export interface ILoginFulfilledAction {
  readonly payload: ILoginResponse;
  readonly type: ActionTypeKeys.LOGIN_FULFILLED;
}

export interface ILoginRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.LOGIN_REJECTED;
}

/** Logout action interfaces */

export interface ILogoutAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.LOGOUT;
}

export interface ILogoutFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.LOGOUT_FULFILLED;
}

export interface ILogoutRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.LOGOUT_REJECTED;
}

/** Set current 2FA register step action interfaces */

export interface ISetCurrentAuthRegistrationStepAction {
  readonly payload: number;
  readonly type: ActionTypeKeys.SET_CURRENT_REGISTER_STEP;
}

/** Generate auth key action interfaces */

export interface IGenerateAuthKeyAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GENERATE_AUTH_KEY;
}

export interface IGenerateAuthKeyFulfilledAction {
  readonly payload: IAuthKeyResp;
  readonly type: ActionTypeKeys.GENERATE_AUTH_KEY_FULFILLED;
}

export interface IGenerateAuthKeyRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GENERATE_AUTH_KEY_REJECTED;
}

/** Confirm auth key action interfaces */

export interface IConfirmAuthKeyAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.CONFIRM_AUTH_KEY;
}

export interface IConfirmAuthKeyFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.CONFIRM_AUTH_KEY_FULFILLED;
}

export interface IConfirmAuthKeyRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.CONFIRM_AUTH_KEY_REJECTED;
}

/** Provide auth key action interfaces */

export interface IProvideAuthKeyAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.PROVIDE_AUTH_KEY;
}

export interface IProvideAuthKeyFulfilledAction {
  readonly payload: ILoginResponse;
  readonly type: ActionTypeKeys.PROVIDE_AUTH_KEY_FULFILLED;
}

export interface IProvideAuthKeyRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.PROVIDE_AUTH_KEY_REJECTED;
}

export type TAuthAction =
  | ILoginFulfilledAction
  | ILogoutFulfilledAction
  | IGenerateAuthKeyFulfilledAction
  | ISetCurrentAuthRegistrationStepAction
  | IConfirmAuthKeyFulfilledAction
  | IProvideAuthKeyFulfilledAction;
