import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { isDirty } from 'redux-form';

import { FormName } from 'consts';

import DetailsEndpointModal from './DetailsEndpointModal';

import {
  currentEndpointSelector,
  handleGetEndpoint,
  isGettingEndpointSelector,
  IStoreState,
} from 'store';

const dirty = isDirty(FormName.ENDPOINT);

const mapStateToProps = (state: IStoreState) => ({
  currentEndpoint: currentEndpointSelector(state),
  isFormDirty: dirty(state),
  isLoading: isGettingEndpointSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getEndpoint: handleGetEndpoint,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsEndpointModal);
