import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';
import {
  Button,
  MaskField,
  NumberFormatField,
} from 'components';
import {
  DateFormat,
  FormName,
  MaskFormat,
} from 'consts';

import { THandleIllustrateLoan } from 'store';

import { formErrorUtil } from 'utils';

interface ILoanIllustrationForm {
  illustrateLoanProduct: THandleIllustrateLoan;
  isIllustrationLoading: boolean;
  mode: 'product' | 'account' | 'transaction';
}

type TLoanIllustrationForm = ILoanIllustrationForm & InjectedFormProps<{}, ILoanIllustrationForm>;

const LoanIllustrationForm: React.FC<TLoanIllustrationForm> = ({
  handleSubmit,
  illustrateLoanProduct,
  isIllustrationLoading,
  mode,
}) => {
  // eslint-disable-next-line
  const handleSubmitFormIllustrate = React.useCallback(
    handleSubmit(illustrateLoanProduct),
    [handleSubmit, illustrateLoanProduct]
  );

  return (
    <form onSubmit={handleSubmitFormIllustrate}>
      <Flex
        alignItems="flex-end"
        flexWrap="wrap"
        width="100%"
        mx="-8px"
      >
        <Box width="120px" p="8px">
          <Field
            id="startDate"
            name="startDate"
            component={MaskField}
            label="Disbursement Date"
            placeholder={DateFormat.DATE}
            mask={MaskFormat.DATE}
            disabled={isIllustrationLoading}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>
        <Box width="120px" p="8px">
          <Field
            id="numberOfInstalments"
            name="numberOfInstalments"
            component={NumberFormatField}
            placeholder="0"
            fixedDecimalScale={true}
            decimalScale={0}
            label="Number of Instalments"
            disabled={isIllustrationLoading}
            validate={[
              formErrorUtil.isRequired,
              formErrorUtil.isStrictPositive,
            ]}
          />
        </Box>
        <Box width="120px" p="8px">
          <Field
            id="principal"
            name="principal"
            component={NumberFormatField}
            label="Loan Amount"
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            autoFocus={mode === 'product'}
            disabled={isIllustrationLoading}
            validate={[
              formErrorUtil.isRequired,
              formErrorUtil.isStrictPositive,
            ]}
          />
        </Box>
        <Box width="120px" p="8px">
          <Field
            id="apr"
            name="apr"
            component={NumberFormatField}
            label="APR"
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            disabled={isIllustrationLoading}
            validate={[
              formErrorUtil.isRequired,
              formErrorUtil.isPositive,
            ]}
          />
        </Box>
        <Box p="8px">
          <Button
            text="Illustrate"
            isLoading={isIllustrationLoading}
            disabled={isIllustrationLoading}
            onClick={handleSubmitFormIllustrate}
          />
          </Box>
          {mode === 'transaction' && (
            <Box p="8px 0">
              <Button
                text="Convert"
                isLoading={false}
                disabled={true}
                onClick={() => console.log('--- Convert to loan')}
              />
            </Box>
          )}
      </Flex>
    </form>
  );
};

export default reduxForm<{}, ILoanIllustrationForm>({
  form: FormName.PRODUCT_LOAN_ILLUSTRATION,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(LoanIllustrationForm);
