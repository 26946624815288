import { IResponseStatus, TApiResponse } from 'types';

import { IAccountDataResp, ICommonAccountsData } from './types';
import { IManualTransactionResponse } from './typesManualTransaction';

export enum ActionTypeKeys {
  ADD_ACCOUNT = 'accounts/ADD_ACCOUNT',
  ADD_ACCOUNT_FULFILLED = 'accounts/ADD_ACCOUNT_FULFILLED',
  ADD_ACCOUNT_REJECTED = 'accounts/ADD_ACCOUNT_REJECTED',

  UPDATE_ACCOUNT = 'accounts/UPDATE_ACCOUNT',
  UPDATE_ACCOUNT_FULFILLED = 'accounts/UPDATE_ACCOUNT_FULFILLED',
  UPDATE_ACCOUNT_REJECTED = 'accounts/UPDATE_ACCOUNT_REJECTED',

  GET_ACCOUNT = 'accounts/GET_ACCOUNT',
  GET_ACCOUNT_FULFILLED = 'accounts/GET_ACCOUNT_FULFILLED',
  GET_ACCOUNT_REJECTED = 'accounts/GET_ACCOUNT_REJECTED',

  FILTER_ACCOUNTS = 'accounts/FILTER_ACCOUNTS',
  FILTER_ACCOUNTS_FULFILLED = 'accounts/FILTER_ACCOUNTS_FULFILLED',
  FILTER_ACCOUNTS_REJECTED = 'accounts/FILTER_ACCOUNTS_REJECTED',

  RESET_ACCOUNTS = 'accounts/RESET_ACCOUNTS',

  RESET_ACCOUNT = 'accounts/RESET_ACCOUNT',

  MAKE_TRANSACTION = 'accounts/MAKE_TRANSACTION',
  MAKE_TRANSACTION_FULFILLED = 'accounts/MAKE_TRANSACTION_FULFILLED',
  MAKE_TRANSACTION_REJECTED = 'accounts/MAKE_TRANSACTION_REJECTED',

  RESET_TRANSACTION_RESULT = 'account/RESET_TRANSACTION_RESULT',
}

/** Add account action interfaces */

export interface IAddAccountAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ADD_ACCOUNT;
}

export interface IAddAccountFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.ADD_ACCOUNT_FULFILLED;
}

export interface IAddAccountRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.ADD_ACCOUNT_REJECTED;
}

/** Update account action interfaces */

export interface IUpdateAccountAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.UPDATE_ACCOUNT;
}

export interface IUpdateAccountFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.UPDATE_ACCOUNT_FULFILLED;
}

export interface IUpdateAccountRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.UPDATE_ACCOUNT_REJECTED;
}

/** Get account action interfaces */

export interface IGetAccountAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_ACCOUNT;
}

export interface IGetAccountFulfilledAction {
  readonly payload: IAccountDataResp;
  readonly type: ActionTypeKeys.GET_ACCOUNT_FULFILLED;
}

export interface IGetAccountRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_ACCOUNT_REJECTED;
}

/** Filter accounts action interfaces */

export interface IFilterAccountsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_ACCOUNTS;
}

export interface IFilterAccountsFulfilledAction {
  readonly payload: ICommonAccountsData;
  readonly type: ActionTypeKeys.FILTER_ACCOUNTS_FULFILLED;
}

export interface IFilterAccountsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_ACCOUNTS_REJECTED;
}

/** Reset accounts action interface */

export interface IResetAccountsAction {
  readonly type: ActionTypeKeys.RESET_ACCOUNTS;
}

/** Reset account action interface */

export interface IResetAccountAction {
  readonly type: ActionTypeKeys.RESET_ACCOUNT;
}

/**
 * Make manual transaction action interfaces
 */

export interface IMakeTransactionAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.MAKE_TRANSACTION;
}

export interface IMakeTransactionFulfilledAction {
  readonly payload: IManualTransactionResponse;
  readonly type: ActionTypeKeys.MAKE_TRANSACTION_FULFILLED;
}

export interface IMakeTransactionRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.MAKE_TRANSACTION_REJECTED;
}

/** Reset transaction result interface */

export interface IResetTransactionResultAction {
  readonly type: ActionTypeKeys.RESET_TRANSACTION_RESULT;
}

export type TAccountsAction =
  | IUpdateAccountFulfilledAction
  | IGetAccountFulfilledAction
  | IAddAccountFulfilledAction
  | IFilterAccountsFulfilledAction
  | IResetAccountsAction
  | IResetAccountAction
  | IMakeTransactionFulfilledAction
  | IResetTransactionResultAction;
