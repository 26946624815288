import React from 'react';

import { Modal } from 'components';

import { ModalName } from 'consts';

import { CodeScreen } from './components';
import { PasswordForm } from './forms';

import {
  THandleConfirmAuthKey,
  TSetCurrentAuthRegistrationStep,
} from 'store';

interface IRegister2faModal {
  code: string;
  confirmAuthKey: THandleConfirmAuthKey;
  currentStep: number;
  dataUrl: string;
  setCurrentAuthRegistrationStep: TSetCurrentAuthRegistrationStep;
}

const modalName = ModalName.REGISTER_2FA;

const Register2faModal: React.FC<IRegister2faModal> = ({
  code,
  confirmAuthKey,
  currentStep,
  dataUrl,
  setCurrentAuthRegistrationStep,
}) => {
  React.useEffect(
    () => {
      setCurrentAuthRegistrationStep(1);
      return () => setCurrentAuthRegistrationStep(null);
    },
    [setCurrentAuthRegistrationStep]
  );

  const isSecondStep = React.useMemo(
    () => currentStep === 2,
    [currentStep]
  );

  return (
    <Modal
      name={modalName}
      title={isSecondStep ? '2FA registration' : 'Password'}
      containerWidth={isSecondStep ? '500px' : '300px'}
    >
      {isSecondStep
        ? (
          <CodeScreen
            onConfirm={confirmAuthKey}
            onRegenerate={() => setCurrentAuthRegistrationStep(1)}
            code={code}
            dataUrl={dataUrl}
          />
        )
        : (
          <PasswordForm />
        )}
    </Modal>
  );
};

export default Register2faModal;
