import React, { useMemo } from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';
import { FormName, productRulesComments } from 'consts';

import {
  ActionButtons,
  HighlightCodeField,
} from 'components';
import {
  IProductRule,
  THandleAddProductRule,
  THandleDeleteProductRule,
  THandleUpdateProductRule,
  TResetProductRule,
} from 'store';
import styled from 'theme';

const FormWrapper = styled.form`
  width: 100%;
`;

interface IRuleForm {
  currentProductRule: Partial<IProductRule>;
  isReadOnly: boolean;
  isRuleLoading: boolean;
  isRuleUpdating: boolean;
  productType: string | number;
  addRule: THandleAddProductRule;
  deleteRule: THandleDeleteProductRule;
  onCancel: () => void;
  updateRule: THandleUpdateProductRule;
  resetRule: TResetProductRule;
}

type TRuleForm = IRuleForm & InjectedFormProps<{}, IRuleForm>;

const RuleForm: React.FC<TRuleForm> = ({
  currentProductRule,
  dirty,
  isReadOnly,
  isRuleUpdating,
  productType,
  addRule,
  deleteRule,
  handleSubmit,
  onCancel,
  updateRule,
  resetRule,
  change,
}) => {
  React.useEffect(
    () => {
      if (!currentProductRule.script) {
        change(
          'script',
          currentProductRule.script
            || productRulesComments[`${productType}_${currentProductRule?.actionType?.value}_${currentProductRule?.eventId?.value}`]
        );
      }
    },
    [change, currentProductRule, productType]
  );

  const scriptLabel = useMemo(
    () => {
      return currentProductRule?.eventId && currentProductRule?.actionType
        ? {
          label: `Script (event: ${currentProductRule?.eventId?.label}, action type: ${currentProductRule?.actionType?.label})`,
          hint: '',
          isAccentColor: true,
        }
        : {
          label: 'Script',
          hint: 'Select event and action type to retrieve the script',
          isAccentColor: false,
        };
    },
    [currentProductRule]
  );

    // eslint-disable-next-line
  const handleSubmitForm = React.useCallback(
    handleSubmit((data: Partial<IProductRule>) => {
      const script = data?.script;
      const initialScript = currentProductRule?.script;

      if (!script) {
        deleteRule(data);
      } else if (!initialScript && script) {
        addRule(data);
      } else {
        updateRule(data);
      }
    }),
    [
      currentProductRule,
      addRule,
      deleteRule,
      handleSubmit,
      updateRule,
    ]
  );

  return (
    <FormWrapper onSubmit={isReadOnly ? null : handleSubmitForm}>
      <Flex
        width={[1]}
        alignItems="flex-start"
        flexWrap="wrap"
        mx="-8px"
      >

        <Box width={[1]} p="8px">
          <Field
            id="rule-script"
            name="script"
            component={HighlightCodeField}
            label={scriptLabel.label}
            accentLabel={scriptLabel.isAccentColor}
            fontSize={13}
            disabled={isReadOnly}
            hint={scriptLabel.hint}
          />
        </Box>

        <Flex
          justifyContent="flex-end"
          width={[1]}
          p="8px"
        >
          <ActionButtons
            okText="Save"
            cancelText="Clear"
            isLoadingOk={isRuleUpdating}
            withCancelConfirmation={dirty}
            hideOk={isReadOnly}
            disabledOk={!currentProductRule?.eventId?.value}
            onCancel={resetRule}
          />
        </Flex>
      </Flex>
    </FormWrapper>
  );
};

export default reduxForm<{}, IRuleForm>({
  form: FormName.PRODUCT_RULE,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(RuleForm);
