export const padStartN = (num: number, N: number) => {
  let s = num.toString();

  while (s.length < N) {
    s = '0' + s;
  }

  return s;
};

export const numberToFixed = (value: string | number, toFixedNumber: number) => {
  let fixedNumber;

  if (value || value === 0) {
    fixedNumber = Number(value).toFixed(toFixedNumber);
  }

  return fixedNumber;
};

export const toNumber = (value: string | number) => value || value === 0 ? Number(value) : null;

export const normalizeQueryString = (data: object) => Object.keys(data)
  .filter(key => data[key])
  .map(key => `${key}=${encodeURIComponent(data[key])}`)
  .join('&');

export const getObjectDiff = (obj1: object, obj2: object) => {
  const keysFound = {};

  Object.keys(obj1).forEach(key => {
    if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
      keysFound[key] = obj1[key];
    }
  });

  return keysFound;
};
