import { createSelector } from 'reselect';

import { CommonStatus, commonStatusOptions, Flag } from 'consts';
import { IStoreState } from 'store';
import { currenciesOptionsSelector } from 'store/domains/admin';
import { createLoadingSelector } from 'store/domains/loader';

import { ActionTypeKeys } from './actionTypes';
import { normalizeCommonProduct, normalizeCurrentProduct } from './utils';

/**
 * Products selectors
 */

export const defaultProductsSelector = (state: IStoreState) =>
  state.productDesigner.productGeneral.products;

export const productsSelector = createSelector(
  defaultProductsSelector,
  products => products?.map(product => normalizeCommonProduct(product))
);

export const productsTotalPagesSelector = (state: IStoreState) =>
  state.productDesigner.productGeneral.totalPages;

export const productsTotalResultsSelector = (state: IStoreState) =>
  state.productDesigner.productGeneral.totalResults;

export const productsCurrentPageSelector = (state: IStoreState) =>
  state.productDesigner.productGeneral.pageNumber;

export const defaultProductsForSelectionSelector = (state: IStoreState) =>
  state.productDesigner.productGeneral.productsForSelection;

export const productsOptionsSelector = createSelector(
  defaultProductsForSelectionSelector,
  products => products?.asMutable().map(product => {
    const isDisabled = product.status !== CommonStatus.ACTIVE;
    const statusName = commonStatusOptions.find(el => el.value === product.status)?.label;

    const value = product.id;
    const label = `${product.name} ${isDisabled ? `(${statusName})` : ''}`;

    return { value, label };
  })
);

export const isProductsFilteringSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_PRODUCTS,
]);

export const isProductsForSelectionLoadingSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_PRODUCTS_FOR_SELECTION,
]);

/**
 * Product selectors
 */

export const defaultCurrentProductSelector = (state: IStoreState) =>
  state.productDesigner.productGeneral.currentProduct;

export const currentProductSelector = createSelector(
  defaultCurrentProductSelector,
  currenciesOptionsSelector,
  (product, currencyCodesOptions) => normalizeCurrentProduct(product, currencyCodesOptions)
);

export const currentProductInstitutionSelector = createSelector(
  defaultCurrentProductSelector,
  data => {
    const { institution_id, institution_name } = data || {};

    return { value: institution_id, label: institution_name };
  }
);

export const currentProductLockedFlagSelector = createSelector(
  defaultCurrentProductSelector,
  data => data?.locked_flag === Flag.YES
);

export const isProductLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_PRODUCT,
]);

export const isProductAddingSelector = createLoadingSelector([
  ActionTypeKeys.ADD_PRODUCT,
]);

export const isProductUpdatingSelector = createLoadingSelector([
  ActionTypeKeys.UPDATE_PRODUCT,
]);

export const isProductDeletingSelector = createLoadingSelector([
  ActionTypeKeys.DELETE_PRODUCT,
]);

export const isProductCloningSelector = createLoadingSelector([
  ActionTypeKeys.CLONE_PRODUCT,
]);
