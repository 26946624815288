import React from 'react';

import styled from 'theme';

import { Spinner } from './Spinner';

import { componentUtil } from 'utils';

export interface ISpinner {
  isLoading: boolean;
}

interface IOptions {
  color?: string;
  isFixed?: boolean;
  isGlobal?: boolean;
  size?: string | number;
}

const SpinnerContainer = styled.div<ISpinner & IOptions>`
  ${({ isLoading }) => isLoading ? 'position: relative' : ''};
  width: 100%;
  height: 100%;

  ${({ isLoading, isGlobal }) => isGlobal && isLoading
    ? `max-height: 100vh; overflow-y: hidden;`
    : ''
  }
`;

export const withSpinner = ({
  color,
  isFixed = false,
  isGlobal = false,
  size,
}: IOptions = {}) => <OriginalProps extends {}>(
  Component: React.ComponentType<OriginalProps & Partial<ISpinner>>
) => {
    const WithSpinner: React.FC<OriginalProps & ISpinner> = props => {
      const { isLoading } = props;

      return (
        <SpinnerContainer
          className="spinner-container"
          isGlobal={isGlobal}
          isLoading={isLoading}
        >
          <Component {...props} />
          {isLoading && (
            <Spinner
              isFixed={isFixed}
              color={color}
              size={size}
            />
          )}
        </SpinnerContainer>
      );
    };

    WithSpinner.displayName = `WithSpinner(${componentUtil.getDisplayName(Component)})`;

    return WithSpinner;
  };
