import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import { ActionButtons, Button } from 'components';
import { FormName, IconName } from 'consts';
import { InterfaceFields } from './../../components';

import {
  THandleAddInterface,
  THandleDeleteInterface,
  THandleGetDictionaryInterfaceTypes,
  THandleUpdateInterface,
} from 'store';
import { ISelectValue } from 'types';

interface IInterfaceForm {
  addInterface: THandleAddInterface;
  currentInterfaceName?: string;
  deleteInterface: THandleDeleteInterface;
  getDictionaryInterfaceTypes: THandleGetDictionaryInterfaceTypes;
  institutionsOptions: Array<ISelectValue>;
  interfaceTypesOptions: Array<ISelectValue>;
  isAdding: boolean;
  isEditMode?: boolean;
  isLoadingTypesSelector: boolean;
  isReadOnly?: boolean;
  isUpdating: boolean;
  onCancel: () => void;
  updateInterface: THandleUpdateInterface;
}

type TInterfaceForm = IInterfaceForm & InjectedFormProps<{}, IInterfaceForm>;

const InterfaceForm: React.FC<TInterfaceForm> = ({
  addInterface,
  currentInterfaceName,
  deleteInterface,
  dirty,
  getDictionaryInterfaceTypes,
  handleSubmit,
  institutionsOptions,
  interfaceTypesOptions,
  isAdding,
  isEditMode,
  isLoadingTypesSelector,
  isReadOnly,
  isUpdating,
  onCancel,
  pristine,
  updateInterface,
}) => {
  React.useEffect(
    () => {
      getDictionaryInterfaceTypes();
    },
    [getDictionaryInterfaceTypes]
  );

  const submitFormAction = React.useMemo(
    () => isEditMode ? updateInterface : addInterface,
    [isEditMode, updateInterface, addInterface]
  );

  return (
    <form onSubmit={isReadOnly ? null : handleSubmit(submitFormAction)}>
      <InterfaceFields
        interfaceTypesOptions={interfaceTypesOptions}
        institutionsOptions={institutionsOptions}
        isEditMode={isEditMode}
        isLoadingTypesSelector={isLoadingTypesSelector}
        isReadOnly={isReadOnly || isUpdating || isAdding}
      />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt="10px"
      >
        <Box>
          {isEditMode && !isReadOnly && (
            <Button
              text="Delete"
              iconName={IconName.DELETE}
              type="reset"
              withConfirmation={true}
              confirmationText={`Delete interface: "${currentInterfaceName}"?`}
              onClick={deleteInterface}
            />
          )}
        </Box>
        <ActionButtons
          okText="Save"
          cancelText="Close"
          onCancel={onCancel}
          withCancelConfirmation={dirty}
          disabledOk={pristine}
          hideOk={isReadOnly}
          isLoadingOk={isUpdating || isAdding}
        />
      </Flex>
    </form >
  );
};

export default reduxForm<{}, IInterfaceForm>({
  form: FormName.INTERFACE,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(InterfaceForm);
