export enum UserStatus {
  ACTIVE = 'A',
  INACTIVE = 'I',
  DELETED = 'D',
  LOCKED = 'L',
  REGISTRATION_PENDING = 'F',
  AUTHENTICATION_PENDING = 'P',
}

export const userStatusOptions = [
  { value: UserStatus.ACTIVE, label: 'Active' },
  { value: UserStatus.INACTIVE, label: 'Inactive' },
  { value: UserStatus.DELETED, label: 'Deleted' },
  { value: UserStatus.LOCKED, label: 'Locked' },
];

export const userStatusWith2faOptions = [
  ...userStatusOptions,
  { value: UserStatus.REGISTRATION_PENDING, label: '2FA registration pending' },
];
