import React from 'react';

import { Modal } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';
import { SystemPropertyForm } from './../../forms';

interface IAddSystemPropertyModal extends IWithModal {
  isFormDirty: boolean;
}

const modalName = ModalName.ADD_SYSTEM_PROPERTY;

const AddSystemPropertyModal: React.FC<IAddSystemPropertyModal> = ({
  closeModal,
  isFormDirty,
}) => {
  return (
    <Modal
      name={modalName}
      title="Create system property"
      containerWidth="600px"
      withCloseConfirmation={isFormDirty}
    >
      <SystemPropertyForm onCancel={() => closeModal(modalName)} />
    </Modal>
  );
};

export default withModal(AddSystemPropertyModal);
