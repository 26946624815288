import Immutable, { ImmutableObject } from 'seamless-immutable';

import { ActionTypeKeys, TIllustrationAction } from './actionTypes';
import { IProductIllustrationState } from './types';

export const productIllustrationInitialState: ImmutableObject<IProductIllustrationState> =
  Immutable({
    loanIllustration: {
      number_of_instalments: null,
      principal: null,
      product: null,
      schedule: Immutable([]),
      start_date: null,
      total_interest: null,
    },
    revCreditIllustration: null,
  });

const productIllustrationReducer =
  (state = productIllustrationInitialState, action: TIllustrationAction) => {
    switch (action.type) {
      case ActionTypeKeys.ILLUSTRATE_LOAN_FULFILLED:
        return state.set('loanIllustration', action.payload.illustration);

      case ActionTypeKeys.ILLUSTRATE_REVOLVING_CREDIT_FULFILLED:
        return state.set('revCreditIllustration', action.payload.revolving_credit_illustration);

      case ActionTypeKeys.RESET_PRODUCT_ILLUSTRATION:
        return state = productIllustrationInitialState;

      default:
        return state;
    }
  };

export default productIllustrationReducer;
