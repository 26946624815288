import { push } from 'connected-react-router';
import { reset as resetForm } from 'redux-form';

import { basePath, Flag, FormName, ModalName, UserStatus } from 'consts';
import { apiClientService } from 'services';
import { closeModal, openModal } from 'store';
import { Thunk, VoidThunk } from 'types';
import { errorDecoratorUtil, storageUtil, urlUtil } from 'utils';

import * as api from './api';

import {
  ActionTypeKeys,
  IConfirmAuthKeyAction,
  IGenerateAuthKeyAction,
  ILoginAction,
  ILogoutAction,
  IProvideAuthKeyAction,
} from './actionTypes';

import {
  IAuthCode,
  IAuthPassword,
  ILoginRequest,
  ILoginRequestToSend,
} from './types';

import {
  normalizeLoginData,
  normalizeLoginRequest,
  setLoginDataToStorage,
} from './utils';

/**
 * Login action
 */

export type THandleLogin = (data: ILoginRequest) => Thunk<void>;
export type TLogin = (data: ILoginRequestToSend) => ILoginAction;

export const login: TLogin = data => ({
  type: ActionTypeKeys.LOGIN,
  payload: api.login(data),
});

export const handleLogin: THandleLogin = (data) =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        storageUtil.clear();

        const loginRequest = normalizeLoginRequest(data);
        const loginResponse = (await dispatch(login(loginRequest))) as any;

        const loginData = normalizeLoginData(loginResponse.value);

        const is2fa = loginData.status === UserStatus.ACTIVE
          && loginData.requires2faFlag === Flag.YES;

        if (is2fa) {
          dispatch(openModal({ name: ModalName.LOGIN_CODE_2FA }));
          storageUtil.setSessionId(loginData.sessionId, loginData.sessionTimeout);
          storageUtil.setAuthPendingFlag(loginData.sessionTimeout);
        } else {
          setLoginDataToStorage(loginData);
          dispatch(push(basePath));
        }

        dispatch(resetForm(FormName.LOGIN));
      },
      dispatch
    );
  };

/**
 * Provide auth key action
 */

export type THandleProvideAuthKey = (data: IAuthCode) => Thunk<void>;
export type TProvideAuthKey = (data: IAuthCode) => IProvideAuthKeyAction;

export const provideAuthKey: TProvideAuthKey = code => ({
  type: ActionTypeKeys.PROVIDE_AUTH_KEY,
  payload: api.provideAuthKey(code),
});

export const handleProvideAuthKey: THandleProvideAuthKey = (data) =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const loginResponse = (await dispatch(provideAuthKey(data))) as any;

        const loginData = normalizeLoginData(loginResponse.value);

        setLoginDataToStorage(loginData);

        dispatch(closeModal(ModalName.LOGIN_CODE_2FA));
        dispatch(push(basePath));
      },
      dispatch
    );
  };

/**
 * Logout action
 */

export type THandleLogout = VoidThunk;
export type TLogout = () => ILogoutAction;

export const logout: TLogout = () => ({
  type: ActionTypeKeys.LOGOUT,
  payload: api.logout(),
});

export const handleLogout: THandleLogout = () =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const isSessionId = storageUtil.getSessionId();

        if (isSessionId) {
          await dispatch(logout());
        }

        apiClientService.clear();
        storageUtil.clear();
        urlUtil.openLocation(basePath);
      },
      dispatch
    );
  };

/**
 * Set current auth registration 2FA step action
 */

export type TSetCurrentAuthRegistrationStep = (step: number) => void;

export const setCurrentAuthRegistrationStep: TSetCurrentAuthRegistrationStep = step => ({
  type: ActionTypeKeys.SET_CURRENT_REGISTER_STEP,
  payload: step,
});

/**
 * Generate auth key action
 */

export type THandleGenerateAuthKey = (data: IAuthPassword) => Thunk<void>;
export type TGenerateAuthKey = (data: IAuthPassword) => IGenerateAuthKeyAction;

export const generateAuthKey: TGenerateAuthKey = data => ({
  type: ActionTypeKeys.GENERATE_AUTH_KEY,
  payload: api.generateAuthKey(data),
});

export const handleGenerateAuthKey: THandleGenerateAuthKey = (data) =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        await dispatch(generateAuthKey(data));
      },
      dispatch
    );
  };

/**
 * Confirm registration 2FA auth key action
 */

export type THandleConfirmAuthKey = VoidThunk;
export type TConfirmAuthKey = () => IConfirmAuthKeyAction;

export const confirmAuthKey: TConfirmAuthKey = () => ({
  type: ActionTypeKeys.CONFIRM_AUTH_KEY,
  payload: api.confirmAuthKey(),
});

export const handleConfirmAuthKey: THandleConfirmAuthKey = () =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        await dispatch(confirmAuthKey());

        dispatch(closeModal(ModalName.REGISTER_2FA));

        apiClientService.clear();
        storageUtil.clear();
        urlUtil.openLocation(basePath);
      },
      dispatch
    );
  };
