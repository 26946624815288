import React from 'react';

import { Modal, PersonAddIcon } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';
import { CustomerForm } from './../../forms';

interface IAddCustomerModal extends IWithModal {
  isFormDirty: boolean;
}

const modalName = ModalName.ADD_CUSTOMER;

const AddCustomerModal: React.FC<IAddCustomerModal> = ({
  closeModal,
  isFormDirty,
}) => (
  <Modal
    name={modalName}
    title="Create customer"
    containerWidth="1010px"
    Icon={<PersonAddIcon size="26" />}
    withCloseConfirmation={isFormDirty}
  >
    <CustomerForm onCancel={() => closeModal(modalName)} />
  </Modal>
);

export default withModal(AddCustomerModal);
