import { TApiResponse } from 'types';

import {
  IChangePassword,
  IPermissionsData,
  IProfileResp,
  IUserInstitutionsData,
} from './types';

export enum ActionTypeKeys {
  GET_PROFILE = 'profile/GET_PROFILE',
  GET_PROFILE_FULFILLED = 'profile/GET_PROFILE_FULFILLED',
  GET_PROFILE_REJECTED = 'profile/GET_PROFILE_REJECTED',

  GET_PERMISSIONS = 'profile/GET_PERMISSIONS',
  GET_PERMISSIONS_FULFILLED = 'profile/GET_PERMISSIONS_FULFILLED',
  GET_PERMISSIONS_REJECTED = 'profile/GET_PERMISSIONS_REJECTED',

  GET_USER_INSTITUTIONS = 'profile/GET_USER_INSTITUTIONS',
  GET_USER_INSTITUTIONS_FULFILLED = 'profile/GET_USER_INSTITUTIONS_FULFILLED',
  GET_USER_INSTITUTIONS_REJECTED = 'profile/GET_USER_INSTITUTIONS_REJECTED',

  CHANGE_PASSWORD = 'profile/CHANGE_PASSWORD',
  CHANGE_PASSWORD_FULFILLED = 'profile/CHANGE_PASSWORD_FULFILLED',
  CHANGE_PASSWORD_REJECTED = 'profile/CHANGE_PASSWORD_REJECTED',
}

/** Get user data action interfaces */

export interface IGetProfileAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_PROFILE;
}

export interface IGetProfileFulfilledAction {
  readonly payload: IProfileResp;
  readonly type: ActionTypeKeys.GET_PROFILE_FULFILLED;
}

export interface IGetProfileRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_PROFILE_REJECTED;
}

/** Get permissions action interfaces */

export interface IGetPermissionsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_PERMISSIONS;
}

export interface IGetPermissionsFulfilledAction {
  readonly payload: IPermissionsData;
  readonly type: ActionTypeKeys.GET_PERMISSIONS_FULFILLED;
}

export interface IGetPermissionsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_PERMISSIONS_REJECTED;
}

/** Get user institutions action interfaces */

export interface IGetUserInstitutionsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_USER_INSTITUTIONS;
}

export interface IGetUserInstitutionsFulfilledAction {
  readonly payload: IUserInstitutionsData;
  readonly type: ActionTypeKeys.GET_USER_INSTITUTIONS_FULFILLED;
}

export interface IGetUserInstitutionsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_USER_INSTITUTIONS_REJECTED;
}

/** Change password action interfaces */

export interface IChangePasswordAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.CHANGE_PASSWORD;
}

export interface IChangePasswordFulfilledAction {
  readonly payload: IChangePassword;
  readonly type: ActionTypeKeys.CHANGE_PASSWORD_FULFILLED;
}

export interface IChangePasswordRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.CHANGE_PASSWORD_REJECTED;
}

export type TAuthAction =
  | IGetProfileFulfilledAction
  | IGetPermissionsFulfilledAction
  | IGetUserInstitutionsFulfilledAction
  | IChangePasswordFulfilledAction;
