import { Flag } from 'consts';
import { stringsUtil } from 'utils';

import {
  ISysProp,
  ISysPropData,
  ISysPropsFilter,
  ISysPropsFilterToSend,
} from './types';

export const normalizeFilterQueryString = (data: ISysPropsFilter): string => {
  const { propertyName } = data || {};

  const normalizedData: ISysPropsFilterToSend = {
    property_name: propertyName,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeSystemProperty = (data: ISysPropData): ISysProp => {
  const {
    current_value,
    last_datetime,
    locked_flag,
    previous_value,
    property_name,
  } = data || {};

  return {
    currentValue: current_value,
    lastDatetime: last_datetime,
    lockedFlag: locked_flag === Flag.YES,
    previousValue: previous_value,
    id: property_name,
  };
};

export const normalizeSystemPropertyForSending = (
  data: Partial<ISysProp>
): Partial<ISysPropData> => {
  const { currentValue, lockedFlag, id } = data || {};

  return {
    current_value: currentValue,
    locked_flag: lockedFlag ? Flag.YES : Flag.NO,
    property_name: id,
  };
};
