import Immutable, { ImmutableObject } from 'seamless-immutable';

import { ActionTypeKeys, TStatementsAction } from './actionTypes';
import { IStatementsState } from './types';

export const statementsInitialState: ImmutableObject<IStatementsState> = Immutable({
  pageNumber: null,
  totalPages: null,
  totalResults: null,
  statements: Immutable([]),
  currentStatement: null,
  statementAprs: Immutable([]),
});

const statementsReducer = (state = statementsInitialState, action: TStatementsAction) => {
  switch (action.type) {
    case ActionTypeKeys.FILTER_STATEMENTS_FULFILLED:
      return state
        .set('statements', action.payload.statements)
        .set('pageNumber', action.payload.page_number)
        .set('totalPages', action.payload.total_pages)
        .set('totalResults', action.payload.total_results);

    case ActionTypeKeys.GET_STATEMENT_FULFILLED:
      return state.set('currentStatement', action.payload.statement);

    case ActionTypeKeys.GET_STATEMENT_APRS_FULFILLED:
      return state.set('statementAprs', action.payload.statement_aprs);

    case ActionTypeKeys.RESET_STATEMENTS:
      return state = statementsInitialState;

    case ActionTypeKeys.RESET_STATEMENT:
      return state.set('currentStatement', null);

    case ActionTypeKeys.RESET_STATEMENT_APRS:
      return state.set('statementAprs', Immutable([]));

    default: return state;
  }
};

export default statementsReducer;
