import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import RevolvingCreditIllustration from './RevolvingCreditIllustration';

import {
  handleIllustrateRevCredit,
  isRevCreditIllustrationLoadingSelector,
  IStoreState,
  revCreditTransIllustrationSelector,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  isLoading: isRevCreditIllustrationLoadingSelector(state),
  illustration: revCreditTransIllustrationSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    illustrate: handleIllustrateRevCredit,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RevolvingCreditIllustration);
