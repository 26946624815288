import React from 'react';

import { Modal } from 'components';
import { ModalName } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import { IPayloadUpdateProductAPRModal } from 'store';

import AprsForm from './../AprsForm';

interface IUpdateProductAPRModal extends IWithModal {
  productAPRDetails: IPayloadUpdateProductAPRModal;
}

const modalName = ModalName.UPDATE_PRODUCT_APR;

const UpdateProductAPRModal: React.FC<IUpdateProductAPRModal> = ({
  closeModal,
  productAPRDetails,
}) => (
  <Modal
    name={modalName}
    title="Edit product APR"
    containerWidth="800px"
  >
    <AprsForm
      initialValues={productAPRDetails.details}
      isUpdateMode={true}
      onCancel={() => closeModal(modalName)}
    />
  </Modal>
);

export default withModal(UpdateProductAPRModal);
