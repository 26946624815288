import React from 'react';

import { Modal } from 'components';

import {
  CommonStatus,
  commonStatusOptions,
  ModalName,
  ProductType,
  productTypesOptions,
} from 'consts';

import { IWithModal, withModal } from 'HOCs';

import AddProductForm from './AddProductForm';

interface IAddProductModal extends IWithModal {}

const modalName = ModalName.ADD_PRODUCT;
const initialStatus = commonStatusOptions.find(el => el.value === CommonStatus.ACTIVE);
const defaultProductTypeOption = productTypesOptions.find(el => el.value === ProductType.REVOLVING_CREDIT);

const AddProductModal: React.FC<IAddProductModal> = ({
  closeModal,
}) => (
  <Modal
    name={modalName}
    title="Create product"
    containerWidth="950px"
    minContainerHeight="650px"
  >
    <AddProductForm
      initialValues={{
        productType: defaultProductTypeOption,
        status: initialStatus,
      }}
      onCancel={() => closeModal(modalName)}
    />
  </Modal>
);

export default withModal(AddProductModal);
