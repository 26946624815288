
import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { dictionaryEventsSelector } from 'store/domains/admin';
import { createLoadingSelector } from 'store/domains/loader';
import { activeProductTypeSelector } from 'store/domains/utils';

import { ActionTypeKeys } from './actionTypes';
import { normalizeProductRule } from './utils';

export const defaultCurrentProductRuleSelector = (state: IStoreState) =>
  state.productDesigner.productRules.currentProductRule;

export const currentProductRuleSelector = createSelector(
  defaultCurrentProductRuleSelector,
  dictionaryEventsSelector,
  activeProductTypeSelector,
  (currentRule, events, productType) => normalizeProductRule(currentRule, events, productType));

/**
 * Product rules loading selectors
 */

export const isProductRuleLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_PRODUCT_RULE,
]);

export const isProductRuleUpdatingSelector = createLoadingSelector([
  ActionTypeKeys.ADD_PRODUCT_RULE,
  ActionTypeKeys.UPDATE_PRODUCT_RULE,
  ActionTypeKeys.DELETE_PRODUCT_RULE,
]);
