import styled from 'theme';
import { highlightCss } from 'theme/styles';

interface IHorizontalLine {
  accentColor?: boolean;
  noSpaceY?: boolean;
}

export const HorizontalLine = styled.div<IHorizontalLine>`
  margin-top: ${({ noSpaceY }) => noSpaceY ? '1px' : '9px'};
  margin-bottom:${({ noSpaceY }) => noSpaceY ? '0' : '7px'};
  padding: 1px 0;
  border-top: 1px solid ${({ theme, accentColor }) =>
    accentColor ? 'transparent' : theme.colors.lightGray};
  width: 100%;
  ${({ accentColor }) => accentColor && highlightCss};

  &:before {
    top: -6px;
  }
`;

export const NewLine = styled.div`
  width: 100%;
`;
