import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  currentUsersGroupSelector,
  handleAddUsersGroupMember,
  handleFilterUsers,
  isAddingUsersGroupMemberSelector,
  isFilteringUsersSelector,
  IStoreState,
  usersGroupMembersSelector,
  usersOptionsByIdSelector,
} from 'store';

import EditUsersGroupMembersForm from './EditUsersGroupMembersForm';

const mapStateToProps = (state: IStoreState) => ({
  isAdding: isAddingUsersGroupMemberSelector(state),
  isUsersLoading: isFilteringUsersSelector(state),
  usersGroup: currentUsersGroupSelector(state),
  usersGroupMembers: usersGroupMembersSelector(state),
  usersOptions: usersOptionsByIdSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    addUsersGroupMember: handleAddUsersGroupMember,
    filterUsers: handleFilterUsers,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUsersGroupMembersForm);
