import React from 'react';

import styled from 'theme';

import { basePath } from 'consts';

// import { logo } from 'resources/images';

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  width: 150px;

  img {
    display: block;
    max-width: 100%;
  }
`;

const Logo: React.FC = () => (
  <Wrapper
    href={basePath}
    aria-label="UI"
  >
    {/* <img src={logo} alt="" /> */}
    <img src="/ui/logo.svg" alt="" />
  </Wrapper>
);

export default Logo;
