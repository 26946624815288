import React from 'react';

import { Modal, ProductTypeIcons, Tabs, TabsPanel } from 'components';
import { ModalName, ProductType } from 'consts';

import LoanIllustration from 'containers/ProductDesigner/Products/LoanIllustration';
import RevolvingCreditIllustration from 'containers/ProductDesigner/Products/RevolvingCreditIllustration';

import { IWithModal, withModal } from 'HOCs';
import { IAccount, THandleGetAccount, TResetAccount, TResetProductIllustration } from 'store';

import { AccountForm } from './../../forms';

interface IDetailsAccountModal extends IWithModal {
  currentAccount: IAccount;
  getAccount: THandleGetAccount;
  isLoading: boolean;
  productType: string;
  resetAccount: TResetAccount;
  resetProductIllustration: TResetProductIllustration;
}

const modalName = ModalName.DETAILS_ACCOUNT;

const DetailsAccountModal: React.FC<IDetailsAccountModal> = ({
  closeModal,
  currentAccount,
  getAccount,
  isLoading,
  isReadOnlyPage,
  productType,
  resetAccount,
  resetProductIllustration,
}) => {
  React.useEffect(
    () => {
      getAccount();

      return () => {
        resetAccount();
        resetProductIllustration();
      };
    },
    [getAccount, resetAccount, resetProductIllustration]
  );

  const handleOnCancel = React.useCallback(
    () => closeModal(modalName),
    [closeModal]
  );

  return (
    <Modal
      containerWidth="860px"
      title={`Account #${currentAccount?.id || ''}`}
      isLoading={isLoading}
      minContainerHeight="750px"
      name={modalName}
    >
      <Tabs>
        <TabsPanel
          title="Details"
          Icon={ProductTypeIcons[productType]}
        >
          <AccountForm
            currentAccountStatus={currentAccount?.status?.value}
            initialValues={currentAccount}
            isEditMode={true}
            isReadOnly={isReadOnlyPage}
            onCancel={handleOnCancel}
          />
        </TabsPanel>

        <TabsPanel
          title="Illustration"
          isHidden={productType !== ProductType.LOAN}
        >
          <LoanIllustration
            mode="account"
            initialFormValues={{
              apr: currentAccount?.standardApr,
              principal: currentAccount?.originalLoanAmount,
              productId: currentAccount?.productId,
              productName: currentAccount?.productName,
              startDate: currentAccount?.disbursementDate,
              numberOfInstalments: currentAccount?.numberOfInstalments,
            }}
          />
        </TabsPanel>

        <TabsPanel
          title="Illustration"
          isHidden={productType !== ProductType.REVOLVING_CREDIT}
        >
          <RevolvingCreditIllustration
            initialFormValues={{
              productId: currentAccount?.productId,
              accountId: currentAccount?.id,
            }}
          />
        </TabsPanel>
      </Tabs>
    </Modal>
  );
};

export default withModal(DetailsAccountModal);
