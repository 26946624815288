import { IMessageResponse, IResponseStatus } from 'types';
import { IProductRuleData, IProductRuleResp } from './types';

export enum ActionTypeKeys {
  /** Add product rule action type keys */
  ADD_PRODUCT_RULE = 'products/rules/ADD_PRODUCT_RULE',
  ADD_PRODUCT_RULE_FULFILLED = 'products/rules/ADD_PRODUCT_RULE_FULFILLED',
  ADD_PRODUCT_RULE_REJECTED = 'products/rules/ADD_PRODUCT_RULE_REJECTED',

  /** Get product rule action type keys */
  GET_PRODUCT_RULE = 'products/rules/GET_PRODUCT_RULE',
  GET_PRODUCT_RULE_FULFILLED = 'products/rules/GET_PRODUCT_RULE_FULFILLED',
  GET_PRODUCT_RULE_REJECTED = 'products/rules/GET_PRODUCT_RULE_REJECTED',

  /** Delete product rule action type keys */
  DELETE_PRODUCT_RULE = 'products/rules/DELETE_PRODUCT_RULE',
  DELETE_PRODUCT_RULE_FULFILLED = 'products/rules/DELETE_PRODUCT_RULE_FULFILLED',
  DELETE_PRODUCT_RULE_REJECTED = 'products/rules/DELETE_PRODUCT_RULE_REJECTED',

  /** Update product rule action type keys */
  UPDATE_PRODUCT_RULE = 'products/rules/UPDATE_PRODUCT_RULE',
  UPDATE_PRODUCT_RULE_FULFILLED = 'products/rules/UPDATE_PRODUCT_RULE_FULFILLED',
  UPDATE_PRODUCT_RULE_REJECTED = 'products/rules/UPDATE_PRODUCT_RULE_REJECTED',

  /** Reset product rule action type keys */
  RESET_PRODUCT_RULE = 'products/rules/RESET_PRODUCT_RULE',
}

/** Add product rule action interfaces */

export interface IAddProductRuleAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ADD_PRODUCT_RULE;
}

export interface IAddProductRuleFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.ADD_PRODUCT_RULE_FULFILLED;
}

export interface IAddProductRuleRejectedAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.ADD_PRODUCT_RULE_REJECTED;
}

/** Get product rule action interfaces */

export interface IGetProductRuleAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_PRODUCT_RULE;
}

export interface IGetProductRuleFulfilledAction {
  readonly payload: IProductRuleResp;
  readonly type: ActionTypeKeys.GET_PRODUCT_RULE_FULFILLED;
  readonly meta: { data: IProductRuleData, productType: string };
}

export interface IGetProductRuleRejectedAction {
  readonly payload: IMessageResponse;
  readonly type: ActionTypeKeys.GET_PRODUCT_RULE_REJECTED;
  readonly meta: { data: IProductRuleData, productType: string };
}

/** Delete product rule action interfaces */

export interface IDeleteProductRuleAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_RULE;
}

export interface IDeleteProductRuleFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_RULE_FULFILLED;
  readonly meta: { data: IProductRuleData, productType: string };
}

export interface IDeleteProductRuleRejectedAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_RULE_REJECTED;
}

/** Update product rule action interfaces */

export interface IUpdateProductRuleAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.UPDATE_PRODUCT_RULE;
}

export interface IUpdateProductRuleFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.UPDATE_PRODUCT_RULE_FULFILLED;
}

export interface IUpdateProductRuleRejectedAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.UPDATE_PRODUCT_RULE_REJECTED;
}

/** Reset product rule action interfaces */

export interface IResetProductRuleAction {
  readonly type: ActionTypeKeys.RESET_PRODUCT_RULE;
}

export type TProductRuleAction =
  | IAddProductRuleFulfilledAction
  | IDeleteProductRuleFulfilledAction
  | IGetProductRuleFulfilledAction
  | IGetProductRuleRejectedAction
  | IResetProductRuleAction
  | IUpdateProductRuleFulfilledAction;
