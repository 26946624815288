import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import TransactionModal from './TransactionModal';

import {
  currentTransactionSelector,
  handleGetTransaction,
  isLoadingTransactionSelector,
  IStoreState,
  resetTransaction,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  currentTransaction: currentTransactionSelector(state),
  isLoading: isLoadingTransactionSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getTransaction: handleGetTransaction,
    resetTransaction,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionModal);
