import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Flex } from '@rebass/grid';

import { ActionButtons } from 'components';

import { FormName } from 'consts';
import { UsersGroupFields } from './../../../components';

import { THandleAddUsersGroup } from 'store';

interface IEditUsersGroupFrom {
  isReadOnly: boolean;
  isUpdating: boolean;
  onCancel: () => void;
  updateUsersGroup: THandleAddUsersGroup;
}

type TEditUsersGroupFrom = IEditUsersGroupFrom & InjectedFormProps<{}, IEditUsersGroupFrom>;

const EditUsersGroupFrom: React.FC<TEditUsersGroupFrom> = ({
  dirty,
  handleSubmit,
  isReadOnly,
  isUpdating,
  onCancel,
  pristine,
  updateUsersGroup,
}) => (
  <form onSubmit={isReadOnly ? null : handleSubmit(updateUsersGroup)}>
    <UsersGroupFields
      isEditMode={true}
      isReadOnly={isReadOnly || isUpdating}
    />

    <Flex
      justifyContent="flex-end"
      mt="15px"
    >
      <ActionButtons
        okText="Save"
        cancelText="Close"
        disabledOk={pristine}
        onCancel={onCancel}
        withCancelConfirmation={dirty}
        hideOk={isReadOnly}
        isLoadingOk={isUpdating}
      />
    </Flex>
  </form>
);

export default reduxForm<{}, IEditUsersGroupFrom>({
  form: FormName.DETAILS_USERS_GROUP,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(EditUsersGroupFrom);
