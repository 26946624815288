// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
// import { throttleUtil } from 'utils';

// import { apiCallMock, apiCallsMock } from './mock';

/**
 * Filter API calls API
 */
export const filterApiCalls = (queryString: string) =>
  // throttleUtil.getDataAfter(apiCallsMock, 500);
  apiClientService.get(`api-calls?${queryString}`);

/**
 * Get API call details API
 */
export const getApiCall = (apiCallId: number) =>
  // throttleUtil.getDataAfter(apiCallMock, 500);
  apiClientService.get(`api-calls/${apiCallId}`);
