import moment from 'moment';

import { DateFormat } from 'consts';

export const todayDateTime = () => moment().format(DateFormat.DATE_TIME);
export const yesterdayDateTime = () => moment().subtract(1, 'day').format(DateFormat.DATE_TIME);

export const todayDate = () => moment().format(DateFormat.DATE);
export const yesterdayDate = () => moment().subtract(1, 'day').format(DateFormat.DATE);

export const getCurrentYear = () => moment().year();

/**
 * Formatting date from dd/MM/YYYY to YYYY-MM-DD
 */
export const formatDateForSending = (date: string) => date?.split('/').reverse().join('-');
