import React, { ReactChild } from 'react';

import { ScrollDisable } from 'theme/styles';

import { modalsList } from 'containers/Modals/modalsList';

interface IModals {
  modalsStateList?: object;
}

const Modals: React.FC<IModals> = ({ modalsStateList }) => {
  const activeModals = React.useMemo(
    () => {
      const activeModalsList: Array<ReactChild> = [];

      for (const modalName in modalsList) {
        if (modalsStateList[`is${modalName}`]) {
          activeModalsList.push(
            <React.Fragment key={modalName}>
              {modalName && (<ScrollDisable />)}
              {modalsList[modalName]}
            </React.Fragment>
          );
        }
      }

      return activeModalsList;
    },
    [modalsStateList]
  );

  return (
    <>
      {activeModals}
    </>
  );
};

export default Modals;
