import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { formValueSelector, isDirty } from 'redux-form';

import AddProductForm from './AddProductForm';

import { FormName } from 'consts';
import { handleAddProduct, isProductAddingSelector, IStoreState } from 'store';

const dirty = isDirty(FormName.ADD_PRODUCT);
const formSelector = formValueSelector(FormName.ADD_PRODUCT);

const mapStateToProps = (state: IStoreState) => ({
  currentProductType: formSelector(state, 'productType'),
  isFormDirty: dirty(state),
  isAdding: isProductAddingSelector(state),
  statementCycleType: formSelector(state, 'statementCycleType'),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      addProduct: handleAddProduct,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddProductForm);
