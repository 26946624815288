export interface ITheme {
  theme: Theme;
}

export default interface Theme {
  fonts: {
    code: string;
    text: string,
    title: string;
  };
  colors: {
    accent: string;
    darkAccent: string;

    inputAccent: string;

    black: string;
    blackOpacity: string;

    red: string;

    gray: string;
    darkGray: string;
    lightGray: string;
    lighterGray: string;
    grayCell: string;

    white: string;
    whiteOpacity: string;

    green: string;
  };
  shadows: {
    aroundBox: string;
    bottomBox: string;
    normalBox: string;
  };
}

export const theme: Theme = {
  fonts: {
    code: 'Roboto Mono, monospace',
    text: 'Roboto, sans-serif',
    title: 'Raleway, sans-serif',
  },
  colors: {
    accent: '#f47a71',
    darkAccent: '#d86f67',

    inputAccent: '#8e244c',

    black: '#0f1f38',
    blackOpacity: '#000000CC',  // rgba(black, .8)

    red: '#e83327',

    gray: '#6b7280',
    darkGray: '#000000CC',  // rgba(black, .8)
    lightGray: '#00000026', // rgba(black, .15)
    lighterGray: '#0000000D', // rgba(black, .05)
    grayCell: '#00000005', // rgba(black, .02)

    white: '#ffffff',
    whiteOpacity: 'rgba(255, 255, 255, .9)',

    green: '#4bb543',
  },
  shadows: {
    aroundBox: '0 1px 2px 0 rgba(60, 64, 67, .3), 0 2px 6px 2px rgba(60, 64, 67, .15)',
    bottomBox: '0 3px 3px 0 rgba(0, 0, 0, .1)',
    normalBox: '0 2px 4px 0 rgba(0, 0, 0, .1)',
  },
};
