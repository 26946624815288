import { reset as resetForm } from 'redux-form';

import { FormName, ModalName } from 'consts';
import { activeItemIdSelector, closeModal, openModal } from 'store';
import { Thunk, VoidPromiseThunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import {
  ActionTypeKeys,
  IAddUsersGroupAction,
  IAddUsersGroupMemberAction,
  IAddUsersGroupPermissionsAction,
  IDeleteUsersGroupMemberAction,
  IDeleteUsersGroupPermissionAction,
  IGetUsersGroupMembersAction,
  IGetUsersGroupPermissionsAction,
  IGetUsersGroupsAction,
  IUpdateUsersGroupAction,
  IUpdateUsersGroupPermissionAction,
} from './actionTypes';
import * as api from './api';
import {
  IUsersGroup,
  IUsersGroupData,
  IUsersGroupDetails,
  IUsersGroupMemberDataToSend,
  IUsersGroupMemberRequest,
  IUsersGroupPermission,
  IUsersGroupPermissionData,
  IUsersGroupPermissionDataToSend,
  IUsersGroupPermissionRequest,
} from './types';
import {
  normalizePermissionForSending,
  normalizePermissionForUpdating,
  normalizeUsersGroupForSending,
} from './utils';

/**
 * Get users groups action
 */

export type TGetUsersGroups = () => IGetUsersGroupsAction;
export type THandleGetUsersGroups = VoidPromiseThunk;

export const getUsersGroups: TGetUsersGroups = () => ({
  type: ActionTypeKeys.GET_USERS_GROUPS,
  payload: api.getUsersGroups(),
});

export const handleGetUsersGroups: THandleGetUsersGroups = () =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        await dispatch(getUsersGroups());
      },
      dispatch
    );
  };

/**
 * Add users group action
 */

export type TAddUsersGroup = (data: Partial<IUsersGroupData>) => IAddUsersGroupAction;
export type THandleAddUsersGroup = (data: Partial<IUsersGroupDetails>) => Thunk<void>;

export const addUsersGroup: TAddUsersGroup = data => ({
  type: ActionTypeKeys.ADD_USERS_GROUP,
  payload: api.addUsersGroup(data),
});

export const handleAddUsersGroup: THandleAddUsersGroup = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const normalizedData = normalizeUsersGroupForSending(data);

        await dispatch(addUsersGroup(normalizedData));
        dispatch(closeModal(ModalName.ADD_USERS_GROUP));
        await dispatch(handleGetUsersGroups());

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: {
              title: 'Users group has been created',
              isSuccess: true,
            },
          })
        );
      },
      dispatch
    );
  };

/**
 * Update users group action
 */

export type TUpdateUsersGroup = (data: Partial<IUsersGroupData>, groupId: number) =>
  IUpdateUsersGroupAction;

export type THandleUpdateUsersGroup = (data: Partial<IUsersGroupDetails>) => Thunk<void>;

export const updateUsersGroups: TUpdateUsersGroup = (data, groupId) => ({
  type: ActionTypeKeys.UPDATE_USERS_GROUP,
  payload: api.updateUsersGroup(data, groupId),
});

export const handleUpdateUsersGroup: THandleUpdateUsersGroup = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const groupId = activeItemIdSelector(state);
        const normalizedData = normalizeUsersGroupForSending(data);

        await dispatch(updateUsersGroups(normalizedData, groupId));
        await dispatch(handleGetUsersGroups());
      },
      dispatch
    );
  };

// ---------------------------------------------------------------------

/** Get users group members action */

export type TGetUsersGroupMembers = (groupId: number) => IGetUsersGroupMembersAction;
export type THandleGetUsersGroupMembers = (group: IUsersGroup) => Thunk<void>;

export const getUsersGroupMembers: TGetUsersGroupMembers = groupId => ({
  type: ActionTypeKeys.GET_USERS_GROUP_MEMBERS,
  payload: api.getUsersGroupMembers(groupId),
});

export const handleGetUsersGroupMembers: THandleGetUsersGroupMembers = group =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const groupId = group?.id;

        await dispatch(getUsersGroupMembers(groupId));
      },
      dispatch
    );
  };

/** Delete member from the users group action */

export type TDeleteUsersGroupMember = (groupId: number, userId: number) =>
  IDeleteUsersGroupMemberAction;

export type THandleDeleteUsersGroupMember = (groupId: number, userId: number) =>
  Thunk<void>;

export const deleteUsersGroupMember: TDeleteUsersGroupMember = (groupId, userId) => ({
  type: ActionTypeKeys.DELETE_USERS_GROUP_MEMBER,
  payload: api.deleteUsersGroupMember(groupId, userId),
});

export const handleDeleteUsersGroupMember: THandleDeleteUsersGroupMember = (groupId, userId) =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        await dispatch(deleteUsersGroupMember(groupId, userId));
        dispatch(getUsersGroupMembers(groupId));
      },
      dispatch
    );
  };

/** Add member to the users group action */

export type TAddUsersGroupMember = (data: IUsersGroupMemberDataToSend) =>
  IAddUsersGroupMemberAction;

export type THandleAddUsersGroupMember = (data: Partial<IUsersGroupMemberRequest>) => Thunk<void>;

export const addUsersGroupMember: TAddUsersGroupMember = data => ({
  type: ActionTypeKeys.ADD_USERS_GROUP_MEMBER,
  payload: api.addUsersGroupMember(data),
});

export const handleAddUsersGroupMember: THandleAddUsersGroupMember = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const groupId = data?.usersGroupId;
        const userId = data?.user?.value;

        const normalizedData = {
          user_group_id: groupId,
          user_id: userId,
        };

        await dispatch(addUsersGroupMember(normalizedData));

        dispatch(getUsersGroupMembers(groupId));
        dispatch(resetForm(FormName.DETAILS_USERS_GROUP_MEMBERS));
      },
      dispatch
    );
  };

// ---------------------------------------------------------------------

/** Get users group permissions action */

export type TGetUsersGroupPermissions = (groupId: number) => IGetUsersGroupPermissionsAction;
export type THandleGetUsersGroupPermissions = (group: IUsersGroup) => Thunk<void>;

export const getUsersGroupPermissions: TGetUsersGroupPermissions = groupId => ({
  type: ActionTypeKeys.GET_USERS_GROUP_PERMISSIONS,
  payload: api.getUsersGroupPermissions(groupId),
});

export const handleGetUsersGroupPermissions: THandleGetUsersGroupPermissions = group =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const groupId = group?.id;

        await dispatch(getUsersGroupPermissions(groupId));
      },
      dispatch
    );
  };

/** Delete permission from the users group action */

export type TDeleteUsersGroupPermission = (id: number, uiItem: string) =>
  IDeleteUsersGroupPermissionAction;

export type THandleDeleteUsersGroupPermission = (groupId: number, uiItem: string) => Thunk<void>;

export const deleteUsersGroupPermission: TDeleteUsersGroupPermission = (groupId, uiItem) => ({
  type: ActionTypeKeys.DELETE_USERS_GROUP_PERMISSION,
  payload: api.deleteUsersGroupPermission(groupId, uiItem),
});

export const handleDeleteUsersGroupPermission:
  THandleDeleteUsersGroupPermission = (groupId, uiItem) =>
    async dispatch => {
      errorDecoratorUtil.withErrorHandler(
        async () => {
          await dispatch(deleteUsersGroupPermission(groupId, uiItem));
          dispatch(getUsersGroupPermissions(groupId));
        },
        dispatch
      );
    };

/** Add permissions to the users group action */

export type TAddUsersGroupPermissions = (data: IUsersGroupPermissionDataToSend) =>
  IAddUsersGroupPermissionsAction;

export type THandleAddUsersGroupPermissions = (data: Partial<IUsersGroupPermissionRequest>) =>
  Thunk<void>;

export const addGroupPermission: TAddUsersGroupPermissions = data => ({
  type: ActionTypeKeys.ADD_USERS_GROUP_PERMISSIONS,
  payload: api.addGroupPermission(data),
});

export const handleAddUsersGroupPermission: THandleAddUsersGroupPermissions = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const groupId = data?.usersGroupId;
        const normalizedData = normalizePermissionForSending(data);

        await dispatch(addGroupPermission(normalizedData));

        dispatch(getUsersGroupPermissions(groupId));
        dispatch(resetForm(FormName.ADD_USERS_GROUP_PERMISSIONS));
      },
      dispatch
    );
  };

/** Update permission action */

export type TUpdateUsersGroupPermission = (
  id: number,
  uiItem: string,
  data: Partial<IUsersGroupPermissionData>
) =>
  IUpdateUsersGroupPermissionAction;

export type THandleUpdateUsersGroupPermission = (
  data: Partial<IUsersGroupPermission>
) => Thunk<void>;

export const updateUsersGroupPermission: TUpdateUsersGroupPermission = (groupId, uiItem, data) => ({
  type: ActionTypeKeys.UPDATE_USERS_GROUP_PERMISSION,
  payload: api.updateUsersGroupPermission(groupId, uiItem, data),
});

export const handleUpdateUsersGroupPermission: THandleUpdateUsersGroupPermission = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const groupId = data?.usersGroupId;
        const uiItem = data?.uiItem;
        const normalizedData = normalizePermissionForUpdating(data);

        await dispatch(updateUsersGroupPermission(groupId, uiItem, normalizedData));
        dispatch(getUsersGroupPermissions(groupId));
      },
      dispatch
    );
  };

// ---------------------------------------------------------------------

/** Reset users groups action */

export type TResetUsersGroups = () => void;

export const resetUsersGroups: TResetUsersGroups = () => ({
  type: ActionTypeKeys.RESET_USERS_GROUPS,
});

export type TResetUsersGroup = () => void;

export const resetUsersGroup: TResetUsersGroup = () => ({
  type: ActionTypeKeys.RESET_USERS_GROUP,
});
