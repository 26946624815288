import { IStoreState } from 'store';

export const activeItemIdSelector = (state: IStoreState) => state.utils.activeItemId;

export const activeProductTypeSelector = (state: IStoreState) => state.utils.activeProductType;

export const activeDebitCreditIndicatorSelector = (state: IStoreState) =>
    state.utils.activeDebitCreditIndicator;

export const isReadOnlyPageSelector = (state: IStoreState) => state.utils.isReadOnlyActivePage;

export const isLockedItemSelector = (state: IStoreState) => state.utils.isLockedActiveItem;
