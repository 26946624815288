import { createSelector } from 'reselect';

import {
  dataTypesOptions,
  debitCreditIndicatorOptions,
  disabledTransactionTypesList,
  productTypesOptions,
} from 'consts';

import { IStoreState } from 'store';
import { createLoadingSelector } from 'store/domains/loader';
import { ActionTypeKeys } from './actionTypes';
import { valueLabelParse } from './utils';

import { stringsUtil } from 'utils';

/**
 * Account statuses selectors
 */

export const defaultDictionaryAccountStatusesSelector = (state: IStoreState) =>
  state.admin.dictionaries.accountStatuses;

export const dictionaryAccountStatusesOptionsSelector = createSelector(
  defaultDictionaryAccountStatusesSelector,
  data => data?.asMutable().map(el => {
    const { status, name } = el || {};

    return {
      value: status,
      label: name,
    };
  })
);

export const isAccountStatusesLoadedSelector = createSelector(
  defaultDictionaryAccountStatusesSelector,
  data => data?.length > 0
);

/**
 * Customer statuses selectors
 */

export const defaultDictionaryCustomerStatusesSelector = (state: IStoreState) =>
  state.admin.dictionaries.customerStatuses;

export const dictionaryCustomerStatusesOptionsSelector = createSelector(
  defaultDictionaryCustomerStatusesSelector,
  data => data?.asMutable().map(el => {
    const { id, description } = el || {};

    return {
      value: id,
      label: description,
    };
  })
);

export const isLoadingCustomerStatusesSelector = createLoadingSelector([
  ActionTypeKeys.GET_DICTIONARY_CUSTOMER_STATUSES,
]);

/**
 * Endpoint types selectors
 */

export const defaultDictionaryEndpointTypesSelector = (state: IStoreState) =>
  state.admin.dictionaries.endpointTypes;

export const endpointTypesOptionsSelector = createSelector(
  defaultDictionaryEndpointTypesSelector,
  data => data && valueLabelParse(data)
);

export const isEndpointTypesLoadedSelector = createSelector(
  defaultDictionaryEndpointTypesSelector,
  data => data?.length > 0
);

export const isLoadingEndpointsTypesSelector = createLoadingSelector([
  ActionTypeKeys.GET_DICTIONARY_ENDPOINT_TYPES,
]);

/**
 * Interface types selectors
 */

export const defaultDictionaryInterfaceTypesSelector = (state: IStoreState) =>
  state.admin.dictionaries.interfaceTypes;

export const interfaceTypesOptionsSelector = createSelector(
  defaultDictionaryInterfaceTypesSelector,
  data => data && valueLabelParse(data)
);

export const isInterfaceTypesLoadedSelector = createSelector(
  defaultDictionaryInterfaceTypesSelector,
  data => data?.length > 0
);

export const isLoadingInterfacesTypesSelector = createLoadingSelector([
  ActionTypeKeys.GET_DICTIONARY_INTERFACE_TYPES,
]);

/**
 * Transaction types selectors
 */

export const defaultDictionaryTransTypesSelector = (state: IStoreState) =>
  state.admin.dictionaries.transactionTypes;

export const dictionaryTransTypesSelector = createSelector(
  defaultDictionaryTransTypesSelector,
  data => data?.map(el => {
    const { description, id, debit_credit_indicator } = el || {};
    const DCInd = debitCreditIndicatorOptions
      .find(option => option.value === debit_credit_indicator);

    return {
      debitCreditIndicator: DCInd?.label,
      debitCreditIndicatorValue: debit_credit_indicator,
      description,
      id,
    };
  })
);

export const dictionaryTransTypesOptionsSelector = createSelector(
  defaultDictionaryTransTypesSelector,
  data => data?.asMutable().map(el => {
    const { debit_credit_indicator, description, id } = el || {};
    const debitCreditIndicatorName = debitCreditIndicatorOptions
      .find(option => option.value === debit_credit_indicator)?.label;
    const isDisabled = disabledTransactionTypesList.includes(id);

    return {
      value: id,
      label: `${id} - ${description} - [${debitCreditIndicatorName}]`,
      isDisabled,
      meta: { debitCreditIndicator: debit_credit_indicator },
    };
  })
);

export const isTransTypesLoadedSelector = createSelector(
  defaultDictionaryTransTypesSelector,
  data => data?.length > 0
);

export const transTypesOptionsForRulesSelector = createSelector(
  defaultDictionaryTransTypesSelector,
  data => data?.map(el => {
    const { description, id } = el || {};

    return {
      value: id,
      label: `${id} - ${description}`,
    };
  })
);

export const isTransTypesLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_DICTIONARY_TRANSACTION_TYPES,
]);

/**
 * Countries selectors
 */

export const defaultDictionaryCountriesSelector = (state: IStoreState) =>
  state.admin.dictionaries.countries;

export const dictionaryCountriesSelector = createSelector(
  defaultDictionaryCountriesSelector,
  data => data?.map(el => {
    const { alpha2_code, country_code, name, numeric_code } = el || {};

    return {
      alpha2Code: alpha2_code,
      countryCode: country_code,
      name,
      numericCode: stringsUtil.padStartN(numeric_code, 3),
    };
  })
);

export const countriesOptionsSelector = createSelector(
  defaultDictionaryCountriesSelector,
  data => data?.asMutable().map(el => {
    const { country_code, name } = el;

    return {
      value: country_code,
      label: `${country_code} - ${name}`,
    };
  })
);

export const isCountriesLoadedSelector = createSelector(
  dictionaryCountriesSelector,
  data => data?.length > 0
);

export const isCountriesLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_DICTIONARY_COUNTRIES,
]);

/**
 * Currencies selectors
 */

export const defaultDictionaryCurrenciesSelector = (state: IStoreState) =>
  state.admin.dictionaries.currencies;

export const dictionaryCurrenciesSelector = createSelector(
  defaultDictionaryCurrenciesSelector,
  data => data?.map(el => {
    const { currency_code, name, numeric_code } = el || {};

    return {
      currencyCode: currency_code,
      name,
      numericCode: stringsUtil.padStartN(numeric_code, 3),
    };
  })
);

export const currenciesOptionsSelector = createSelector(
  defaultDictionaryCurrenciesSelector,
  data => data?.asMutable().map(el => {
    const { currency_code, name } = el || {};

    return {
      value: currency_code,
      label: `${currency_code} - ${name}`,
    };
  })
);

export const currencyNumsOptionsSelector = createSelector(
  defaultDictionaryCurrenciesSelector,
  data => data?.asMutable().map(el => {
    const { currency_code, name, numeric_code } = el || {};

    return {
      value: numeric_code,
      label: `${currency_code} - ${name}`,
    };
  })
);

export const isCurrenciesLoadedSelector = createSelector(
  defaultDictionaryCurrenciesSelector,
  data => data?.length > 0
);

export const isCurrenciesLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_DICTIONARY_CURRENCIES,
]);

/**
 * Events selectors
 */

export const defaultDictionaryEventsSelector = (state: IStoreState) =>
  state.admin.dictionaries.events;

export const dictionaryEventsSelector = createSelector(
  defaultDictionaryEventsSelector,
  data => data?.asMutable().map(el => {
    const { id, name, product_type } = el || {};
    const productTypeName = productTypesOptions
      .find(option => option.value === product_type)?.label;

    return {
      value: id,
      label: name,
      productType: product_type,
      productTypeName,
    };
  })
);

export const dictionaryEventsOptionsSelector = createSelector(
  defaultDictionaryEventsSelector,
  data => data?.asMutable().map(el => {
    const { id, name, product_type } = el;
    const productTypeName = productTypesOptions
      .find(option => option.value === product_type)?.label;

    return {
      value: id,
      label: `${name} ${productTypeName ? `(${productTypeName})` : ''}`,
    };
  })
);

export const isEventsLoadingSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_DICTIONARY_EVENTS,
]);

/**
 * Event data element selectors
 */

export const defaultDictionaryEventDataElemsSelector = (state: IStoreState) =>
  state.admin.dictionaries.eventDataElems;

export const dictionaryEventDataElemsSelector = createSelector(
  defaultDictionaryEventDataElemsSelector,
  defaultDictionaryEventsSelector,
  (dataElems, events) => dataElems?.map(el => {
    const { description, event_id, name, data_type } = el || {};

    return {
      dataType: dataTypesOptions.find(option => option.value === data_type)?.label,
      description,
      event: events.find(event => event.id === event_id)?.name,
      eventId: event_id,
      name,
    };
  })
);

export const eventDataElemsOptionsForRulesSelector = createSelector(
  defaultDictionaryEventDataElemsSelector,
  data => data?.map(el => {
    const { description, name, data_type } = el || {};
    const dataType = dataTypesOptions.find(type => type.value === data_type);

    return {
      value: name,
      label: `${description} (${dataType?.label})`,
    };
  })
);

export const isEventDataElemsLoadingSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_DICTIONARY_EVENT_DATA_ELEMS,
]);

/**
 * Repayment methods selectors
 */

export const defaultDictionaryRepaymentMethodsSelector = (state: IStoreState) =>
  state.admin.dictionaries.repaymentMethods;

export const dictionaryRepaymentMethodsOptionsSelector = createSelector(
  defaultDictionaryRepaymentMethodsSelector,
  data => {
    return data?.asMutable().map(el => {
      const { id, description } = el;

      return {
        value: id,
        label: description,
      };
    });
  }
);

export const isRepaymentMethodsLoadedSelector = createSelector(
  defaultDictionaryRepaymentMethodsSelector,
  data => data?.length > 0
);

export const isRepaymentMethodsLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_DICTIONARY_REPAYMENT_METHODS,
]);

/**
 * Repayment types selectors
 */

export const defaultDictionaryRepaymentTypesSelector = (state: IStoreState) =>
  state.admin.dictionaries.repaymentTypes;

export const dictionaryRepaymentTypesOptionsSelector = createSelector(
  defaultDictionaryRepaymentTypesSelector,
  data => data?.asMutable().map(el => {
    const { id, description } = el;

    return {
      value: id,
      label: description,
    };
  })
);

export const isRepaymentTypesLoadedSelector = createSelector(
  defaultDictionaryRepaymentTypesSelector,
  data => data?.length > 0
);

export const isRepaymentTypesLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_DICTIONARY_REPAYMENT_TYPES,
]);
