import React from 'react';
import { Link } from 'react-router-dom';

import { Box } from '@rebass/grid';

import styled from 'theme';

import { ChevronRightIcon } from './../../Icons';
import { NavListWrapper } from './NavListWrapper';

import {
  basePath,
  disabledScreensList,
  ScreenType,
} from 'consts';

import { clearMenu, menuClasses, toggleOpenMenu } from './utils';

import { IPermission } from 'store';

const ChevronIconStyled = styled(ChevronRightIcon)`
  color: ${({ theme }) => theme.colors.darkGray};
`;

interface INavbar {
  permissions: Array<IPermission>;
}

const Navbar: React.FC<INavbar> = ({ permissions }) => {
  const menuRef = React.useRef(null);

  const renderItem = (item: IPermission) => {
    const { id, parentId, title, type, separator } = item;
    const hasChildren = type === ScreenType.MENU_PARENT;

    return (
      <Box
        key={id}
        className={`${menuClasses.MENU_ITEM} ${separator ? 'is-separator' : ''}`}
        onMouseEnter={e => toggleOpenMenu(e)}
      >

        {!hasChildren && (
          <Link
            to={`${basePath}${id}`}
            className={`
              ${menuClasses.MENU_TITLE}
              ${disabledScreensList.find(el => el === item.id) ? 'is-disabled' : 'is-cursor-pointer'}
            `}
            onClick={clearMenu}
          >
            {title}
          </Link>
        )}

        {hasChildren && (
          <div className={menuClasses.MENU_TITLE}>
            {title}
            {hasChildren && parentId && (
              <ChevronIconStyled
                className="icon"
                size="16"
              />
            )}
          </div>
        )}

        {hasChildren && renderMenu(id)}

      </Box>
    );
  };

  const renderMenu = (id?: string) => id && (
    <NavListWrapper className={menuClasses.SUB_MENU}>
      {permissions?.map(item => item.parentId === id && renderItem(item))}
    </NavListWrapper>
  );

  return (
    <NavListWrapper
      ref={menuRef}
      className={menuClasses.MENU}
      onMouseLeave={clearMenu}
    >
      {permissions?.map(item => !item.parentId && renderItem(item))}
    </NavListWrapper>
  );
};

export default Navbar;
