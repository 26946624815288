import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { ImmutableArray } from 'seamless-immutable';

import { Box, Flex } from '@rebass/grid';

import { Button, SelectField } from 'components';
import { FormName, IconName } from 'consts';
import {
  IUsersGroup,
  IUsersGroupMember,
  THandleAddUsersGroupMember,
  THandleFilterUsers,
} from 'store';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface IEditUsersGroupMembersForm {
  addUsersGroupMember: THandleAddUsersGroupMember;
  filterUsers: THandleFilterUsers;
  isAdding: boolean;
  isUsersLoading: boolean;
  usersGroup: IUsersGroup;
  usersGroupMembers: ImmutableArray<IUsersGroupMember>;
  usersOptions: Array<ISelectValue>;
}

type TEditUsersGroupMembers = IEditUsersGroupMembersForm &
  InjectedFormProps<{}, IEditUsersGroupMembersForm>;

const EditUsersGroupMembersForm: React.FC<TEditUsersGroupMembers> = ({
  addUsersGroupMember,
  filterUsers,
  handleSubmit,
  isAdding,
  isUsersLoading,
  pristine,
  usersGroup,
  usersGroupMembers,
  usersOptions,
}) => {
  React.useEffect(
    () => {
      const filterValues = {
        institutionId: {
          value: usersGroup?.institutionId,
          label: usersGroup?.institutionName,
        },
      };

      filterUsers(filterValues);
    },
    [filterUsers, usersGroup]
  );

  const usersOptionsFiltered = React.useMemo(
    () => {
      const users: Array<ISelectValue> = [];

      usersOptions.forEach(option => {
        const isMember = usersGroupMembers.find(member => member.userId === option.value);

        if (!isMember) {
          users.push(option);
        }
      });

      return users;
    },
    [usersOptions, usersGroupMembers]
  );

  // eslint-disable-next-line
  const handleSubmitForm = React.useCallback(
    handleSubmit(data => addUsersGroupMember({
      usersGroupId: usersGroup?.id,
      ...data,
    })),
    [handleSubmit, addUsersGroupMember]
  );

  return (
    <form onSubmit={handleSubmitForm}>
      <Flex alignItems="flex-end">
        <Box width={[3 / 4]} mb="15px" mr="10px">
          <Field
            id="user"
            name="user"
            placeholder="Select User"
            component={SelectField}
            label="User"
            options={usersOptionsFiltered}
            isDisabled={isAdding}
            isLoading={isUsersLoading}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>
        <Box width={[1 / 4]} pb="15px">
          <Button
            iconName={IconName.ADD}
            text="Add to the group"
            isLoading={isAdding}
            disabled={pristine}
          />
        </Box>
      </Flex>
    </form >
  );
};

export default reduxForm<{}, IEditUsersGroupMembersForm>({
  form: FormName.DETAILS_USERS_GROUP_MEMBERS,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(EditUsersGroupMembersForm);
