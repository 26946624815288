import React from 'react';
import { CellInfo } from 'react-table';
import { ImmutableArray } from 'seamless-immutable';

import { Flex } from '@rebass/grid';

import {
  Button,
  Table,
  TableCell,
  TableHeader,
  withSpinner,
} from 'components';

import { IconName } from 'consts';

import {
  IProductReward,
  THandleDeleteProductReward,
} from 'store';

import { ITableCell } from 'types';

type TCell<T extends keyof IProductReward> = ITableCell<IProductReward[T]>;

interface IRewardsTable {
  deleteReward: THandleDeleteProductReward;
  isReadOnly: boolean;
  isDeleting: boolean;
  rewards: ImmutableArray<IProductReward>;
}

const RewardsTable: React.FC<IRewardsTable> = ({
  deleteReward,
  isReadOnly,
  isDeleting,
  rewards,
}) => {

  const tableColumns = React.useMemo(
    () => [
      {
        maxWidth: 75,
        accessor: 'rewardId',
        Header: <TableHeader title="Reward ID" />,
        Cell: (props: TCell<'rewardId'>) => (
          <TableCell
            value={props.value}
            isNumber={true}
          />
        ),
      },
      {
        maxWidth: 400,
        accessor: 'description',
        Header: <TableHeader title="Description" />,
        Cell: (props: TCell<'description'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 75,
        accessor: 'rate',
        Header: <TableHeader title="Rate" />,
        Cell: (props: TCell<'rate'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 75,
        accessor: 'amount',
        Header: <TableHeader title="Amount" />,
        Cell: (props: TCell<'amount'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 400,
        accessor: 'rewardApplicationConditionName',
        Header: <TableHeader title="Application Condition" />,
        Cell: (props: TCell<'rewardApplicationConditionName'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 50,
        accessor: 'deleteButton',
        Cell: (cellInfo: CellInfo) => !isReadOnly && (
          <Flex
            justifyContent="center"
            width="100%"
            p="4px"
          >
            <Button
              iconName={IconName.DELETE}
              title="Delete"
              withConfirmation={true}
              confirmationText={`Confirm you want to delete "${cellInfo.original.description}"?`}
              classNames={['is-circle']}
              onClick={() => deleteReward(cellInfo.original)}
              isLoading={isDeleting}
            />
          </Flex>
        ),
      },
    ],
    [deleteReward, isReadOnly, isDeleting]
  );

  return (
    <Table
      columns={tableColumns}
      data={rewards}
      pageSize={10}
    />
  );
};

export default withSpinner()(RewardsTable);
