import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import AprsForm from './AprsForm';

import {
  handleAddProductApr,
  handleDeleteProductApr,
  handleUpdateProductApr,
  isProductAprAddingSelector,
  isProductAprDeletingSelector,
  isProductAprUpdatingSelector,
  IStoreState,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  isAdding: isProductAprAddingSelector(state),
  isDeleting: isProductAprDeletingSelector(state),
  isUpdating: isProductAprUpdatingSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    addProductApr: handleAddProductApr,
    deleteProductApr: handleDeleteProductApr,
    updateProductApr: handleUpdateProductApr,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AprsForm);
