import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  aprCalcTypesOptions,
  aprTypesOptions,
  FormName,
  IconName,
} from 'consts';

import {
  ActionButtons,
  Button,
  CheckboxField,
  InputField,
  NewLine,
  NumberFormatField,
  SelectField,
  TextareaField,
} from 'components';

import {
  IProductApr,
  THandleAddProductApr,
  THandleDeleteProductApr,
  THandleUpdateProductApr,
} from 'store';

import { formErrorUtil } from 'utils';

interface IAprsForm {
  addProductApr: THandleAddProductApr;
  deleteProductApr: THandleDeleteProductApr;
  initialValues?: IProductApr;
  isAdding: boolean;
  isDeleting: boolean;
  isUpdateMode?: boolean;
  isUpdating: boolean;
  onCancel?: () => void;
  updateProductApr: THandleUpdateProductApr;
}

type TAprsForm = IAprsForm & InjectedFormProps<{}, IAprsForm>;

const AprsForm: React.FC<TAprsForm> = ({
  addProductApr,
  deleteProductApr,
  dirty,
  handleSubmit,
  initialValues,
  isAdding,
  isDeleting,
  isUpdateMode = false,
  isUpdating,
  onCancel,
  pristine,
  updateProductApr,
}) => (
  <form onSubmit={handleSubmit(isUpdateMode ? updateProductApr : addProductApr)}>
    <Flex
      alignItems="flex-start"
      flexDirection="row"
      flexWrap="wrap"
      mx="-8px"
    >
      <Box width={[1 / 2]}>
        <Box p="8px">
          <Field
            id="description"
            name="description"
            component={TextareaField}
            label="Description"
            placeholder="Enter Description"
            disabled={isAdding || isUpdating || isDeleting}
            height={96}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>

        {!isUpdateMode && (
          <Flex>
            <Box p="8px">
              <Field
                id="alwaysChargeInterest"
                name="alwaysChargeInterest"
                component={CheckboxField}
                label="Always Charge Interest"
                disabled={isAdding || isUpdating || isDeleting}
              />
            </Box>
            <Box p="8px">
              <Field
                id="allowResidualInterest"
                name="allowResidualInterest"
                component={CheckboxField}
                label="Allow Residual Interest"
                disabled={isAdding || isUpdating || isDeleting}
              />
            </Box>
          </Flex>
        )}

        {isUpdateMode && (
          <>
            <Box p="8px" width={[1]}>
              <Field
                id="alwaysChargeInterestToBeChanged"
                name="alwaysChargeInterest"
                component={CheckboxField}
                label="Always Charge Interest"
                disabled={isAdding || isUpdating || isDeleting}
              />
            </Box>
            <Box p="8px">
              <Field
                id="allowResidualInterestToBeChanged"
                name="allowResidualInterest"
                component={CheckboxField}
                label="Allow Residual Interest"
                disabled={isAdding || isUpdating || isDeleting}
              />
            </Box>
          </>
        )}
      </Box>

      <Flex
        alignItems="flex-end"
        flexWrap="wrap"
        width={[1 / 2]}
      >
        {isUpdateMode && (
          <>
            <Box width={[1 / 2]} p="8px">
              <Field
                id="productId"
                name="productId"
                component={InputField}
                label="Product ID"
                isNumber={true}
                disabled={true}
              />
            </Box>
            <Box width={[1 / 2]} p="8px">
              <Field
                id="productAprId"
                name="productAprId"
                component={InputField}
                label="APR ID"
                isNumber={true}
                disabled={true}
              />
            </Box>
            <NewLine />
          </>
        )}

        <Box width={[1 / 2]} p="8px">
          <Field
            id="calculationMethod"
            name="calculationMethod"
            component={SelectField}
            label="Calculation Method"
            options={aprCalcTypesOptions}
            placeholder="Select Method"
            isDisabled={isAdding || isUpdating || isDeleting}
            isClearable={false}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>
        <Box width={[1 / 2]} p="8px">
          <Field
            id="rate"
            name="rate"
            component={NumberFormatField}
            label="Rate %"
            disabled={isAdding || isUpdating || isDeleting}
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            validate={[
              formErrorUtil.isRequired,
              formErrorUtil.isPositive,
            ]}
          />
        </Box>

        <NewLine />

        <Box width={[1 / 2]} p="8px">
          <Field
            id="aprType"
            name="aprType"
            component={SelectField}
            label="APR Type"
            options={aprTypesOptions}
            placeholder="Select APR Type"
            isDisabled={isAdding || isUpdating || isDeleting}
            isClearable={false}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>
        <Box width={[1 / 2]} p="8px">
          <Field
            id="spendTypeDesc"
            name="spendTypeDesc"
            component={InputField}
            label="Spend Type"
            placeholder="ATM / POS / ECOM"
            disabled={isAdding || isUpdating || isDeleting}
            isClearable={false}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>
      </Flex>
    </Flex>

    {!isUpdateMode && (
      <Flex
        justifyContent="flex-end"
        mt="-32px"
      >
        <Button
          text="Add"
          iconName={IconName.ADD}
          disabled={pristine}
          isLoading={isAdding}
        />
      </Flex>
    )}

    {isUpdateMode && (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mt="10px"
      >
        <Button
          text="Delete"
          iconName={IconName.DELETE}
          type="reset"
          withConfirmation={true}
          confirmationText={`Confirm you want to delete APR "${initialValues.description}"?`}
          onClick={() => deleteProductApr({
            productId: initialValues.productId,
            productAprId: initialValues.productAprId,
          })}
          isLoading={isDeleting}
        />

        <ActionButtons
          okText="Save"
          cancelText="Close"
          onCancel={onCancel}
          withCancelConfirmation={dirty}
          disabledOk={pristine || isUpdating || isDeleting}
          isLoadingOk={isUpdating}
        />
      </Flex>
    )}
  </form>
);

export default reduxForm<{}, IAprsForm>({
  form: FormName.PRODUCT_APR,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AprsForm);
