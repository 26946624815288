import React from 'react';

import { Modal, withSpinner } from 'components';
import { ModalName } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import { TResetUsersGroup } from 'store';

import { EditUsersGroupForms } from './../../forms';

interface IDetailsUsersGroupModal extends IWithModal {
  groupName: string;
  isGeneralInfoFormDirty: boolean;
  isGroupPermissionFormDirty: boolean;
  isUsersGroupMembersFormDirty: boolean;
  resetUsersGroup: TResetUsersGroup;
}

const modalName = ModalName.DETAILS_USERS_GROUP;

const DetailsUsersGroupModal: React.FC<IDetailsUsersGroupModal> = ({
  closeModal,
  groupName,
  isGeneralInfoFormDirty,
  isGroupPermissionFormDirty,
  isReadOnlyPage,
  isUsersGroupMembersFormDirty,
  resetUsersGroup,
}) => {
  React.useEffect(
    () => {
      return () => resetUsersGroup();
    },
    [resetUsersGroup]
  );

  const isAnyFormDirty = React.useMemo(
    () => {
      return isGeneralInfoFormDirty || isUsersGroupMembersFormDirty || isGroupPermissionFormDirty;
    },
    [isGeneralInfoFormDirty, isUsersGroupMembersFormDirty, isGroupPermissionFormDirty]
  );

  return (
    <Modal
      name={modalName}
      title={groupName}
      minContainerHeight="360px"
      withCloseConfirmation={isAnyFormDirty}
    >
      <EditUsersGroupForms
        onCancel={() => closeModal(modalName)}
        isAnyFormDirty={isAnyFormDirty}
        isReadOnly={isReadOnlyPage}
      />
    </Modal>
  );
};

export default withSpinner({
  isFixed: true,
})(withModal(DetailsUsersGroupModal));
