import { Action, } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { basePath, ModalName, ResponseStatusCode } from 'consts';
import { apiClientService } from 'services';
import { IStoreState } from 'store';
import { IMessageResponse } from 'types';
import { storageUtil, urlUtil } from 'utils';

import { openModal } from './../modals';
import {
  ActionTypeKeys,
  ISetActiveItemAttributesAction,
  ISetActivePagePermissionAction,
} from './actionTypes';

import { IActiveItemAttributes, INotification } from './types';

/**
 * Set active item ID util action
 */

export type TSetActiveItemAttributes = (data: IActiveItemAttributes) =>
  ISetActiveItemAttributesAction;

export const setActiveItemAttributes: TSetActiveItemAttributes = data => ({
  type: ActionTypeKeys.SET_ACTIVE_ITEM_ATTRIBUTES,
  payload: data,
});

/**
 * Set permission for active page util action
 */

export type TSetActivePagePermission = (value: boolean) => ISetActivePagePermissionAction;

export const setActivePagePermission: TSetActivePagePermission = value => ({
  type: ActionTypeKeys.SET_ACTIVE_PAGE_PERMISSION,
  payload: value,
});

/**
 * Reset utils action
 */

export type TResetUtils = () => void;

export const resetUtils: TResetUtils = () => ({
  type: ActionTypeKeys.RESET_UTILS,
});

/**
 * Notification action
 */

export type TSendNotification = (res: IMessageResponse, isCatch?: boolean) =>
  (dispatch: ThunkDispatch<IStoreState, {}, Action>) => void;

const getNotification = (data: INotification) => openModal({
  name: ModalName.MESSAGE,
  payload: data,
});

export const handleSendNotification: TSendNotification = (res, isCatch = false) =>
  async dispatch => {
    if (isCatch) {
      if (res?.body?.response_status) {
        const { error_message, status_message, status_code } = res.body.response_status;

        const isRelogin = [
          ResponseStatusCode.SESSION_TIMEOUT,
          ResponseStatusCode.USER_NOT_AUTHENTICATED,
        ].includes(status_code as ResponseStatusCode);

        if (isRelogin) {
          storageUtil.clear();

          apiClientService.clear();

          dispatch(getNotification({
            title: 'Info',
            message: error_message || status_message,
            isError: true,
            onClose: () => urlUtil.openLocation(`${basePath}login`),
          }));
        } else {
          dispatch(getNotification({
            title: 'Info',
            message: error_message || status_message,
            isError: true,
          }));
        }
      } else {
        dispatch(getNotification({
          title: 'Error',
          message: (res?.error?.message) ? res.error.message.toString() : '',
          isError: true,
        }));
      }
    }
  };
