import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  currentUsersGroupSelector,
  handleDeleteUsersGroupPermission,
  handleGetUsersGroupPermissions,
  handleUpdateUsersGroupPermission,
  isDeletingUsersGroupPermissionSelector,
  isLoadingUsersGroupPermissionsSelector,
  IStoreState,
  isUpdatingUsersGroupPermissionSelector,
  usersGroupPermissionsSelector,
} from 'store';

import UsersGroupPermissions from './UsersGroupPermissions';

const mapStateToProps = (state: IStoreState) => ({
  isDeleting: isDeletingUsersGroupPermissionSelector(state),
  isLoading: isLoadingUsersGroupPermissionsSelector(state),
  isUpdating: isUpdatingUsersGroupPermissionSelector(state),
  permissions: usersGroupPermissionsSelector(state),
  usersGroup: currentUsersGroupSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getUsersGroupPermissions: handleGetUsersGroupPermissions,
    deleteUsersGroupPermission: handleDeleteUsersGroupPermission,
    updateUsersGroupPermission: handleUpdateUsersGroupPermission,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersGroupPermissions);
