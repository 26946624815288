import { createSelector } from 'reselect';

import { IStoreState } from 'store';
import { countriesOptionsSelector, dictionaryCustomerStatusesOptionsSelector } from 'store/domains/admin';
import { createLoadingSelector } from 'store/domains/loader';
import { ActionTypeKeys } from './actionTypes';
import {
  normalizeCommonCustomer,
  normalizeCurrentCustomer,
} from './utils';

/**
 * Customers selectors
 */

export const defaultCustomersSelector = (state: IStoreState) => state.ledger.customers.customers;

export const customersSelector = createSelector(
  defaultCustomersSelector,
  customers => customers?.map(customer => normalizeCommonCustomer(customer))
);

export const customersTotalPagesSelector = (state: IStoreState) =>
  state.ledger.customers.totalPages;

export const customersTotalResultsSelector = (state: IStoreState) =>
  state.ledger.customers.totalResults;

export const customersCurrentPageSelector = (state: IStoreState) =>
  state.ledger.customers.pageNumber;

/**
 * Current customer selectors
 */

export const defaultCurrentCustomerSelector = (state: IStoreState) =>
  state.ledger.customers.currentCustomer;

export const currentCustomerSelector = createSelector(
  defaultCurrentCustomerSelector,
  countriesOptionsSelector,
  dictionaryCustomerStatusesOptionsSelector,
  (
    customer,
    countriesOptions,
    customerStatusOptions
  ) => normalizeCurrentCustomer(
    customer,
    countriesOptions,
    customerStatusOptions
  )
);

export const currentCustomerCountryCodeSelector = createSelector(
  defaultCurrentCustomerSelector,
  data => data?.address_country_code
);

/**
 * Customer loading selectors
 */

export const isLoadingCustomersSelector = createLoadingSelector([
  ActionTypeKeys.FILTER_CUSTOMERS,
]);

export const isGettingCustomerSelector = createLoadingSelector([
  ActionTypeKeys.GET_CUSTOMER,
]);

export const isUpdatingCustomerSelector = createLoadingSelector([
  ActionTypeKeys.UPDATE_CUSTOMER,
]);

export const isAddingCustomerSelector = createLoadingSelector([
  ActionTypeKeys.ADD_CUSTOMER,
]);
