import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { ImmutableArray } from 'seamless-immutable';

import { Box, Flex } from '@rebass/grid';

import { Button, NumberFormatField, SelectField, TextareaField } from 'components';
import {
  ApplicationCondition,
  applicationConditionOptions,
  FormName,
  IconName,
} from 'consts';
import { THandleAddProductFee } from 'store';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface IFeeForm {
  addFee: THandleAddProductFee;
  applicationCondition: ISelectValue;
  aprsOptions: ImmutableArray<ISelectValue>;
  isAprsLoading: boolean;
  isAprsVisible: boolean;
  isDisabled: boolean;
  isAdding: boolean;
}

type TFeeForm = IFeeForm & InjectedFormProps<{}, IFeeForm>;

const FeeForm: React.FC<TFeeForm> = ({
  addFee,
  applicationCondition,
  aprsOptions,
  handleSubmit,
  isAprsLoading,
  isAprsVisible,
  isDisabled,
  isAdding,
  pristine,
}) => {
  const isOnlyAmount = React.useMemo(
    () => applicationCondition?.value === ApplicationCondition.APPLY_ONLY_FIXED_AMOUNT,
    [applicationCondition]
  );

  const isOnlyRate = React.useMemo(
    () => applicationCondition?.value === ApplicationCondition.APPLY_ONLY_RATE,
    [applicationCondition]
  );

  const rateValidators = React.useMemo(
    () => !isOnlyAmount ? [ formErrorUtil.isRequired, formErrorUtil.isPositive ] : null,
    [isOnlyAmount]
  );

  const amountValidators = React.useMemo(
    () => !isOnlyRate ? [ formErrorUtil.isRequired, formErrorUtil.isPositive ] : null,
    [isOnlyRate]
  );

  return (
    <form onSubmit={handleSubmit(addFee)}>
      <Flex
        alignItems="flex-start"
        flexDirection="row"
        flexWrap="wrap"
        mx="-8px"
      >
        <Box width={[1 / 2]} p="8px">
          <Field
            id="description"
            name="description"
            component={TextareaField}
            label="Description"
            placeholder="Enter Description"
            disabled={isDisabled}
            height={96}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>
        <Flex
          flexWrap="wrap"
          width={[1 / 2]}
        >
          <Box width={[1]} p="8px">
            <Field
              id="feeApplicationCondition"
              name="feeApplicationCondition"
              component={SelectField}
              label="Application Condition"
              options={applicationConditionOptions}
              placeholder="Select Condition"
              isDisabled={isDisabled}
              validate={[formErrorUtil.isRequired]}
            />
          </Box>
          {isAprsVisible && (
            <Box width={[3 / 5]} p="8px">
              <Field
                id="apr"
                name="apr"
                component={SelectField}
                label="APR"
                isLoading={isAprsLoading}
                options={aprsOptions}
                placeholder="Select APR"
                isDisabled={isDisabled}
                validate={[formErrorUtil.isRequired]}
              />
            </Box>
          )}
          <Box width={[1 / 5]} p="8px">
            <Field
              id="rate"
              name="rate"
              component={NumberFormatField}
              label="Rate %"
              disabled={isDisabled || isOnlyAmount}
              placeholder="0.00"
              fixedDecimalScale={true}
              decimalScale={2}
              validate={rateValidators}
            />
          </Box>
          <Box width={[1 / 5]} p="8px">
            <Field
              id="amount"
              name="amount"
              component={NumberFormatField}
              label="Amount"
              disabled={isDisabled || isOnlyRate}
              placeholder="0.00"
              fixedDecimalScale={true}
              decimalScale={2}
              validate={amountValidators}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex
        justifyContent="flex-end"
        mt="5px"
      >
        <Button
          text="Add"
          isLoading={isAdding}
          iconName={IconName.ADD}
          disabled={pristine}
        />
      </Flex>
    </form>
  );
};

export default reduxForm<{}, IFeeForm>({
  form: FormName.PRODUCT_FEE,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(FeeForm);
