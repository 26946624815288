import { connect } from 'react-redux';
import { isDirty } from 'redux-form';

import { FormName } from 'consts';

import AddInterfaceModal from './AddInterfaceModal';

import { IStoreState } from 'store';

const dirty = isDirty(FormName.INTERFACE);

const mapStateToProps = (state: IStoreState) => ({
  isFormDirty: dirty(state),
});

export default connect(
  mapStateToProps
)(AddInterfaceModal);
