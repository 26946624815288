import React from 'react';
import { Field } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  InputField,
  MaskField,
  NewLine,
  SelectField,
} from 'components';
import {
  DateFormat,
  MaskFormat,
  productTypesOptions,
} from 'consts';
import { formErrorUtil } from 'utils';

interface IStatementsFilter {
  isDisabled: boolean;
}

const StatementsFilter: React.FC<IStatementsFilter> = ({
  isDisabled,
}) => (
  <Flex alignItems="flex-start" flexWrap="wrap">
    <Box width="150px" p="8px">
      <Field
        id="customerId"
        name="customerId"
        component={InputField}
        label="Customer ID"
        placeholder="ID"
        isNumber={true}
        disabled={isDisabled}
        validate={[
          formErrorUtil.isInteger,
          formErrorUtil.isStrictPositive,
        ]}
      />
    </Box>
    <Box width="240px" p="8px">
      <Field
        id="productType"
        name="productType"
        component={SelectField}
        label="Product Type"
        placeholder="Select Type"
        options={productTypesOptions}
        isDisabled={isDisabled}
        isRequired={true}
        validate={[formErrorUtil.isRequired]}
      />
    </Box>
    <Box width="360px" p="8px">
      <Field
        id="firstName"
        name="firstName"
        component={InputField}
        label="First Name"
        placeholder="Enter First Name"
        disabled={isDisabled}
      />
    </Box>

    <NewLine />

    <Box width="150px" p="8px">
      <Field
        id="accountId"
        name="accountId"
        component={InputField}
        label="Account ID"
        placeholder="ID"
        isNumber={true}
        disabled={isDisabled}
        validate={[
          formErrorUtil.isInteger,
          formErrorUtil.isStrictPositive,
        ]}
      />
    </Box>
    <Box width="120px" p="8px">
      <Field
        id="dateFrom"
        name="dateFrom"
        component={MaskField}
        label="Date from"
        placeholder={DateFormat.DATE}
        mask={MaskFormat.DATE}
        disabled={isDisabled}
        validate={[
          formErrorUtil.isDate,
        ]}
      />
    </Box>
    <Box width="120px" p="8px">
      <Field
        id="dateTo"
        name="dateTo"
        component={MaskField}
        label="Date to"
        placeholder={DateFormat.DATE}
        mask={MaskFormat.DATE}
        disabled={isDisabled}
        validate={[
          formErrorUtil.isDate,
        ]}
      />
    </Box>
    <Box width="360px" p="8px">
      <Field
        id="lastName"
        name="lastName"
        component={InputField}
        label="Last Name"
        placeholder="Enter Last Name"
        disabled={isDisabled}
      />
    </Box>
  </Flex>
);

export default StatementsFilter;
