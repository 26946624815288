import { TApiResponse } from 'types';
import { IApiCallDataResp, IApiCalls } from './types';

export enum ActionTypeKeys {
  /** Get apiCall action type keys */
  GET_API_CALL = 'apiCalls/GET_API_CALL',
  GET_API_CALL_FULFILLED = 'apiCalls/GET_API_CALL_FULFILLED',
  GET_API_CALL_REJECTED = 'apiCalls/GET_API_CALL_REJECTED',

  /** Filter apiCalls action type keys */
  FILTER_API_CALLS = 'apiCalls/FILTER_API_CALLS',
  FILTER_API_CALLS_FULFILLED = 'apiCalls/FILTER_API_CALLS_FULFILLED',
  FILTER_API_CALLS_REJECTED = 'apiCalls/FILTER_API_CALLS_REJECTED',

  /** Reset apiCalls action type keys */
  RESET_API_CALLS = 'apiCalls/RESET_API_CALLS',
}

/** Get API call action interfaces */

export interface IGetApiCallAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_API_CALL;
}

export interface IGetApiCallFulfilledAction {
  readonly payload: IApiCallDataResp;
  readonly type: ActionTypeKeys.GET_API_CALL_FULFILLED;
}

export interface IGetApiCallRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_API_CALL_REJECTED;
}

/** Filter API calls action interfaces */

export interface IFilterApiCallsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_API_CALLS;
}

export interface IFilterApiCallsFulfilledAction {
  readonly payload: IApiCalls;
  readonly type: ActionTypeKeys.FILTER_API_CALLS_FULFILLED;
}

export interface IFilterApiCallsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_API_CALLS_REJECTED;
}

/** Reset API calls action interfaces */

export interface IResetApiCallsAction {
  readonly type: ActionTypeKeys.RESET_API_CALLS;
}

export type TApiCallsAction =
  | IFilterApiCallsFulfilledAction
  | IGetApiCallFulfilledAction
  | IResetApiCallsAction;
