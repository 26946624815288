import Immutable, { ImmutableObject } from 'seamless-immutable';

import { ActionTypeKeys, TAuthAction } from './actionTypes';
import { ILoginState } from './types';

export const loginInitialState: ImmutableObject<ILoginState> = Immutable({
  authKey: null,
  currentAuthRegistrationStep: null,
});

const loginReducer = (state = loginInitialState, action: TAuthAction) => {
  switch (action.type) {
    case ActionTypeKeys.LOGOUT_FULFILLED:
      return state = loginInitialState;

    case ActionTypeKeys.GENERATE_AUTH_KEY_FULFILLED:
      return state
        .set('authKey', action.payload.auth_key)
        .set('currentAuthRegistrationStep', 2);

    case ActionTypeKeys.SET_CURRENT_REGISTER_STEP:
      return state
        .set('authKey', null)
        .set('currentAuthRegistrationStep', action.payload);

    default: return state;
  }
};

export default loginReducer;
