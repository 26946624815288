import React from 'react';

import { Box, Flex } from '@rebass/grid';

import {
  Modal,
  ProductTypeIcons,
  T2,
  Text,
 } from 'components';

import {
  ModalName,
  ProductType,
  TransactionTypeId,
} from 'consts';

import {
  IWithLoadTransactionTypes,
  IWithModal,
  withLoadTransactionTypes,
  withModal,
} from 'HOCs';

import {
  IManualTransactionInitialValues,
  THandleGetDictionaryCurrencies,
  THandleMakeTransaction,
} from 'store';

import { ISelectValue } from 'types';

import ManualTransactionForm from './ManualTransactionForm';

interface IManualTransactionModal extends IWithModal, IWithLoadTransactionTypes {
  currenciesNumOptions: Array<ISelectValue>;
  currenciesOptions: Array<ISelectValue>;
  getCurrencies: THandleGetDictionaryCurrencies;
  initialValues: IManualTransactionInitialValues;
  isCurrenciesLoading: boolean;
  isLoading: boolean;
  makeTransaction: THandleMakeTransaction;
  transactionType: ISelectValue;
}
const modalName = ModalName.MANUAL_TRANSACTION;

const ManualTransactionModal: React.FC<IManualTransactionModal> = ({
  closeModal,
  currenciesNumOptions,
  currenciesOptions,
  getCurrencies,
  initialValues,
  isCurrenciesLoading,
  isLoading,
  isTransTypesLoading,
  makeTransaction,
  transactionType,
  transactionTypesOptions,
}) => {
  React.useEffect(
    () => {
      getCurrencies();
    },
    [getCurrencies]
  );

  const currencies = React.useMemo(
    () => initialValues?.productType === ProductType.LOAN
      ? currenciesOptions
      : currenciesNumOptions,
    [currenciesNumOptions, currenciesOptions, initialValues]
  );

  return (
    <Modal
      containerWidth="500px"
      isLoading={isLoading}
      loadingText="Transaction is being processed"
      name={modalName}
    >
      <T2>Manual transaction</T2>

      {initialValues?.productType && (
        <Flex m="7px 0 20px" alignItems="center">
          <Box>{ProductTypeIcons[initialValues.productType]}</Box>
          <Text>{initialValues.productTypeName}</Text>
        </Flex>
      )}

      <ManualTransactionForm
        currenciesOptions={currencies}
        initialValues={initialValues}
        isCurrenciesLoading={isCurrenciesLoading}
        isFundsDisbursementTrType={transactionType?.value === TransactionTypeId.FUNDS_DISBURSEMENT}
        isTransTypesLoading={isTransTypesLoading}
        makeTransaction={makeTransaction}
        onCancel={() => closeModal(modalName)}
        transactionTypesOptions={transactionTypesOptions}
      />
    </Modal>
  );
};

const withTransactionTypes = withLoadTransactionTypes(ManualTransactionModal);

export default withModal(withTransactionTypes);
