/**
 * Product types
 */

export enum ProductType {
  LOAN = 'L',
  REVOLVING_CREDIT = 'C',
}

export const productTypesOptions = [
  {
    value: ProductType.REVOLVING_CREDIT,
    label: 'Revolving credit',
  },
  {
    value: ProductType.LOAN,
    label: 'Loan',
  },
];

/**
 * Loan types
 */

export enum LoanRepaymentCalcType {
  DECLINING_BALANCE = 'D',
  EQUAL_INSTALMENTS_EMI = 'E',
  FIXED_PRINCIPAL = 'F',
  INTEREST_ONLY = 'I',
}

export const loanRepaymentCalcTypesOptions = [
  {
    value: LoanRepaymentCalcType.EQUAL_INSTALMENTS_EMI,
    label: 'Equal instalments (EMI)',
  },
  {
    value: LoanRepaymentCalcType.FIXED_PRINCIPAL,
    label: 'Fixed principal',
    isDisabled: true,
  },
  {
    value: LoanRepaymentCalcType.DECLINING_BALANCE,
    label: 'Declining balance',
    isDisabled: true,
  },
  {
    value: LoanRepaymentCalcType.INTEREST_ONLY,
    label: 'Interest only',
    isDisabled: true,
  },
];

/**
 * Cycle types
 */

export enum CycleType {
  MONTHLY = 1,
  BI_MONTHLY = 2,
  WEEKLY = 3,
  BI_WEEKLY = 4,
  FIXED_NUMBER_OF_DAYS = 5,
}

export const statementCycleTypesOptions = [
  {
    value: CycleType.MONTHLY,
    label: 'Monthly',
    isDisabled: false,
  },
  {
    value: CycleType.BI_MONTHLY,
    label: 'Bi-monthly',
    isDisabled: true,
  },
  {
    value: CycleType.WEEKLY,
    label: 'Weekly',
    isDisabled: false,
  },
  {
    value: CycleType.BI_WEEKLY,
    label: 'Bi-weekly',
    isDisabled: true,
  },
  {
    value: CycleType.FIXED_NUMBER_OF_DAYS,
    label: 'Fixed number of days',
    isDisabled: true,
  },
];

/**
 * Product APRs
 */

export const aprCalcTypesOptions = [
  { value: 'A', label: 'Actual/Actual' },
  { value: 'D', label: 'Actual/360' },
  { value: 'B', label: '30/360', isDisabled: true },
  { value: 'C', label: 'Actual/365', isDisabled: true },
];

export const aprTypesOptions = [
  { value: 'S', label: 'Spend' },
  { value: 'F', label: 'Fees' },
  { value: 'P', label: 'Penalties' },
];

/**
 * Product fees, rewards. aprs and penalties
 */

export enum ApplicationCondition {
  APPLY_ONLY_FIXED_AMOUNT = 'A',
  APPLY_ONLY_RATE = 'R',
  APPLY_BOTH_FIXED_AMOUNT_AND_RATE = 'B',
  APPLY_WHICHEVER_IS_GREATER_RATE_OR_FIXED_AMOUNT = 'G',
  APPLY_WHICHEVER_IS_SMALLER_RATE_OR_FIXED_AMOUNT = 'S',
}

export const applicationConditionOptions = [
  {
    value: ApplicationCondition.APPLY_ONLY_FIXED_AMOUNT,
    label: 'Apply only fixed amount',
  },
  { value: ApplicationCondition.APPLY_ONLY_RATE,
    label: 'Apply only % rate' },
  {
    value: ApplicationCondition.APPLY_BOTH_FIXED_AMOUNT_AND_RATE,
    label: 'Apply both fixed amount and % rate',
  },
  {
    value: ApplicationCondition.APPLY_WHICHEVER_IS_GREATER_RATE_OR_FIXED_AMOUNT,
    label: 'Apply whichever is greater: % rate or the fixed amount',
  },
  {
    value: ApplicationCondition.APPLY_WHICHEVER_IS_SMALLER_RATE_OR_FIXED_AMOUNT,
    label: 'Apply whichever is smaller: % rate or the fixed amount',
  },
];

/**
 * Product rules
 */

export const dataTypesOptions = [
  { value: 'I', label: 'Integer' },
  { value: 'S', label: 'String' },
  { value: 'F', label: 'Float' },
];

export enum ActionType {
  API_CALL = 'A',
  APPROVE = 'B',
  FEE = 'F',
  MINIMUM_REPAYMENT_CALCULATION = 'C',
  PENALTY = 'P',
  REWARD = 'R',
  SET_APR = 'T',
  SET_NON_PROMOTIONAL_APR = 'N',
  SET_PROMOTIONAL_APR = 'P',
}

export enum RevCreditEventId {
  ACCOUNT_CREATED = 1,
  ACCOUNT_CLOSED = 2,
  TRANSACTION = 3,
  DAILY_SETTLEMENT_BEFORE_EXECUTION = 4,
  DAILY_SETTLEMENT_AFTER_EXECUTION = 6,
  END_OF_CYCLE_SETTLEMENT = 5,
}

export enum LoanEventId {
  TRANSACTION = 3,
  ACCOUNT_OVER_LIMIT = 4,
  END_CYCLE = 5,
  ACCOUNT_ON_HOLD = 10,
  ACCOUNT_DEFAULTED = 11,
  START_CYCLE = 12,
}

export const actionTypesConfig: Array<{
  value: string;
  label: string;
  productType: string;
  eventIds: Array<number>;
}> = [
  // Revolving credit
  {
    value: ActionType.API_CALL,
    label: 'API Call',
    productType: ProductType.REVOLVING_CREDIT,
    eventIds: [
      RevCreditEventId.ACCOUNT_CREATED,
      RevCreditEventId.ACCOUNT_CLOSED,
      RevCreditEventId.TRANSACTION,
      RevCreditEventId.DAILY_SETTLEMENT_BEFORE_EXECUTION,
      RevCreditEventId.END_OF_CYCLE_SETTLEMENT,
    ],
  },
  {
    value: ActionType.APPROVE,
    label: 'Approve / Deny',
    productType: ProductType.REVOLVING_CREDIT,
    eventIds: [
      RevCreditEventId.TRANSACTION,
      RevCreditEventId.END_OF_CYCLE_SETTLEMENT,
    ],
  },
  {
    value: ActionType.SET_APR,
    label: 'Set APR',
    productType: ProductType.REVOLVING_CREDIT,
    eventIds: [
      RevCreditEventId.TRANSACTION,
      RevCreditEventId.END_OF_CYCLE_SETTLEMENT,
    ],
  },
  {
    value: ActionType.REWARD,
    label: 'Add reward',
    productType: ProductType.REVOLVING_CREDIT,
    eventIds: [
      RevCreditEventId.ACCOUNT_CREATED,
      RevCreditEventId.TRANSACTION,
      RevCreditEventId.DAILY_SETTLEMENT_BEFORE_EXECUTION,
      RevCreditEventId.DAILY_SETTLEMENT_AFTER_EXECUTION,
      RevCreditEventId.END_OF_CYCLE_SETTLEMENT,
    ],
  },
  {
    value: ActionType.FEE,
    label: 'Add fee',
    productType: ProductType.REVOLVING_CREDIT,
    eventIds: [
      RevCreditEventId.ACCOUNT_CREATED,
      RevCreditEventId.TRANSACTION,
      RevCreditEventId.DAILY_SETTLEMENT_BEFORE_EXECUTION,
      RevCreditEventId.DAILY_SETTLEMENT_AFTER_EXECUTION,
      RevCreditEventId.END_OF_CYCLE_SETTLEMENT,
    ],
  },
  {
    value: ActionType.MINIMUM_REPAYMENT_CALCULATION,
    label: 'Minimum Repayment Calculation',
    productType: ProductType.REVOLVING_CREDIT,
    eventIds: [
      RevCreditEventId.ACCOUNT_CREATED,
      RevCreditEventId.ACCOUNT_CLOSED,
      RevCreditEventId.TRANSACTION,
      RevCreditEventId.DAILY_SETTLEMENT_BEFORE_EXECUTION,
      RevCreditEventId.END_OF_CYCLE_SETTLEMENT,
    ],
  },
  // Loans
  {
    value: ActionType.REWARD,
    label: 'Reward',
    productType: ProductType.LOAN,
    eventIds: [
      LoanEventId.TRANSACTION,
      LoanEventId.START_CYCLE,
      LoanEventId.END_CYCLE,
    ],
  },
  {
    value: ActionType.FEE,
    label: 'Fee',
    productType: ProductType.LOAN,
    eventIds: [
      LoanEventId.TRANSACTION,
      LoanEventId.START_CYCLE,
      LoanEventId.END_CYCLE,
    ],
  },
  {
    value: ActionType.APPROVE,
    label: 'Approve',
    productType: ProductType.LOAN,
    eventIds: [
      LoanEventId.TRANSACTION,
    ],
  },
  {
    value: ActionType.PENALTY,
    label: 'Penalty',
    productType: ProductType.LOAN,
    eventIds: [
      LoanEventId.ACCOUNT_ON_HOLD,
      LoanEventId.ACCOUNT_OVER_LIMIT,
      LoanEventId.ACCOUNT_DEFAULTED,
    ],
  },
];

export const productRulesComments = {
  [`${ProductType.REVOLVING_CREDIT}_${ActionType.APPROVE}_${RevCreditEventId.TRANSACTION}`]: `/*
  This rule approves or denies transactions.
  In an event of transaction you can approve or deny transaction by implementing 'function run()'.
  The function must return 'true' if you need to approve transaction or 'false' if you need to deny transaction.
  Operate the dta from database.
*/

/*
  function run() {
    return true;
  }
*/

// Write your rule here
`,
  [`${ProductType.REVOLVING_CREDIT}_${ActionType.SET_APR}_${RevCreditEventId.TRANSACTION}`]: `/*
  This rule sets transaction APR.
  In an event of transaction you can choose what APR should be assigned to the transaction by implementing run() function.
  The function must return id of one of the APRs defined in the corresponding product.
  Operate the data from database.
*/

/*
  function run() {
    return 1;
  }
*/

// Write your rule here
`,
[`${ProductType.LOAN}_${ActionType.REWARD}_${LoanEventId.TRANSACTION}`]: `/*
  Returns a reward ID, the reward is applied as a new transaction.
*/

// Write your rule here
`,
[`${ProductType.LOAN}_${ActionType.FEE}_${LoanEventId.TRANSACTION}`]: `/*
  Returns a fee ID, the fee is applied as a new transaction.
*/

// Write your rule here
`,
[`${ProductType.LOAN}_${ActionType.APPROVE}_${LoanEventId.TRANSACTION}`]: `/*
  Returns "true" if the transaction is approved, "false" if it is rejected.
*/

// Write your rule here
`,
[`${ProductType.LOAN}_${ActionType.PENALTY}_${LoanEventId.ACCOUNT_ON_HOLD}`]: `/*
  Returns a penalty ID, the penalty is then applied as a new (child) transaction
*/

// Write your rule here
`,
[`${ProductType.LOAN}_${ActionType.PENALTY}_${LoanEventId.ACCOUNT_OVER_LIMIT}`]: `/*
  Returns a penalty ID, the penalty is then applied as a new (child) transaction
*/

// Write your rule here
`,
[`${ProductType.LOAN}_${ActionType.PENALTY}_${LoanEventId.ACCOUNT_DEFAULTED}`]: `/*
  Returns a penalty ID, the penalty is then applied as a new (child) transaction
*/

// Write your rule here
`,
[`${ProductType.LOAN}_${ActionType.REWARD}_${LoanEventId.START_CYCLE}`]: `/*
  The rule returns a reward ID, the reward is applied as a new (child) transaction, backdated to the start of the cycle
*/

// Write your rule here
`,
[`${ProductType.LOAN}_${ActionType.FEE}_${LoanEventId.START_CYCLE}`]: `/*
  The rule returns a fee ID, the fee is applied as a new (child) transaction, backdated to the start of the cycle
*/

// Write your rule here
`,
[`${ProductType.LOAN}_${ActionType.FEE}_${LoanEventId.END_CYCLE}`]: `/*
  The rule returns a reward ID, the reward is applied as a new (child) transaction, backdated to the end of the previous cycle
*/

// Write your rule here
`,
[`${ProductType.LOAN}_${ActionType.FEE}_${LoanEventId.END_CYCLE}`]: `/*
  The rule returns a fee ID, the fee is applied as a new (child) transaction, backdated to the end of the previous cycle
*/

// Write your rule here
`,
};

export const productRuleScriptSnippets = [
  {
    label: 'function run() {\n\xa0\xa0// code to be executed\n}',
    value: 'function run() {\n\xa0\xa0\xa0\xa0// code to be executed\n\xa0\xa0\xa0\xa0\n}',
    shiftCharCount: 48,
  },
  {
    label: 'def rule(){\n\xa0\xa0// code to be executed\n}',
    value: 'def rule(){\n\xa0\xa0\xa0\xa0// code to be executed\n\xa0\xa0\xa0\xa0\n}',
    shiftCharCount: 43,
  },
  {
    label: 'if (condition) {\n\xa0\xa0// code block\n} else {\n\xa0\xa0// code block\n}',
    value: 'if () {\n\xa0\xa0\xa0\xa0// code block\n} else {\n\xa0\xa0\xa0\xa0// code block\n}',
    shiftCharCount: 4,
  },
];
