import { connect } from 'react-redux';

import Home from './Home';

import {
  IStoreState,
  profileSelector,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  profile: profileSelector(state),
});

export default connect(
  mapStateToProps
)(Home);
