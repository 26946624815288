import { ModalName } from 'consts';
import {
  activeItemIdSelector,
  activeProductTypeSelector,
  openModal,
} from 'store';
import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import {
  ActionTypeKeys,
  IAddProductRuleAction,
  IDeleteProductRuleAction,
  IGetProductRuleAction,
  IUpdateProductRuleAction,
} from './actionTypes';

import * as api from './api';

import {
  IProductRule,
  IProductRuleData,
  IProductRulesFilter,
} from './types';

import {
  normalizeFilterQueryString,
  normalizeProductRuleForSending,
} from './utils';

/**
 * Reset product rule action
 */

export type TResetProductRule = () => void;

export const resetProductRule: TResetProductRule = () => ({
  type: ActionTypeKeys.RESET_PRODUCT_RULE,
});

/**
 * Get product rule action
 */

export type TGetProductRule = (
  data: IProductRuleData,
  queryString: string,
  productType: string
) => IGetProductRuleAction;

export type THandleGetProductRule = (data: Partial<IProductRulesFilter>) => Thunk<void>;

export const getProductRule: TGetProductRule = (data, queryString, productType) => ({
  type: ActionTypeKeys.GET_PRODUCT_RULE,
  payload: api.getProductRule(queryString, productType),
  meta: { data, productType },
});

export const handleGetProductRule: THandleGetProductRule = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const productId = activeItemIdSelector(state);
        const productType = activeProductTypeSelector(state);

        const queryString = normalizeFilterQueryString(data, productId);
        const normalizedData = normalizeProductRuleForSending(data, productId);

        await dispatch(getProductRule(normalizedData, queryString, productType));
      },
      dispatch
    );
  };

/**
 * Create product rule action
 */
export type TAddProductRule = (
  data: IProductRuleData,
  productType: string
) => IAddProductRuleAction;

export type THandleAddProductRule = (data: Partial<IProductRule>) => Thunk<void>;

export const addProductRule: TAddProductRule = (data, productType) => ({
  type: ActionTypeKeys.ADD_PRODUCT_RULE,
  payload: api.addProductRule(data, productType),
});

export const handleAddProductRule: THandleAddProductRule = data => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();
      const productId = activeItemIdSelector(state);
      const productType = activeProductTypeSelector(state);

      const queryString = normalizeFilterQueryString(data, productId);
      const normalizedData = normalizeProductRuleForSending(data, productId);

      await dispatch(addProductRule(normalizedData, productType));
      await dispatch(getProductRule(normalizedData, queryString, productType));

      dispatch(
        openModal({
          name: ModalName.MESSAGE,
          payload: { title: 'Product rule has been created', isSuccess: true },
        })
      );
    },
    dispatch
  );
};

/**
 * Delete product rule action
 */
export type TDeleteProductRule = (
  data: IProductRuleData,
  queryString: string,
  productType: string
) => IDeleteProductRuleAction;

export type THandleDeleteProductRule = (data: Partial<IProductRule>) => Thunk<void>;

export const deleteProductRule: TDeleteProductRule = (data, queryString, productType) => ({
  type: ActionTypeKeys.DELETE_PRODUCT_RULE,
  payload: api.deleteProductRule(queryString, productType),
  meta: { data, productType },
});

export const handleDeleteProductRule: THandleDeleteProductRule = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const productId = activeItemIdSelector(state);
        const productType = activeProductTypeSelector(state);

        const queryString = normalizeFilterQueryString(data, productId);
        const normalizedData = normalizeProductRuleForSending(data, productId);

        await dispatch(deleteProductRule(normalizedData, queryString, productType));

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: { title: 'Product rule has been deleted', isSuccess: true },
          })
        );
      },
      dispatch
    );
  };

/**
 * Update product rule action
 */

export type TUpdateProductRule = (
  data: Partial<IProductRuleData>,
  queryString: string,
  productType: string
) =>
  IUpdateProductRuleAction;

export type THandleUpdateProductRule = (data: Partial<IProductRule>) => Thunk<void>;

export const updateProductRule: TUpdateProductRule = (data, queryString, productType) => ({
  type: ActionTypeKeys.UPDATE_PRODUCT_RULE,
  payload: api.updateProductRule(data, queryString, productType),
});

export const handleUpdateProductRule: THandleUpdateProductRule = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const productId = activeItemIdSelector(state);
        const productType = activeProductTypeSelector(state);

        const queryString = normalizeFilterQueryString(data, productId);
        const normalizedData = normalizeProductRuleForSending(data, productId);

        await dispatch(updateProductRule({ script: data?.script }, queryString, productType));
        await dispatch(getProductRule(normalizedData, queryString, productType));

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: { title: 'Product rule has been updated', isSuccess: true },
          })
        );
      },
      dispatch
    );
  };
