import React from 'react';
import { Field } from 'redux-form';

import { Box } from '@rebass/grid';
import { CheckboxField, NumberFormatField, T3 } from 'components';
import { formErrorUtil } from 'utils';

interface IRCDetailsFields {
  isReadOnly: boolean;
  statementCycleParameterValidation: any;
}

const RCDetailsFields: React.FC<IRCDetailsFields> = ({
  isReadOnly,
  statementCycleParameterValidation,
}) => (
  <>
    <Box width={[1]} p="8px">
      <T3>Repayments</T3>
    </Box>

    <Box width="150px" p="8px">
      <Field
        id="minimumRepaymentAmount"
        name="minimumRepaymentAmount"
        component={NumberFormatField}
        placeholder="0.00"
        fixedDecimalScale={true}
        decimalScale={2}
        label="Minimum Repayment Amount"
        disabled={isReadOnly}
        validate={[formErrorUtil.isRequired, formErrorUtil.isPositive]}
      />
    </Box>
    <Box width="150px" p="8px">
      <Field
        id="minimumRepaymentRate"
        name="minimumRepaymentRate"
        component={NumberFormatField}
        placeholder="0.00"
        fixedDecimalScale={true}
        decimalScale={2}
        label="Minimum Repayment Rate"
        disabled={isReadOnly}
        validate={[formErrorUtil.isRequired, formErrorUtil.isPositive]}
      />
    </Box>
    <Box width="150px" p="8px">
      <Field
        id="numberOfDaysToMakeRepayment"
        name="numberOfDaysToMakeRepayment"
        component={NumberFormatField}
        placeholder="0"
        fixedDecimalScale={true}
        decimalScale={0}
        label="Number of Days to Make Repayment"
        disabled={isReadOnly}
        hint="Number of days since the end of cycle allowed to take repayment."
        validate={statementCycleParameterValidation}
      />
    </Box>
    <Box width={[1]} p="8px">
      <Field
        id="repaymentPeriodIsStatementCycle"
        name="repaymentPeriodIsStatementCycle"
        component={CheckboxField}
        label="Repayment Period Equals One Statement Cycle"
        disabled={isReadOnly}
      />
    </Box>

    <Box width={[1]} p="8px">
      <T3>Disputes</T3>
    </Box>

    <Box width={[1]} p="8px">
      <Field
        id="immediateChargeback"
        name="immediateChargeback"
        component={CheckboxField}
        label="Make immediate changes when chargeback is raised"
        hint={`
          If ticked, a credit transactions for chargeback amount and any posted interest will be applied immediately to the account.
          If unticked, credit transactions will only be made when the chargeback is Accepted.
        `}
        disabled={isReadOnly}
      />
    </Box>
    <Box width={[1]} p="8px">
      <Field
        id="applyRejectedChargebackInterest"
        name="applyRejectedChargebackInterest"
        component={CheckboxField}
        label="Add calculated interest if chargeback rejected"
        hint={`
          If ticked, calculated interest from the date the chargeback was raised until the date the chargeback was rejected will be added to the account.
        `}
        disabled={isReadOnly}
      />
    </Box>
  </>
);

export default RCDetailsFields;
