import { storageUtil } from 'utils';

import {
  IAuthUserData,
  ILoginRequest,
  ILoginRequestToSend,
  ILoginResponse,
} from './types';

export const normalizeLoginRequest = (data: ILoginRequest): ILoginRequestToSend => {
  const { loginUsername, loginPassword } = data || {};

  return ({
    username: loginUsername,
    password: loginPassword,
  });
};

export const normalizeLoginData = (data: ILoginResponse): IAuthUserData => {
  const {
    requires_2fa_flag,
    session_id,
    session_timeout,
    status,
  } = data || {};

  return {
    requires2faFlag: requires_2fa_flag,
    sessionId: session_id,
    sessionTimeout: session_timeout,
    status,
  };
};

export const setLoginDataToStorage = (data: IAuthUserData) => {
  const { sessionId, sessionTimeout } = data || {};

  storageUtil.setSessionId(sessionId, sessionTimeout);
  storageUtil.setLoggedInFlag();
  storageUtil.removeAuthPendingFlag();
};
