import { ModalName } from 'consts';
import {
  activeItemIdSelector,
  closeModal,
  openModal,
} from 'store';
import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import {
  ActionTypeKeys,
  IAddCustomerAction,
  IFilterCustomersAction,
  IGetCustomerAction,
  IUpdateCustomerAction,
} from './actionTypes';
import * as api from './api';
import { defaultCurrentCustomerSelector } from './selectors';
import {
  ICustomer,
  ICustomerData,
  ICustomersFilter,
} from './types';
import {
  normalizeDataForCreating,
  normalizeDataForUpdating,
  normalizeFilterQueryString,
} from './utils';

/**
 * Filter customers action
 */

export type TFilterCustomers = (queryString: string) => IFilterCustomersAction;
export type THandleFilterCustomers = (data: ICustomersFilter) => Thunk<void>;

export const filterCustomers: TFilterCustomers = queryString => ({
  type: ActionTypeKeys.FILTER_CUSTOMERS,
  payload: api.filterCustomers(queryString),
});

export const handleFilterCustomers: THandleFilterCustomers = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterQueryString(data);

        await dispatch(filterCustomers(queryString));
      },
      dispatch
    );
  };

/**
 * Get customer action
 */

export type TGetCustomer = (id: number) => IGetCustomerAction;
export type THandleGetCustomer = () => Thunk<void>;

export const getCustomer: TGetCustomer = id => ({
  type: ActionTypeKeys.GET_CUSTOMER,
  payload: api.getCustomer(id),
});

export const handleGetCustomer: THandleGetCustomer = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const id = activeItemIdSelector(state);

        await dispatch(getCustomer(id));
      },
      dispatch
    );
  };

/**
 * Add customer action
 */

export type TAddCustomer = (data: Partial<ICustomerData>) => IAddCustomerAction;
export type THandleAddCustomer = (data: Partial<ICustomer>) => Thunk<void>;

export const addCustomer: TAddCustomer = data => ({
  type: ActionTypeKeys.ADD_CUSTOMER,
  payload: api.addCustomer(data),
});

export const handleAddCustomer: THandleAddCustomer = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const normalizedData = normalizeDataForCreating(data);

        const res = await dispatch(addCustomer(normalizedData)) as any;

        dispatch(closeModal(ModalName.ADD_CUSTOMER));
        dispatch(openModal({
          name: ModalName.MESSAGE,
          payload: {
            title: 'Customer has been created',
            message: `Customer ID: ${res.value.customer_id}`,
            isSuccess: true,
          },
        }));
      },
      dispatch
    );
  };

/**
 * Update customer action
 */

export type TUpdateCustomer = (data: Partial<ICustomerData>, customerId: number) =>
  IUpdateCustomerAction;

export type THandleUpdateCustomer = (data: Partial<ICustomer>) => Thunk<void>;

export const updateCustomer: TUpdateCustomer = (data, customerId) => ({
  type: ActionTypeKeys.UPDATE_CUSTOMER,
  payload: api.updateCustomer(data, customerId),
});

export const handleUpdateCustomer: THandleUpdateCustomer = data => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();
      const customerId = activeItemIdSelector(state);
      const currentData = defaultCurrentCustomerSelector(state);
      const normalizedData = normalizeDataForUpdating(data, currentData);

      await dispatch(updateCustomer(normalizedData, customerId));
      await dispatch(getCustomer(customerId));
    },
    dispatch
  );
};

/**
 * Reset customers action
 */

export type TResetCustomers = () => void;

export const resetCustomers: TResetCustomers = () => ({
  type: ActionTypeKeys.RESET_CUSTOMERS,
});

/**
 * Reset customer action
 */

export type TResetCustomer = () => void;

export const resetCustomer: TResetCustomer = () => ({
  type: ActionTypeKeys.RESET_CUSTOMER,
});
