import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import Aprs from './Aprs';

import { resetPayments } from 'store';

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    resetPayments,
  },
  dispatch
);

export default connect(
  null,
  mapDispatchToProps
)(Aprs);
