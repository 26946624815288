import Immutable, * as seamlessImmutable from 'seamless-immutable';
import { ActionTypeKeys, TUserAction } from './actionTypes';
import { IUsersState } from './types';

export const usersInitialState: seamlessImmutable.ImmutableObject<IUsersState> = Immutable({
  currentUser: null,
  users: Immutable([]),
});

const usersReducer = (state = usersInitialState, action: TUserAction) => {
  switch (action.type) {
    case ActionTypeKeys.FILTER_USERS_FULFILLED:
      return state.set('users', action.payload.users);

    case ActionTypeKeys.GET_USER_FULFILLED:
      return state.set('currentUser', action.payload.user);

    case ActionTypeKeys.RESET_USERS:
      return state = usersInitialState;

    default:
      return state;
  }
};

export default usersReducer;
