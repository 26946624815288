import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import StatementModal from './StatementModal';

import {
  activeProductTypeSelector,
  currentStatementSelector,
  handleDownloadStatement,
  handleFilterStatementTransactions,
  handleGetStatement,
  handleGetStatementAprs,
  isStatementLoadingSelector,
  IStoreState,
  resetStatement,
  resetStatementAprs,
  resetTransactions,
  statementAprsSelector,
  transactionsSelector,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  activeProductType: activeProductTypeSelector(state),
  currentStatement: currentStatementSelector(state),
  isLoading: isStatementLoadingSelector(state),
  statementAprs: statementAprsSelector(state),
  statementTransactions: transactionsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    downloadStatement: handleDownloadStatement,
    filterTransactions: handleFilterStatementTransactions,
    getStatement: handleGetStatement,
    getStatementAprs: handleGetStatementAprs,
    resetStatement,
    resetStatementAprs,
    resetTransactions,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatementModal);
