import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import { Button, NumberFormatField, SelectField, TextareaField } from 'components';
import {
  ApplicationCondition,
  applicationConditionOptions,
  FormName,
  IconName,
} from 'consts';
import { THandleAddProductReward } from 'store';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface IRewardForm {
  addReward: THandleAddProductReward;
  applicationCondition: ISelectValue;
  isDisabled: boolean;
  isAdding: boolean;
}

type TRewardForm = IRewardForm & InjectedFormProps<{}, IRewardForm>;

const RewardForm: React.FC<TRewardForm> = ({
  addReward,
  applicationCondition,
  handleSubmit,
  isDisabled,
  isAdding,
  pristine,
}) => {
  const isOnlyAmount = React.useMemo(
    () => applicationCondition?.value === ApplicationCondition.APPLY_ONLY_FIXED_AMOUNT,
    [applicationCondition]
  );

  const isOnlyRate = React.useMemo(
    () => applicationCondition?.value === ApplicationCondition.APPLY_ONLY_RATE,
    [applicationCondition]
  );

  const rateValidators = React.useMemo(
    () => !isOnlyAmount ? [ formErrorUtil.isRequired, formErrorUtil.isPositive ] : null,
    [isOnlyAmount]
  );

  const amountValidators = React.useMemo(
    () => !isOnlyRate ? [ formErrorUtil.isRequired, formErrorUtil.isPositive ] : null,
    [isOnlyRate]
  );

  return (
    <form onSubmit={handleSubmit(addReward)}>
      <Flex
        alignItems="flex-start"
        flexDirection="row"
        flexWrap="wrap"
        mx="-8px"
      >
        <Box width={[1 / 2]} p="8px">
          <Field
            id="description"
            name="description"
            component={TextareaField}
            label="Description"
            placeholder="Enter Description"
            disabled={isDisabled}
            height={96}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>
        <Flex
          flexWrap="wrap"
          width={[1 / 2]}
        >
          <Box width={[1]} p="8px">
            <Field
              id="rewardApplicationCondition"
              name="rewardApplicationCondition"
              component={SelectField}
              label="Application Condition"
              options={applicationConditionOptions}
              placeholder="Select Condition"
              isDisabled={isDisabled}
              validate={[formErrorUtil.isRequired]}
            />
          </Box>
          <Box width={[1 / 5]} p="8px">
            <Field
              id="rate"
              name="rate"
              component={NumberFormatField}
              label="Rate %"
              disabled={isDisabled || isOnlyAmount}
              placeholder="0.00"
              fixedDecimalScale={true}
              decimalScale={2}
              validate={rateValidators}
            />
          </Box>
          <Box width={[1 / 5]} p="8px">
            <Field
              id="amount"
              name="amount"
              component={NumberFormatField}
              label="Amount"
              disabled={isDisabled || isOnlyRate}
              placeholder="0.00"
              fixedDecimalScale={true}
              decimalScale={2}
              validate={amountValidators}
            />
          </Box>
        </Flex>
      </Flex>
      <Flex
        justifyContent="flex-end"
        mt="5px"
      >
        <Button
          text="Add"
          isLoading={isAdding}
          iconName={IconName.ADD}
          disabled={pristine}
        />
      </Flex>
    </form>
  );
};

export default reduxForm<{}, IRewardForm>({
  form: FormName.PRODUCT_REWARD,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(RewardForm);
