import { dateUtil, stringsUtil } from 'utils';
import { IApiCallData, IApiCallsFilter, IApiCallsFilterToSend } from './types';

export const normalizeFilterQueryString = (data: IApiCallsFilter): string => {
  const {
    apiName,
    dateFrom,
    dateTo,
    endpointId,
    institutionId,
    pageNumber,
  } = data || {};

  const normalizedData: IApiCallsFilterToSend = {
    api_name: apiName,
    date_from: dateUtil.formatDateForSending(dateFrom),
    date_to: dateUtil.formatDateForSending(dateTo),
    endpoint_id: endpointId?.value,
    institution_id: institutionId?.value,
    page_number: pageNumber,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeData = (data: IApiCallData) => {
  const {
    api_name,
    endpoint_id,
    endpoint_name,
    event_datetime,
    id,
    institution_id,
    institution_name,
    request_body,
    response_body,
  } = data || {};

  return {
    apiName: api_name,
    endpointId: endpoint_id,
    endpointName: endpoint_name,
    eventDatetime: event_datetime,
    id,
    institutionId: institution_id,
    institutionName: institution_name,
    requestBody: request_body,
    responseBody: response_body,
  };
};
