import { createSelector } from 'reselect';
import { IStoreState } from 'store';

import { createLoadingSelector } from 'store/domains/loader';
import { activeProductTypeSelector } from 'store/domains/utils';
import { ActionTypeKeys } from './actionTypes';
import {
  normalizeProductAprs,
  normalizeProductFee,
  normalizeProductPenalty,
  normalizeProductReward,
} from './utils';

/**
 * Fees
 */

export const defaultProductFeesSelector = (state: IStoreState) =>
  state.productDesigner.productPayments.productFees;

export const productFeesSelector = createSelector(
  defaultProductFeesSelector,
  activeProductTypeSelector,
  (data, productType) => data?.map(fee => normalizeProductFee(fee, productType))
);

export const productFeesOptionsSelector = createSelector(
  productFeesSelector,
  data => data?.map(fee => {
    const { feeId, description } = fee;

    return {
      value: feeId,
      label: `${feeId} - ${description}`,
    };
  })
);

export const isProductFeesLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_PRODUCT_FEES,
]);

export const isProductFeeAddingSelector = createLoadingSelector([
  ActionTypeKeys.ADD_PRODUCT_FEE,
]);

export const isProductFeeDeletingSelector = createLoadingSelector([
  ActionTypeKeys.DELETE_PRODUCT_FEE,
]);

/**
 * Rewards
 */

export const defaultProductRewardsSelector = (state: IStoreState) =>
  state.productDesigner.productPayments.productRewards;

export const productRewardsSelector = createSelector(
  defaultProductRewardsSelector,
  activeProductTypeSelector,
  (data, productType) => data?.map(reward => normalizeProductReward(reward, productType))
);

export const productRewardsOptionsForRulesSelector = createSelector(
  productRewardsSelector,
  data => data?.map(reward => {
    const { rewardId, description } = reward;

    return {
      value: rewardId,
      label: `${rewardId} - ${description}`,
    };
  })
);

export const isProductRewardsLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_PRODUCT_REWARDS,
]);

export const isProductRewardAddingSelector = createLoadingSelector([
  ActionTypeKeys.ADD_PRODUCT_REWARD,
]);

export const isProductRewardDeletingSelector = createLoadingSelector([
  ActionTypeKeys.DELETE_PRODUCT_REWARD,
]);

/**
 * Penalties
 */

export const defaultProductPenaltiesSelector = (state: IStoreState) =>
  state.productDesigner.productPayments.productPenalties;

export const productPenaltiesSelector = createSelector(
 defaultProductPenaltiesSelector,
 activeProductTypeSelector,
 (data, productType) => data?.map(penalty => normalizeProductPenalty(penalty, productType))
);

export const productPenaltiesOptionsForRulesSelector = createSelector(
  productPenaltiesSelector,
  data => data?.map(penalty => {
   const { penaltyId, description } = penalty;

   return {
    value: penaltyId,
    label: `${penaltyId} - ${description}`,
  };
 })
);

export const isProductPenaltiesLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_PRODUCT_PENALTIES,
]);

export const isProductPenaltyAddingSelector = createLoadingSelector([
  ActionTypeKeys.ADD_PRODUCT_PENALTY,
]);

export const isProductPenaltyDeletingSelector = createLoadingSelector([
  ActionTypeKeys.DELETE_PRODUCT_PENALTY,
]);

/**
 * APRs
 */

export const defaultProductAprsSelector = (state: IStoreState) =>
  state.productDesigner.productPayments.productAprs;

export const productAprsSelector = createSelector(
  defaultProductAprsSelector,
  data => normalizeProductAprs(data)
);

export const productAprsOptionsSelector = createSelector(
   defaultProductAprsSelector,
   data => data?.map(apr => {
     const { product_apr_id, description } = apr;

     return {
      value: product_apr_id,
      label: `${product_apr_id} - ${description}`,
    };
  })
);

export const isProductAprsLoadingSelector = createLoadingSelector([
  ActionTypeKeys.GET_PRODUCT_APRS,
]);

export const isProductAprAddingSelector = createLoadingSelector([
  ActionTypeKeys.ADD_PRODUCT_APR,
]);

export const isProductAprUpdatingSelector = createLoadingSelector([
  ActionTypeKeys.UPDATE_PRODUCT_APR,
]);

export const isProductAprDeletingSelector = createLoadingSelector([
  ActionTypeKeys.DELETE_PRODUCT_APR,
]);
