import styled from 'theme';

import { CheckedBoxIcon, UncheckedBoxIcon } from './../../Icons';

const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 10px;
`;

interface ICheckBoxTableCell {
  isChecked: boolean;
}

export const CheckBoxTableCell: React.FC<ICheckBoxTableCell> = ({ isChecked }) =>  (
  <CheckBoxWrapper>
    {isChecked ? (<CheckedBoxIcon />) : (<UncheckedBoxIcon />)}
  </CheckBoxWrapper>
);
