// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
// import { throttleUtil } from 'utils';

// import {
//   authResponseMock,
//   authSecretKeyMock,
// } from './mock';

import {
  IAuthCode,
  IAuthPassword,
  ILoginRequestToSend,
} from './types';

/**
 * Login API
 */
export const login = (data: ILoginRequestToSend) =>
  // throttleUtil.getDataAfter(authResponseMock, 500);
  apiClientService.post('auth/login', { data });

/**
 * Provide auth key API
 */
export const provideAuthKey = (data: IAuthCode) =>
  // throttleUtil.getDataAfter(authResponseMock, 500);
  apiClientService.post('auth/login', { data });

/**
 * Logout API
 */
export const logout = () =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.post('auth/logout', { data: {} });

/**
 * Generate auth key API
 */
export const generateAuthKey = (data: IAuthPassword) =>
  // throttleUtil.getDataAfter(authSecretKeyMock, 500);
  apiClientService.post('auth/key', { data });

/**
 * Confirm the auth key API
 */
export const confirmAuthKey = () =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.post('auth/key/confirm', { data: {} });
