import React from 'react';
import { ImmutableArray } from 'seamless-immutable';

import { Table } from 'components';
import { ITransaction } from 'store';

import { tableColumns } from 'containers/Ledger/Transactions/components';

interface ITransactionsTable {
  data: ImmutableArray<ITransaction>;
}

export const TransactionsTable: React.FC<ITransactionsTable> = ({ data }) => (
  <Table
    columns={tableColumns}
    data={data}
    pageSize={10}
  />
);

export default TransactionsTable;
