import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  currenciesOptionsSelector,
  handleGetDictionaryCurrencies,
  isCurrenciesLoadingSelector,
  IStoreState,
  userInstitutionsOptionsSelector,
} from 'store';

import GeneralProductFields from './GeneralProductFields';

const mapStateToProps = (state: IStoreState) => ({
  isCurrenciesLoading: isCurrenciesLoadingSelector(state),
  institutionsOptions: userInstitutionsOptionsSelector(state),
  currenciesOptions: currenciesOptionsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getDictionaryCurrencies: handleGetDictionaryCurrencies,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralProductFields);
