// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
// import { throttleUtil } from 'utils';

// import {
//   customerMock,
//   customersMock,
// } from './mock';

import { ICustomerData } from './types';

/**
 * Filter customers API
 */
export const filterCustomers = (queryString: string) =>
  // throttleUtil.getDataAfter(customersMock, 500);
  apiClientService.get(`customers?${queryString}`);

/**
 * Get customer details API
 */
export const getCustomer = (customerId: number) =>
  // throttleUtil.getDataAfter(customerMock, 500);
  apiClientService.get(`customers/${customerId}`);

/**
 * Update customer details API
 */
export const updateCustomer = (data: Partial<ICustomerData>, customerId: number) =>
  // throttleUtil.getDataAfter(successResponseMock, 1000);
  apiClientService.put(`customers/${customerId}`, { data });

/**
 * Create customer API
 */
export const addCustomer = (data: Partial<ICustomerData>) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.post('customers', { data });
