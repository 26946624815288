import React, { Suspense } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';

import { Flex } from '@rebass/grid';

import { Container, PrivateRoute, Spinner, withSpinner } from 'components';
import { basePath, Flag, ModalName } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import {
  THandleGetPermissions,
  THandleGetProfile,
  THandleGetUserInstitutions,
  THandleLogout,
} from 'store';
import styled from 'theme';
import { storageUtil } from 'utils';

import { Footer, Header, Home, Login } from 'containers/Landings';
import Modals from 'containers/Modals';
import { screensList } from 'containers/screensList';

const RootWrapper = styled(Flex)`
  min-width: 1025px;
  min-height: 100vh;
  font-size: 0;
`;

interface IRoot extends RouteComponentProps, IWithModal {
  getInstitutions: THandleGetUserInstitutions;
  getPermissions: THandleGetPermissions;
  getProfile: THandleGetProfile;
  isActiveUser: boolean;
  isAuthRegistrationPending: boolean;
  isLoadedPermissions: number;
  logout: THandleLogout;
  visiblePermissionsList: Array<string>;
}

const Root: React.FC<IRoot> = ({
  getInstitutions,
  getPermissions,
  getProfile,
  isActiveUser,
  isAuthRegistrationPending,
  isLoadedPermissions,
  logout,
  openModal,
  visiblePermissionsList = [],
}) => {
  const sessionId = storageUtil.getSessionId() ;
  const isAuthPendingFlag = storageUtil.getAuthPendingFlag() === Flag.YES;
  const isLoggedInFlag = storageUtil.getLoggedInFlag() === Flag.YES;

  const isAuthPending = sessionId && isAuthPendingFlag;
  const isLoggedIn = sessionId && !isAuthPendingFlag;

  React.useEffect(
    () => {
      if (isAuthPending) {
        openModal({ name: ModalName.LOGIN_CODE_2FA });
      }
    },
    [isAuthPending, openModal]
  );

  React.useEffect(
    () => {
      if (isLoggedIn) {
        getProfile();
      }
    },
    [isLoggedIn, getProfile]
  );

  React.useEffect(
    () => {
      if (isLoggedIn && isActiveUser) {
        Promise.all([
          getInstitutions(),
          getPermissions(),
        ]);
      }
    },
    [isActiveUser, isLoggedIn, getInstitutions, getPermissions]
  );

  React.useEffect(
    () => {
      if (!isLoggedIn && isLoggedInFlag) {
        logout();
      }
    },
    [isLoggedIn, logout, isLoggedInFlag]
  );

  const privateRoutesList = React.useMemo(
    () => {
      const routesList: Array<object> = [];

      for (const path in screensList) {
        if (visiblePermissionsList.includes(path)) {
          routesList.push(
            <PrivateRoute
              key={path}
              path={`${basePath}${path}`}
              component={() => screensList[path]}
              isLoggedIn={isLoggedIn}
            />
          );
        }
      }

      return routesList;
    },
    [isLoggedIn, visiblePermissionsList]
  );

  return (
    <>
      <RootWrapper
        flexDirection="column"
        justifyContent="space-between"
        pt="50px"
      >
        <div>
          {isLoggedIn && (<Header />)}

          <Container>
            <Suspense fallback={<Spinner isFixed={true} />}>
              <Switch>
                <>
                  <Route
                    exact={true}
                    path={`${basePath}login`}
                    render={() => isLoggedIn ? <Redirect to={basePath} /> : <Login />}
                  />

                  {privateRoutesList}

                  {(isLoadedPermissions || isAuthRegistrationPending) && (
                      <PrivateRoute
                        exact={true}
                        path={basePath}
                        component={Home}
                        isLoggedIn={isLoggedIn}
                      />
                    )
                  }

                  {!isLoggedIn && (
                    <Redirect
                      from="*"
                      to={`${basePath}login`}
                    />
                  )}
                </>
              </Switch>
            </Suspense>
          </Container>
        </div>

        <Footer />
      </RootWrapper>
      <Modals />
    </>
  );
};

export default withSpinner({
  isFixed: true,
})(withRouter(withModal(Root)));
