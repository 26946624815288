import React from 'react';
import { CellInfo } from 'react-table';
import { ImmutableArray } from 'seamless-immutable';

import { Flex } from '@rebass/grid';

import {
  Button,
  EditableTableSelectCell,
  Table,
  TableCell,
  TableHeader,
  withSpinner,
} from 'components';
import { IconName, permissionTypesOptions } from 'consts';

import {
  IUsersGroup,
  IUsersGroupPermission,
  THandleDeleteUsersGroupPermission,
  THandleGetUsersGroupPermissions,
  THandleUpdateUsersGroupPermission,
} from 'store';

import { ITableCell } from 'types';

interface IUsersGroupMembers {
  deleteUsersGroupPermission: THandleDeleteUsersGroupPermission;
  getUsersGroupPermissions: THandleGetUsersGroupPermissions;
  isDeleting: boolean;
  isLoading: boolean;
  isReadOnly: boolean;
  isUpdating: boolean;
  permissions: ImmutableArray<IUsersGroupPermission>;
  updateUsersGroupPermission: THandleUpdateUsersGroupPermission;
  usersGroup: IUsersGroup;
}

type TCell<T extends keyof IUsersGroupPermission> = ITableCell<IUsersGroupPermission[T]>;

export const UsersGroupMembers: React.FC<IUsersGroupMembers> = ({
  deleteUsersGroupPermission,
  getUsersGroupPermissions,
  isDeleting,
  isLoading,
  isReadOnly,
  isUpdating,
  permissions,
  updateUsersGroupPermission,
  usersGroup,
}) => {
  React.useEffect(
    () => {
      getUsersGroupPermissions(usersGroup);
    },
    [getUsersGroupPermissions, usersGroup]
  );

  const isEditableCell = React.useMemo(
    () => !isReadOnly && !isLoading && !isDeleting && !isUpdating,
    [isDeleting, isLoading, isReadOnly, isUpdating]
  );

  const tableColumns = React.useMemo(
    () => [
      {
        minWidth: 300,
        Header: <TableHeader title="UI Item" />,
        accessor: 'uiItemLabel',
        Cell: (props: TCell<'uiItemLabel'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 300,
        Header: <TableHeader title="Permission" />,
        accessor: 'permission',
        Cell: (cellInfo: CellInfo) => (
          <EditableTableSelectCell
            updateAction={updateUsersGroupPermission}
            cellInfo={cellInfo}
            selectOptions={permissionTypesOptions}
            isEditable={isEditableCell}
          />
        ),
      },
      {
        maxWidth: 65,
        accessor: 'deleteButton',
        Cell: (cellInfo: CellInfo) => (
          <Flex
            justifyContent="center"
            width="100%"
            p="4px"
          >
            <Button
              iconName={IconName.DELETE}
              title="Remove"
              size="10"
              classNames={['is-circle']}
              withConfirmation={true}
              disabled={isReadOnly || isDeleting || isUpdating}
              confirmationText={`Remove permission "${cellInfo.original.uiItemLabel}" from the users group "${usersGroup.name}"?`}
              onClick={() =>
                deleteUsersGroupPermission(
                  cellInfo.original.usersGroupId,
                  cellInfo.original.uiItem
                )
              }
            />
          </Flex>
        ),
      },
    ],
    [
      deleteUsersGroupPermission,
      isDeleting,
      isEditableCell,
      isReadOnly,
      isUpdating,
      updateUsersGroupPermission,
      usersGroup,
    ]
  );

  return (
    <Table
      data={permissions}
      columns={tableColumns}
      pageSize={15}
      isScrollbar={false}
    />
  );
};

export default withSpinner()(UsersGroupMembers);
