import { commonStatusOptions } from 'consts';
import {
  IEndpointData,
  IEndpointDetails,
  IEndpointsFilter,
  IEndpointsFilterToSend,
} from './types';

import { ISelectValue } from 'types';
import { stringsUtil } from 'utils';

export const normalizeFilterQueryString = (data: IEndpointsFilter): string => {
  const { institutionId } = data || {};

  const normalizedData: IEndpointsFilterToSend = {
    institution_id: institutionId?.value || null,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeDataForSending = (data: Partial<IEndpointDetails>) => {
  const {
    connectionAttributes,
    endpointTypeId,
    logFileLocation,
    name,
    port,
    privateKeyLocation,
    sourceIpAddress,
    status,
  } = data || {};

  return {
    connection_attributes: connectionAttributes,
    endpoint_type_id: endpointTypeId?.value,
    log_file_location: logFileLocation,
    name,
    port,
    private_key_location: privateKeyLocation,
    source_ip_address: sourceIpAddress,
    status: status?.value,
  };
};

export const normalizeData = (data: Partial<IEndpointData>, institution?: ISelectValue) => {
  const {
    id,
    name,
    port,
    private_key_location,
    log_file_location,
    status,
    endpoint_type_id,
    endpoint_type_name,
    connection_attributes,
    source_ip_address,
    last_message_datetime,
    last_fault_datetime,
  } = data || {};

  return {
    id,
    institutionId: institution?.label,
    name,
    port,
    privateKeyLocation: private_key_location,
    logFileLocation: log_file_location,
    status: commonStatusOptions.find(el => el.value === status)?.label,
    endpointTypeId: endpoint_type_id,
    endpointTypeName: endpoint_type_name,
    connectionAttributes: connection_attributes,
    sourceIpAddress: source_ip_address,
    lastMessageDatetime: last_message_datetime,
    lastFaultDatetime: last_fault_datetime,
  };
};

export const normalizeDetails = (data: Partial<IEndpointData>) => {
  const { endpoint_type_id, endpoint_type_name, status } = data || {};

  return {
    ...normalizeData(data),
    status: commonStatusOptions.find(el => el.value === status),
    endpointTypeId: {
      value: endpoint_type_id,
      label: endpoint_type_name,
    },
  };
};
