import { commonStatusOptions } from 'consts';
import {
  IInterfaceData,
  IInterfaceDetails,
  IInterfacesFilter,
  IInterfacesFilterToSend,
} from './types';

import { ISelectValue } from 'types';
import { stringsUtil } from 'utils';

export const normalizeFilterQueryString = (data: IInterfacesFilter): string => {
  const { institutionId } = data || {};

  const normalizedData: IInterfacesFilterToSend = {
    institution_id: institutionId?.value || null,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeDataForSending = (data: Partial<IInterfaceDetails>) => {
  const {
    connectionAttributes,
    interfaceTypeId,
    logFileLocation,
    name,
    privateKeyLocation,
    status,
    url,
  } = data || {};

  return {
    connection_attributes: connectionAttributes,
    interface_type_id: interfaceTypeId?.value,
    log_file_location: logFileLocation,
    name,
    private_key_location: privateKeyLocation,
    status: status?.value,
    url,
  };
};

export const normalizeData = (data: Partial<IInterfaceData>, institution?: ISelectValue) => {
  const {
    connection_attributes,
    id,
    interface_type_id,
    interface_type_name,
    last_fault_datetime,
    last_message_datetime,
    log_file_location,
    name,
    private_key_location,
    status,
    url,
  } = data || {};

  return {
    connectionAttributes: connection_attributes,
    id,
    institutionId: institution?.label,
    interfaceTypeId: interface_type_id,
    interfaceTypeName: interface_type_name,
    lastFaultDatetime: last_fault_datetime,
    lastMessageDatetime: last_message_datetime,
    logFileLocation: log_file_location,
    name,
    privateKeyLocation: private_key_location,
    status: commonStatusOptions.find(el => el.value === status)?.label,
    url,
  };
};

export const normalizeDetails = (data: Partial<IInterfaceData>) => {
  const { status, interface_type_id, interface_type_name } = data || {};

  return {
    ...normalizeData(data),
    status: commonStatusOptions.find(el => el.value === status),
    interfaceTypeId: {
      value: interface_type_id,
      label: interface_type_name,
    },
  };
};
