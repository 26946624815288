import { ImmutableArray } from 'seamless-immutable';

import {
  Flag,
  PermissionType,
  ScreenType,
} from 'consts';

import {
  IChangePassword,
  IChangePasswordToSend,
  IPermission,
  IPermissionData,
  IProfile,
  IProfileData,
} from './types';

export const normalizeChangePasswordDataForSending = (
  data: IChangePassword
): IChangePasswordToSend => {
  const { currentPassword, newPassword, code } = data || {};

  return {
    current_password: currentPassword,
    new_password: newPassword,
    code: code || '0',
  };
};

export const normalizeProfile = (data: IProfileData): IProfile => {

  const {
    first_name,
    institution_name,
    last_activity,
    last_name,
    master_institution_flag,
    username,
    status,
  } = data || {};

  return {
    fullName: `${first_name || ''} ${last_name || ''}` ,
    institutionName: `${institution_name || ''} ${master_institution_flag === Flag.YES ? '(master institution)' : ''}`,
    lastActivity: last_activity,
    username,
    status,
  };
};

const sortByOrderNumber = (items: Array<IPermissionData>) => items.sort((a, b) =>
  a.order_number > b.order_number
    ? 1
    : a.order_number < b.order_number
      ? -1
      : null
);

export const normalizePermissions = (data: ImmutableArray<IPermissionData>): Array<IPermission> => {
  const sortedItems = sortByOrderNumber(Array.from(data));

  return sortedItems?.map(item => {
    const {
      description,
      item_type,
      order_number,
      permission,
      ui_item,
    } = item;

    const isReadOnly = permission === PermissionType.READ_ONLY;
    const isSeparator = item_type === ScreenType.SEPARATOR;
    const parentId = ui_item.split('/').slice(0, -1).join('/');

    return {
      id: ui_item,
      isReadOnly,
      orderNumber: order_number,
      parentId,
      permission,
      separator: isSeparator,
      title: description,
      type: item_type,
    };
  });
};
