import React, { ReactChild } from 'react';

import { Box, Flex } from '@rebass/grid';

import { ModalName, ModalType } from 'consts';
import { IWithModal, withModal } from 'HOCs';

import { EllipseLabel } from './../../Layout';
import { T2 } from './../../Text';
import { Spinner } from './../Spinner';

import { ModalWrapper } from './ModalWrapper';
import StatusIcon from './StatusIcon';

const zIndexes = {
  [ModalType.DETAILS]: 100,
  [ModalType.CONFIRMATION]: 101,
  [ModalType.MESSAGE]: 102,
};

interface IModal extends IWithModal {
  children: React.ReactNode;

  /** Width of modal window container */
  containerWidth?: string;

  /** Blurs backdrop */
  isBluredBackdrop?: boolean;

  /** Min height of modal window container */
  minContainerHeight?: string;

  /** Name of modal window used in store */
  name: string;

  /** Title of modal window */
  title?: string;

  /** Enables icon which is next to the title */
  isSuccessIcon?: boolean;
  isInfoIcon?: boolean;

  /** Icon near the title */
  Icon?: ReactChild;

  /** Modal window type */
  type?: ModalType;

  /** Opens confirmation modal by modal window close */
  withCloseConfirmation?: boolean;

  /** Defines appearance of label 'Read Only' */
  isReadonly?: boolean;

  /** Enables loading indicator */
  isLoading?: boolean;

  /** Text appeared while loading */
  loadingText?: string;

  /** Hides close icon */
  hideCloseIcon?: boolean;
}

const Modal: React.FC<IModal> = ({
  children,
  closeModal,
  containerWidth = '720px',
  hideCloseIcon,
  Icon,
  isBluredBackdrop = true,
  isInfoIcon,
  isLoading,
  isLockedItem,
  isReadOnlyPage,
  isSuccessIcon,
  loadingText,
  minContainerHeight,
  name,
  openModal,
  title,
  type = ModalType.DETAILS,
  withCloseConfirmation,
}) => {

  // eslint-disable-next-line
  const handleCloseModal = React.useCallback(
    withCloseConfirmation
      ? () => openModal({
        name: ModalName.CONFIRMATION,
        payload: {
          confirmationAction: () => closeModal(name),
          confirmationTitle: 'Close the window?',
          confirmationText: 'You have unsaved changes.',
        },
      })
      : () => closeModal(name),
    [name, closeModal, withCloseConfirmation]
  );

  const labels = React.useMemo(
    () => {
      const config = [
        {
          isDisplayed: isLockedItem,
          title: 'Locked',
        },
        {
          isDisplayed: isReadOnlyPage,
          title: 'Read only',
          isGreen: true,
        },
      ];

      return config;
    },
    [isLockedItem, isReadOnlyPage]
  );

  return (
    <ModalWrapper
      containerWidth={containerWidth}
      minContainerHeight={minContainerHeight}
      zIndex={zIndexes[type]}
    >
      <div className={`modal-backdrop ${isBluredBackdrop ? 'is-blured' : '' }`} />

      <div className="modal-container-wrapper">
        <div className={isLoading ? 'modal-container is-loading' : 'modal-container'}>

          {isLoading && (
            <Spinner text={loadingText} />
          )}

          {!hideCloseIcon && (
            <span
              className="modal-close"
              title="Close"
              onClick={handleCloseModal}
            >
              &times;
            </span>
          )}

          <Flex
            alignItems="flex-start"
            flexWrap="wrap"
          >
            {title && (
              <>
                {isSuccessIcon && (<StatusIcon className="is-success" />)}
                {isInfoIcon && (<StatusIcon className="is-info" />)}

                <T2>{Icon} {title}</T2>
              </>
            )}

            {type === ModalType.DETAILS && labels.map((label, i) => label.isDisplayed && (
              <Box ml="7px" key={i}>
                <EllipseLabel text={label.title} isGreen={label.isGreen} />
              </Box>
            ))}
          </Flex>

          {children}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default withModal(Modal);
