import { connect } from 'react-redux';
import { isDirty } from 'redux-form';

import { FormName } from 'consts';

import DetailsInstitutionModal from './DetailsInstitutionModal';

import { currentInstitutionSelector, IStoreState } from 'store';

const dirty = isDirty(FormName.INSTITUTION);

const mapStateToProps = (state: IStoreState) => ({
  isFormDirty: dirty(state),
  currentInstitution: currentInstitutionSelector(state),
});

export default connect(
  mapStateToProps
)(DetailsInstitutionModal);
