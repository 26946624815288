import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { ActionButtons } from 'components';
import { FormName } from 'consts';
import { THandleAddProduct } from 'store';
import { ISelectValue } from 'types';

import GeneralProductFields from './../GeneralProductFields';

interface IAddProductForm {
  addProduct: THandleAddProduct;
  currentProductType: ISelectValue;
  isAdding: boolean;
  onCancel: () => void;
  statementCycleType: ISelectValue;
}

type TAddProductForm = IAddProductForm & InjectedFormProps<{}, IAddProductForm>;

const AddProductForm: React.FC<TAddProductForm> = ({
  addProduct,
  currentProductType,
  dirty,
  handleSubmit,
  isAdding,
  onCancel,
  pristine,
  statementCycleType,
}) => (
  <form onSubmit={handleSubmit(addProduct)}>
    <GeneralProductFields
      statementCycleType={statementCycleType}
      productType={currentProductType?.value}
      isLoading={isAdding}
    />

    <ActionButtons
      okText="Save"
      cancelText="Close"
      onCancel={onCancel}
      withCancelConfirmation={dirty}
      disabledOk={pristine}
      isLoadingOk={isAdding}
    />
  </form>
);

export default reduxForm<{}, IAddProductForm>({
  form: FormName.ADD_PRODUCT,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(AddProductForm);
