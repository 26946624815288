import Immutable, { ImmutableObject } from 'seamless-immutable';

import { ActionTypeKeys, TProductPaymentsAction } from './actionTypes';
import { IProductPaymentsState } from './types';

export const productPaymentsInitialState: ImmutableObject<IProductPaymentsState> = Immutable({
  productAprs: Immutable([]),
  productFees: Immutable([]),
  productPenalties: Immutable([]),
  productRewards: Immutable([]),
});

const productPaymentsReducer =
  (state = productPaymentsInitialState, action: TProductPaymentsAction) => {
    switch (action.type) {
      case ActionTypeKeys.GET_PRODUCT_APRS_FULFILLED:
        return state.set('productAprs', action.payload.product_aprs);

      case ActionTypeKeys.GET_PRODUCT_FEES_FULFILLED:
        return state.set('productFees', action.payload.product_fees);

      case ActionTypeKeys.GET_PRODUCT_REWARDS_FULFILLED:
        return state.set('productRewards', action.payload.product_rewards);

      case ActionTypeKeys.GET_PRODUCT_PENALTIES_FULFILLED:
        return state.set('productPenalties', action.payload.product_penalties);

      case ActionTypeKeys.RESET_PAYMENTS:
        return (state = productPaymentsInitialState);

      default: return state;
    }
  };

export default productPaymentsReducer;
