import { ModalName } from 'consts';
import { closeModal, openModal } from 'store';
import { Thunk, VoidPromiseThunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import * as api from './api';

import {
  ActionTypeKeys,
  IChangePasswordAction,
  IGetPermissionsAction,
  IGetProfileAction,
  IGetUserInstitutionsAction,
} from './actionTypes';

import {
  isLoadedPermissionsSelector,
  isLoadedProfileSelector,
  isUserInstitutionsLoadedSelector,
} from './selectors';

import {
  IChangePassword,
  IChangePasswordToSend,
} from './types';

import { normalizeChangePasswordDataForSending } from './utils';

/**
 * Get profile action
 */

export type TGetProfile = () => IGetProfileAction;
export type THandleGetProfile = VoidPromiseThunk;

export const getProfile: TGetProfile = () => ({
  type: ActionTypeKeys.GET_PROFILE,
  payload: api.getProfile(),
});

export const handleGetProfile: THandleGetProfile = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const isLoaded = isLoadedProfileSelector(state);

        if (!isLoaded) {
          await dispatch(getProfile());
        }
      },
      dispatch
    );
  };

/**
 * Get permissions action
 */

export type TGetPermissions = () => IGetPermissionsAction;
export type THandleGetPermissions = VoidPromiseThunk;

export const getPermissions: TGetPermissions = () => ({
  type: ActionTypeKeys.GET_PERMISSIONS,
  payload: api.getPermissions(),
});

export const handleGetPermissions: THandleGetPermissions = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const isLoaded = isLoadedPermissionsSelector(state);

        if (!isLoaded) {
          await dispatch(getPermissions());
        }
      },
      dispatch
    );
  };

/**
 * Change password action
 */

export type TChangePassword = (data: IChangePasswordToSend) => IChangePasswordAction;
export type THandleChangePassword = (data: Partial<IChangePassword>) => Thunk<void>;

export const changePassword: TChangePassword = data => ({
  type: ActionTypeKeys.CHANGE_PASSWORD,
  payload: api.changePassword(data),
});

export const handleChangePassword: THandleChangePassword = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const normalizedData = normalizeChangePasswordDataForSending(data as IChangePassword);

        await dispatch(changePassword(normalizedData));

        dispatch(closeModal(ModalName.CHANGE_PASSWORD));

        dispatch(openModal({
          name: ModalName.MESSAGE,
          payload: {
            title: 'Password has been changed',
            isSuccess: true,
          },
        }));
      },
      dispatch
    );
  };

/**
 * Get user institutions action
 */

export type TGetUserInstitutions = () => IGetUserInstitutionsAction;
export type THandleGetUserInstitutions = VoidPromiseThunk;

export const getUserInstitutions: TGetUserInstitutions = () => ({
  type: ActionTypeKeys.GET_USER_INSTITUTIONS,
  payload: api.getUserInstitutions(),
});

export const handleGetUserInstitutions: THandleGetUserInstitutions = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const isLoaded = isUserInstitutionsLoadedSelector(state);

        if (!isLoaded) {
          await dispatch(getUserInstitutions());
        }
      },
      dispatch
    );
  };
