import styled from 'theme';

import { menuClasses } from './utils';

export const NavListWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  line-height: 1.4;

  .${menuClasses.MENU_TITLE} {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 7px 10px 6px;
    text-decoration: none;

    .icon {
      position: absolute;
      right: 2px;
      top: 50%;
      margin-top: -7px;
    }

    &:hover .icon {
      color: ${({ theme }) => theme.colors.accent};
    }

    &.is-cursor-pointer {
      cursor: pointer;
    }

    &.is-disabled {
      pointer-events: none;
      opacity: .4;
    }
  }

  .${menuClasses.MENU_ITEM} {
    position: relative;
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .4pt;
    color: ${({ theme }) => theme.colors.black};
    user-select: none;
    margin: 0 1px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: all .1s linear;
    z-index: 100;


    &:hover > .${menuClasses.MENU_TITLE}:not(.is-disabled) {
      background-color: ${({ theme }) => theme.colors.lighterGray};
      color: ${({ theme }) => theme.colors.accent};
    }

    &.is-separator {
      position: relative;
      pointer-events: none;
      margin: 1px;
      height: 1.3px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
      font-size: 0;
      overflow: hidden;
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -10px;
      right: -10px;
      bottom: -10px;
      cursor: default;
    }

    &.${menuClasses.ACTIVE} {
      & > .${menuClasses.SUB_MENU} {
        display: block;
      }
    }
  }

  .${menuClasses.SUB_MENU} {
    position: absolute;
    left: 0;
    top: calc(100% + 8px);
    padding: 1px 0;
    display: flex;
    flex-direction: column;
    display: none;
    width: 180px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({ theme }) => theme.shadows.aroundBox};
    border: 1px solid ${({ theme }) => theme.colors.darkGray};
    border-radius: 2px;

    .${menuClasses.SUB_MENU} {
      left: calc(100% + 1px);
      top: -2px;
    }

    .${menuClasses.MENU_ITEM} {
      border-radius: 0;
      text-transform: none;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: normal;
      color: ${({ theme }) => theme.colors.black};
    }

    .${menuClasses.MENU_TITLE} {
      white-space: normal;
      padding-right: 15px;
      color: inherit;
    }
  }
`;
