import { Box } from '@rebass/grid';

import styled from 'theme';

import { ProductType } from 'consts';
import { LoanIcon, RevolvingCreditIcon } from './../Icons';

const Wrapper = styled(Box)`
  margin-right: 3px;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray};
  opacity: .9;
`;

export const ProductTypeIcons = {
  [ProductType.REVOLVING_CREDIT]: (
    <Wrapper mt="-1px">
      <RevolvingCreditIcon size="20" />
    </Wrapper>
  ),
  [ProductType.LOAN]: (
    <Wrapper mt="-2px">
      <LoanIcon size="20" />
    </Wrapper>
  ),
};
