import React from 'react';
import { Field } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  HorizontalLine,
  NewLine,
  NumberFormatField,
  SelectField,
} from 'components';

import { RepaymentType } from 'consts';

import { IAccount } from 'store';

import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface IRevolvingCreditFields {
  currentAccount?: IAccount;
  currentRepaymentType: ISelectValue;
  isEditMode: boolean;
  isReadOnly: boolean;
  isRepaymentMethodsLoading: boolean;
  isRepaymentTypesLoading: boolean;
  repaymentMethodsOptions: Array<ISelectValue>;
  repaymentTypesOptions: Array<ISelectValue>;
}

const RevolvingCreditFields: React.FC<IRevolvingCreditFields> = ({
  currentAccount,
  currentRepaymentType,
  isEditMode,
  isReadOnly,
  isRepaymentMethodsLoading,
  isRepaymentTypesLoading,
  repaymentMethodsOptions,
  repaymentTypesOptions,
}) => (
  <Flex
    alignItems="flex-end"
    flexWrap="wrap"
    mx="-8px"
  >
    <Box width="360px" p="8px">
      <Field
        id="repaymentType"
        name="repaymentType"
        component={SelectField}
        label="Repayment Type"
        placeholder="Select Type"
        isDisabled={isReadOnly}
        isLoading={isRepaymentTypesLoading}
        options={repaymentTypesOptions}
        validate={[formErrorUtil.isRequired]}
      />
    </Box>
    {currentRepaymentType?.value === RepaymentType.FIXED_AMOUNT && (
      <Box width="120px" p="8px">
        <Field
          id="repaymentFixedAmount"
          name="repaymentFixedAmount"
          label="Repayment Fixed Amount"
          component={NumberFormatField}
          placeholder="0.00"
          fixedDecimalScale={true}
          decimalScale={2}
          disabled={isReadOnly}
          validate={[
            formErrorUtil.isRequired,
            formErrorUtil.isStrictPositive,
          ]}
        />
      </Box>
    )}

    <NewLine />
    <Box width="240px" p="8px">
      <Field
        id="repaymentMethod"
        name="repaymentMethod"
        component={SelectField}
        label="Repayment Method"
        placeholder="Select Method"
        isDisabled={isReadOnly}
        isLoading={isRepaymentMethodsLoading}
        options={repaymentMethodsOptions}
        validate={[formErrorUtil.isRequired]}
      />
    </Box>
    <Box width="120px" p="8px">
      <Field
        id="statementCycleParameter"
        name="statementCycleParameter"
        label="Cycle Parameter"
        component={NumberFormatField}
        placeholder="0"
        fixedDecimalScale={true}
        decimalScale={0}
        disabled={isReadOnly}
        validate={[formErrorUtil.isRequired, formErrorUtil.isStrictPositive]}
      />
    </Box>

    {isEditMode && (
      <>
        <HorizontalLine />
        <Box width="120px" p="8px">
          <Field
            id="balanceSettled"
            name="balanceSettled"
            label="Balance Settled"
            component={NumberFormatField}
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            disabled={true}
          />
        </Box>
        <Box width="120px" p="8px">
          <Field
            id="balanceAuthorised"
            name="balanceAuthorised"
            label="Balance Authorised"
            component={NumberFormatField}
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            disabled={true}
          />
        </Box>
        <Box width="120px" p="8px">
          <Field
            id="repaymentAmountDue"
            name="repaymentAmountDue"
            label="Repayment Amount Due"
            component={NumberFormatField}
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            disabled={true}
          />
        </Box>
        <Box width="120px" p="8px">
          <Field
            id="amountOverpaid"
            name="amountOverpaid"
            label="Amount Overpaid"
            component={NumberFormatField}
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            disabled={true}
          />
        </Box>
        <Box width="120px" p="8px">
          <Field
            id="amountOverdue"
            name="amountOverdue"
            label="Amount Overdue"
            component={NumberFormatField}
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            disabled={true}
          />
        </Box>
        <Box width="120px" p="8px">
          <Field
            id="numberOfTimesOverdueCycles"
            name="numberOfTimesOverdueCycles"
            label="Number of Times Overdue Cycles"
            component={NumberFormatField}
            placeholder="0"
            fixedDecimalScale={true}
            decimalScale={0}
            disabled={true}
          />
        </Box>
        <HorizontalLine />
      </>
    )}
  </Flex>
);

export default RevolvingCreditFields;
