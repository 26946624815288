import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { isDirty } from 'redux-form';

import { FormName } from 'consts';

import DetailsInterfaceModal from './DetailsInterfaceModal';

import {
  currentInterfaceSelector,
  handleGetInterface,
  isGettingInterfaceSelector,
  IStoreState,
} from 'store';

const dirty = isDirty(FormName.INTERFACE);

const mapStateToProps = (state: IStoreState) => ({
  currentInterface: currentInterfaceSelector(state),
  isFormDirty: dirty(state),
  isLoading: isGettingInterfaceSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getInterface: handleGetInterface,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsInterfaceModal);
