import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import { Button, SelectField } from 'components';
import { FormName } from 'consts';
import { THandleGetProductRule } from 'store';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface IRulesFilterForm {
  actionTypeSelected: ISelectValue;
  actionTypesOptions: Array<ISelectValue>;
  eventIdSelected: ISelectValue;
  eventsOptions: Array<ISelectValue>;
  getRule: THandleGetProductRule;
  isEventsLoading: boolean;
  isRuleLoading: boolean;
}

type TRulesFilterForm = IRulesFilterForm & InjectedFormProps<{}, IRulesFilterForm>;

const RulesFilterForm: React.FC<TRulesFilterForm> = ({
  actionTypeSelected,
  actionTypesOptions,
  eventIdSelected,
  eventsOptions,
  getRule,
  handleSubmit,
  isEventsLoading,
  isRuleLoading,
  change,
}) => {
  React.useEffect(
    () => {
      if (!eventIdSelected) {
        change('actionType', null);
      }
    },
    [eventIdSelected, change]
  );

  return (
    <form onSubmit={handleSubmit(getRule)}>
      <Flex
        width={[1]}
        flexWrap="wrap"
        alignItems="flex-end"
        mx="-8px"
      >
        <Flex width="78%">
          <Box width={[1 / 2]} p="8px">
            <Field
              id="eventId"
              name="eventId"
              component={SelectField}
              label="Event"
              placeholder="Select Event"
              options={eventsOptions}
              isLoading={isEventsLoading}
              isDisabled={isRuleLoading}
              validate={[formErrorUtil.isRequired]}
            />
          </Box>

          <Box width={[1 / 2]} p="8px">
            <Field
              id="actionType"
              name="actionType"
              component={SelectField}
              label="Action Type"
              placeholder="Select Action Type"
              options={actionTypesOptions}
              isDisabled={isRuleLoading}
              validate={[formErrorUtil.isRequired]}
            />
          </Box>
        </Flex>

        <Box width="22%" p="8px">
          <Button
            text="Retrieve"
            isLoading={isRuleLoading}
          />
        </Box>
      </Flex>
    </form>
  );
};

export default reduxForm<{}, IRulesFilterForm>({
  form: FormName.PRODUCT_RULES_FILTER,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(RulesFilterForm);
