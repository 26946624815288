import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import {
  currentUsersGroupSelector,
  handleDeleteUsersGroupMember,
  handleGetUsersGroupMembers,
  isDeletingUsersGroupMemberSelector,
  isLoadingUsersGroupMembersSelector,
  IStoreState,
  usersGroupMembersSelector,
} from 'store';

import UsersGroupMembers from './UsersGroupMembers';

const mapStateToProps = (state: IStoreState) => ({
  isDeleting: isDeletingUsersGroupMemberSelector(state),
  isLoading: isLoadingUsersGroupMembersSelector(state),
  members: usersGroupMembersSelector(state),
  usersGroup: currentUsersGroupSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    getUsersGroupMembers: handleGetUsersGroupMembers,
    deleteUsersGroupMember: handleDeleteUsersGroupMember,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersGroupMembers);
