import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import {
  ActionTypeKeys,
  IFilterUsersActivityAction,
} from './actionTypes';
import * as api from './api';
import { IUsersActivityFilter } from './types';
import { normalizeFilterQueryString } from './utils';

/**
 * Filter user activity action
 */

export type TFilterUsersActivity = (queryString: string) => IFilterUsersActivityAction;
export type THandleFilterUsersActivity = (data: IUsersActivityFilter) => Thunk<void>;

export const filterUsersActivity: TFilterUsersActivity = queryString => ({
  type: ActionTypeKeys.FILTER_USERS_ACTIVITY,
  payload: api.filterUsersActivity(queryString),
});

export const handleFilterUsersActivity: THandleFilterUsersActivity = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterQueryString(data);

        await dispatch(filterUsersActivity(queryString));
      },
      dispatch
    );
  };

/**
 * Reset user activity action
 */

export type TResetUsersActivity = () => void;

export const resetUsersActivity: TResetUsersActivity = () => ({
  type: ActionTypeKeys.RESET_USERS_ACTIVITY,
});
