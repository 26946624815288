import { getFormValues } from 'redux-form';

import { CommonStatus, FormName, ModalName } from 'consts';
import {
  activeItemIdSelector,
  activeProductTypeSelector,
  closeModal,
  defaultCurrentProductSelector,
  openModal,
} from 'store';
import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

import {
  ActionTypeKeys,
  IAddProductAction,
  ICloneProductAction,
  IDeleteProductAction,
  IFilterProductsAction,
  IFilterProductsForSelectionAction,
  IGetProductAction,
  IUpdateProductAction,
} from './actionTypes';

import * as api from './api';

import {
  IProduct,
  IProductData,
  IProductsFilter,
} from './types';

import {
  normalizeCurrentProductForCreating,
  normalizeCurrentProductForUpdating,
  normalizeFilterQueryString,
} from './utils';

/**
 * Filter products action
 */
export type TFilterProducts = (queryString: string, productType: string | number) =>
  IFilterProductsAction;

export type THandleFilterProducts = (data: IProductsFilter) => Thunk<void>;

export const filterProducts: TFilterProducts = (queryString, productType) => ({
  type: ActionTypeKeys.FILTER_PRODUCTS,
  payload: api.getProductsList(queryString, productType),
});

export const handleFilterProducts: THandleFilterProducts = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const queryString = normalizeFilterQueryString(data);
        const productType = data?.productType?.value;

        await dispatch(filterProducts(queryString, productType));
      },
      dispatch
    );
  };

/**
 * Filter products for selection action
 */
export type TFilterProductsForSelection = (queryString: string, productType: string | number) =>
  IFilterProductsForSelectionAction;

export type THandleFilterProductsForSelection = (data: { productType: string | number }) =>
  Thunk<void>;

export const filterProductsForSelection: TFilterProductsForSelection = (
  queryString,
  productType
) => ({
  type: ActionTypeKeys.FILTER_PRODUCTS_FOR_SELECTION,
  payload: api.getProductsList(queryString, productType),
});

export const handleFilterProductsForSelection: THandleFilterProductsForSelection = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const { productType } = data;
        const queryString = `product_type=${productType}&status=${CommonStatus.ACTIVE}`;

        await dispatch(filterProductsForSelection(queryString, productType));
      },
      dispatch
    );
  };

/**
 * Get product action
 */
export type TGetProduct = (productId: number, productType: string) => IGetProductAction;
export type THandleGetProduct = () => Thunk<void>;

export const getProduct: TGetProduct = (productId, productType) => ({
  type: ActionTypeKeys.GET_PRODUCT,
  payload: api.getProduct(productId, productType),
});

export const handleGetProduct: THandleGetProduct = () => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();
      const productId = activeItemIdSelector(state);
      const productType = activeProductTypeSelector(state);

      await dispatch(getProduct(productId, productType));
    },
    dispatch
  );
};

/**
 * Create product action
 */
export type TAddProduct = (data: IProductData, productType: string | number) => IAddProductAction;
export type THandleAddProduct = (data: Partial<IProduct>) => Thunk<void>;

export const addProduct: TAddProduct = (data, productType) => ({
  type: ActionTypeKeys.ADD_PRODUCT,
  payload: api.addProduct(data, productType),
});

export const handleAddProduct: THandleAddProduct = data => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const normalizedData = normalizeCurrentProductForCreating(data as IProduct);
      const productType = normalizedData.product_type;

      const res = (await dispatch(
        addProduct(normalizedData, productType)
      )) as any;

      dispatch(closeModal(ModalName.ADD_PRODUCT));
      dispatch(
        openModal({
          name: ModalName.MESSAGE,
          payload: {
            title: 'Product has been created',
            message: `Product ID: ${res.value.product_id}`,
            isSuccess: true,
          },
        })
      );
    },
    dispatch
  );
};

/**
 * Clone product action
 */
export type TCloneProduct = (productId: number, productType: string) => ICloneProductAction;
export type THandleCloneProduct = () => Thunk<void>;

export const cloneProduct: TCloneProduct = (productId, productType) => ({
  type: ActionTypeKeys.CLONE_PRODUCT,
  payload: api.cloneProduct(productId, productType),
});

export const handleCloneProduct: THandleCloneProduct = () => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();
      const productId = activeItemIdSelector(state);
      const productType = activeProductTypeSelector(state);

      const res = (await dispatch(
        cloneProduct(productId, productType)
      )) as any;

      dispatch(
        openModal({
          name: ModalName.MESSAGE,
          payload: {
            title: 'Product has been cloned',
            message: `Product ID: ${res.value.product_id}`,
            isSuccess: true,
          },
        })
      );
    },
    dispatch
  );
};

/**
 * Update product action
 */
export type TUpdateProduct = (
  data: Partial<IProductData>,
  productId: number,
  productType: string
) => IUpdateProductAction;

export type THandleUpdateProduct = (data: Partial<IProduct>) => Thunk<void>;

export const updateProduct: TUpdateProduct = (data, productId, productType) => ({
type: ActionTypeKeys.UPDATE_PRODUCT,
payload: api.updateProduct(data, productId, productType),
});

export const handleUpdateProduct: THandleUpdateProduct = data => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();
      const productId = activeItemIdSelector(state);
      const productType = activeProductTypeSelector(state);
      const currentData = defaultCurrentProductSelector(state);
      const normalizedData = normalizeCurrentProductForUpdating(data, currentData);

      await dispatch(updateProduct(normalizedData, productId, productType));
      await dispatch(getProduct(productId, productType));
    },
    dispatch
  );
};

/**
 * Delete product action
 */
export type TDeleteProduct = (productId: number, productType: string) => IDeleteProductAction;
export type THandleDeleteProduct = () => Thunk<void>;

export const deleteProduct: TDeleteProduct = (productId, productType) => ({
  type: ActionTypeKeys.DELETE_PRODUCT,
  payload: api.deleteProduct(productId, productType),
});

export const handleDeleteProduct: THandleDeleteProduct = () => async (dispatch, getState) => {
  errorDecoratorUtil.withErrorHandler(
    async () => {
      const state = getState();
      const productId = activeItemIdSelector(state);
      const productType = activeProductTypeSelector(state);

      const filterFormValues = getFormValues(FormName.FILTER);

      await dispatch(deleteProduct(productId, productType));
      dispatch(closeModal(ModalName.DETAILS_PRODUCT));

      dispatch(
        openModal({
          name: ModalName.MESSAGE,
          payload: {
            title: 'Product has been deleted',
            message: `Product ID: ${productId}`,
            isSuccess: true,
          },
        })
      );

      await dispatch(handleFilterProducts(filterFormValues(state)  as IProductsFilter));
    },
    dispatch
  );
};

/**
 * Reset products action
 */
export type TResetProducts = () => void;

export const resetProducts: TResetProducts = () => ({
  type: ActionTypeKeys.RESET_PRODUCTS,
});

/**
 * Reset product action
 */
export type TResetProduct = () => void;

export const resetProduct: TResetProduct = () => ({
  type: ActionTypeKeys.RESET_PRODUCT,
});
