import { TApiResponse } from 'types';
import { IUsersActivity } from './types';

export enum ActionTypeKeys {
  /** Filter user activity action type keys */
  FILTER_USERS_ACTIVITY = 'usersActivity/FILTER_USERS_ACTIVITY',
  FILTER_USERS_ACTIVITY_FULFILLED = 'usersActivity/FILTER_USERS_ACTIVITY_FULFILLED',
  FILTER_USERS_ACTIVITY_REJECTED = 'usersActivity/FILTER_USERS_ACTIVITY_REJECTED',

  /** Reset user activity action type keys */
  RESET_USERS_ACTIVITY = 'usersActivity/RESET_USER_ACTIVITY',
}

/** Filter API calls action interfaces */

export interface IFilterUsersActivityAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_USERS_ACTIVITY;
}

export interface IFilterUsersActivityFulfilledAction {
  readonly payload: IUsersActivity;
  readonly type: ActionTypeKeys.FILTER_USERS_ACTIVITY_FULFILLED;
}

export interface IFilterUsersActivityRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_USERS_ACTIVITY_REJECTED;
}

/** Reset API calls action interfaces */

export interface IResetUsersActivityAction {
  readonly type: ActionTypeKeys.RESET_USERS_ACTIVITY;
}

export type TUsersActivityAction =
  | IFilterUsersActivityFulfilledAction
  | IResetUsersActivityAction;
