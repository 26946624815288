export enum CookiesName {
  AUTH_PENDING_FLAG = 'auth_pending',
  SESSION_ID = 'session_id',
}

export const SESSION_TIMEOUT = 1200; // in seconds

export enum LocalStorageName {
  FIRST_SCREEN_FLAG = 'first_screen',
  LAST_SCREEN_PATHNAME = 'last_screen',
  SESSION_TIMEOUT = 'session_timeout',
  LOGGED_IN_FLAG = 'logged_in',
}
