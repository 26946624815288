import { combineReducers } from 'redux-seamless-immutable';
import apiCallsReducer from './apiCalls/reducer';
import uiSessionsReducer from './uiSessions/reducer';
import usersActivityReducer from './usersActivity/reducer';

const auditReducer = combineReducers({
  apiCalls: apiCallsReducer,
  uiSessions: uiSessionsReducer,
  usersActivity: usersActivityReducer,
});

export default auditReducer;
