import React from 'react';
import { Field } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  MaskField,
  NewLine,
  NumberFormatField,
} from 'components';

import { MaskFormat } from 'consts';

import { formErrorUtil } from 'utils';

interface ILoanFields {
  isEditMode: boolean;
  isReadOnly: boolean;
}

const LoanFields: React.FC<ILoanFields> = ({ isEditMode, isReadOnly }) =>  (
  <Flex
    alignItems="flex-end"
    flexWrap="wrap"
    mx="-8px"
  >
    {isEditMode && (
      <>
        <NewLine />
        <Box width="120px" p="8px">
          <Field
            id="originalLoanAmount"
            name="originalLoanAmount"
            label="Original Loan Amount"
            component={NumberFormatField}
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            disabled={true}
          />
        </Box>
      </>
    )}
    <Box width="120px" p="8px">
      <Field
        id="numberOfInstalments"
        name="numberOfInstalments"
        label="Number of Instalments"
        component={NumberFormatField}
        placeholder="0"
        fixedDecimalScale={true}
        decimalScale={0}
        disabled={true}
        hint={isEditMode ? '' : 'This is zero when the account is created but is set to a positive value when the loan is disbursed'}

      />
    </Box>
    <Box width="120px" p="8px">
      <Field
        id="standardApr"
        name="standardApr"
        label="Standard APR"
        component={NumberFormatField}
        placeholder="0.00"
        fixedDecimalScale={true}
        decimalScale={2}
        disabled={isReadOnly}
        validate={[
          formErrorUtil.isRequired,
          formErrorUtil.isStrictPositive,
        ]}
      />
    </Box>
    <Box width="120px" p="8px">
      <Field
        id="alternateApr"
        name="alternateApr"
        label="Alternate APR"
        component={NumberFormatField}
        placeholder="0.00"
        fixedDecimalScale={true}
        decimalScale={2}
        disabled={isReadOnly}
        validate={[
          formErrorUtil.isRequired,
          formErrorUtil.isPositive,
        ]}
      />
    </Box>
    {isEditMode && (
      <>
        <NewLine />
        <Box width="120px" p="8px">
          <Field
            id="principal"
            name="principal"
            label="Principal"
            component={NumberFormatField}
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            disabled={true}
          />
        </Box>
        <Box width="120px" p="8px">
          <Field
            id="interestPosted"
            name="interestPosted"
            label="Interest Posted"
            component={NumberFormatField}
            placeholder="0.00"
            fixedDecimalScale={true}
            decimalScale={2}
            disabled={true}
          />
        </Box>
        <Box width="120px" p="8px">
          <Field
            id="disbursementDate"
            name="disbursementDate"
            label="Disbursement Date"
            component={MaskField}
            placeholder="-"
            mask={MaskFormat.DATE}
            disabled={true}
          />
        </Box>
      </>
    )}
  </Flex>
);

export default LoanFields;
