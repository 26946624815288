export const accountTypesOptions = [
  { value: 'checking', label: 'checking' },
  { value: 'savings', label: 'savings' },
];

export enum IdentificationType {
  NO_IDENTIFICATION = 'N',
  PASSPORT = 'P',
  DRIVING_LICENSE = 'D',
  ID_CARD = 'I',
}

export const identificationTypesOptions = [
  { value: IdentificationType.NO_IDENTIFICATION, label: 'No Identification' },
  { value: IdentificationType.PASSPORT, label: 'Passport' },
  { value: IdentificationType.DRIVING_LICENSE, label: 'Driving license' },
  { value: IdentificationType.ID_CARD, label: 'ID card' },
];

export enum CustomerStatus {
  PENDING = 'P',
  ACTIVE = 'A',
  INACTIVE = 'I',
  ACTION_REQUIRED = 'R',
  CLOSED = 'C',
  DELETED = 'D',
}
