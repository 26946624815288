import { ModalName, } from 'consts';

import { activeItemIdSelector, closeModal, isEndpointsLoadedSelector, openModal } from 'store';
import {
  ActionTypeKeys,
  IAddEndpointAction,
  IDeleteEndpointAction,
  IFilterEndpointsAction,
  IGetEndpointAction,
  IUpdateEndpointAction,
} from './actionTypes';

import * as api from './api';

import {
  IEndpointData,
  IEndpointDetails,
  IEndpointsFilter,
} from './types';

import { normalizeDataForSending, normalizeFilterQueryString } from './utils';

import { Thunk } from 'types';
import { errorDecoratorUtil } from 'utils';

/**
 * Filter endpoints action
 */

export type TFilterEndpoints = (queryString: string) => IFilterEndpointsAction;
export type THandleFilterEndpoints = (data: IEndpointsFilter) => Thunk<void>;

export const filterEndpoints: TFilterEndpoints = queryString => ({
  type: ActionTypeKeys.FILTER_ENDPOINTS,
  payload: api.filterEndpoints(queryString),
});

export const handleFilterEndpoints: THandleFilterEndpoints = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const queryString = normalizeFilterQueryString(data);

        if (!isEndpointsLoadedSelector(state)) {
          await dispatch(filterEndpoints(queryString));
        }
      },
      dispatch
    );
  };

/**
 * Add endpoint action
 */

export type TAddEndpoint = (data: Partial<IEndpointData>) => IAddEndpointAction;
export type THandleAddEndpoint = (data: Partial<IEndpointDetails>) => Thunk<void>;

export const addEndpoint: TAddEndpoint = data => ({
  type: ActionTypeKeys.ADD_ENDPOINT,
  payload: api.addEndpoint(data),
});

export const handleAddEndpoint: THandleAddEndpoint = data =>
  async dispatch => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const normalizedData = normalizeDataForSending(data);

        const res = (await dispatch(
          addEndpoint(normalizedData)
        )) as any;

        dispatch(closeModal(ModalName.ADD_ENDPOINT));

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: {
              title: 'Endpoint has been created',
              message: `Endpoint ID: ${res.value.endpoint_id}`,
              isSuccess: true,
            },
          })
        );
      },
      dispatch
    );
  };

/**
 * Get endpoint action
 */

export type TGetEndpoint = (id: number) => IGetEndpointAction;
export type THandleGetEndpoint = () => Thunk<void>;

export const getEndpoint: TGetEndpoint = id => ({
  type: ActionTypeKeys.GET_ENDPOINT,
  payload: api.getEndpoint(id),
});

export const handleGetEndpoint: THandleGetEndpoint = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const id = activeItemIdSelector(state);

        await dispatch(getEndpoint(id));
      },
      dispatch
    );
  };

/**
 * Delete endpoint action
 */

export type TDeleteEndpoint = (id: number) => IDeleteEndpointAction;
export type THandleDeleteEndpoint = () => Thunk<void>;

export const deleteEndpoint: TDeleteEndpoint = id => ({
  type: ActionTypeKeys.DELETE_ENDPOINT,
  payload: api.deleteEndpoint(id),
  meta: { id },
});

export const handleDeleteEndpoint: THandleDeleteEndpoint = () =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const id = activeItemIdSelector(state);

        await dispatch(deleteEndpoint(id));
        dispatch(closeModal(ModalName.DETAILS_ENDPOINT));

        dispatch(
          openModal({
            name: ModalName.MESSAGE,
            payload: {
              title: 'Endpoint has been deleted',
              message: `Endpoint ID: ${id}`,
              isSuccess: true,
            },
          })
        );
      },
      dispatch
    );
  };

/**
 * Update endpoint action
 */

export type TUpdateEndpoint = (data: Partial<IEndpointData>, endpointId: number) =>
  IUpdateEndpointAction;

export type THandleUpdateEndpoint = (data: Partial<IEndpointDetails>) => Thunk<void>;

export const updateEndpoint: TUpdateEndpoint = (data, endpointId) => ({
  type: ActionTypeKeys.UPDATE_ENDPOINT,
  payload: api.updateEndpoint(data, endpointId),
});

export const handleUpdateEndpoint: THandleUpdateEndpoint = data =>
  async (dispatch, getState) => {
    errorDecoratorUtil.withErrorHandler(
      async () => {
        const state = getState();
        const endpointId = activeItemIdSelector(state);
        const normalizedData = normalizeDataForSending(data);

        await dispatch(updateEndpoint(normalizedData, endpointId));
        await dispatch(getEndpoint(endpointId));
      },
      dispatch
    );
  };

/**
 * Reset endpoints action
 */

export type TResetEndpoints = () => void;

export const resetEndpoints: TResetEndpoints = () => ({
  type: ActionTypeKeys.RESET_ENDPOINTS,
});
