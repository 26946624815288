import {
  Flag,
  ProductType,
  productTypesOptions,
  TransactionStatus,
  transactionStatusOptions,
  TransactionTypeId,
} from 'consts';
import { ISelectValue } from 'types';
import { stringsUtil } from 'utils';

import {
  IManualTransactionFromValues,
  IManualTransactionRequest,
  IManualTransactionResponseData,
} from './typesManualTransaction';

import { ICommonAccountData } from './types';

export const normalizeInitialValuesForManualTransaction = (
  data: ICommonAccountData,
  currenciesOptions: Array<ISelectValue>
): Partial<IManualTransactionFromValues> => {
  const {
    currency_code,
    currency_numeric_code,
    id,
    product_type_name,
    product_type,
  } = data || {};

  const currencyCode = product_type === ProductType.LOAN ? currency_code : currency_numeric_code;

  return {
    accountId: id,
    currencyCode: currenciesOptions.find(currency => currency.value === currencyCode),
    productType: product_type,
    productTypeName: product_type_name,
    settledFlag: true,
  };
};

export const normalizeManualTransactionValuesForSending = (
  data: IManualTransactionFromValues
): IManualTransactionRequest => {
  const {
    accountId,
    amount,
    currencyCode,
    description,
    productType,
    settledFlag,
    transactionType,

    // funds disbursement
    numberOfInstalments,
    apr,
  } = data || {};

  /**
   * Transaction type option example:
   * {
   *  value: number,
   *  label: 'transactionTypeDescription',
   *  isDisabled: boolean,
   *  meta: { debitCreditIndicator: DebitCreditIndicator}
   * }
   *
   * { value: 1, label: 'Purchase - card', isDisabled: false, meta: { debitCreditIndicator: 'D' } }
   */

  const specificProductTypeFields = {
    [ProductType.LOAN]: {
      currency: currencyCode.value,
      status: settledFlag ? TransactionStatus.SETTLED : TransactionStatus.PENDING,
    },
    [ProductType.REVOLVING_CREDIT]: {
      currency_num_code: currencyCode.value,
      settled: settledFlag ? Flag.YES : Flag.NO,
    },
  };

  const specificTransactionTypeFields = {
    [TransactionTypeId.FUNDS_DISBURSEMENT]: {
      number_of_instalments: stringsUtil.toNumber(numberOfInstalments),
      apr: stringsUtil.toNumber(apr),
    },
  };

  return {
    account_id: stringsUtil.toNumber(accountId),
    amount: stringsUtil.toNumber(amount),
    description,
    transaction_type_id: transactionType.value,
    ...specificProductTypeFields[productType],
    ...specificTransactionTypeFields[transactionType.value],
  };
};

export const normalizeManualTransactionResult = (
  data: IManualTransactionResponseData,
  productType: string
) => {
  const {
    // common
    status,
    transaction_id,

    // revolving credit
    balance_authorised_after,
    balance_authorised_before,
    balance_settled_after,
    balance_settled_before,

    // loan
    account_id,
    amount_settled,
    amount,
    currency,
    description,
    principal_after,
    principal_before,
    transaction_datetime,
    transaction_type_id,
  } = data || {};

  const specificProductTypeFields = {
    [ProductType.LOAN]: [
      ['Account ID', account_id],
      ['Amount settled', stringsUtil.numberToFixed(amount_settled, 2)],
      ['Amount', stringsUtil.numberToFixed(amount, 2)],
      ['Currency', currency],
      ['Description', description],
      ['Principal before', stringsUtil.numberToFixed(principal_before, 2)],
      ['Principal after', stringsUtil.numberToFixed(principal_after, 2)],
      ['Transaction datetime', transaction_datetime],
      ['Transaction type ID', transaction_type_id],
    ],
    [ProductType.REVOLVING_CREDIT]: [
      ['Balance authorised before', stringsUtil.numberToFixed(balance_authorised_before, 2)],
      ['Balance authorised after', stringsUtil.numberToFixed(balance_authorised_after, 2)],
      ['Balance settled after', stringsUtil.numberToFixed(balance_settled_before, 2)],
      ['Balance settled after', stringsUtil.numberToFixed(balance_settled_after, 2)],
    ],
  };

  return [
    ['Transaction ID', transaction_id],
    ['Product type', productTypesOptions.find(el => el.value === productType)?.label],
    ['Status', transactionStatusOptions.find(el => el.value === status)?.label],
    ...specificProductTypeFields[productType],
  ];
};
