import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  ActionButtons,
  Button,
  CheckboxField,
  InputField,
  NumberFormatField,
  SelectField,
  TextareaField,
} from 'components';
import { FormName } from 'consts';
import { THandleMakeTransaction } from 'store';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface IManualTransactionForm {
  currenciesOptions: Array<ISelectValue>;
  isCurrenciesLoading: boolean;
  isFundsDisbursementTrType: boolean;
  isTransTypesLoading: boolean;
  makeTransaction: THandleMakeTransaction;
  onCancel: () => void;
  transactionTypesOptions: Array<ISelectValue>;
}

type TManualTransactionForm = IManualTransactionForm
  & InjectedFormProps<{}, IManualTransactionForm>;

const ManualTransactionForm: React.FC<TManualTransactionForm> = ({
  currenciesOptions,
  dirty,
  handleSubmit,
  isCurrenciesLoading,
  isFundsDisbursementTrType,
  isTransTypesLoading,
  makeTransaction,
  onCancel,
  pristine,
  reset,
  transactionTypesOptions,
}) => (
  <form onSubmit={handleSubmit(makeTransaction)}>
    <Flex
      alignItems="flex-end"
      flexWrap="wrap"
      mx="-8px"
    >
      <Box width={[1]} p="8px">
        <Field
          id="transactionType"
          name="transactionType"
          component={SelectField}
          label="Transaction Type"
          placeholder="Select Type"
          isLoading={isTransTypesLoading}
          options={transactionTypesOptions}
          validate={[formErrorUtil.isRequired]}
        />
      </Box>
      <Box width={[1]} p="8px">
        <Field
          id="currencyCode"
          name="currencyCode"
          component={SelectField}
          label="Currency"
          placeholder="Select Currency"
          options={currenciesOptions}
          isLoading={isCurrenciesLoading}
          validate={[formErrorUtil.isRequired]}
        />
      </Box>
      <Box width="120px" p="8px">
        <Field
          id="accountId"
          name="accountId"
          component={InputField}
          label="Account ID"
          placeholder="Enter ID"
          isNumber={true}
          disabled={true}
        />
      </Box>
      <Box width="120px" p="8px">
        <Field
          id="amount"
          name="amount"
          component={NumberFormatField}
          placeholder="0.00"
          fixedDecimalScale={true}
          decimalScale={2}
          label="Amount"
          validate={[
            formErrorUtil.isRequired,
            formErrorUtil.isStrictPositive,
          ]}
        />
      </Box>

      {isFundsDisbursementTrType && (
        <>
          <Box width="120px" p="8px">
            <Field
              id="numberOfInstalments"
              name="numberOfInstalments"
              component={NumberFormatField}
              placeholder="0"
              fixedDecimalScale={true}
              decimalScale={0}
              label="Number of Instalments"
              validate={[
                formErrorUtil.isRequired,
                formErrorUtil.isStrictPositive,
              ]}
            />
          </Box>
          <Box width="120px" p="8px">
            <Field
              id="apr"
              name="apr"
              component={NumberFormatField}
              placeholder="0.00"
              fixedDecimalScale={true}
              decimalScale={2}
              label="APR"
              validate={[
                formErrorUtil.isRequired,
                formErrorUtil.isStrictPositive,
              ]}
            />
          </Box>
        </>
      )}

      <Box width={[1]} p="8px">
        <Field
          id="description"
          name="description"
          component={TextareaField}
          placeholder="Enter Description"
          label="Description"
          height={100}
          validate={[formErrorUtil.isRequired]}
        />
      </Box>
      <Box width={[1]} p="8px" pb="0">
        <Field
          id="settledFlag"
          name="settledFlag"
          component={CheckboxField}
          label="Settled"
        />
      </Box>
    </Flex>

    <Flex
      justifyContent="space-between"
      mt="15px"
    >
      <Button
        text="Reset form"
        disabled={pristine}
        type="reset"
        onClick={reset}
      />
      <ActionButtons
        okText="Apply"
        cancelText="Cancel"
        onCancel={onCancel}
        withCancelConfirmation={dirty}
        disabledOk={pristine}
      />
    </Flex>
  </form >
);

export default reduxForm<{}, IManualTransactionForm>({
  form: FormName.MANUAL_TRANSACTION,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(ManualTransactionForm);
