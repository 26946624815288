import { IResponseStatus, TApiResponse } from 'types';
import { ICommonCustomersData, ICustomerDataResp } from './types';

export enum ActionTypeKeys {
  /** customers */

  FILTER_CUSTOMERS = 'customers/FILTER_CUSTOMERS',
  FILTER_CUSTOMERS_FULFILLED = 'customers/FILTER_CUSTOMERS_FULFILLED',
  FILTER_CUSTOMERS_REJECTED = 'customers/FILTER_CUSTOMERS_REJECTED',

  GET_CUSTOMER = 'customers/GET_CUSTOMER',
  GET_CUSTOMER_FULFILLED = 'customers/GET_CUSTOMER_FULFILLED',
  GET_CUSTOMER_REJECTED = 'customers/GET_CUSTOMER_REJECTED',

  ADD_CUSTOMER = 'customers/ADD_CUSTOMER',
  ADD_CUSTOMER_FULFILLED = 'customers/ADD_CUSTOMER_FULFILLED',
  ADD_CUSTOMER_REJECTED = 'customers/ADD_CUSTOMER_REJECTED',

  UPDATE_CUSTOMER = 'customers/UPDATE_CUSTOMER',
  UPDATE_CUSTOMER_FULFILLED = 'customers/UPDATE_CUSTOMER_FULFILLED',
  UPDATE_CUSTOMER_REJECTED = 'customers/UPDATE_CUSTOMER_REJECTED',

  RESET_CUSTOMERS = 'customers/RESET_CUSTOMERS',

  RESET_CUSTOMER = 'customers/RESET_CUSTOMER',
}

/** Filter customers action interface */

export interface IFilterCustomersAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_CUSTOMERS;
}

export interface IFilterCustomersFulfilledAction {
  readonly payload: ICommonCustomersData;
  readonly type: ActionTypeKeys.FILTER_CUSTOMERS_FULFILLED;
}

export interface IFilterCustomersRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_CUSTOMERS_REJECTED;
}

/** Get customer action interfaces */

export interface IGetCustomerAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_CUSTOMER;
}

export interface IGetCustomerFulfilledAction {
  readonly payload: ICustomerDataResp;
  readonly type: ActionTypeKeys.GET_CUSTOMER_FULFILLED;
}

export interface IGetCustomerRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_CUSTOMER_REJECTED;
}

/** Add customer action interface */

export interface IAddCustomerAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ADD_CUSTOMER;
}

export interface IAddCustomerFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.ADD_CUSTOMER_FULFILLED;
}

export interface IAddCustomerRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.ADD_CUSTOMER_REJECTED;
}

/** Update customer action interface */

export interface IUpdateCustomerAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.UPDATE_CUSTOMER;
}

export interface IUpdateCustomerFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.UPDATE_CUSTOMER_FULFILLED;
}

export interface IUpdateCustomerRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.UPDATE_CUSTOMER_REJECTED;
}

/** Reset customers action interface */

export interface IResetCustomersAction {
  readonly type: ActionTypeKeys.RESET_CUSTOMERS;
}

/** Reset customer action interface */

export interface IResetCustomerAction {
  readonly type: ActionTypeKeys.RESET_CUSTOMER;
}

export type TCustomersAction =
  | IAddCustomerFulfilledAction
  | IFilterCustomersFulfilledAction
  | IGetCustomerFulfilledAction
  | IResetCustomerAction
  | IResetCustomersAction
  | IUpdateCustomerFulfilledAction;
