import React from 'react';

import { Modal, T2 } from 'components';
import { ModalName } from 'consts';
import { IWithModal, withModal } from 'HOCs';
import { ISettlementFormValues, THandleSettleTransaction } from 'store';

import SettleTransactionForm from './SettleTransactionForm';

interface ISettleTransactionModal extends IWithModal {
  isDirtySettleTransactionForm: boolean;
  isLoading: boolean;
  settleTransaction: THandleSettleTransaction;
  transactionForSettlement: ISettlementFormValues;
}

const modalName = ModalName.SETTLE_TRANSACTION;

const SettleTransactionModal: React.FC<ISettleTransactionModal> = ({
  closeModal,
  isDirtySettleTransactionForm,
  isLoading,
  settleTransaction,
  transactionForSettlement,
}) => {
  return (
    <Modal
      name={modalName}
      containerWidth="400px"
      isLoading={isLoading}
      loadingText="Settlement is being processed"
      withCloseConfirmation={isDirtySettleTransactionForm}
    >
      <T2>Settle transaction</T2>

      <SettleTransactionForm
        isDisabled={isLoading}
        initialValues={transactionForSettlement}
        onCancel={() => closeModal(modalName)}
        settleTransaction={settleTransaction}
      />
    </Modal>
  );
};

export default withModal(SettleTransactionModal);
