import { TApiResponse } from 'types';
import {
  ICommonStatementsData,
  IStatementAprsData,
  IStatementDataResp,
} from './types';

export enum ActionTypeKeys {
  /** Filter statements action type keys */
  FILTER_STATEMENTS = 'statements/FILTER_STATEMENTS',
  FILTER_STATEMENTS_FULFILLED = 'statements/FILTER_STATEMENTS_FULFILLED',
  FILTER_STATEMENTS_REJECTED = 'statements/FILTER_STATEMENTS_REJECTED',

  /** Get statement action type keys */
  GET_STATEMENT = 'statements/GET_STATEMENT',
  GET_STATEMENT_FULFILLED = 'statements/GET_STATEMENT_FULFILLED',
  GET_STATEMENT_REJECTED = 'statements/GET_STATEMENT_REJECTED',

  /** Get statement APRs action type keys */
  GET_STATEMENT_APRS = 'statements/GET_STATEMENT_APRS',
  GET_STATEMENT_APRS_FULFILLED = 'statements/GET_STATEMENT_APRS_FULFILLED',
  GET_STATEMENT_APRS_REJECTED = 'statements/GET_STATEMENT_APRS_REJECTED',

  /** Change minimum repayment action type keys */
  CHANGE_MINIMUM_REPAYMENT = 'statements/CHANGE_MINIMUM_REPAYMENT',
  CHANGE_MINIMUM_REPAYMENT_FULFILLED = 'statements/CHANGE_MINIMUM_REPAYMENT_FULFILLED',
  CHANGE_MINIMUM_REPAYMENT_REJECTED = 'statements/CHANGE_MINIMUM_REPAYMENT_REJECTED',

  /** Reset statements action type keys */
  RESET_STATEMENTS = 'statements/RESET_STATEMENTS',

  /** Reset statement action type keys */
  RESET_STATEMENT = 'statements/RESET_STATEMENT',

  /** Reset statement APRs action type keys */
  RESET_STATEMENT_APRS = 'statements/RESET_STATEMENT_APRS',
}

/** Filter statements action interfaces */
export interface IFilterStatementsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_STATEMENTS;
}

export interface IFilterStatementsFulfilledAction {
  readonly payload: ICommonStatementsData;
  readonly type: ActionTypeKeys.FILTER_STATEMENTS_FULFILLED;
}

export interface IFilterStatementsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_STATEMENTS_REJECTED;
}

/** Get statement action interfaces */

export interface IGetStatementAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_STATEMENT;
}

export interface IGetStatementFulfilledAction {
  readonly payload: IStatementDataResp;
  readonly type: ActionTypeKeys.GET_STATEMENT_FULFILLED;
}

export interface IGetStatementRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_STATEMENT_REJECTED;
}

/** Get statement APRs action interfaces */
export interface IGetStatementAprsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_STATEMENT_APRS;
}

export interface IGetStatementAprsFulfilledAction {
  readonly payload: IStatementAprsData;
  readonly type: ActionTypeKeys.GET_STATEMENT_APRS_FULFILLED;
}

export interface IGetStatementAprsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_STATEMENT_APRS_REJECTED;
}

/** Reset statements action interfaces */
export interface IResetStatementsAction {
  readonly type: ActionTypeKeys.RESET_STATEMENTS;
}

/** Reset statement action interfaces */
export interface IResetStatementAction {
  readonly type: ActionTypeKeys.RESET_STATEMENT;
}

/** Reset statement APRs action interfaces */
export interface IResetStatementAprsAction {
  readonly type: ActionTypeKeys.RESET_STATEMENT_APRS;
}

export type TStatementsAction =
  | IFilterStatementsFulfilledAction
  | IGetStatementAprsFulfilledAction
  | IResetStatementsAction
  | IGetStatementFulfilledAction
  | IResetStatementAction
  | IResetStatementAprsAction;
