import { dateUtil, stringsUtil } from 'utils';
import {
  IUsersActivityData,
  IUsersActivityFilter,
  IUsersActivityFilterToSend,
  IUsersActivityItem,
} from './types';

export const normalizeFilterQueryString = (data: IUsersActivityFilter): string => {
  const {
    dateFrom,
    dateTo,
    institutionId,
    pageNumber,
    username,
  } = data || {};

  const normalizedData: IUsersActivityFilterToSend = {
    date_from: dateUtil.formatDateForSending(dateFrom),
    date_to: dateUtil.formatDateForSending(dateTo),
    institution_id: institutionId?.value,
    page_number: pageNumber,
    username: username?.value,
  };

  const queryString = stringsUtil.normalizeQueryString(normalizedData);

  return queryString;
};

export const normalizeData = (data: IUsersActivityData): IUsersActivityItem => {
  const {
    api_name,
    description,
    event_datetime,
    first_name,
    id,
    last_name,
    username,
  } = data || {};

  return {
    apiName: api_name,
    description,
    eventDatetime: event_datetime,
    fullName: `${first_name || ''} ${last_name || ''}`,
    id,
    username,
  };
};
