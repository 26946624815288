import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import SystemPropertyForm from './SystemPropertyForm';

import {
  currentSysPropSelector,
  handleAddSysProp,
  handleDeleteSysProp,
  handleUpdateSysProp,
  isSysPropAddingSelector,
  isSysPropDeletingSelector,
  isSysPropUpdatingSelector,
  IStoreState,
} from 'store';

const mapStateToProps = (state: IStoreState) => ({
  isAdding: isSysPropAddingSelector(state),
  isDeleting: isSysPropDeletingSelector(state),
  isUpdating: isSysPropUpdatingSelector(state),
  initialValues: currentSysPropSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    addSystemProperty: handleAddSysProp,
    deleteSystemProperty: handleDeleteSysProp,
    updateSystemProperty: handleUpdateSysProp,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemPropertyForm);
