import React from 'react';

import { Modal } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';
import { InterfaceForm } from './../../forms';

interface IAddInterfaceModal extends IWithModal {
  isFormDirty: boolean;
}

const modalName = ModalName.ADD_INTERFACE;

const AddInterfaceModal: React.FC<IAddInterfaceModal> = ({
  closeModal,
  isFormDirty,
}) => {
  return (
    <Modal
      name={modalName}
      containerWidth="900px"
      title="Create interface"
      withCloseConfirmation={isFormDirty}
    >
      <InterfaceForm onCancel={() => closeModal(modalName)} />
    </Modal>
  );
};

export default withModal(AddInterfaceModal);
