import { IActiveItemAttributes } from './types';

export enum ActionTypeKeys {
  SET_ACTIVE_ITEM_ATTRIBUTES = 'utils/SET_ACTIVE_ITEM_ATTRIBUTES',
  SET_ACTIVE_PAGE_PERMISSION = 'utils/SET_ACTIVE_PAGE_PERMISSION',

  RESET_UTILS = 'utils/RESET_UTILS',
}

export interface ISetActiveItemAttributesAction {
  readonly payload: IActiveItemAttributes;
  readonly type: ActionTypeKeys.SET_ACTIVE_ITEM_ATTRIBUTES;
}

export interface ISetActivePagePermissionAction {
  readonly type: ActionTypeKeys.SET_ACTIVE_PAGE_PERMISSION;
  readonly payload: boolean;
}

export interface IResetUtilsAction {
  readonly type: ActionTypeKeys.RESET_UTILS;
}

export type TUtilsAction =
  | ISetActiveItemAttributesAction
  | ISetActivePagePermissionAction
  | IResetUtilsAction;
