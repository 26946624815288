import React from 'react';

import { Modal } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';
import { EndpointForm } from './../../forms';

import { IEndpointDetails, THandleGetEndpoint } from 'store';

interface IDetailsEndpointModal extends IWithModal {
  currentEndpoint: Partial<IEndpointDetails>;
  getEndpoint: THandleGetEndpoint;
  isFormDirty: boolean;
  isLoading: boolean;
}

const modalName = ModalName.DETAILS_ENDPOINT;

const DetailsEndpointModal: React.FC<IDetailsEndpointModal> = ({
  closeModal,
  currentEndpoint,
  getEndpoint,
  isFormDirty,
  isLoading,
  isReadOnlyPage,
}) => {
  React.useEffect(
    () => {
      getEndpoint();
    },
    [getEndpoint]
  );

  return (
    <Modal
      title="Endpoint"
      isLoading={isLoading}
      name={modalName}
      containerWidth="600px"
      withCloseConfirmation={isFormDirty}
    >
      <EndpointForm
        onCancel={() => closeModal(modalName)}
        isEditMode={true}
        initialValues={currentEndpoint}
        currentEndpointName={currentEndpoint?.name}
        isReadOnly={isReadOnlyPage}
      />
    </Modal>
  );
};

export default withModal(DetailsEndpointModal);
