import React, { ReactChild } from 'react';

import { SelectInput, TextInput } from './../../Inputs';

import { TableItemWrapper } from './TableItemWrapper';

import { ISelectValue } from 'types';

interface ITableCell {
  defaultSelectValue?: ISelectValue;
  Icon?: ReactChild;
  isDate?: boolean;
  isEditable?: boolean;
  isNumber?: boolean;
  isSelect?: boolean;
  isSmaller?: boolean;
  onBlur?: any;
  onCenter?: boolean;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  onSelectChange?: any;
  selectLabel?: string;
  selectOptions?: Array<ISelectValue>;
  style?: object;
  value: string | number;
}

export const TableCell: React.FC<ITableCell> = ({
  defaultSelectValue,
  Icon,
  isDate,
  isEditable,
  isNumber,
  isSelect,
  isSmaller,
  onBlur,
  onCenter,
  onKeyUp,
  onSelectChange,
  selectLabel,
  selectOptions,
  style,
  value,
}) => {
  return (
    <TableItemWrapper
      style={style}
      textRight={isNumber}
      onBlur={onBlur}
      onKeyUp={onKeyUp}
      isDate={isDate}
      isEditable={isEditable}
      isSelect={isSelect}
      textCenter={onCenter}
      isAccentColor={value === 'Pending'}
      isSmaller={isSmaller}
    >
      {Icon}
      {isEditable
        ? isSelect
          ? (<SelectInput
              isClearable={false}
              options={selectOptions}
              defaultValue={defaultSelectValue}
              isEditableCellStyle={true}
              onChange={onSelectChange}
            />)
          : (<TextInput
              value={value}
              isNumber={isNumber}
              isEditableCellStyle={true}
            />)
        : isSelect
          ? selectLabel
          : value
      }
    </TableItemWrapper>
  );
};
