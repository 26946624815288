import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Box, Flex } from '@rebass/grid';

import {
  ActionButtons,
  CheckboxField,
  HorizontalLine,
  InputField,
  PasswordField,
  SelectField,
} from 'components';

import {
  FormName,
  UserStatus,
  userStatusOptions,
  userStatusWith2faOptions,
} from 'consts';

import { THandleAddUser, THandleUpdateUser } from 'store';

import { ISelectValue } from 'types';

import { formErrorUtil } from 'utils';

interface IUserForm {
  addUser: THandleAddUser;
  institutionsOptions: Array<ISelectValue>;
  isAdding: boolean;
  isEditMode?: boolean;
  isReadOnly?: boolean;
  isUpdating: boolean;
  onCancel?: () => void;
  requires2faFlagValue: boolean;
  statusValue: ISelectValue;
  updateUser: THandleUpdateUser;
}

type TUserForm = IUserForm & InjectedFormProps<{}, IUserForm>;

const UserForm: React.FC<TUserForm> = ({
  addUser,
  change,
  dirty,
  handleSubmit,
  institutionsOptions,
  isAdding,
  isEditMode,
  isReadOnly,
  isUpdating,
  onCancel,
  pristine,
  requires2faFlagValue,
  statusValue,
  updateUser,
}) => {
  React.useEffect(
    () => {
      const isRegistrationPendingStatus = !requires2faFlagValue
        && statusValue?.value === UserStatus.REGISTRATION_PENDING;

      if (isRegistrationPendingStatus) {
        change('status', null);
      }
    },
    [change, requires2faFlagValue, statusValue]
  );

  const submitAction = React.useMemo(
    () => isEditMode ? updateUser : addUser,
    [isEditMode, updateUser, addUser]
  );

  const passwordValidation = React.useMemo(
    () => isEditMode
      ? null
      : [formErrorUtil.isRequired],
    [isEditMode]
  );

  const repeatPasswordValidation = React.useMemo(
    () => isEditMode
      ? [formErrorUtil.passwordsMatch]
      : [formErrorUtil.isRequired, formErrorUtil.passwordsMatch],
    [isEditMode]
  );

  return (
    <form onSubmit={isReadOnly ? null : handleSubmit(submitAction)}>
      <Flex
        flexWrap="wrap"
        alignItems="flex-end"
        mx="-8px"
      >
        <Box width={[1 / 2]} p="8px">
          <Field
            id="firstName"
            name="firstName"
            placeholder="Enter First Name"
            component={InputField}
            label="First Name"
            disabled={isReadOnly || isAdding || isUpdating}
            validate={[
              formErrorUtil.isRequired,
              formErrorUtil.isAlpha,
            ]}
          />
        </Box>
        <Box width={[1 / 2]} p="8px">
          <Field
            id="lastName"
            name="lastName"
            placeholder="Enter Last Name"
            component={InputField}
            label="Last Name"
            disabled={isReadOnly}
            validate={[
              formErrorUtil.isRequired,
              formErrorUtil.isAlpha,
            ]}
          />
        </Box>
        <Box width={[1 / 2]} p="8px">
          <Field
            id="username"
            name="username"
            component={InputField}
            label="Username"
            placeholder="Enter Username"
            disabled={isEditMode || isReadOnly || isAdding || isUpdating}
            validate={[
              formErrorUtil.isRequired,
              formErrorUtil.isUsername,
            ]}
          />
        </Box>
        <Box width={[1 / 2]} p="8px">
          <Field
            id="email"
            name="email"
            placeholder="Enter Email"
            component={InputField}
            label="Email"
            disabled={isReadOnly || isAdding || isUpdating}
            validate={[
              formErrorUtil.isRequired,
              formErrorUtil.isEmail,
            ]}
          />
        </Box>
        <Box width={[1 / 2]} p="8px">
          <Field
            id="institution"
            name="institution"
            placeholder="Select Institution"
            component={SelectField}
            label="Institution"
            options={institutionsOptions}
            isClearable={false}
            isDisabled={isEditMode || isReadOnly || isAdding || isUpdating}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>
        <Box width={[1 / 2]} p="8px">
          <Field
            id="status"
            name="status"
            component={SelectField}
            label="Status"
            placeholder="Select Status"
            options={requires2faFlagValue ? userStatusWith2faOptions : userStatusOptions}
            isDisabled={isReadOnly || isAdding || isUpdating}
            validate={[formErrorUtil.isRequired]}
          />
        </Box>
        <Box width="100%" p="8px 8px 5px">
          <Field
            id="requires2faFlag"
            name="requires2faFlag"
            component={CheckboxField}
            label="2FA Required"
            disabled={isReadOnly || isAdding || isUpdating}
          />
        </Box>

        {!isReadOnly && (
          <>
            <HorizontalLine />
            <Box width={[1 / 2]} p="8px">
              <Field
                id="password"
                name="password"
                placeholder="Enter Password"
                component={PasswordField}
                autoComplete="new-password"
                label="Password"
                validate={passwordValidation}
                disabled={isReadOnly || isAdding || isUpdating}
              />
            </Box>
            <Box width={[1 / 2]} p="8px">
              <Field
                id="passwordRepeat"
                name="passwordRepeat"
                placeholder="Repeat Password"
                component={PasswordField}
                autoComplete="new-password"
                label="Repeat Password"
                validate={repeatPasswordValidation}
                disabled={isReadOnly || isAdding || isUpdating}
              />
            </Box>
          </>
        )}
      </Flex>

      <Flex
        justifyContent="flex-end"
        mt="15px"
      >
        <ActionButtons
          okText="Save"
          cancelText="Close"
          onCancel={onCancel}
          withCancelConfirmation={dirty}
          disabledOk={pristine}
          hideOk={isReadOnly}
          isLoadingOk={isAdding || isUpdating}
        />
      </Flex>
    </form >
  );
};

export default reduxForm<{}, IUserForm>({
  form: FormName.USER,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(UserForm);
