import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { formValueSelector } from 'redux-form';

import { FormName } from 'consts';

import AccountForm from './AccountForm';

import {
  dictionaryAccountStatusesOptionsSelector,
  dictionaryRepaymentMethodsOptionsSelector,
  dictionaryRepaymentTypesOptionsSelector,
  handleAddAccount,
  handleFilterProductsForSelection,
  handleGetDictionaryAccountStatuses,
  handleGetDictionaryRepaymentMethods,
  handleGetDictionaryRepaymentTypes,
  handleUpdateAccount,
  isAddingAccountSelector,
  isProductsForSelectionLoadingSelector,
  isRepaymentMethodsLoadingSelector,
  isRepaymentTypesLoadingSelector,
  IStoreState,
  isUpdatingAccountSelector,
  productsOptionsSelector,
  userInstitutionsOptionsSelector,
} from 'store';

const formSelector = formValueSelector(FormName.ACCOUNT);

const mapStateToProps = (state: IStoreState) => ({
  currentProductType: formSelector(state, 'productType'),
  currentRepaymentType: formSelector(state, 'repaymentType'),
  institutionsOptions: userInstitutionsOptionsSelector(state),
  isAdding: isAddingAccountSelector(state),
  isProductsLoading: isProductsForSelectionLoadingSelector(state),
  isRepaymentMethodsLoading: isRepaymentMethodsLoadingSelector(state),
  isRepaymentTypesLoading: isRepaymentTypesLoadingSelector(state),
  isUpdating: isUpdatingAccountSelector(state),
  productsOptions: productsOptionsSelector(state),
  repaymentMethodsOptions: dictionaryRepaymentMethodsOptionsSelector(state),
  repaymentTypesOptions: dictionaryRepaymentTypesOptionsSelector(state),
  statusesOptions: dictionaryAccountStatusesOptionsSelector(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators(
  {
    addAccount: handleAddAccount,
    filterProductsForSelection: handleFilterProductsForSelection,
    getAccountStatuses: handleGetDictionaryAccountStatuses,
    getRepaymentMethods: handleGetDictionaryRepaymentMethods,
    getRepaymentTypes: handleGetDictionaryRepaymentTypes,
    updateAccount: handleUpdateAccount,
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountForm);
