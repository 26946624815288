import React from 'react';
import { CellInfo } from 'react-table';
import { ImmutableArray } from 'seamless-immutable';

import { Flex } from '@rebass/grid';

import {
  Button,
  Table,
  TableCell,
  TableHeader,
  withSpinner,
} from 'components';

import { IconName } from 'consts';

import {
  IProductPenalty,
  THandleDeleteProductPenalty,
} from 'store';

import { ITableCell } from 'types';

type TCell<T extends keyof IProductPenalty> = ITableCell<IProductPenalty[T]>;

interface IPenaltiesTable {
  deletePenalty: THandleDeleteProductPenalty;
  isDeleting: boolean;
  isReadOnly: boolean;
  penalties: ImmutableArray<IProductPenalty>;
}

const PenaltiesTable: React.FC<IPenaltiesTable> = ({
  deletePenalty,
  isDeleting,
  isReadOnly,
  penalties,
}) => {

  const tableColumns = React.useMemo(
    () => [
      {
        maxWidth: 75,
        accessor: 'penaltyId',
        Header: <TableHeader title="Penalty ID" />,
        Cell: (props: TCell<'penaltyId'>) => (
          <TableCell
            value={props.value}
            isNumber={true}
          />
        ),
      },
      {
        maxWidth: 400,
        accessor: 'description',
        Header: <TableHeader title="Description" />,
        Cell: (props: TCell<'description'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 75,
        accessor: 'rate',
        Header: <TableHeader title="Rate" />,
        Cell: (props: TCell<'rate'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 75,
        accessor: 'amount',
        Header: <TableHeader title="Amount" />,
        Cell: (props: TCell<'amount'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 400,
        accessor: 'penaltyApplicationConditionName',
        Header: <TableHeader title="Application Condition" />,
        Cell: (props: TCell<'penaltyApplicationConditionName'>) => (
          <TableCell
            value={props.value}
          />
        ),
      },
      {
        maxWidth: 50,
        accessor: 'deleteButton',
        Cell: (cellInfo: CellInfo) => !isReadOnly && (
          <Flex
            justifyContent="center"
            width="100%"
            p="4px"
          >
            <Button
              iconName={IconName.DELETE}
              title="Delete"
              withConfirmation={true}
              confirmationText={`Confirm you want to delete "${cellInfo.original.description}"?`}
              classNames={['is-circle']}
              onClick={() => deletePenalty(cellInfo.original)}
              isLoading={isDeleting}
            />
          </Flex>
        ),
      },
    ],
    [deletePenalty, isDeleting, isReadOnly]
  );

  return (
    <Table
      columns={tableColumns}
      data={penalties}
      pageSize={10}
    />
  );
};

export default withSpinner()(PenaltiesTable);
