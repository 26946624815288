import React from 'react';
import styled from 'theme';

interface IDropdownOptionWrapper {
  isBorderBottom?: boolean;
  isDisabled?: boolean;
}

const DropdownOptionWrapper = styled.div<IDropdownOptionWrapper>`
  ${({ isBorderBottom, theme }) => isBorderBottom && `
    border-bottom: 1px solid ${theme.colors.lightGray};
  `};

  ${({ isDisabled }) => isDisabled && `
    pointer-events: none;
    opacity: .5;
  `};
`;

interface IDropdownOption {
  children: React.ReactNode;
  isBorderBottom?: boolean;
  isDisabled?: boolean;
}

const DropdownOption: React.FC<IDropdownOption> = ({
  children,
  isBorderBottom,
  isDisabled,
}) => (
  <DropdownOptionWrapper
    isBorderBottom={isBorderBottom}
    isDisabled={isDisabled}
  >
    {children}
  </DropdownOptionWrapper>
);

export default DropdownOption;
