import React from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { Flex } from '@rebass/grid';

import { ActionButtons } from 'components';
import { CustomerStatus, FormName, IdentificationType } from 'consts';
import { CustomerInfo } from './../../components';

import {
  ICustomer,
  THandleAddCustomer,
  THandleGetDictionaryCountries,
  THandleGetDictionaryCustomerStatuses,
  THandleUpdateCustomer,
} from 'store';

import { ISelectValue } from 'types';

interface IEditCustomerForm {
  addCustomer: THandleAddCustomer;
  countryCodes: Array<ISelectValue>;
  countryCodeValue: ISelectValue;
  customerStatusOptions: Array<ISelectValue>;
  identificationTypeValue: ISelectValue;
  initialValues: ICustomer;
  institutionsOptions: Array<ISelectValue>;
  isAdding: boolean;
  isCountryCodesLoading: boolean;
  isEditMode?: boolean;
  isLoadingCustomerStatuses: boolean;
  isReadOnly?: boolean;
  isUpdating: boolean;
  loadCountryCodes: THandleGetDictionaryCountries;
  loadCustomerStatuses: THandleGetDictionaryCustomerStatuses;
  onCancel: () => void;
  updateCustomer: THandleUpdateCustomer;
}

type TEditCustomerForm = IEditCustomerForm & InjectedFormProps<{}, IEditCustomerForm>;

const EditCustomerForm: React.FC<TEditCustomerForm> = ({
  addCustomer,
  countryCodes,
  countryCodeValue,
  customerStatusOptions,
  dirty,
  handleSubmit,
  identificationTypeValue,
  initialValues,
  institutionsOptions,
  isAdding,
  isCountryCodesLoading,
  isEditMode,
  isLoadingCustomerStatuses,
  isReadOnly,
  isUpdating,
  loadCountryCodes,
  loadCustomerStatuses,
  onCancel,
  pristine,
  updateCustomer,
}) => {
  React.useEffect(
    () => {
      Promise.all([
        loadCountryCodes(),
        loadCustomerStatuses(),
      ]);
    },
    [loadCountryCodes, loadCustomerStatuses]
  );

  const submitAction = React.useMemo(
    () => isEditMode ? updateCustomer : addCustomer,
    [isEditMode, updateCustomer, addCustomer]
  );

  const customerStatusOptionsPrepared = React.useMemo(
    () => {
      const statusesConfig: Array<{
        from: CustomerStatus;
        to: Array<CustomerStatus>;
      }> = [
        {
          from: CustomerStatus.PENDING,
          to: [CustomerStatus.PENDING, CustomerStatus.DELETED],
        },
        {
          from: CustomerStatus.INACTIVE,
          to: [CustomerStatus.INACTIVE, CustomerStatus.ACTIVE, CustomerStatus.CLOSED],
        },
        {
          from: CustomerStatus.CLOSED,
          to: [CustomerStatus.CLOSED, CustomerStatus.DELETED],
        },
      ];

      const options: Array<ISelectValue> = JSON.parse(JSON.stringify(customerStatusOptions));

      const currentStatus = initialValues?.status?.value;

      const currentState = statusesConfig.find(el => el.from === currentStatus);
      const statusesTo = currentState ? currentState.to : [];

      options.map(
        option => statusesTo.find(status => status === option.value)
          ? option.isDisabled = false
          : option.isDisabled = true
      );

      return options;
    },
    [customerStatusOptions, initialValues]
  );

  return (
    <form onSubmit={isReadOnly ? null : handleSubmit(submitAction)}>
      <CustomerInfo
        countryCodes={countryCodes}
        customerStatusOptions={customerStatusOptionsPrepared}
        institutionsOptions={institutionsOptions}
        isCountryCodesLoading={isCountryCodesLoading}
        isCounty={countryCodeValue?.value === 'USA'}
        isEditMode={isEditMode}
        isIdentification={identificationTypeValue?.value !== IdentificationType.NO_IDENTIFICATION}
        isLoadingCustomerStatuses={isLoadingCustomerStatuses}
        isReadOnly={isReadOnly || isUpdating || isAdding}
      />

      <Flex
        justifyContent="flex-end"
        mt="15px"
      >
        <ActionButtons
          okText="Save"
          cancelText="Close"
          onCancel={onCancel}
          withCancelConfirmation={dirty}
          disabledOk={pristine}
          hideOk={isReadOnly}
          isLoadingOk={isUpdating || isAdding}
        />
      </Flex>
    </form >
  );
};

export default reduxForm<{}, IEditCustomerForm>({
  form: FormName.CUSTOMER,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(EditCustomerForm);
