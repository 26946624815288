export enum FormName {
  // Common
  FILTER = 'filterForm',

  // Administration
  ADD_SYSTEM_PROPERTY = 'addSystemPropertyForm',
  API_CALL = 'apiCallForm',
  ENDPOINT = 'endpointsForm',
  INSTITUTION = 'institutionForm',
  INTERFACE = 'interfaceForm',

  // Users
  ADD_USERS_GROUP = 'addUsersGroupForm',
  ADD_USERS_GROUP_PERMISSIONS = 'addUsersGroupPermissionsForm',
  CHANGE_PASSWORD = 'changePasswordForm',
  DETAILS_USERS_GROUP = 'detailsUsersGroupForm',
  DETAILS_USERS_GROUP_MEMBERS = 'detailsUsersGroupMembersForm',
  LOGIN = 'loginForm',
  LOGIN_CODE = 'loginCodeForm',
  PASSWORD = 'passwordForm',
  USER = 'userForm',
  USERS_GROUP = 'usersGroupForm',

  // Products
  ADD_PRODUCT = 'addProductForm',
  GENERAL_PRODUCT = 'generalProductForm',
  PRODUCT_APR = 'productAprForm',
  PRODUCT_FEE = 'productFeeForm',
  PRODUCT_LOAN_ILLUSTRATION = 'productLoanIllustrationForm',
  PRODUCT_PENALTY = 'productPenaltiesForm',
  PRODUCT_REVOLVING_CREDIT_ILLUSTRATION = 'productRevolvingCreditIllustrationForm',
  PRODUCT_REWARD = 'productRewardForm',
  PRODUCT_RULE = 'productRuleForm',
  PRODUCT_RULES_FILTER = 'productRulesFilterForm',
  PRODUCT_SERVICES = 'productServicesForm',

  // Customers
  CUSTOMER = 'customerForm',

  // Accounts
  ACCOUNT = 'accountForm',
  ACCOUNT_CARDS = 'accountCardsForm',
  MANUAL_TRANSACTION = 'manualTransactionForm',
  RESULT_MANUAL_TRANSACTION = 'resultManualTransactionForm',

  // Transactions
  TRANSACTION = 'transactionForm',
  SETTLE_TRANSACTION = 'settleTransactionForm',

  // Statements
  STATEMENT = 'statementForm',
}
