import { IStoreState } from 'store';

export const modalsStateListSelector = (state: IStoreState) => state.modals;

export const isMessageModalSelector = (state: IStoreState) => state.modals.isMessageModal;

export const payloadMessageModalSelector = (state: IStoreState) => state.modals.payloadMessageModal;

export const payloadConfirmationModalSelector = (state: IStoreState) => state.modals.payloadConfirmationModal;

export const payloadUpdateProductAPRModalSelector = (state: IStoreState) => state.modals.payloadUpdateProductAPRModal;
