// import { successResponseMock } from 'consts';
import { apiClientService } from 'services';
// import { throttleUtil } from 'utils';

// import { endpointMock, endpointsMock } from './mock';
import { IEndpointData } from './types';

/**
 * Filter endpoints API
 */
export const filterEndpoints = (queryString: string) =>
  // throttleUtil.getDataAfter(endpointsMock, 500);
  apiClientService.get(`endpoints?${queryString}`);

/**
 * Get endpoint details API
 */
export const getEndpoint = (endpointId: number) =>
  // throttleUtil.getDataAfter(endpointMock, 500);
  apiClientService.get(`endpoints/${endpointId}`);

/**
 * Delete endpoint details API
 */
export const deleteEndpoint = (endpointId: number) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.delete(`endpoints/${endpointId}`);

/**
 * Update endpoint details API
 */
export const updateEndpoint = (data: Partial<IEndpointData>, endpointId: number) =>
  // throttleUtil.getDataAfter(successResponseMock, 1000);
  apiClientService.put(`endpoints/${endpointId}`, { data });

/**
 * Create endpoint API
 */
export const addEndpoint = (data: Partial<IEndpointData>) =>
  // throttleUtil.getDataAfter(successResponseMock, 500);
  apiClientService.post('endpoints', { data });
