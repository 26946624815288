import React from 'react';
import { ImmutableArray } from 'seamless-immutable';

import { Box } from '@rebass/grid';

import {
  ActionType,
  FormName,
  productRuleScriptSnippets,
} from 'consts';

import {
  Dropdown,
  DropdownOption,
  Text,
} from 'components';

import styled from 'theme';

import { ISelectValue } from 'types';

const HelperWrapper = styled.div`
  position: relative;
  padding: 10px 10px 8px;
  font-size: 13px;
  white-space: pre-line;
  transition: all .1s linear;

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 8px;
    right: 8px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.lighterGray};
  }

  &.is-code {
    font-family: ${({ theme }) => theme.fonts.code};
    font-size: 10px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

interface IRuleHelpers {
  actionTypeSelected: ISelectValue;
  aprsOptions: ImmutableArray<ISelectValue>;
  change: any;
  eventDataElemsOptions: ImmutableArray<ISelectValue>;
  eventIdSelected: ISelectValue;
  feesOptions: ImmutableArray<ISelectValue>;
  isReadOnly: boolean;
  penaltiesOptions: ImmutableArray<ISelectValue>;
  productType: { isLoan: boolean; isRevolvingCredit: boolean; type: string | number; };
  rewardsOptions: ImmutableArray<ISelectValue>;
  transactionTypesOptions: ImmutableArray<ISelectValue>;
}

const RuleHelpers: React.FC<IRuleHelpers> = ({
  actionTypeSelected,
  aprsOptions,
  change,
  eventDataElemsOptions,
  eventIdSelected,
  feesOptions,
  isReadOnly,
  penaltiesOptions,
  productType,
  rewardsOptions,
  transactionTypesOptions,
}) => {
  const helpers = React.useMemo(
    () => [
      {
        isVisible: true,
        isDisabled: false,
        name: 'Transaction types',
        noDataMessage: 'No available transaction types',
        options: transactionTypesOptions,
      },
      {
        isVisible: productType.isRevolvingCredit,
        isDisabled: !eventIdSelected,
        name: 'Event data elements',
        noDataMessage: 'No available data elements',
        options: eventDataElemsOptions,
      },
      {
        isVisible: productType.isRevolvingCredit,
        isDisabled: actionTypeSelected?.value !== ActionType.SET_APR,
        name: 'APRs',
        noDataMessage: 'No available APRs',
        options: aprsOptions,
      },
      {
        isVisible: true,
        isDisabled: actionTypeSelected?.value !== ActionType.FEE,
        name: 'Fees',
        noDataMessage: 'No available fees',
        options: feesOptions,
      },
      {
        isVisible: productType.isLoan,
        isDisabled: actionTypeSelected?.value !== ActionType.PENALTY,
        name: 'Penalties',
        noDataMessage: 'No available penalties',
        options: penaltiesOptions,
      },
      {
        isVisible: true,
        isDisabled: actionTypeSelected?.value !== ActionType.REWARD,
        name: 'Rewards',
        noDataMessage: 'No available rewards',
        options: rewardsOptions,
      },
      {
        isVisible: true,
        isDisabled: false,
        name: 'Code snippets',
        noDataMessage: 'No available code snippets',
        options: productRuleScriptSnippets,
      },
    ],
    [
      actionTypeSelected,
      aprsOptions,
      eventDataElemsOptions,
      eventIdSelected,
      feesOptions,
      penaltiesOptions,
      productType,
      rewardsOptions,
      transactionTypesOptions,
    ]
  );

  const insertItem = React.useCallback(
    (value: string | number, shiftCharCount?: number) => {
      const scriptEditor = document.querySelector('#rule-script') as HTMLInputElement;
      const currentText = scriptEditor.value;
      const startIndex = scriptEditor.selectionStart;

      const startPart = currentText.slice(0, startIndex);
      const endPart = currentText.slice(startIndex);
      const resultText = `${startPart}${value}${endPart}`;

      const cursorShiftChars = shiftCharCount
        ? startPart.length + shiftCharCount
        : `${startPart}${value}`.length;

      change(FormName.PRODUCT_RULE, 'script', resultText);
      scriptEditor.focus();

      setTimeout(() => scriptEditor.setSelectionRange(cursorShiftChars, cursorShiftChars), 50);
    },
    [change]
  );

  return (
    <>
      <Box mb="5px">
        <Text>Parameters</Text>
      </Box>

      {helpers.map(item => {
        return item.isVisible && (
          <Box width={[1]} py="7px" key={item.name}>
            <Dropdown
              selectable={false}
              isDisabled={item.isDisabled}
              ToggleButtonComponent={(
                <Text fontSize="14px">{item.name}</Text>
              )}
            >
              {item.options.length
                ? item.options.map((el: {
                  value: string | number;
                  label: string;
                  shiftCharCount?: number;
                }) => (
                  <DropdownOption key={el.value}>
                    <HelperWrapper
                      className={`${item.name === 'Code snippets' ? 'is-code' : ''}`}
                      onClick={isReadOnly ? null : () => insertItem(el.value, el.shiftCharCount)}
                    >
                      {el.label}
                    </HelperWrapper>
                  </DropdownOption>
                ))
                : (
                  <DropdownOption isDisabled={true}>
                    <HelperWrapper>{item.noDataMessage}</HelperWrapper>
                  </DropdownOption>
                )}
            </Dropdown>
          </Box>
        );
      })}
    </>
  );
};

export default RuleHelpers;
