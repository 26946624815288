export enum AccountStatus {
  ACTIVE = 'A',
  INACTIVE = 'I',
  CLOSED = 'C',
  DELETED = 'D',
  HOLD = 'H',
  DEBT_COLLECTION = 'T',
  WRITTEN_OFF = 'W',
  OVERLIMIT = 'L',
  OVERPAID = 'P',
  DELINQUENT = 'Q',
  MATURED = 'M',
  ARREARS = 'R',
}

export enum RepaymentType {
  CALCULATED_MINIMUM = 'C',
  FIXED_AMOUNT = 'F',
  HIGHER_OF_PRODUCT_MINIMUM_OR_CALCULATED = 'H',
  OUTSTANDING_BALANCE = 'O',
}
