import { IResponseStatus, TApiResponse } from 'types';

import {
  IProductAprsData,
  IProductFeesData,
  IProductPenaltiesData,
  IProductRewardsData,
} from './types';

export enum ActionTypeKeys {
  /**
   * APRs
   */
  GET_PRODUCT_APRS = 'products/payments/GET_PRODUCT_APRS',
  GET_PRODUCT_APRS_FULFILLED = 'products/payments/GET_PRODUCT_APRS_FULFILLED',
  GET_PRODUCT_APRS_REJECTED = 'products/payments/GET_PRODUCT_APRS_REJECTED',

  ADD_PRODUCT_APR = 'products/payments/ADD_PRODUCT_APR',
  ADD_PRODUCT_APR_FULFILLED = 'products/payments/ADD_PRODUCT_APR_FULFILLED',
  ADD_PRODUCT_APR_REJECTED = 'products/payments/ADD_PRODUCT_APR_REJECTED',

  UPDATE_PRODUCT_APR = 'products/payments/UPDATE_PRODUCT_APR',
  UPDATE_PRODUCT_APR_FULFILLED = 'products/payments/UPDATE_PRODUCT_APR_FULFILLED',
  UPDATE_PRODUCT_APR_REJECTED = 'products/payments/UPDATE_PRODUCT_APR_REJECTED',

  DELETE_PRODUCT_APR = 'products/payments/DELETE_PRODUCT_APR',
  DELETE_PRODUCT_APR_FULFILLED = 'products/payments/DELETE_PRODUCT_APR_FULFILLED',
  DELETE_PRODUCT_APR_REJECTED = 'products/payments/DELETE_PRODUCT_APR_REJECTED',

  /**
   * Fees
   */
  GET_PRODUCT_FEES = 'products/payments/GET_PRODUCT_FEES',
  GET_PRODUCT_FEES_FULFILLED = 'products/payments/GET_PRODUCT_FEES_FULFILLED',
  GET_PRODUCT_FEES_REJECTED = 'products/payments/GET_PRODUCT_FEES_REJECTED',

  ADD_PRODUCT_FEE = 'products/payments/ADD_PRODUCT_FEE',
  ADD_PRODUCT_FEE_FULFILLED = 'products/payments/ADD_PRODUCT_FEE_FULFILLED',
  ADD_PRODUCT_FEE_REJECTED = 'products/payments/ADD_PRODUCT_FEE_REJECTED',

  DELETE_PRODUCT_FEE = 'products/payments/DELETE_PRODUCT_FEE',
  DELETE_PRODUCT_FEE_FULFILLED = 'products/payments/DELETE_PRODUCT_FEE_FULFILLED',
  DELETE_PRODUCT_FEE_REJECTED = 'products/payments/DELETE_PRODUCT_FEE_REJECTED',

  /**
   * Rewards
   */
  GET_PRODUCT_REWARDS = 'products/payments/GET_PRODUCT_REWARDS',
  GET_PRODUCT_REWARDS_FULFILLED = 'products/payments/GET_PRODUCT_REWARDS_FULFILLED',
  GET_PRODUCT_REWARDS_REJECTED = 'products/payments/GET_PRODUCT_REWARDS_REJECTED',

  ADD_PRODUCT_REWARD = 'products/payments/ADD_PRODUCT_REWARD',
  ADD_PRODUCT_REWARD_FULFILLED = 'products/payments/ADD_PRODUCT_REWARD_FULFILLED',
  ADD_PRODUCT_REWARD_REJECTED = 'products/payments/ADD_PRODUCT_REWARD_REJECTED',

  DELETE_PRODUCT_REWARD = 'products/payments/DELETE_PRODUCT_REWARD',
  DELETE_PRODUCT_REWARD_FULFILLED = 'products/payments/DELETE_PRODUCT_REWARD_FULFILLED',
  DELETE_PRODUCT_REWARD_REJECTED = 'products/payments/DELETE_PRODUCT_REWARD_REJECTED',

  /**
   * Penalties
   */
  GET_PRODUCT_PENALTIES = 'products/payments/GET_PRODUCT_PENALTIES',
  GET_PRODUCT_PENALTIES_FULFILLED = 'products/payments/GET_PRODUCT_PENALTIES_FULFILLED',
  GET_PRODUCT_PENALTIES_REJECTED = 'products/payments/GET_PRODUCT_PENALTIES_REJECTED',

  ADD_PRODUCT_PENALTY = 'products/payments/ADD_PRODUCT_PENALTY',
  ADD_PRODUCT_PENALTY_FULFILLED = 'products/payments/ADD_PRODUCT_PENALTY_FULFILLED',
  ADD_PRODUCT_PENALTY_REJECTED = 'products/payments/ADD_PRODUCT_PENALTY_REJECTED',

  DELETE_PRODUCT_PENALTY = 'products/payments/DELETE_PRODUCT_PENALTY',
  DELETE_PRODUCT_PENALTY_FULFILLED = 'products/payments/DELETE_PRODUCT_PENALTY_FULFILLED',
  DELETE_PRODUCT_PENALTY_REJECTED = 'products/payments/DELETE_PRODUCT_PENALTY_REJECTED',

  /**
   * Reset
   */
  RESET_PAYMENTS = 'products/payments/RESET_PAYMENTS',
}

/**
 * APRs
 */

// Get APRs
export interface IGetProductAprsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_PRODUCT_APRS;
}

export interface IGetProductAprsFulfilledAction {
  readonly payload: IProductAprsData;
  readonly type: ActionTypeKeys.GET_PRODUCT_APRS_FULFILLED;
}

export interface IGetProductAprsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_PRODUCT_APRS_REJECTED;
}

// Add APR
export interface IAddProductAprAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ADD_PRODUCT_APR;
}

export interface IAddProductAprFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.ADD_PRODUCT_APR_FULFILLED;
}

export interface IAddProductAprRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.ADD_PRODUCT_APR_REJECTED;
}

// Update APR
export interface IUpdateProductAprAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.UPDATE_PRODUCT_APR;
}

export interface IUpdateProductAprFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.UPDATE_PRODUCT_APR_FULFILLED;
}

export interface IUpdateProductAprRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.UPDATE_PRODUCT_APR_REJECTED;
}

// Delete APR
export interface IDeleteProductAprAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_APR;
}

export interface IDeleteProductAprFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_APR_FULFILLED;
}

export interface IDeleteProductAprRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_APR_REJECTED;
}

/**
 * Fees
 */

// Get fees
export interface IGetProductFeesAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_PRODUCT_FEES;
}

export interface IGetProductFeesFulfilledAction {
  readonly payload: IProductFeesData;
  readonly type: ActionTypeKeys.GET_PRODUCT_FEES_FULFILLED;
}

export interface IGetProductFeesRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_PRODUCT_FEES_REJECTED;
}

// Add fee
export interface IAddProductFeeAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ADD_PRODUCT_FEE;
}

export interface IAddProductFeeFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.ADD_PRODUCT_FEE_FULFILLED;
}

export interface IAddProductFeeRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.ADD_PRODUCT_FEE_REJECTED;
}

// Delete fee
export interface IDeleteProductFeeAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_FEE;
}

export interface IDeleteProductFeeFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_FEE_FULFILLED;
}

export interface IDeleteProductFeeRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_FEE_REJECTED;
}

/**
 * Rewards
 */

// Get rewards
export interface IGetProductRewardsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_PRODUCT_REWARDS;
}

export interface IGetProductRewardsFulfilledAction {
  readonly payload: IProductRewardsData;
  readonly type: ActionTypeKeys.GET_PRODUCT_REWARDS_FULFILLED;
}

export interface IGetProductRewardsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_PRODUCT_REWARDS_REJECTED;
}

// Add reward
export interface IAddProductRewardAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ADD_PRODUCT_REWARD;
}

export interface IAddProductRewardFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.ADD_PRODUCT_REWARD_FULFILLED;
}

export interface IAddProductRewardRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.ADD_PRODUCT_REWARD_REJECTED;
}

// Delete reward
export interface IDeleteProductRewardAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_REWARD;
}

export interface IDeleteProductRewardFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_REWARD_FULFILLED;
}

export interface IDeleteProductRewardRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_REWARD_REJECTED;
}

/**
 * Penalties
 */

// Get penalties
export interface IGetProductPenaltiesAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_PRODUCT_PENALTIES;
}

export interface IGetProductPenaltiesFulfilledAction {
  readonly payload: IProductPenaltiesData;
  readonly type: ActionTypeKeys.GET_PRODUCT_PENALTIES_FULFILLED;
}

export interface IGetProductPenaltiesRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_PRODUCT_PENALTIES_REJECTED;
}

// Add penalty
export interface IAddProductPenaltyAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ADD_PRODUCT_PENALTY;
}

export interface IAddProductPenaltyFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.ADD_PRODUCT_PENALTY_FULFILLED;
}

export interface IAddProductPenaltyRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.ADD_PRODUCT_PENALTY_REJECTED;
}

// Delete penalty
export interface IDeleteProductPenaltyAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_PENALTY;
}

export interface IDeleteProductPenaltyFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_PENALTY_FULFILLED;
}

export interface IDeleteProductPenaltyRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_PENALTY_REJECTED;
}

export interface IResetPaymentsAction {
  readonly type: ActionTypeKeys.RESET_PAYMENTS;
}

export type TProductPaymentsAction =
  | IAddProductAprFulfilledAction
  | IAddProductFeeFulfilledAction
  | IAddProductPenaltyFulfilledAction
  | IAddProductRewardFulfilledAction
  | IDeleteProductAprFulfilledAction
  | IDeleteProductFeeFulfilledAction
  | IDeleteProductPenaltyFulfilledAction
  | IDeleteProductRewardFulfilledAction
  | IGetProductAprsFulfilledAction
  | IGetProductFeesFulfilledAction
  | IGetProductPenaltiesFulfilledAction
  | IGetProductRewardsFulfilledAction
  | IResetPaymentsAction
  | IUpdateProductAprFulfilledAction;
