import { ModalName } from 'consts';

import ConfirmationModal from 'containers/Modals/ConfirmationModal';
import LoginCode2faModal from 'containers/Modals/LoginCode2faModal';
import MessageModal from 'containers/Modals/MessageModal';
import Register2faModal from 'containers/Modals/Register2faModal';

import ChangePasswordModal from './ChangePasswordModal';

import { endpointsModals } from 'containers/Admin/Endpoints/modals';
import { institutionsModals } from 'containers/Admin/Institutions/modals';
import { interfacesModals } from 'containers/Admin/Interfaces/modals';
import { usersModals } from 'containers/Admin/Permission/Users/modals';
import { usersGroupModals } from 'containers/Admin/Permission/UsersGroups/modals';
import { systemPropertiesModals } from 'containers/Admin/SystemProperties/modals';
import { apiCallsModals } from 'containers/Audit/ApiCalls/modals';
import { accountsModals } from 'containers/Ledger/Accounts/modals';
import { customersModals } from 'containers/Ledger/Customers/modals';
import { statementsModals } from 'containers/Ledger/Statements/modals';
import { transactionsModals } from 'containers/Ledger/Transactions/modals';
import { productModals } from 'containers/ProductDesigner/Products/productModals';

export const modalsList = {
  [ModalName.CHANGE_PASSWORD]: (<ChangePasswordModal />),
  [ModalName.CONFIRMATION]: (<ConfirmationModal />),
  [ModalName.LOGIN_CODE_2FA]: (<LoginCode2faModal />),
  [ModalName.MESSAGE]: (<MessageModal />),
  [ModalName.REGISTER_2FA]: (<Register2faModal />),
  ...accountsModals,
  ...apiCallsModals,
  ...customersModals,
  ...endpointsModals,
  ...institutionsModals,
  ...interfacesModals,
  ...productModals,
  ...statementsModals,
  ...systemPropertiesModals,
  ...transactionsModals,
  ...usersGroupModals,
  ...usersModals,
};
