import React from 'react';

import { Modal } from 'components';
import { IWithModal, withModal } from 'HOCs';

import { ModalName } from 'consts';
import { InterfaceForm } from './../../forms';

import { IInterfaceDetails, THandleGetInterface } from 'store';

interface IDetailsInterfaceModal extends IWithModal {
  currentInterface: IInterfaceDetails;
  getInterface: THandleGetInterface;
  isFormDirty: boolean;
  isLoading: boolean;
}

const modalName = ModalName.DETAILS_INTERFACE;

const DetailsInterfaceModal: React.FC<IDetailsInterfaceModal> = ({
  closeModal,
  currentInterface,
  getInterface,
  isFormDirty,
  isLoading,
  isReadOnlyPage,
}) => {
  React.useEffect(
    () => {
      getInterface();
    },
    [getInterface]
  );

  return (
    <Modal
      title="Interface"
      isLoading={isLoading}
      containerWidth="900px"
      name={modalName}
      withCloseConfirmation={isFormDirty}
    >
      <InterfaceForm
        onCancel={() => closeModal(modalName)}
        isEditMode={true}
        initialValues={currentInterface}
        currentInterfaceName={currentInterface?.name}
        isReadOnly={isReadOnlyPage}
      />
    </Modal>
  );
};

export default withModal(DetailsInterfaceModal);
