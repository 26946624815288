import Immutable, { ImmutableObject } from 'seamless-immutable';

import { ActionTypeKeys, TProductsAction } from './actionTypes';
import { IProductGeneralState } from './types';

export const productsInitialState: ImmutableObject<IProductGeneralState> = Immutable({
  pageNumber: null,
  totalPages: null,
  totalResults: null,
  products: Immutable([]),
  currentProduct: null,
  productsForSelection: Immutable([]),
});

const productGeneralReducer = (state = productsInitialState, action: TProductsAction) => {
  switch (action.type) {
    case ActionTypeKeys.FILTER_PRODUCTS_FULFILLED:
      return state
        .set('products', action.payload.products)
        .set('pageNumber', action.payload.page_number)
        .set('totalPages', action.payload.total_pages)
        .set('totalResults', action.payload.total_results);

    case ActionTypeKeys.FILTER_PRODUCTS_FOR_SELECTION_FULFILLED:
      return state.set('productsForSelection', action.payload.products);

    case ActionTypeKeys.GET_PRODUCT_FULFILLED:
      return state.set('currentProduct', action.payload.product);

    case ActionTypeKeys.RESET_PRODUCTS:
      return (state = productsInitialState);

    case ActionTypeKeys.RESET_PRODUCT:
      return state.set('currentProduct', null);

    default:
      return state;
  }
};

export default productGeneralReducer;
