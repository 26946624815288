import { IResponseStatus, TApiResponse } from 'types';
import { ICommonProductsDataResp, IProductDataResp } from './types';

export enum ActionTypeKeys {
  FILTER_PRODUCTS = 'products/general/FILTER_PRODUCTS',
  FILTER_PRODUCTS_FULFILLED = 'products/general/FILTER_PRODUCTS_FULFILLED',
  FILTER_PRODUCTS_REJECTED = 'products/general/FILTER_PRODUCTS_REJECTED',

  FILTER_PRODUCTS_FOR_SELECTION = 'products/general/FILTER_PRODUCTS_FOR_SELECTION',
  FILTER_PRODUCTS_FOR_SELECTION_FULFILLED = 'products/general/FILTER_PRODUCTS_FOR_SELECTION_FULFILLED',
  FILTER_PRODUCTS_FOR_SELECTION_REJECTED =
    'products/general/FILTER_PRODUCTS_FOR_SELECTION_REJECTED',

  ADD_PRODUCT = 'products/general/ADD_PRODUCT',
  ADD_PRODUCT_FULFILLED = 'products/general/ADD_PRODUCT_FULFILLED',
  ADD_PRODUCT_REJECTED = 'products/general/ADD_PRODUCT_REJECTED',

  GET_PRODUCT = 'products/general/GET_PRODUCT',
  GET_PRODUCT_FULFILLED = 'products/general/GET_PRODUCT_FULFILLED',
  GET_PRODUCT_REJECTED = 'products/general/GET_PRODUCT_REJECTED',

  UPDATE_PRODUCT = 'products/general/UPDATE_PRODUCT',
  UPDATE_PRODUCT_FULFILLED = 'products/general/UPDATE_PRODUCT_FULFILLED',
  UPDATE_PRODUCT_REJECTED = 'products/general/UPDATE_PRODUCT_REJECTED',

  DELETE_PRODUCT = 'products/general/DELETE_PRODUCT',
  DELETE_PRODUCT_FULFILLED = 'products/general/DELETE_PRODUCT_FULFILLED',
  DELETE_PRODUCT_REJECTED = 'products/general/DELETE_PRODUCT_REJECTED',

  CLONE_PRODUCT = 'products/general/CLONE_PRODUCT',
  CLONE_PRODUCT_FULFILLED = 'products/general/CLONE_PRODUCT_FULFILLED',
  CLONE_PRODUCT_REJECTED = 'products/general/CLONE_PRODUCT_REJECTED',

  RESET_PRODUCTS = 'products/general/RESET_PRODUCTS',

  RESET_PRODUCT = 'products/general/RESET_PRODUCT',
}

export interface IDeleteProductAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.DELETE_PRODUCT;
}

export interface IDeleteProductFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_FULFILLED;
}

export interface IDeleteProductRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.DELETE_PRODUCT_REJECTED;
}

export interface IFilterProductsAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_PRODUCTS;
}

export interface IFilterProductsFulfilledAction {
  readonly payload: ICommonProductsDataResp;
  readonly type: ActionTypeKeys.FILTER_PRODUCTS_FULFILLED;
}

export interface IFilterProductsRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_PRODUCTS_REJECTED;
}

export interface IFilterProductsForSelectionAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.FILTER_PRODUCTS_FOR_SELECTION;
}

export interface IFilterProductsForSelectionFulfilledAction {
  readonly payload: ICommonProductsDataResp;
  readonly type: ActionTypeKeys.FILTER_PRODUCTS_FOR_SELECTION_FULFILLED;
}

export interface IFilterProductsForSelectionRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.FILTER_PRODUCTS_FOR_SELECTION_REJECTED;
}

export interface IGetProductAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.GET_PRODUCT;
}

export interface IGetProductFulfilledAction {
  readonly payload: IProductDataResp;
  readonly type: ActionTypeKeys.GET_PRODUCT_FULFILLED;
}

export interface IGetProductRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.GET_PRODUCT_REJECTED;
}

export interface IUpdateProductAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.UPDATE_PRODUCT;
}

export interface IUpdateProductFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.UPDATE_PRODUCT_FULFILLED;
}

export interface IUpdateProductRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.UPDATE_PRODUCT_REJECTED;
}

export interface IAddProductAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.ADD_PRODUCT;
}

export interface IAddProductFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.ADD_PRODUCT_FULFILLED;
}

export interface IAddProductRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.ADD_PRODUCT_REJECTED;
}

export interface ICloneProductAction {
  readonly payload: Promise<object>;
  readonly type: ActionTypeKeys.CLONE_PRODUCT;
}

export interface ICloneProductFulfilledAction {
  readonly payload: IResponseStatus;
  readonly type: ActionTypeKeys.CLONE_PRODUCT_FULFILLED;
}

export interface ICloneProductRejectedAction {
  readonly payload: TApiResponse;
  readonly type: ActionTypeKeys.CLONE_PRODUCT_REJECTED;
}

export interface IResetProductsAction {
  readonly type: ActionTypeKeys.RESET_PRODUCTS;
}

export interface IResetProductAction {
  readonly type: ActionTypeKeys.RESET_PRODUCT;
}

export type TProductsAction =
  | IDeleteProductFulfilledAction
  | IFilterProductsFulfilledAction
  | IFilterProductsForSelectionFulfilledAction
  | IGetProductFulfilledAction
  | IAddProductFulfilledAction
  | ICloneProductFulfilledAction
  | IUpdateProductFulfilledAction
  | IResetProductsAction
  | IResetProductAction;
