import { apiClientService } from 'services';

// import {
//   dictionaryAccountStatusesMock,
//   dictionaryCountriesMock,
//   dictionaryCurrenciesMock,
//   dictionaryCustomerStatusesMock,
//   dictionaryEndpointTypesMock,
//   dictionaryEventDataElemsMock,
//   dictionaryEventsMock,
//   dictionaryInterfaceTypesMock,
//   dictionaryRepaymentMethodsMock,
//   dictionaryRepaymentTypesMock,
//   dictionaryTransactionTypesMock,
// } from './mock';

// import { throttleUtil } from 'utils';

/**
 * Get dictionary account statuses API
 */
export const getDictionaryAccountStatuses = () =>
  apiClientService.get('dictionaries/account-statuses');
  // throttleUtil.getDataAfter(dictionaryAccountStatusesMock, 300);

/**
 * Get dictionary customer statuses API
 */
export const getDictionaryCustomerStatuses = () =>
  apiClientService.get('dictionaries/customer-statuses');
  // throttleUtil.getDataAfter(dictionaryCustomerStatusesMock, 300);

/**
 * Get dictionary transaction types API
 */
export const getDictionaryTransactionTypes = () =>
  apiClientService.get('dictionaries/transaction-types');
  // throttleUtil.getDataAfter(dictionaryTransactionTypesMock, 300);

/**
 * Get dictionary countries API
 */
export const getDictionaryCountries = () =>
  apiClientService.get('dictionaries/countries');
  // throttleUtil.getDataAfter(dictionaryCountriesMock, 300);

/**
 * Get dictionary currencies API
 */
export const getDictionaryCurrencies = () =>
  apiClientService.get('dictionaries/currencies');
  // throttleUtil.getDataAfter(dictionaryCurrenciesMock, 300);

/**
 * Filter dictionary events API
 */
export const filterDictionaryEvents = (queryString: string) =>
  apiClientService.get(`dictionaries/events?${queryString}`);
  // throttleUtil.getDataAfter(dictionaryEventsMock, 300);

/**
 * Get dictionary event data elements API
 */
export const filterDictionaryEventDataElems = (queryString: string) =>
  apiClientService.get(`dictionaries/event-data-elements?${queryString}`);
  // throttleUtil.getDataAfter(dictionaryEventDataElemsMock, 300);

/**
 * Get dictionary repayment methods API
 */
export const getDictionaryRepaymentMethods = () =>
  apiClientService.get('dictionaries/repayment-methods');
  // throttleUtil.getDataAfter(dictionaryRepaymentMethodsMock, 300);

/**
 * Get dictionary repayment types API
 */
export const getDictionaryRepaymentTypes = () =>
  apiClientService.get('dictionaries/repayment-types');
  // throttleUtil.getDataAfter(dictionaryRepaymentTypesMock, 300);

/**
 * Get dictionary endpoint types API
 */
export const getDictionaryEndpointTypes = () =>
  apiClientService.get('dictionaries/endpoint-types');
  // throttleUtil.getDataAfter(dictionaryEndpointTypesMock, 300);

/**
 * Get dictionary interface types API
 */
export const getDictionaryInterfaceTypes = () =>
  apiClientService.get('dictionaries/interface-types');
  // throttleUtil.getDataAfter(dictionaryInterfaceTypesMock, 300);
