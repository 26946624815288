import styled from 'theme';

interface ITabTitle {
  isDisabled?: boolean;
}

export const TabTitle = styled.div<ITabTitle>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  text-transform: uppercase;
  font-weight: 500;
  cursor: ${({ isDisabled }) => isDisabled ? 'auto' : 'pointer'};
  color: ${({ theme }) => theme.colors.gray};
  font-size: 12px;
  letter-spacing: .4pt;
  user-select: none;
  transition: all .1s linear;

  .icon {
    margin-right: 3px;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    width: 0;
    background-color: ${({ theme }) => theme.colors.accent};
    transition: all .1s linear;
  }

  ${({ isDisabled, theme }) => !isDisabled && `
    &:hover {
      * {
        color: ${theme.colors.accent};
      }

      &:after {
        width: 100%;
      }
    }
  `}

  ${({ isDisabled }) => isDisabled && `
    .title, .icon {
      opacity: .5;
    }
  `}

  &.is-active {
    cursor: default;
    user-select: inherit;

    * {
      color: ${({ theme }) => theme.colors.accent};
    }

    &:after {
      width: 100%;
    }
  }
`;
