import Immutable, { ImmutableObject } from 'seamless-immutable';
import { ActionTypeKeys, TCustomersAction } from './actionTypes';
import { ICustomersState } from './types';

export const customersInitialState: ImmutableObject<ICustomersState> = Immutable({
  currentCustomer: null,
  customers: Immutable([]),
  pageNumber: null,
  totalPages: null,
  totalResults: null,
});

const customersReducer = (state = customersInitialState, action: TCustomersAction) => {
  switch (action.type) {
    case ActionTypeKeys.FILTER_CUSTOMERS_FULFILLED:
      return state
        .set('customers', action.payload.customers)
        .set('pageNumber', action.payload.page_number)
        .set('totalPages', action.payload.total_pages)
        .set('totalResults', action.payload.total_results);

    case ActionTypeKeys.GET_CUSTOMER_FULFILLED:
      return state.set('currentCustomer', action.payload.customer);

    case ActionTypeKeys.RESET_CUSTOMERS:
      return state = customersInitialState;

    case ActionTypeKeys.RESET_CUSTOMER:
      return state.set('currentCustomer', null);

    default: return state;
  }
};

export default customersReducer;
