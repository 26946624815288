import { TableCell, TableHeader } from 'components';
import { ICommonTransaction } from 'store';
import { ITableCell } from 'types';

type TCell<T extends keyof ICommonTransaction> = ITableCell<ICommonTransaction[T]>;

export const tableColumns = [
  {
    maxWidth: 100,
    Header: <TableHeader title="Transaction ID" />,
    accessor: 'id',
    Cell: (props: TCell<'id'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 100,
    Header: <TableHeader title="Account ID" />,
    accessor: 'accountId',
    Cell: (props: TCell<'accountId'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },  {
    maxWidth: 120,
    Header: <TableHeader title="Product Type" />,
    accessor: 'productTypeName',
    Cell: (props: TCell<'productTypeName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 300,
    Header: <TableHeader title="Description" />,
    accessor: 'description',
    Cell: (props: TCell<'description'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 200,
    Header: <TableHeader title="Transaction Type" />,
    accessor: 'transactionTypeDescription',
    Cell: (props: TCell<'transactionTypeDescription'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
  {
    maxWidth: 85,
    Header: <TableHeader title="Debit / Credit" />,
    accessor: 'debitCredit',
    Cell: (props: TCell<'debitCredit'>) => (
      <TableCell
        value={props.value}
        onCenter={true}
      />
    ),
  },
  {
    maxWidth: 85,
    Header: <TableHeader title="Currency" />,
    accessor: 'currency',
    Cell: (props: TCell<'currency'>) => (
      <TableCell
        value={props.value}
        onCenter={true}
      />
    ),
  },
  {
    maxWidth: 85,
    Header: <TableHeader title="Amount" />,
    accessor: 'amount',
    Cell: (props: TCell<'amount'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 85,
    Header: <TableHeader title="Amount Settled" />,
    accessor: 'amountSettled',
    Cell: (props: TCell<'amountSettled'>) => (
      <TableCell
        value={props.value}
        isNumber={true}
      />
    ),
  },
  {
    maxWidth: 100,
    Header: <TableHeader title="Transaction Datetime" />,
    accessor: 'transactionDatetime',
    Cell: (props: TCell<'transactionDatetime'>) => (
      <TableCell
        value={props.value}
        isDate={true}
      />
    ),
  },
  {
    maxWidth: 100,
    Header: <TableHeader title="Settled Date" />,
    accessor: 'settledDate',
    Cell: (props: TCell<'settledDate'>) => (
      <TableCell
        value={props.value}
        isDate={true}
      />
    ),
  },
  {
    maxWidth: 80,
    Header: <TableHeader title="Status" />,
    accessor: 'statusName',
    Cell: (props: TCell<'statusName'>) => (
      <TableCell
        value={props.value}
      />
    ),
  },
];
