/**
 * Formats
 */

export enum DateFormat {
  DATE = 'DD/MM/YYYY',
  DATE_TIME = 'DD/MM/YYYY HH:mm:ss',
}

export enum MaskFormat {
  DATE = '99/99/9999',
  DATE_TIME = '99/99/9999 99:99:99',
}

export enum Flag {
  NO = 'N',
  YES = 'Y',
}

/**
 * Icon names
 */
export enum IconName {
  ADD = 'add',
  ARROW_DOWN = 'arrowDown',
  ARROW_UP = 'arrowUp',
  CLONE = 'clone',
  DELETE = 'delete',
  DETAILS = 'details',
  DOWNLOAD = 'download',
  FILE_PDF = 'filePdf',
  LOAN = 'loan',
  LOGOUT = 'logout',
  QRCODE = 'qrcode',
  REFRESH = 'refresh',
  SETTINGS = 'setting',
  SMARTPHONE = 'smartphone',
  TRANSACTION = 'transaction',
}

/**
 * Common statuses
 */

export enum CommonStatus {
  ACTIVE = 'A',
  INACTIVE = 'I',
  DELETED = 'D',
}

export const commonStatusOptions = [
  { value: CommonStatus.ACTIVE, label: 'Active' },
  { value: CommonStatus.INACTIVE, label: 'Inactive' },
  { value: CommonStatus.DELETED, label: 'Deleted' },
];

/**
 * Mocks
 */
export const successResponseMock = {
  response_status: {
    status_code: '00',
    status_message: 'Success',
  },
};

/**
 * Response status codes
 */

export enum ResponseStatusCode {
  SESSION_TIMEOUT = '02',
  USER_NOT_AUTHENTICATED = '03',
}
