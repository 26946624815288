import React from 'react';

import { CheckIcon, InfoIcon } from 'components';
import styled from 'theme';

const StatusIconWrapper = styled.div`
  width: 20px;
  margin-top: 4px;
  margin-right: 7px;

  .wrapper {
    width: 20px;
    height: 20px;

    &.is-success {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }) => theme.colors.green};
      border: 1px solid ${({ theme }) => theme.colors.green};
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.white};
      box-shadow: ${({ theme }) => theme.shadows.aroundBox};
    }

    &.is-info {
      color: ${({ theme }) => theme.colors.gray};
      font-size: 0;
    }
  }
`;

interface IStatusIcon {
  className: 'is-success' | 'is-info';
}

const StatusIcon: React.FC<IStatusIcon> = ({ className }) => {
  return (
    <StatusIconWrapper>
      <div className={`wrapper ${className}`}>
        {className === 'is-success' && (
          <CheckIcon size={14} />
        )}

        {className === 'is-info' && (
          <InfoIcon size={22} />
        )}
      </div>
    </StatusIconWrapper>
  );
};

export default StatusIcon;
