import React from 'react';
import styled from 'theme';

const NoDataWrapper = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;

  .title {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .2pt;
  }
`;

interface INoData {
  title?: string;
}

export const TableNoData: React.FC<INoData> = ({
  title = 'No data found',
}) => (
    <NoDataWrapper>
      <div className="title">{title}</div>
    </NoDataWrapper>
  );
