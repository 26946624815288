import React from 'react';
import { Field } from 'redux-form';

import { Box } from '@rebass/grid';

import { SelectField } from 'components';
import { userStatusWith2faOptions } from 'consts';
import { ISelectValue } from 'types';
import { formErrorUtil } from 'utils';

interface IUsersFilter {
  institutionsOptions: Array<ISelectValue>;
  isDisabled: boolean;
}

const UsersFilter: React.FC<IUsersFilter> = ({
  institutionsOptions,
  isDisabled,
}) => {
  return (
    <>
      <Box width="300px" p="8px">
        <Field
          id="institutionId"
          name="institutionId"
          placeholder="Select Institution"
          component={SelectField}
          label="Institution"
          options={institutionsOptions}
          isClearable={false}
          isDisabled={isDisabled}
          isRequired={true}
          validate={[formErrorUtil.isRequired]}
        />
      </Box>
      <Box width="220px" p="8px">
        <Field
          id="status"
          name="status"
          component={SelectField}
          label="Status"
          placeholder="Select Status"
          options={userStatusWith2faOptions}
          isDisabled={isDisabled}
        />
      </Box>
    </>
  );
};

export default UsersFilter;
