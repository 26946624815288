import React from 'react';
import { ImmutableArray } from 'seamless-immutable';

import { Box, Flex } from '@rebass/grid';

import { Spinner } from 'components';
import { ActionType, actionTypesConfig } from 'consts';

import {
  IProductRule,
  THandleAddProductRule,
  THandleDeleteProductRule,
  THandleFilterDictionaryEventDataElems,
  THandleFilterDictionaryEvents,
  THandleGetDictionaryTransactionTypes,
  THandleGetProductAprs,
  THandleGetProductFees,
  THandleGetProductPenalties,
  THandleGetProductRewards,
  THandleGetProductRule,
  THandleUpdateProductRule,
  TResetPayments,
  TResetProductRule,
} from 'store';

import { ISelectValue } from 'types';

import RuleForm from './RuleForm';
import RuleHelpers from './RuleHelpers';
import RulesFilterForm from './RulesFilterForm';

interface IRules {
  actionTypeSelected: ISelectValue;
  aprsOptions: ImmutableArray<ISelectValue>;
  change: any;
  currentProductRule: Partial<IProductRule>;
  eventDataElemsOptions: ImmutableArray<ISelectValue>;
  eventIdSelected: ISelectValue;
  eventsOptions: Array<ISelectValue>;
  feesOptions: ImmutableArray<ISelectValue>;
  isEventsLoading: boolean;
  isReadOnly: boolean;
  isRuleLoading: boolean;
  isRuleUpdating: boolean;
  penaltiesOptions: ImmutableArray<ISelectValue>;
  productType: { isLoan: boolean; isRevolvingCredit: boolean; type: string | number; };
  rewardsOptions: ImmutableArray<ISelectValue>;
  transactionTypesOptions: ImmutableArray<ISelectValue>;
  addRule: THandleAddProductRule;
  deleteRule: THandleDeleteProductRule;
  filterDictionaryEventDataElems: THandleFilterDictionaryEventDataElems;
  filterEvents: THandleFilterDictionaryEvents;
  getProductAprs: THandleGetProductAprs;
  getProductFees: THandleGetProductFees;
  getProductPenalties: THandleGetProductPenalties;
  getProductRewards: THandleGetProductRewards;
  getRule: THandleGetProductRule;
  getTransactionTypes: THandleGetDictionaryTransactionTypes;
  onCancel?: () => void;
  resetPayments: TResetPayments;
  resetRule: TResetProductRule;
  updateRule: THandleUpdateProductRule;
}

const Rules: React.FC<IRules> = ({
  actionTypeSelected,
  aprsOptions,
  change,
  currentProductRule,
  eventDataElemsOptions,
  eventIdSelected,
  eventsOptions,
  feesOptions,
  isEventsLoading,
  isReadOnly,
  isRuleLoading,
  isRuleUpdating,
  penaltiesOptions,
  productType,
  rewardsOptions,
  transactionTypesOptions,
  addRule,
  deleteRule,
  filterDictionaryEventDataElems,
  filterEvents,
  getProductAprs,
  getProductFees,
  getProductPenalties,
  getProductRewards,
  getRule,
  getTransactionTypes,
  onCancel,
  resetPayments,
  resetRule,
  updateRule,
}) => {
  React.useEffect(
    () => {
      Promise.all([
        filterEvents({
          productType: {
            value: productType.type,
            label: '',
          },
        }),
        getTransactionTypes(),
      ]);
    },
    [
      productType,
      filterEvents,
      getTransactionTypes,
    ]
  );

  React.useEffect(
    () => {
      if (eventIdSelected && productType.isRevolvingCredit) {
        filterDictionaryEventDataElems({ eventId: eventIdSelected });
      }
    },
    [
      eventIdSelected,
      productType,
      filterDictionaryEventDataElems,
    ]
  );

  React.useEffect(
    () => {
      const actionType = actionTypeSelected?.value;

      Promise.all([
        actionType === ActionType.SET_APR && productType.isRevolvingCredit && getProductAprs(),
        actionType === ActionType.PENALTY && productType.isLoan && getProductPenalties(),
        actionType === ActionType.FEE && getProductFees(),
        actionType === ActionType.REWARD && getProductRewards(),
      ]);
    },
    [
      actionTypeSelected,
      getProductAprs,
      getProductFees,
      getProductPenalties,
      getProductRewards,
      productType,
    ]
  );

  const actionTypesOptions = React.useMemo(
    () => actionTypesConfig
      .filter(el => el.productType === productType.type)
      .filter(el => el.eventIds.find(id => id === eventIdSelected?.value))
      .map(el => {
        return { value: el.value, label: el.label };
      }),
    [eventIdSelected, productType]
  );

  return (
    <>
      <RulesFilterForm
        actionTypeSelected={actionTypeSelected}
        actionTypesOptions={actionTypesOptions}
        eventIdSelected={eventIdSelected || currentProductRule.eventId}
        eventsOptions={eventsOptions}
        getRule={getRule}
        isEventsLoading={isEventsLoading}
        isRuleLoading={isRuleLoading}
        initialValues={currentProductRule}
      />

      <Flex
        width={[1]}
        flexWrap="wrap"
        pt="20px"
      >
        {(isRuleLoading || isRuleUpdating) && (
          <Spinner text={isRuleUpdating ? 'Saving' : ''} />
        )}

        <Box width="78%">
          <RuleForm
            currentProductRule={currentProductRule}
            isReadOnly={isReadOnly}
            isRuleLoading={isRuleLoading}
            isRuleUpdating={isRuleUpdating}
            initialValues={currentProductRule}
            productType={productType?.type}
            addRule={addRule}
            updateRule={updateRule}
            deleteRule={deleteRule}
            resetRule={() => {
              resetRule();
              resetPayments();
            }}
            onCancel={onCancel}
          />
        </Box>

        <Box width="22%" pt="50px">
          <RuleHelpers
            actionTypeSelected={actionTypeSelected}
            aprsOptions={aprsOptions}
            change={change}
            eventDataElemsOptions={eventDataElemsOptions}
            eventIdSelected={eventIdSelected}
            feesOptions={feesOptions}
            isReadOnly={isReadOnly}
            penaltiesOptions={penaltiesOptions}
            productType={productType}
            rewardsOptions={rewardsOptions}
            transactionTypesOptions={transactionTypesOptions}
          />
        </Box>
      </Flex>
    </>
  );
};

export default Rules;
