import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import styled from 'styled-components';

import { Box, Flex } from '@rebass/grid';

import {
  HorizontalLine,
  InputField,
  NewLine,
  NumberFormatField,
  T3,
} from 'components';
import { FormName, MaskFormat } from 'consts';

const LeftPartWrapper = styled(Flex)`
  border-right: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

interface IStatementForm {
  productType: {
    isRevolvingCredit: boolean;
    isLoan: boolean;
  };
}

type TStatementForm = IStatementForm & InjectedFormProps<{}, IStatementForm>;

const StatementForm: React.FC<TStatementForm> = ({
  productType,
}) => {
  return (
    <form>
      <Flex
        alignItems="flex-end"
        flexWrap="wrap"
        mx="-8px"
      >
        <Flex alignItems="flex-start">
          <LeftPartWrapper
            width="730px"
            alignItems="flex-end"
            flexWrap="wrap"
            mr="8px"
            pr="8px"
          >
            <Box width="120px" p="8px">
              <Field
                id="sequenceNumber"
                name="sequenceNumber"
                component={InputField}
                label="Sequence Number"
                placeholder="-"
                disabled={true}
                isNumber={true}
              />
            </Box>
            <Box width="120px" p="8px">
              <Field
                id="id"
                name="id"
                component={InputField}
                label="Statement ID"
                placeholder="-"
                disabled={true}
                isNumber={true}
              />
            </Box>

            <Box width="120px" p="8px">
              <Field
                id="startDate"
                name="startDate"
                component={InputField}
                label="Start Date"
                placeholder="-"
                mask={MaskFormat.DATE}
                disabled={true}
              />
            </Box>
            <Box width="120px" p="8px">
              <Field
                id="endDate"
                name="endDate"
                component={InputField}
                label="End Date"
                placeholder="-"
                mask={MaskFormat.DATE}
                disabled={true}
              />
            </Box>

            {productType.isRevolvingCredit && (
              <>
                <Box width="120px" p="8px">
                  <Field
                    id="statementDate"
                    name="statementDate"
                    component={InputField}
                    label="Statement Date"
                    placeholder="-"
                    mask={MaskFormat.DATE}
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="repaymentDueDate"
                    name="repaymentDueDate"
                    component={InputField}
                    label="Repayment Due Date"
                    placeholder="-"
                    mask={MaskFormat.DATE}
                    disabled={true}
                  />
                </Box>
              </>
            )}

            <NewLine />

            <Box width="240px" p="8px">
              <Field
                id="repaymentStatusName"
                name="repaymentStatusName"
                component={InputField}
                label="Repayment Status"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width="240px" p="8px">
              <Field
                id="status"
                name="status"
                component={InputField}
                label="Status"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <HorizontalLine />

            {productType.isRevolvingCredit && (
              <>
                <Box width="100%" p="8px 8px 0">
                  <T3>Balance</T3>
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="balanceOpen"
                    name="balanceOpen"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Balance open"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="balanceClose"
                    name="balanceClose"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Balance close"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="overLimit"
                    name="overLimit"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Over Limit"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <HorizontalLine />
              </>
            )}

            <Box width="120px" p="8px">
              <Field
                id="totalCredits"
                name="totalCredits"
                component={NumberFormatField}
                fixedDecimalScale={true}
                decimalScale={2}
                label="Total Credits"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width="120px" p="8px">
              <Field
                id="totalDebits"
                name="totalDebits"
                component={NumberFormatField}
                fixedDecimalScale={true}
                decimalScale={2}
                label="Total Debits"
                placeholder="-"
                disabled={true}
              />
            </Box>

            {productType.isRevolvingCredit && (
              <>
                <Box width="120px" p="8px">
                  <Field
                    id="totalInterest"
                    name="totalInterest"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Total Interest"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="totalFees"
                    name="totalFees"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Total Fees"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <NewLine />
                <Box width="120px" p="8px">
                  <Field
                    id="firstTransactionId"
                    name="firstTransactionId"
                    component={InputField}
                    label="First Transaction ID"
                    placeholder="-"
                    disabled={true}
                    isNumber={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="lastTransactionId"
                    name="lastTransactionId"
                    component={InputField}
                    label="Last Transaction ID"
                    placeholder="-"
                    disabled={true}
                    isNumber={true}
                  />
                </Box>
              </>
            )}
            <HorizontalLine />

            {productType.isLoan && (
              <>
                <Box width="100%" p="8px 8px 0">
                  <T3>Penalties</T3>
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="totalPenalties"
                    name="totalPenalties"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Total Penalties"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="outstandingPenalties"
                    name="outstandingPenalties"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Outstanding Penalties"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cycleUnpaidPenalties"
                    name="cycleUnpaidPenalties"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Unpaid Penalties"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cycleRepaidPenalties"
                    name="cycleRepaidPenalties"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Repaid Penalties"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="newPenalties"
                    name="newPenalties"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="New Penalties"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <HorizontalLine />

                <Box width="100%" p="8px 8px 0">
                  <T3>Fees</T3>
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="totalFees"
                    name="totalFees"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Total Fees"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="outstandingFees"
                    name="outstandingFees"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Outstanding Fees"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cycleUnpaidFees"
                    name="cycleUnpaidFees"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Unpaid Fees"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cycleRepaidFees"
                    name="cycleRepaidFees"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Repaid Fees"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="newFees"
                    name="newFees"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="New Fees"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <HorizontalLine />

                <Box width="100%" p="8px 8px 0">
                  <T3>Interest</T3>
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="totalInterest"
                    name="totalInterest"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Total Interest"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="outstandingInterest"
                    name="outstandingInterest"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Outstanding Interest"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cyclePostedInterest"
                    name="cyclePostedInterest"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Posted Interest"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cycleUnpaidInterest"
                    name="cycleUnpaidInterest"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Unpaid Interest"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cycleRepaidInterest"
                    name="cycleRepaidInterest"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Repaid Interest"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="accruedInterest"
                    name="accruedInterest"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Accrued Interest"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="newInterest"
                    name="newInterest"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="New Interest"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <HorizontalLine />

                <Box width="100%" p="8px 8px 0">
                  <T3>Overpayments</T3>
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="totalOverpayments"
                    name="totalOverpayments"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Total Overpayments"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cycleOverpayments"
                    name="cycleOverpayments"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Overpayments"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <HorizontalLine />

                <Box width="100%" p="8px 8px 0">
                  <T3>Principal</T3>
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="totalRepaidPrincipal"
                    name="totalRepaidPrincipal"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Total Repaid Principal"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="outstandingPrincipal"
                    name="outstandingPrincipal"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Outstanding Principal"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cycleRepaidPrincipal"
                    name="cycleRepaidPrincipal"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Repaid Principal"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <HorizontalLine />

                <Box width="100%" p="8px 8px 0">
                  <T3>Rewards</T3>
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="totalRewards"
                    name="totalRewards"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Total Rewards"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cycleRewards"
                    name="cycleRewards"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Rewards"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="newRewards"
                    name="newRewards"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="New Rewards"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <HorizontalLine />

                <Box width="100%" p="8px 8px 0">
                  <T3>Repayment</T3>
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cycleExpectedRepayments"
                    name="cycleExpectedRepayments"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Expected Repayments"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="totalRepayments"
                    name="totalRepayments"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Total Repayments"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="cycleRepayments"
                    name="cycleRepayments"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Cycle Repayments"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="newRepayments"
                    name="newRepayments"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="New Repayments"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
              </>
            )}

            {productType.isRevolvingCredit && (
              <>
                <Box width="100%" p="8px 8px 0">
                  <T3>Repayment</T3>
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="repaymentMinimumPercentage"
                    name="repaymentMinimumPercentage"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Repayment Minimum Percentage"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="repaymentMinimumAmount"
                    name="repaymentMinimumAmount"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Repayment minimum amount"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="repaymentMinimumCalculated"
                    name="repaymentMinimumCalculated"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Repayment Minimum Calculated"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="repaymentActualAmountDue"
                    name="repaymentActualAmountDue"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Repayment Actual Amount Due"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="overpayment"
                    name="overpayment"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Overpayment"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <Box width="120px" p="8px">
                  <Field
                    id="totalOverdueRepayments"
                    name="totalOverdueRepayments"
                    component={NumberFormatField}
                    fixedDecimalScale={true}
                    decimalScale={2}
                    label="Total Overdue Repayments"
                    placeholder="-"
                    disabled={true}
                  />
                </Box>
                <HorizontalLine />
                <Box width="130px" p="8px">
                  <Field
                    id="dateOfLastUpdate"
                    name="dateOfLastUpdate"
                    component={InputField}
                    label="Date of Last Update"
                    placeholder="-"
                    mask={MaskFormat.DATE}
                    disabled={true}
                  />
                </Box>
              </>
            )}
          </LeftPartWrapper>

          <Flex
            width="420px"
            alignItems="flex-end"
            flexWrap="wrap"
          >
            <Box width="100%" p="8px 8px 0">
              <T3>Account</T3>
            </Box>
            <Box width={[1]} p="8px">
              <Field
                id="productName"
                name="productName"
                component={InputField}
                label="Product Name"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width="105px" p="8px">
              <Field
                id="accountId"
                name="accountId"
                component={InputField}
                label="Account ID"
                placeholder="-"
                disabled={true}
                isNumber={true}
              />
            </Box>

            <Box width="315px" p="8px">
              <Field
                id="accountReference"
                name="accountReference"
                component={InputField}
                label="Account Reference"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <HorizontalLine />

            <Box width="100%" p="8px 8px 0">
              <T3>Customer</T3>
            </Box>
            <Box width={[1]} p="8px">
              <Field
                id="firstName"
                name="firstName"
                component={InputField}
                label="First Name"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width={[1]} p="8px">
              <Field
                id="lastName"
                name="lastName"
                component={InputField}
                label="Last Name"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width={[3 / 4]} p="8px">
              <Field
                id="institutionName"
                name="institutionName"
                component={InputField}
                label="Institution"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width={[1 / 4]} p="8px">
              <Field
                id="customerId"
                name="customerId"
                component={InputField}
                label="Customer ID"
                placeholder="-"
                disabled={true}
                isNumber={true}
              />
            </Box>
            <HorizontalLine />

            <Box width="100%" p="8px 8px 0">
              <T3>Address</T3>
            </Box>
            <Box width="210px" p="8px">
              <Field
                id="addressTown"
                name="addressTown"
                component={InputField}
                label="Town"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width="105px" p="8px">
              <Field
                id="addressCountryCode"
                name="addressCountryCode"
                component={InputField}
                label="Country Code"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width="105px" p="8px">
              <Field
                id="addressPostCode"
                name="addressPostCode"
                component={InputField}
                label="Post Code"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width={[1]} p="8px">
              <Field
                id="addressLine1"
                name="addressLine1"
                component={InputField}
                label="Line 1"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width={[1]} p="8px">
              <Field
                id="addressLine2"
                name="addressLine2"
                component={InputField}
                label="Line 2"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width={[1]} p="8px">
              <Field
                id="addressLine3"
                name="addressLine3"
                component={InputField}
                label="Line 3"
                placeholder="-"
                disabled={true}
              />
            </Box>
            <Box width={[1]} p="8px">
              <Field
                id="addressLine4"
                name="addressLine4"
                component={InputField}
                label="Line 4"
                placeholder="-"
                disabled={true}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};

export default reduxForm<{}, IStatementForm>({
  form: FormName.STATEMENT,
  destroyOnUnmount: true,
  enableReinitialize: true,
})(StatementForm);
