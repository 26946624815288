import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { handleSendNotification, IStoreState } from 'store';

export const withErrorHandler = async (
  fn: () => Promise<any>,
  dispatch?: ThunkDispatch<IStoreState, {}, Action>,
  returnReject: boolean = false
) => {
  try {
    return await fn();
  } catch (e: any) {
    if (dispatch) {
      handleSendNotification(e, true)(dispatch);
    }

    if (returnReject) {
      return Promise.reject(e);
    }
  }
};
